import luckysheetConfigsetting from './luckysheetConfigsetting';
import menuButton from './menuButton';
import conditionformat from './conditionformat';
import server from './server';
import {luckysheetupdateCell,setCenterInputPosition} from './updateCell';
import { keycode } from './constant';
import { 
    luckysheetMoveHighlightCell, 
    luckysheetMoveHighlightCell2, 
    luckysheetMoveHighlightRange, 
    luckysheetMoveHighlightRange2 
} from './sheetMove';
import { selectHightlightShow, selectIsOverlap } from './select';
import selection from './selection';
import searchReplace from './searchReplace';
import controlHistory from './controlHistory';
import imageCtrl from './imageCtrl';

import { 
    getByteLen,
    getNowDateTime,
    luckysheetactiveCell,
} from '../utils/util';
import { getSheetIndex } from '../methods/get';
import { hasPartMC, isEditMode } from '../global/validate';
import { luckysheetRangeLast } from '../global/cursorPos';
import formula from '../global/formula';
import cleargridelement from '../global/cleargridelement';
import tooltip from '../global/tooltip';
import locale from '../locale/locale';
import {enterKeyControll} from './inlineString';
import currentSheet from "./common/currentSheet"; // 引入当前实例相关js
import multiInstanceStore from '../store/multiInstance';

let luckysheet_shiftkeydown = false;

function formulaMoveEvent(dir, ctrlKey, shiftKey, event, instance){
    const containerId = instance.getInstanceId();
    let Store = instance.getStore();
    let currentInstance = $(`#${containerId}`);
    if (currentInstance.find("#luckysheet-formula-search-c").is(":visible") && (dir=="up" || dir=="down") ) {
        let $obj;
        if(dir=="down"){
            $obj = currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item-active").next();
            if ($obj.length == 0) {
                $obj = currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item").first();
            }
        }
        else if(dir=="up"){
            $obj = currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item-active").prev();
            if ($obj.length == 0) {
                $obj = currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item").last();
            }
        }
        

        currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item").removeClass("luckysheet-formula-search-item-active");
        $obj.addClass("luckysheet-formula-search-item-active");

        event.preventDefault();
    }
    else{
        if(currentInstance.find("#luckysheet-formula-functionrange-select").is(":visible")){
            if(ctrlKey && shiftKey){
                instance.sheetMove.luckysheetMoveHighlightRange2(dir, "rangeOfFormula");
            }
            else if(ctrlKey){
                instance.sheetMove.luckysheetMoveHighlightCell2(dir, "rangeOfFormula");
            }
            else if(shiftKey){
                let dir_n = dir, step = 1;
                if(dir == 'up'){
                    dir_n = 'down';
                    step = -1;
                }
                if(dir == 'left'){
                    dir_n = 'right';
                    step = -1;
                }

                instance.sheetMove.luckysheetMoveHighlightRange(dir_n, step, "rangeOfFormula");
            }
            else{
                let dir_n = dir, step = 1;
                if(dir == 'up'){
                    dir_n = 'down';
                    step = -1;
                }
                if(dir == 'left'){
                    dir_n = 'right';
                    step = -1;
                }

                instance.sheetMove.luckysheetMoveHighlightCell(dir_n, step, "rangeOfFormula");
            }   
            event.preventDefault();
        }
        else if(instance.formula.israngeseleciton()){
            let anchor = $(window.getSelection().anchorNode);
            // console.log(anchor.parent().next().text());
            if(anchor.parent().next().text() == null || anchor.parent().next().text() == ""){
                let vText = currentInstance.find(`#luckysheet-input-box #luckysheet-input-box-index`).text();
                let range = instance.formula.getcellrange(vText);

                if(range == null){
                    range = instance.formula.getcellrange(currentInstance.find("#luckysheet-input-box-index").text());
                }

                let r1 = range["row"][0], r2 = range["row"][1];
                let c1 = range["column"][0], c2 = range["column"][1];

                let row = Store.visibledatarow[r2], 
                    row_pre = r1 - 1 == -1 ? 0 : Store.visibledatarow[r1 - 1];
                let col = Store.visibledatacolumn[c2], 
                    col_pre = c1 - 1 == -1 ? 0 : Store.visibledatacolumn[c1 - 1];

                instance.formula.func_selectedrange = {
                    "left": col_pre,
                    "width": col - col_pre - 1,
                    "top": row_pre,
                    "height": row - row_pre - 1,
                    "left_move": col_pre,
                    "width_move": col - col_pre - 1,
                    "top_move": row_pre,
                    "height_move": row - row_pre - 1,
                    "row": [r1, r2],
                    "column": [c1, c2],
                    "row_focus": r1,
                    "column_focus": c1
                };

                instance.formula.rangeSetValue({ "row": [r1, r2], "column": [c1, c2] });

                instance.formula.rangestart = true;
                instance.formula.rangedrag_column_start = false;
                instance.formula.rangedrag_row_start = false;
                
                if(ctrlKey && shiftKey){
                    instance.sheetMove.luckysheetMoveHighlightRange2(dir, "rangeOfFormula");
                }
                else if(ctrlKey){
                    instance.sheetMove.luckysheetMoveHighlightCell2(dir, "rangeOfFormula");
                }
                else if(shiftKey){
                    let dir_n = dir, step = 1;
                    if(dir == 'up'){
                        dir_n = 'down';
                        step = -1;
                    }
                    if(dir == 'left'){
                        dir_n = 'right';
                        step = -1;
                    }
                    
                    instance.sheetMove.luckysheetMoveHighlightRange(dir_n, step, "rangeOfFormula");
                }
                else{
                    let dir_n = dir, step = 1;
                    if(dir == 'up'){
                        dir_n = 'down';
                        step = -1;
                    }
                    if(dir == 'left'){
                        dir_n = 'right';
                        step = -1;
                    }
                    
                    instance.sheetMove.luckysheetMoveHighlightCell(dir_n, step, "rangeOfFormula");
                } 

                event.preventDefault();
            }

        }
        else if(!ctrlKey && !shiftKey){
            let anchor = $(window.getSelection().anchorNode);
            let anchorOffset = window.getSelection().anchorOffset;

            if(dir == 'up'){
                if(anchor.parent().is("span") && anchor.parent().next().length == 0 && anchorOffset > 0){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("down", -1, "rangeOfSelect");

                    event.preventDefault();
                }
                else if(anchor.is(`#${containerId} #luckysheet-rich-text-editor`) && anchor.context.childElementCount == anchorOffset){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("down", -1, "rangeOfSelect");

                    event.preventDefault();
                }
                else if(anchor.parent().is(`#${containerId} #luckysheet-rich-text-editor`) && anchor.context.length == anchorOffset){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("down", -1, "rangeOfSelect");

                    event.preventDefault();
                }
            }
            else if(dir == 'down'){
                if(anchor.parent().is("span") && anchor.parent().next().length == 0 && anchorOffset > 0){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("down", 1, "rangeOfSelect");

                    event.preventDefault();
                }
                else if(anchor.is(`#${containerId} #luckysheet-rich-text-editor`) && anchor.context.childElementCount == anchorOffset){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("down", 1, "rangeOfSelect");

                    event.preventDefault();
                }
                else if(anchor.parent().is(`#${containerId} #luckysheet-rich-text-editor`) && anchor.context.length == anchorOffset){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("down", 1, "rangeOfSelect");

                    event.preventDefault();
                }
            }
            else if(dir == 'left'){
                if(anchor.parent().is("span") && anchor.parent().prev().length == 0 && anchorOffset == 0){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("right", -1, "rangeOfSelect");

                    event.preventDefault();
                }
                else if(anchor.is(`#${containerId} #luckysheet-rich-text-editor`) && anchorOffset == 1){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("right", -1, "rangeOfSelect");

                    event.preventDefault();
                }
                else if(anchor.parent().is(`#${containerId} #luckysheet-rich-text-editor`) && anchorOffset == 0){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("right", -1, "rangeOfSelect");

                    event.preventDefault();
                }
                else{
                    instance.formula.rangeHightlightselected(currentInstance.find("#luckysheet-rich-text-editor"));
                }
            }
            else if(dir == 'right'){
                if(anchor.parent().is("span") && anchor.parent().next().length == 0 && anchorOffset > 0){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("right", 1, "rangeOfSelect");

                    event.preventDefault();
                }
                else if(anchor.is(`#${containerId} #luckysheet-rich-text-editor`) && anchor.context.childElementCount == anchorOffset){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("right", 1, "rangeOfSelect");

                    event.preventDefault();
                }
                else if(anchor.parent().is(`#${containerId} #luckysheet-rich-text-editor`) && anchor.context.length == anchorOffset){
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    instance.sheetMove.luckysheetMoveHighlightCell("right", 1, "rangeOfSelect");

                    event.preventDefault();
                }
                else{
                    instance.formula.rangeHightlightselected(currentInstance.find("#luckysheet-rich-text-editor"));
                }
            }
        }
    }
}

/**
 * 键盘操作
 */
export function keyboardInitial(){
    const _this = this;
    const instance = _this.instance;
    const _locale = instance.locale();
    const locale_drag = _locale.drag;
    const containerId = instance.getInstanceId();
    const currentInstance = $("#"+containerId);
    let Store = instance.getStore();
    const luckysheetConfigsetting = instance.luckysheetConfigsetting;
    
    //单元格编辑输入
    currentInstance.find("#luckysheet-input-box").click(function () {
        instance.formula.rangeHightlightselected(currentInstance.find("#luckysheet-rich-text-editor"));
    })
    currentInstance.mousedown(function() {
        $(this).trigger(`custom.${containerId}-keydown`);
    })
    currentInstance.on(`custom.${containerId}-keydown`, function (e) {
        $(document).off(`keydown`).on(`keydown`, function (event) {
            let ctrlKey = event.ctrlKey;
            let altKey = event.altKey;
            let shiftKey = event.shiftKey;
            let kcode = event.keyCode;

            if (currentInstance.find(`#luckysheet-modal-dialog-mask`).is(":visible") || $(event.target).hasClass("luckysheet-mousedown-cancel") || $(event.target).hasClass("sp-input") || (parseInt(currentInstance.find("#luckysheet-input-box").css("top")) > 0 && $(event.target).closest(`#${containerId} .luckysheet-input-box`).length > 0 && kcode != keycode.ENTER && kcode != keycode.TAB && kcode != keycode.UP && kcode != keycode.DOWN && kcode != keycode.LEFT && kcode != keycode.RIGHT)) {
                let anchor = $(window.getSelection().anchorNode);
                
                if(anchor.parent().is(`#${containerId} #luckysheet-helpbox-cell`) || anchor.is(`#${containerId} #luckysheet-helpbox-cell`)){
                    if(kcode == keycode.ENTER){
                        let helpboxValue = currentInstance.find("#luckysheet-helpbox-cell").text();

                        if(instance.formula.iscelldata(helpboxValue)){
                            let cellrange = instance.formula.getcellrange(helpboxValue);
                            
                            Store.luckysheet_select_save = [{ "row": cellrange["row"], "column": cellrange["column"], "row_focus": cellrange["row"][0], "column_focus": cellrange["column"][0] }];
                            instance.select.selectHightlightShow();
                            
                            currentInstance.find("#luckysheet-helpbox-cell").blur();

                            let scrollLeft = currentInstance.find("#luckysheet-cell-main").scrollLeft(), 
                                scrollTop = currentInstance.find("#luckysheet-cell-main").scrollTop();
                            let winH = currentInstance.find("#luckysheet-cell-main").height(), 
                                winW = currentInstance.find("#luckysheet-cell-main").width();

                            let row = Store.visibledatarow[cellrange["row"][1]], 
                                row_pre = cellrange["row"][0] - 1 == -1 ? 0 : Store.visibledatarow[cellrange["row"][0] - 1];
                            let col = Store.visibledatacolumn[cellrange["column"][1]], 
                                col_pre = cellrange["column"][0] - 1 == -1 ? 0 : Store.visibledatacolumn[cellrange["column"][0] - 1];

                            if (col - scrollLeft - winW + 20 > 0) {
                                currentInstance.find("#luckysheet-scrollbar-x").scrollLeft(col - winW + 20);
                            }
                            else if (col_pre - scrollLeft - 20 < 0) {
                                currentInstance.find("#luckysheet-scrollbar-x").scrollLeft(col_pre - 20);
                            }

                            if (row - scrollTop - winH + 20 > 0) {
                                currentInstance.find("#luckysheet-scrollbar-y").scrollTop(row - winH + 20);
                            }
                            else if (row_pre - scrollTop - 20 < 0) {
                                currentInstance.find("#luckysheet-scrollbar-y").scrollTop(row_pre - 20);
                            }
                        }
                    }
                }
                
                return;
            }

            if (currentInstance.find(`#luckysheet-modal-dialog-mask`).is(":visible") || $(event.target).hasClass("luckysheet-mousedown-cancel") || $(event.target).hasClass("formulaInputFocus")) {
                return;
            }
            
            let $inputbox = currentInstance.find("#luckysheet-input-box");
            
            if((altKey || event.metaKey) && kcode == keycode.ENTER && parseInt($inputbox.css("top")) > 0){
                let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];
                let row_index = last["row_focus"], col_index = last["column_focus"];
                instance.inlineString.enterKeyControll(Store.flowdata[row_index][col_index]);
                event.preventDefault();
            }
            else if (kcode == keycode.ENTER && parseInt($inputbox.css("top")) > 0) {
                if (currentInstance.find("#luckysheet-formula-search-c").is(":visible") && instance.formula.searchFunctionCell != null) {
                    instance.formula.searchFunctionEnter(currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item-active"));
                }
                else {
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    Store.luckysheet_select_save = [{ 
                        "row": [Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[0]], 
                        "column": [Store.luckysheetCellUpdate[1], Store.luckysheetCellUpdate[1]], 
                        "row_focus": Store.luckysheetCellUpdate[0], 
                        "column_focus": Store.luckysheetCellUpdate[1] 
                    }];
                    instance.sheetMove.luckysheetMoveHighlightCell("down", 1, "rangeOfSelect");
                }

                //若有参数弹出框，隐藏
                if($(`#${containerId} #luckysheet-search-formula-parm`).is(":visible")){
                    $(`#${containerId} #luckysheet-search-formula-parm`).hide();
                }
                //若有参数选取范围弹出框，隐藏
                if($(`#${containerId} #luckysheet-search-formula-parm-select`).is(":visible")){
                    $(`#${containerId} #luckysheet-search-formula-parm-select`).hide();
                }
                event.preventDefault();
            }
            else if (kcode == keycode.TAB) {
                if (parseInt($inputbox.css("top")) > 0) {
                    return;
                }

                instance.sheetMove.luckysheetMoveHighlightCell("right", 1, "rangeOfSelect");
                event.preventDefault();
            }
            else if(kcode == keycode.F2){
                if (parseInt($inputbox.css("top")) > 0) {
                    return;
                }

                let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];

                let row_index = last["row_focus"], col_index = last["column_focus"];

                instance.updateCell.luckysheetupdateCell(row_index, col_index, Store.flowdata);
                event.preventDefault();
            }
            else if (kcode == keycode.F4 && parseInt($inputbox.css("top")) > 0) {
                instance.formula.setfreezonFuc(event);
                event.preventDefault();
            }
            else if (kcode == keycode.ESC && parseInt($inputbox.css("top")) > 0) {
                instance.formula.dontupdate();
                instance.sheetMove.luckysheetMoveHighlightCell("down", 0, "rangeOfSelect");
                event.preventDefault();
            }
            else if (kcode == keycode.ENTER) {
                if($(event.target).hasClass("formulaInputFocus") || currentInstance.find("#luckysheet-conditionformat-dialog").is(":visible")){
                    return;
                }
                else if (String.fromCharCode(kcode) != null && currentInstance.find("#luckysheet-cell-selected").is(":visible")) {
                    let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];

                    let row_index = last["row_focus"], col_index = last["column_focus"];

                    instance.updateCell.luckysheetupdateCell(row_index, col_index, Store.flowdata);
                    event.preventDefault();
                }
            }
            else {
                if (ctrlKey || event.metaKey) {
                    if (shiftKey) {
                        if (!luckysheet_shiftkeydown) {
                            Store.luckysheet_shiftpositon = $.extend(true, {}, Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1]);
                            Store.luckysheet_shiftkeydown = true;
                        }

                        //Ctrl + shift + 方向键  调整选区
                        if (kcode == keycode.UP) {
                            if (parseInt($inputbox.css("top")) > 0 || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                                return;
                            }

                            instance.sheetMove.luckysheetMoveHighlightRange2("up", "rangeOfSelect");
                        }
                        else if (kcode == keycode.DOWN) {
                            if (parseInt($inputbox.css("top")) > 0 || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                                return;
                            }

                            instance.sheetMove.luckysheetMoveHighlightRange2("down", "rangeOfSelect");
                        }
                        else if (kcode == keycode.LEFT) {
                            if (parseInt($inputbox.css("top")) > 0 || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                                return;
                            }

                            instance.sheetMove.luckysheetMoveHighlightRange2("left", "rangeOfSelect");
                        }
                        else if (kcode == keycode.RIGHT) {
                            if (parseInt($inputbox.css("top")) > 0 || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                                return;
                            }

                            instance.sheetMove.luckysheetMoveHighlightRange2("right", "rangeOfSelect");
                        }
                        else if (kcode == 186 || kcode == 222) {
                            let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];
                            let row_index = last["row_focus"], 
                                col_index = last["column_focus"];
                            instance.updateCell.luckysheetupdateCell(row_index, col_index, Store.flowdata, true);

                            let value = getNowDateTime(2);
                            currentInstance.find("#luckysheet-rich-text-editor").html(value);
                            instance.cursorPos.luckysheetRangeLast(currentInstance.find("#luckysheet-rich-text-editor")[0]);
                            instance.formula.functionInputHanddler(currentInstance.find("#luckysheet-functionbox-cell"), currentInstance.find("#luckysheet-rich-text-editor"), kcode);
                        }
                    }
                    else if (kcode == 66) {//Ctrl + B  加粗
                        $(`#${containerId} #luckysheet-icon-bold`).click();
                    }
                    else if (kcode == 67) {//Ctrl + C  复制
                        if(instance.imageCtrl.currentImgId){
                            instance.imageCtrl.copyImgItem(event);
                            return;
                        }

                        //复制时存在格式刷状态，取消格式刷
                        if(instance.menuButton.luckysheetPaintModelOn){
                            instance.menuButton.cancelPaintModel();
                        }
                        
                        if(Store.luckysheet_select_save.length == 0){
                            return;
                        }

                        //复制范围内包含部分合并单元格，提示
                        if(Store.config["merge"] != null){
                            let has_PartMC = false;

                            for(let s = 0; s < Store.luckysheet_select_save.length; s++){
                                let r1 = Store.luckysheet_select_save[s].row[0], 
                                    r2 = Store.luckysheet_select_save[s].row[1];
                                let c1 = Store.luckysheet_select_save[s].column[0], 
                                    c2 = Store.luckysheet_select_save[s].column[1];

                                has_PartMC = instance.validate.hasPartMC(Store.config, r1, r2, c1, c2);

                                if(has_PartMC){
                                    break;
                                }
                            }

                            if(has_PartMC){
                                if(instance.validate.isEditMode()){
                                    alert(locale_drag.noMerge);
                                }
                                else{
                                    instance.tooltip.info(locale_drag.noMerge, ""); 
                                }
                                return;    
                            }
                        }

                        //多重选区 有条件格式时 提示
                        let cdformat = Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].luckysheet_conditionformat_save;
                        if(Store.luckysheet_select_save.length > 1 && cdformat != null && cdformat.length > 0){
                            let hasCF = false;

                            let cf_compute = instance.conditionformat.getComputeMap();

                            label:
                            for(let s = 0; s < Store.luckysheet_select_save.length; s++){
                                if(hasCF){
                                    break;
                                }
                                
                                let r1 = Store.luckysheet_select_save[s].row[0], 
                                    r2 = Store.luckysheet_select_save[s].row[1];
                                let c1 = Store.luckysheet_select_save[s].column[0], 
                                    c2 = Store.luckysheet_select_save[s].column[1];

                                for(let r = r1; r <= r2; r++){
                                    for(let c = c1; c <= c2; c++){
                                        if(instance.conditionformat.checksCF(r, c, cf_compute) != null){
                                            hasCF = true;
                                            continue label;
                                        }
                                    }
                                }
                            }

                            if(hasCF){
                                if(instance.validate.isEditMode()){
                                    alert(locale_drag.noMulti);
                                }
                                else{
                                    instance.tooltip.info(locale_drag.noMulti, "");
                                }
                                return;
                            }
                        }

                        //多重选区 行不一样且列不一样时 提示
                        if(Store.luckysheet_select_save.length > 1){ 
                            let isSameRow = true, 
                                str_r = Store.luckysheet_select_save[0].row[0], 
                                end_r = Store.luckysheet_select_save[0].row[1];
                            let isSameCol = true, 
                                str_c = Store.luckysheet_select_save[0].column[0], 
                                end_c = Store.luckysheet_select_save[0].column[1];
                            
                            for(let s = 1; s < Store.luckysheet_select_save.length; s++){
                                if(Store.luckysheet_select_save[s].row[0] != str_r || Store.luckysheet_select_save[s].row[1] != end_r){
                                    isSameRow = false;
                                }
                                if(Store.luckysheet_select_save[s].column[0] != str_c || Store.luckysheet_select_save[s].column[1] != end_c){
                                    isSameCol = false;
                                }
                            }

                            if((!isSameRow && !isSameCol) || instance.select.selectIsOverlap()){
                                if(instance.validate.isEditMode()){
                                    alert(locale_drag.noMulti);
                                }
                                else{
                                    instance.tooltip.info(locale_drag.noMulti, ""); 
                                }
                                return;
                            }    
                        }

                        instance.selection.copy(event);

                        Store.luckysheet_paste_iscut = false;
                        instance.util.luckysheetactiveCell();

                        event.stopPropagation();
                        return;
                    }
                    else if (kcode == 70) {//Ctrl + F  查找
                        instance.searchReplace.createDialog(0, containerId);
                        instance.searchReplace.init(containerId);

                        currentInstance.find(`#luckysheet-search-replace #searchInput input`).focus();
                    }
                    else if (kcode == 72) {//Ctrl + H  替换
                        instance.searchReplace.createDialog(1, containerId);
                        instance.searchReplace.init(containerId);

                        currentInstance.find(`#luckysheet-search-replace} #searchInput input`).focus();
                    }
                    else if (kcode == 73) {//Ctrl + I  斜体
                        $(`#${containerId} #luckysheet-icon-italic`).click();
                    }
                    else if (kcode == 86) {//Ctrl + V  粘贴
                        if (instance.validate.isEditMode() || Store.allowEdit === false){//此模式下禁用粘贴
                            return;
                        }

                        if($(event.target).hasClass("formulaInputFocus")){
                            return;
                        }

                        if(Store.luckysheet_select_save.length > 1){
                            if(instance.validate.isEditMode()){
                                alert(locale_drag.noPaste);
                            }
                            else{
                                instance.tooltip.info(locale_drag.noPaste, "");
                            }
                            return;
                        }

                        instance.selection.isPasteAction = true;
                        instance.util.luckysheetactiveCell();

                        event.stopPropagation();
                        return;
                    }
                    else if (kcode == 88) {//Ctrl + X  剪切
                        //复制时存在格式刷状态，取消格式刷
                        if(instance.menuButton.luckysheetPaintModelOn){
                            instance.menuButton.cancelPaintModel();
                        }

                        if(Store.luckysheet_select_save.length == 0){
                            return;
                        }

                        //复制范围内包含部分合并单元格，提示
                        if(Store.config["merge"] != null){
                            let has_PartMC = false;

                            for(let s = 0; s < Store.luckysheet_select_save.length; s++){
                                let r1 = Store.luckysheet_select_save[s].row[0], 
                                    r2 = Store.luckysheet_select_save[s].row[1];
                                let c1 = Store.luckysheet_select_save[s].column[0], 
                                    c2 = Store.luckysheet_select_save[s].column[1];

                                has_PartMC = instance.validate.hasPartMC(Store.config, r1, r2, c1, c2);

                                if(has_PartMC){
                                    break;
                                }
                            }

                            if(has_PartMC){
                                if(luckysheetConfigsetting.editMode){
                                    alert(_locale_drag.noMerge);
                                }
                                else{
                                    instance.tooltip.info(_locale_drag.noMerge, ""); 
                                }
                                return;    
                            }
                        }

                        //多重选区时 提示
                        if(Store.luckysheet_select_save.length > 1){
                            if(instance.validate.isEditMode()){
                                alert(locale_drag.noMulti);
                            }
                            else{
                                instance.tooltip.info(locale_drag.noMulti, ""); 
                            }
                            return;
                        }

                        instance.selection.copy(event);

                        Store.luckysheet_paste_iscut = true;
                        instance.util.luckysheetactiveCell();
                        
                        event.stopPropagation();
                        return;
                    }
                    else if (kcode == 90) {//Ctrl + Z  撤销
                        instance.controlHistory.redo(event);
                        instance.util.luckysheetactiveCell();
                        event.stopPropagation();
                        return;
                    }
                    else if (kcode == 89) {//Ctrl + Y  重做
                        instance.controlHistory.undo(event);
                        instance.util.luckysheetactiveCell();
                        event.stopPropagation();
                        return;
                    }
                    else if (kcode == keycode.UP) {//Ctrl + up  调整单元格
                        if (parseInt($inputbox.css("top")) > 0 || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                            return;
                        }

                        instance.sheetMove.luckysheetMoveHighlightCell2("up", "rangeOfSelect");
                    }
                    else if (kcode == keycode.DOWN) {//Ctrl + down  调整单元格
                        if (parseInt($inputbox.css("top")) > 0 || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                            return;
                        }

                        instance.sheetMove.luckysheetMoveHighlightCell2("down", "rangeOfSelect");
                    }
                    else if (kcode == keycode.LEFT) {//Ctrl + top  调整单元格
                        if (parseInt($inputbox.css("top")) > 0 || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                            return;
                        }

                        instance.sheetMove.luckysheetMoveHighlightCell2("left", "rangeOfSelect");
                    }
                    else if (kcode == keycode.RIGHT) {//Ctrl + right  调整单元格
                        if (parseInt($inputbox.css("top")) > 0 || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                            return;
                        }

                        instance.sheetMove.luckysheetMoveHighlightCell2("right", "rangeOfSelect");
                    }
                    else if (kcode == 186) {//Ctrl + ; 填充系统日期
                        let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];
                        let row_index = last["row_focus"], 
                            col_index = last["column_focus"];
                        instance.updateCell.luckysheetupdateCell(row_index, col_index, Store.flowdata, true);

                        let value = getNowDateTime(1);
                        currentInstance.find("#luckysheet-rich-text-editor").html(value);
                        instance.cursorPos.luckysheetRangeLast(currentInstance.find("#luckysheet-rich-text-editor")[0]);
                        instance.formula.functionInputHanddler(currentInstance.find("#luckysheet-functionbox-cell"), currentInstance.find("#luckysheet-rich-text-editor"), kcode);
                    }
                    else if (kcode == 222) {//Ctrl + ' 填充系统时间
                        let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];
                        let row_index = last["row_focus"], 
                            col_index = last["column_focus"];
                        instance.updateCell.luckysheetupdateCell(row_index, col_index, Store.flowdata, true);

                        let value = getNowDateTime(2);
                        currentInstance.find("#luckysheet-rich-text-editor").html(value);
                        instance.cursorPos.luckysheetRangeLast(currentInstance.find("#luckysheet-rich-text-editor")[0]);
                        instance.formula.functionInputHanddler(currentInstance.find("#luckysheet-functionbox-cell"), currentInstance.find("#luckysheet-rich-text-editor"), kcode);
                    }
                    else if (String.fromCharCode(kcode).toLocaleUpperCase() == "A") {//Ctrl + A  全选
                        // currentInstance.find("#luckysheet-left-top").trigger("mousedown");
                        // $(document).trigger("mouseup");
                        currentInstance.find("#luckysheet-left-top").click()
                    }

                    event.preventDefault();
                    return;
                }
                else if (shiftKey && (kcode == keycode.UP || kcode == keycode.DOWN || kcode == keycode.LEFT || kcode == keycode.RIGHT || (altKey && (kcode == 53 || kcode == 101)))) {
                    if (parseInt($inputbox.css("top")) > 0 || $(event.target).hasClass("formulaInputFocus")) {
                        return;
                    }

                    if (!luckysheet_shiftkeydown) {
                        Store.luckysheet_shiftpositon = $.extend(true, {}, Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1]);
                        Store.luckysheet_shiftkeydown = true;
                    }

                    //shift + 方向键 调整选区
                    if (kcode == keycode.UP) {
                        if(currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")){
                            return;
                        } 

                        instance.sheetMove.luckysheetMoveHighlightRange("down", -1, "rangeOfSelect");
                    }
                    else if (kcode == keycode.DOWN) {
                        if(currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")){
                            return;
                        }

                        instance.sheetMove.luckysheetMoveHighlightRange("down", 1, "rangeOfSelect");
                    }
                    else if (kcode == keycode.LEFT) {
                        if(currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")){
                            return;
                        }

                        instance.sheetMove.luckysheetMoveHighlightRange("right", -1, "rangeOfSelect");
                    }
                    else if (kcode == keycode.RIGHT) {
                        if(currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")){
                            return;
                        }
                        
                        instance.sheetMove.luckysheetMoveHighlightRange("right", 1, "rangeOfSelect");
                    }
                    else if (altKey && (kcode == 53 || kcode == 101)) {
                        //Alt + Shift + 5（删除线）
                        currentInstance.find("#luckysheet-icon-strikethrough").click();
                    }
                    // else if (altKey && (kcode == 54 || kcode == 102)) {
                    //     //Alt + Shift + 6（删除线）
                    //     currentInstance.find("#luckysheet-icon-underline").click();
                    // }

                    event.preventDefault();
                }
                else if (kcode == keycode.ESC) {
                    if(instance.menuButton.luckysheetPaintModelOn){
                        instance.menuButton.cancelPaintModel();
                    }
                    else{
                        instance.initSheet.cleargridelement(event);
                        event.preventDefault(); 
                    }

                    instance.select.selectHightlightShow();
                }
                else if (kcode == keycode.DELETE || kcode == keycode.BACKSPACE) {
                    if(instance.imageCtrl.currentImgId){
                        instance.imageCtrl.removeImgItem();
                    }
                    else{
                        if(currentInstance)
                        currentInstance.find("#luckysheet-delete-text").click();
                    }

                    event.preventDefault();
                }
                else if(kcode == 8 && instance.imageCtrl.currentImgId){
                    instance.imageCtrl.removeImgItem();
                    event.preventDefault();
                }
                else if (kcode == keycode.UP) {
                    if (parseInt($inputbox.css("top")) > 0 || Store.luckysheet_cell_selected_move || Store.luckysheet_cell_selected_extend || $(event.target).hasClass("formulaInputFocus") || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                        return;
                    }

                    instance.sheetMove.luckysheetMoveHighlightCell("down", -1, "rangeOfSelect");
                    event.preventDefault();
                }
                else if (kcode == keycode.DOWN) {
                    if (parseInt($inputbox.css("top")) > 0 || Store.luckysheet_cell_selected_move || Store.luckysheet_cell_selected_extend || $(event.target).hasClass("formulaInputFocus") || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                        return;
                    }

                    instance.sheetMove.luckysheetMoveHighlightCell("down", 1, "rangeOfSelect");
                    event.preventDefault();
                }
                else if (kcode == keycode.LEFT) {
                    if (parseInt($inputbox.css("top")) > 0 || Store.luckysheet_cell_selected_move || Store.luckysheet_cell_selected_extend || $(event.target).hasClass("formulaInputFocus") || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                        return;
                    }

                    instance.sheetMove.luckysheetMoveHighlightCell("right", -1, "rangeOfSelect");
                    event.preventDefault();
                }
                else if (kcode == keycode.RIGHT) {
                    if (parseInt($inputbox.css("top")) > 0 || Store.luckysheet_cell_selected_move || Store.luckysheet_cell_selected_extend || $(event.target).hasClass("formulaInputFocus") || currentInstance.find("#luckysheet-singleRange-dialog").is(":visible") || currentInstance.find("#luckysheet-multiRange-dialog").is(":visible")) {
                        return;
                    }

                    instance.sheetMove.luckysheetMoveHighlightCell("right", 1, "rangeOfSelect");
                    event.preventDefault();
                }
                else if (!((kcode >= 112 && kcode <= 123) || kcode <= 46 || kcode == 144 || kcode == 108 || event.ctrlKey || event.altKey || (event.shiftKey && (kcode == 37 || kcode == 38 || kcode == 39 || kcode == 40))) || kcode == 8 || kcode == 32 || kcode == 46 || kcode == 0 || (event.ctrlKey && kcode == 86)) {
                    if (String.fromCharCode(kcode) != null && currentInstance.find("#luckysheet-cell-selected").is(":visible") && (kcode != keycode.CAPSLOCK && kcode != keycode.WIN && kcode != 18)) {
                        let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];

                        let row_index = last["row_focus"], col_index = last["column_focus"];

                        instance.updateCell.luckysheetupdateCell(row_index, col_index, Store.flowdata, true);
                        if(kcode == 8){
                            currentInstance.find("#luckysheet-rich-text-editor").html("<br/>");
                        }
                        instance.formula.functionInputHanddler(currentInstance.find("#luckysheet-functionbox-cell"), currentInstance.find("#luckysheet-rich-text-editor"), kcode);
                    }
                }
            }
            
            instance.util.luckysheetactiveCell();

            event.stopPropagation();
        });
    });

    //单元格编辑 keydown (公式 上下左右键移动)
    $(`#${Store.container} .luckysheet`).add(`#${containerId} #luckysheet-input-box`).keydown(function (event) {
        if (currentInstance.find(`#luckysheet-modal-dialog-mask`).is(":visible") || $(event.target).hasClass("luckysheet-mousedown-cancel") || $(event.target).hasClass("formulaInputFocus")) {
            return;
        }

        let ctrlKey = event.ctrlKey;
        let altKey = event.altKey;
        let shiftKey = event.shiftKey;
        let kcode = event.keyCode;

        let $inputbox = currentInstance.find("#luckysheet-input-box");
        if (kcode == keycode.ESC && parseInt(currentInstance.find("#luckysheet-input-box").css("top")) > 0) {
            instance.formula.dontupdate();
            instance.sheetMove.luckysheetMoveHighlightCell("down", 0, "rangeOfSelect");
            event.preventDefault();
        }
        else if (kcode == keycode.ENTER && parseInt($inputbox.css("top")) > 0) {
            if (currentInstance.find("#luckysheet-formula-search-c").is(":visible") && instance.formula.searchFunctionCell != null) {
                instance.formula.searchFunctionEnter(currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item-active"));
                event.preventDefault();
            }
        }
        else if(kcode == keycode.TAB && parseInt($inputbox.css("top")) > 0){
            if (currentInstance.find("#luckysheet-formula-search-c").is(":visible") && instance.formula.searchFunctionCell != null) {
                instance.formula.searchFunctionEnter(currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item-active"));
            }
            else{
                instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                instance.sheetMove.luckysheetMoveHighlightCell("right", 1, "rangeOfSelect");
            }

            event.preventDefault();
        }
        else if (kcode == keycode.F4 && parseInt($inputbox.css("top")) > 0) {
            instance.formula.setfreezonFuc(event);
            event.preventDefault();
        }
        else if (kcode == keycode.UP && parseInt($inputbox.css("top")) > 0) {
            formulaMoveEvent("up", ctrlKey, shiftKey,event, instance);
        }
        else if (kcode == keycode.DOWN && parseInt($inputbox.css("top")) > 0) {
            formulaMoveEvent("down", ctrlKey, shiftKey,event, instance);
        }
        else if (kcode == keycode.LEFT && parseInt($inputbox.css("top")) > 0) {
            formulaMoveEvent("left", ctrlKey, shiftKey,event, instance);
        }
        else if (kcode == keycode.RIGHT && parseInt($inputbox.css("top")) > 0) {
            formulaMoveEvent("right", ctrlKey, shiftKey,event, instance);
        }
        else if (!((kcode >= 112 && kcode <= 123) || kcode <= 46 || kcode == 144 || kcode == 108 || event.ctrlKey || event.altKey || (event.shiftKey && (kcode == 37 || kcode == 38 || kcode == 39 || kcode == 40 || kcode == keycode.WIN || kcode == keycode.WIN_R || kcode == keycode.MENU))) || kcode == 8 || kcode == 32 || kcode == 46 || (event.ctrlKey && kcode == 86)) {
            // if(event.target.id!="luckysheet-input-box" && event.target.id!="luckysheet-rich-text-editor"){
                instance.formula.functionInputHanddler(currentInstance.find("#luckysheet-functionbox-cell"), currentInstance.find("#luckysheet-rich-text-editor"), kcode);
                instance.updateCell.setCenterInputPosition(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1], Store.flowdata);
            // }
            
        }
    }).keyup(function (e) {
        let kcode = e.keyCode;
        
        if (!e.shiftKey && kcode == 16) {
            Store.luckysheet_shiftkeydown = false;
            Store.luckysheet_shiftpositon = null;
        }

        //输入框中文输入后 shift 和 空格 处理
        if(parseInt(currentInstance.find("#luckysheet-input-box").css("top")) > 0 && (kcode == 13 || kcode == 16 || kcode == 32)){
            // if(event.target.id=="luckysheet-input-box" || event.target.id=="luckysheet-rich-text-editor"){
            //     instance.formula.functionInputHanddler(currentInstance.find("#luckysheet-functionbox-cell"), currentInstance.find("#luckysheet-rich-text-editor"), kcode);
            // }
        }

        e.preventDefault();
    });

    //top workBook rename
    currentInstance.find(`#luckysheet_info_detail_input`).val(instance.server.title).css("width", getByteLen(instance.server.title) * 10).keydown(function(){
        let ctrlKey = event.ctrlKey;
        let altKey = event.altKey;
        let shiftKey = event.shiftKey;
        let kcode = event.keyCode;
        let $t = $(this);
        if(kcode == keycode.ENTER){
            $t.blur().change();
        }
    }).bind('input propertychange', function() { 
        let $t = $(this);
        let inputlen = getByteLen($t.val())*10;
        let updatelen = currentInstance.find(`#luckysheet_info_detail_update`).outerWidth();
        let savelen = currentInstance.find(`#luckysheet_info_detail_save`).outerWidth();
        let userlen = currentInstance.find(`#luckysheet_info_detail_user`).parent().outerWidth()+60;
        let containerlen = $("#" + Store.container).outerWidth();
        let otherlen = 100;

        let minuslen = containerlen- savelen - updatelen - userlen - otherlen;
        if(inputlen > minuslen){
            currentInstance.find(`#luckysheet_info_detail_input`).css("width", minuslen);
        }
        else{
            currentInstance.find(`#luckysheet_info_detail_input`).css("width", inputlen);
        }
    }).change(function(){
        instance.server.saveParam("na", null, $(this).val());
    });


    // 右击菜单的input输入框 敲击Enter一样生效
    $("#" + Store.container).add("input.luckysheet-mousedown-cancel").keydown(function (event) {

        const element =  event.target.closest('.luckysheet-cols-menuitem');
        if (typeof(element) != 'undefined' && element != null && event.keyCode === 13){
            $(element).trigger('click');
        }

    })
}