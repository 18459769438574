/**
 * 创建html相关js
 * 创建时间： 2023.9.13
 * 创建人： wangxiaofei
 */
import { replaceHtml } from "../../utils/util";
import { modelHTML } from "../constant";
import locale from "../../locale/locale";
import currentSheet from "./currentSheet";

const createHtmlDom = {

  /**
   * 实例下创建弹窗
   * @param {*} id 弹窗id
   * @param {*} content 弹窗内容
   * @param {*} container 实例id
   * @param {*} title 弹窗名称
   * @param {*} confirmCallback 弹窗确定回调函数
   * @param {*} isRemove 是否销毁，不销毁时带入上次填写数据,默认不销毁
   * @param {*} isMask 是否显示遮罩，默认显示
   * @param {*} isConfirm 是否显示确定按钮，默认显示
   * @param {*} customButton 自定义按钮，自定义按钮不提供回调，自定义的按钮放到按钮最前面
   * @param {*} beforePositionFuc 设定弹窗位置前执行其他回调
   * @param {*} minWidth 设定.luckysheet-modal-dialog-content最小宽度
   * @param {*} isPosition 是否进行弹窗定位
   * @returns 
   */
  createDialog(id, content ,container, title, confirmCallback, isRemove = false , isMask =true, isConfirm = true, customButton = '', beforePositionFuc, minWidth, isPosition = true){
    const instance = this.instance;
    const _locale = instance.locale();
    const buttonText = _locale.button;
    let buttonHtml = `<button id="${id}-close" class="btn btn-default">${buttonText.cancel}</button>`;
    // 判断是否显示遮罩
    if(isMask) {
      $(`#${container} #luckysheet-modal-dialog-mask`).show();
    } else{
      $(`#${container} #luckysheet-modal-dialog-mask`).hide();
    }
    // 判断是否销毁弹窗
    if(!isRemove){
      if($("#"+ container +" #" + id).length > 0){
        $("#"+ container +" #" + id).show();
        return;
      }
    }else{
      $("#"+ container +" #" + id).remove();
    }
    // 创建弹窗
    buttonHtml = isConfirm? `<button id="${id}-confirm" class="btn btn-primary">${buttonText.confirm}</button>` + buttonHtml : buttonHtml;
    buttonHtml = customButton + buttonHtml;
    $("#" + container).append(replaceHtml(modelHTML, { 
      "id": id, 
      "addclass": id, 
      "title": title, 
      "content": content, 
      "botton":  buttonHtml, 
      "style": "z-index:100003",
      "container": container
    }));
    // 设定弹窗位置前执行方法
    if(beforePositionFuc){
      beforePositionFuc(instance);
    }
    // 设定弹窗位置
    // beforePositionFuc方法有时操作dom，增加延时处理弹窗位置
    setTimeout(() => {
      if (isPosition) {
        this.setDialogPosition(container, id, minWidth);
      }
    }, 0);
    // 弹窗右上角关闭按钮
    $(`#${container} #${id} .luckysheet-modal-dialog-title-close`).click(function() {
    // $(document).off(`click.${id}-title-close-${container}`).on(`click.${id}-title-close-${container}`, `#${container} #${id} .luckysheet-modal-dialog-title-close`, function() {
      $(`#${container} #luckysheet-modal-dialog-mask`).hide();
      $(`#${container} #${id}`).hide();
    });
    // 弹窗取消按钮
    $(`#${container} #${id}-close`).click(function() {
    // $(document).off(`click.${id}-close-${container}`).on(`click.${id}-close-${container}`, `#${container} #${id}-close`, function() {
      $(`#${container} #luckysheet-modal-dialog-mask`).hide();
      $(`#${container} #${id}`).hide();
    });
    // 弹窗确定
    // 多个实例打开弹窗时，有时点击确定按钮没反应 --modify by liluyao 2023/9/14
    $(`#${container} #${id}-confirm`).click(function() {
    // $(document).off(`click.${id}-confirm-${container}`).on(`click.${id}-confirm-${container}`, `#${container} #${id}-confirm`, function() {
      confirmCallback(this, instance);
    });
  },

  /**
   * 关闭实例下遮罩
   * @param {*} container 实例id
   */
  markClose(container){
    $(`#${container} #luckysheet-modal-dialog-mask`).hide();
  },

  /**
   * 设置弹窗位置
   */
  setDialogPosition(container, id, minWidth) {
    // 设定弹窗位置
    if (!minWidth) {
      minWidth = 350;
    }
    let $t = $("#" + container + " #" + id).find(".luckysheet-modal-dialog-content").css("min-width", minWidth).end(), 
      myh = $t.outerHeight(), 
      myw = $t.outerWidth();
    let scrollLeft = $(document).scrollLeft(), 
      scrollTop = $(document).scrollTop();
    let instanceH = $("#" + container).height(),
      instanceW = $("#" + container).width();
    $("#" + container + " #" + id ).css({
      "left":  (instanceW - myw) / 2 ,
      "top":  (instanceH - myh) / 2 < 0 ? 0 : (instanceH - myh) / 2 ,
      "max-height": instanceH - 60,
      "max-width": instanceW - 84,
    }).show();
  },

  /**
     * 打开选择单元格范围弹窗
     * @param {*} id 当前弹窗id
     * 增加传参rangeValue，修改时间 0828 wangxiaofei
     * @param {*} rangeValue 返回类型，默认是单元格范围，range-value为选中单元格值
     */
  createRangeSelectorDialog: function (id , rangeValue) {
    const instance = this.instance;
    const containerId = instance.getInstanceId();
    const currentInstance = $("#"+containerId);
    const dialogId = id || "luckysheet-range-selector-dialog";
    currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
    // 涉及到传值，只能通过销毁在创建的方式处理
    currentInstance.find("#" + dialogId).remove();

    let content = `<div id="${dialogId}" class="dialog-range-selector">
        <span>单元格范围</span>
        <div class="dialog-range-selector-input">
            <input type="text" class="range-val" readonly="">
            <i class="cancel-icon"></i>
            <i class="ok-icon"></i>
        </div>
    </div>`
    $(content).appendTo(currentInstance);
    // 选区回填
    $(`#${containerId}`).mouseup(() => {
        let range = rangeValue && rangeValue === 'range-value' ? instance.api.getRangeArray('oneDimensional').join(",") : instance.api.getRangeAxis();
        $(`#${containerId} #${dialogId} .range-val`).val(range);
    })
  },
};
export default createHtmlDom;