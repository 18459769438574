import pivotTable from "./pivotTable";
import luckysheetPostil from "./postil";
import imageCtrl from "./imageCtrl";
import menuButton from "./menuButton";
import server from "./server";
import method from "../global/method";
import { selectHightlightShow, luckysheet_count_show, selectHelpboxFill } from "./select";
import { getObjType, showrightclickmenu, luckysheetContainerFocus, luckysheetfontformat, $$ } from "../utils/util";
import { getSheetIndex, getRangetxt } from "../methods/get";
import { rowLocation, rowLocationByIndex, colLocation, colLocationByIndex, mouseposition } from "../global/location";
import { isRealNull, isRealNum, hasPartMC, isEditMode, checkIsAllowEdit } from "../global/validate";
import { countfunc } from "../global/count";
import formula from "../global/formula";
import { luckysheetextendtable, luckysheetdeletetable, luckysheetDeleteCell } from "../global/extend";
import { jfrefreshgrid, jfrefreshgridall, jfrefreshgrid_rhcw } from "../global/refresh";
import { getcellvalue } from "../global/getdata";
import tooltip from "../global/tooltip";
import editor from "../global/editor";
import locale from "../locale/locale";
import { getMeasureText, getCellTextInfo } from "../global/getRowlen";
import { luckysheet_searcharray } from "../controllers/sheetSearch";
import { isInlineStringCell } from "./inlineString";
import {
    checkProtectionLockedRangeList,
    checkProtectionAllSelected,
    checkProtectionAuthorityNormal,
} from "./protection";
import luckysheetConfigsetting from "./luckysheetConfigsetting";
import { hideRightButton, initWidthAndHeightRow, initWidthAndHeightColumn} from "./init/initHandler"; // 右侧菜单显隐及控制行高等方法
import currentSheet from "./common/currentSheet";

export function rowColumnOperationInitial() {
    const _this = this;
    const instance = _this.instance;
    const currentId = instance.getInstanceId();
	let Store = instance.getStore();
    // 获取当前实例 wangxiaofei 2023914
    const currentInstance = $("#"+currentId);
    const luckysheetConfigsetting = instance.luckysheetConfigsetting;
    //表格行标题 mouse事件
    currentInstance.find("#luckysheet-rows-h")
        .mousedown(function(event) {
            if (!instance.protection.checkProtectionAllSelected(Store.currentSheetIndex)) {
                return;
            }
            //有批注在编辑时
            instance.postil.removeActivePs();

            //图片 active/cropping
            if (
                currentInstance.find("#luckysheet-modal-dialog-activeImage").is(":visible") ||
                currentInstance.find("#luckysheet-modal-dialog-cropping").is(":visible")
            ) {
                instance.imageCtrl.cancelActiveImgItem(currentId);
            }

            let mouse = instance.location.mouseposition(event.pageX, event.pageY);
            let y = mouse[1] + currentInstance.find("#luckysheet-rows-h").scrollTop();

            let row_location = instance.location.rowLocation(y),
                row = row_location[1],
                row_pre = row_location[0],
                row_index = row_location[2];
            let col_index = Store.visibledatacolumn.length - 1,
                col = Store.visibledatacolumn[col_index],
                col_pre = 0;

                currentInstance.find("#luckysheet-rightclick-menu").hide();
            currentInstance.find(`#luckysheet-sheet-list, #luckysheet-rightclick-sheet-menu`).hide();

            //mousedown是右键
            if (event.which == "3") {
                let isright = false;

                for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                    let obj_s = Store.luckysheet_select_save[s];

                    if (
                        obj_s["row"] != null &&
                        row_index >= obj_s["row"][0] &&
                        row_index <= obj_s["row"][1] &&
                        obj_s["column"][0] == 0 &&
                        obj_s["column"][1] == Store.flowdata[0].length - 1
                    ) {
                        isright = true;
                        break;
                    }
                }

                if (isright) {
                    return;
                }
            }

            let top = row_pre,
                height = row - row_pre - 1;
            let rowseleted = [row_index, row_index];

            Store.luckysheet_scroll_status = true;

            //公式相关
            let $input = currentInstance.find("#luckysheet-input-box");
            if (parseInt($input.css("top")) > 0) {
                if (
                    instance.formula.rangestart ||
                    instance.formula.rangedrag_column_start ||
                    instance.formula.rangedrag_row_start ||
                    instance.formula.israngeseleciton() ||
                    $(`#${currentId} #luckysheet-ifFormulaGenerator-multiRange-dialog`).is(":visible")
                ) {
                    //公式选区
                    let changeparam = instance.menuButton.mergeMoveMain(
                        [0, col_index],
                        rowseleted,
                        { row_focus: row_index, column_focus: 0 },
                        top,
                        height,
                        col_pre,
                        col,
                    );
                    if (changeparam != null) {
                        //columnseleted = changeparam[0];
                        rowseleted = changeparam[1];
                        top = changeparam[2];
                        height = changeparam[3];
                        //left = changeparam[4];
                        //width = changeparam[5];
                    }

                    if (event.shiftKey) {
                        let last = instance.formula.func_selectedrange;

                        let top = 0,
                            height = 0,
                            rowseleted = [];
                        if (last.top > row_pre) {
                            top = row_pre;
                            height = last.top + last.height - row_pre;

                            if (last.row[1] > last.row_focus) {
                                last.row[1] = last.row_focus;
                            }

                            rowseleted = [row_index, last.row[1]];
                        } else if (last.top == row_pre) {
                            top = row_pre;
                            height = last.top + last.height - row_pre;
                            rowseleted = [row_index, last.row[0]];
                        } else {
                            top = last.top;
                            height = row - last.top - 1;

                            if (last.row[0] < last.row_focus) {
                                last.row[0] = last.row_focus;
                            }

                            rowseleted = [last.row[0], row_index];
                        }

                        let changeparam = instance.menuButton.mergeMoveMain(
                            [0, col_index],
                            rowseleted,
                            { row_focus: row_index, column_focus: 0 },
                            top,
                            height,
                            col_pre,
                            col,
                        );
                        if (changeparam != null) {
                            // columnseleted = changeparam[0];
                            rowseleted = changeparam[1];
                            top = changeparam[2];
                            height = changeparam[3];
                            // left = changeparam[4];
                            // width = changeparam[5];
                        }

                        last["row"] = rowseleted;

                        last["top_move"] = top;
                        last["height_move"] = height;

                        instance.formula.func_selectedrange = last;
                    } else if (
                        event.ctrlKey &&
                        currentInstance.find("#luckysheet-rich-text-editor")
                            .find("span")
                            .last()
                            .text() != ","
                    ) {
                        //按住ctrl 选择选区时  先处理上一个选区
                        let vText = currentInstance.find("#luckysheet-rich-text-editor").text() + ",";
                        if (vText.length > 0 && vText.substr(0, 1) == "=") {
                            vText = instance.formula.functionHTMLGenerate(vText);

                            if (window.getSelection) {
                                // all browsers, except IE before version 9
                                let currSelection = window.getSelection();
                                instance.formula.functionRangeIndex = [
                                    $(currSelection.anchorNode)
                                        .parent()
                                        .index(),
                                    currSelection.anchorOffset,
                                ];
                            } else {
                                // Internet Explorer before version 9
                                let textRange = document.selection.createRange();
                                instance.formula.functionRangeIndex = textRange;
                            }

                            currentInstance.find("#luckysheet-rich-text-editor").html(vText);

                            instance.formula.canceFunctionrangeSelected();
                            instance.formula.createRangeHightlight();
                        }

                        instance.formula.rangestart = false;
                        instance.formula.rangedrag_column_start = false;
                        instance.formula.rangedrag_row_start = false;

                        currentInstance.find("#luckysheet-functionbox-cell").html(vText);
                        instance.formula.rangeHightlightselected(currentInstance.find("#luckysheet-rich-text-editor"));

                        //再进行 选区的选择
                        instance.formula.israngeseleciton();
                        instance.formula.func_selectedrange = {
                            left: instance.location.colLocationByIndex(0)[0],
                            width: instance.location.colLocationByIndex(0)[1] - instance.location.colLocationByIndex(0)[0] - 1,
                            top: top,
                            height: height,
                            left_move: col_pre,
                            width_move: col - col_pre - 1,
                            top_move: top,
                            height_move: height,
                            row: rowseleted,
                            column: [0, col_index],
                            row_focus: row_index,
                            column_focus: 0,
                        };
                    } else {
                        instance.formula.func_selectedrange = {
                            left: instance.location.colLocationByIndex(0)[0],
                            width: instance.location.colLocationByIndex(0)[1] - instance.location.colLocationByIndex(0)[0] - 1,
                            top: top,
                            height: height,
                            left_move: col_pre,
                            width_move: col - col_pre - 1,
                            top_move: top,
                            height_move: height,
                            row: rowseleted,
                            column: [0, col_index],
                            row_focus: row_index,
                            column_focus: 0,
                        };
                    }

                    if (
                        instance.formula.rangestart ||
                        instance.formula.rangedrag_column_start ||
                        instance.formula.rangedrag_row_start ||
                        instance.formula.israngeseleciton()
                    ) {
                        instance.formula.rangeSetValue({ row: rowseleted, column: [null, null] });
                    } else if ($(`#${currentId} #luckysheet-ifFormulaGenerator-multiRange-dialog`).is(":visible")) {
                        //if公式生成器
                        let range = instance.get.getRangetxt(
                            Store.currentSheetIndex,
                            { row: rowseleted, column: [0, col_index] },
                            Store.currentSheetIndex,
                        );
                        $(`#${currentId} #luckysheet-ifFormulaGenerator-multiRange-dialog input`).val(range);
                    }

                    instance.formula.rangedrag_row_start = true;
                    instance.formula.rangestart = false;
                    instance.formula.rangedrag_column_start = false;

                    currentInstance.find("#luckysheet-formula-functionrange-select")
                        .css({
                            left: col_pre,
                            width: col - col_pre - 1,
                            top: top,
                            height: height,
                        })
                        .show();
                    currentInstance.find("#luckysheet-formula-help-c").hide();

                    instance.select.luckysheet_count_show(col_pre, top, col - col_pre - 1, height, rowseleted, [0, col_index]);

                    setTimeout(function() {
                        let currSelection = window.getSelection();
                        let anchorOffset = currSelection.anchorNode;

                        let $editor;
                        if (
                            $(`#${currentId} #luckysheet-search-formula-parm`).is(":visible") ||
                            $(`#${currentId} #luckysheet-search-formula-parm-select`).is(":visible")
                        ) {
                            $editor = currentInstance.find("#luckysheet-rich-text-editor");
                            instance.formula.rangechangeindex = instance.formula.data_parm_index;
                        } else {
                            $editor = $(anchorOffset).closest("div");
                        }

                        let $span = $instance.editor.find("span[rangeindex='" + instance.formula.rangechangeindex + "']");

                        instance.formula.setCaretPosition($span.get(0), 0, $span.html().length);
                    }, 1);

                    return;
                } else {
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    Store.luckysheet_rows_selected_status = true;
                }
            } else {
                Store.luckysheet_rows_selected_status = true;
            }

            if (Store.luckysheet_rows_selected_status) {
                if (event.shiftKey) {
                    //按住shift点击行索引选取范围
                    let last = $.extend(
                        true,
                        {},
                        Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1],
                    ); //选区最后一个

                    let top = 0,
                        height = 0,
                        rowseleted = [];
                    if (last.top > row_pre) {
                        top = row_pre;
                        height = last.top + last.height - row_pre;

                        if (last.row[1] > last.row_focus) {
                            last.row[1] = last.row_focus;
                        }

                        rowseleted = [row_index, last.row[1]];
                    } else if (last.top == row_pre) {
                        top = row_pre;
                        height = last.top + last.height - row_pre;
                        rowseleted = [row_index, last.row[0]];
                    } else {
                        top = last.top;
                        height = row - last.top - 1;

                        if (last.row[0] < last.row_focus) {
                            last.row[0] = last.row_focus;
                        }

                        rowseleted = [last.row[0], row_index];
                    }

                    last["row"] = rowseleted;

                    last["top_move"] = top;
                    last["height_move"] = height;

                    Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1] = last;
                } else if (event.ctrlKey) {
                    Store.luckysheet_select_save.push({
                        left: instance.location.colLocationByIndex(0)[0],
                        width: instance.location.colLocationByIndex(0)[1] - instance.location.colLocationByIndex(0)[0] - 1,
                        top: top,
                        height: height,
                        left_move: col_pre,
                        width_move: col - col_pre - 1,
                        top_move: top,
                        height_move: height,
                        row: rowseleted,
                        column: [0, col_index],
                        row_focus: row_index,
                        column_focus: 0,
                        row_select: true,
                    });
                } else {
                    Store.luckysheet_select_save.length = 0;
                    Store.luckysheet_select_save.push({
                        left: instance.location.colLocationByIndex(0)[0],
                        width: instance.location.colLocationByIndex(0)[1] - instance.location.colLocationByIndex(0)[0] - 1,
                        top: top,
                        height: height,
                        left_move: col_pre,
                        width_move: col - col_pre - 1,
                        top_move: top,
                        height_move: height,
                        row: rowseleted,
                        column: [0, col_index],
                        row_focus: row_index,
                        column_focus: 0,
                        row_select: true,
                    });
                }

                instance.select.selectHightlightShow();

                //允许编辑后的后台更新时
                instance.server.saveParam("mv", Store.currentSheetIndex, Store.luckysheet_select_save);
            }

            instance.select.selectHelpboxFill();

            setTimeout(function() {
                clearTimeout(Store.countfuncTimeout);
                instance.count.countfunc();
            }, 101);
        })
        .mousemove(function(event) {
            if (
                Store.luckysheet_rows_selected_status ||
                Store.luckysheet_rows_change_size ||
                Store.luckysheet_select_status
            ) {
                currentInstance.find("#luckysheet-rows-h-hover").hide();
                return;
            }

            let mouse = instance.location.mouseposition(event.pageX, event.pageY);
            let y = mouse[1] + currentInstance.find("#luckysheet-rows-h").scrollTop();

            let row_location = instance.location.rowLocation(y),
                row = row_location[1],
                row_pre = row_location[0],
                row_index = row_location[2];

            currentInstance.find("#luckysheet-rows-h-hover").css({ top: row_pre, height: row - row_pre - 1, display: "block" });

            if (y < row - 1 && y >= row - 5) {
                currentInstance.find("#luckysheet-rows-change-size").css({ top: row - 3, opacity: 0 });
            } else {
                currentInstance.find("#luckysheet-rows-change-size").css("opacity", 0);
            }
        })
        .mouseleave(function(event) {
            currentInstance.find("#luckysheet-rows-h-hover").hide();
            currentInstance.find("#luckysheet-rows-change-size").css("opacity", 0);
        })
        .mouseup(function(event) {
            if (event.which == 3) {
                // *如果禁止前台编辑，则中止下一步操作
                if (!instance.validate.checkIsAllowEdit()) {
                    return;
                }
                if (instance.validate.isEditMode()) {
                    //非编辑模式下禁止右键功能框
                    return;
                }
                Store.luckysheetRightHeadClickIs = "row";
                let $menu = $("#"+currentId+" #luckysheet-rightclick-menu");
                hideRightButton(currentId, luckysheetConfigsetting, "row", instance);  
                instance.util.showrightclickmenu($menu, $(this).offset().left + 46 * Store.scale, event.pageY);
                Store.luckysheet_cols_menu_status = true;
                // 列宽默认值
                initWidthAndHeightRow(instance);
            }
        });

    //表格列标题 mouse事件
    currentInstance.find("#luckysheet-cols-h-c")
        .mousedown(function(event) {
            if (!instance.protection.checkProtectionAllSelected(Store.currentSheetIndex)) {
                return;
            }
            //有批注在编辑时
            instance.postil.removeActivePs();

            //图片 active/cropping
            if (
                currentInstance.find("#luckysheet-modal-dialog-activeImage").is(":visible") ||
                currentInstance.find("#luckysheet-modal-dialog-cropping").is(":visible")
            ) {
                instance.imageCtrl.cancelActiveImgItem(currentId);
            }

            let mouse = instance.location.mouseposition(event.pageX, event.pageY);
            let x = mouse[0] + $(this).scrollLeft();

            let row_index = Store.visibledatarow.length - 1,
                row = Store.visibledatarow[row_index],
                row_pre = 0;
            let col_location = instance.location.colLocation(x),
                col = col_location[1],
                col_pre = col_location[0],
                col_index = col_location[2];

            Store.orderbyindex = col_index; //排序全局函数

            currentInstance.find("#luckysheet-rightclick-menu").hide();
            currentInstance.find(`#luckysheet-sheet-list, #luckysheet-rightclick-sheet-menu`).hide();
            currentInstance.find(`#luckysheet-filter-menu, #luckysheet-filter-submenu`).hide();

            //mousedown是右键
            if (event.which == "3") {
                let isright = false;

                for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                    let obj_s = Store.luckysheet_select_save[s];

                    if (
                        obj_s["column"] != null &&
                        col_index >= obj_s["column"][0] &&
                        col_index <= obj_s["column"][1] &&
                        obj_s["row"][0] == 0 &&
                        obj_s["row"][1] == Store.flowdata.length - 1
                    ) {
                        isright = true;
                        break;
                    }
                }

                if (isright) {
                    return;
                }
            }

            let left = col_pre,
                width = col - col_pre - 1;
            let columnseleted = [col_index, col_index];

            Store.luckysheet_scroll_status = true;

            //公式相关
            let $input = currentInstance.find("#luckysheet-input-box");
            if (parseInt($input.css("top")) > 0) {
                if (
                    instance.formula.rangestart ||
                    instance.formula.rangedrag_column_start ||
                    instance.formula.rangedrag_row_start ||
                    instance.formula.israngeseleciton() ||
                    $(`#${currentId} #luckysheet-ifFormulaGenerator-multiRange-dialog`).is(":visible")
                ) {
                    //公式选区
                    let changeparam = instance.menuButton.mergeMoveMain(
                        columnseleted,
                        [0, row_index],
                        { row_focus: 0, column_focus: col_index },
                        row_pre,
                        row,
                        left,
                        width,
                    );
                    if (changeparam != null) {
                        columnseleted = changeparam[0];
                        //rowseleted= changeparam[1];
                        //top = changeparam[2];
                        //height = changeparam[3];
                        left = changeparam[4];
                        width = changeparam[5];
                    }

                    if (event.shiftKey) {
                        let last = instance.formula.func_selectedrange;

                        let left = 0,
                            width = 0,
                            columnseleted = [];
                        if (last.left > col_pre) {
                            left = col_pre;
                            width = last.left + last.width - col_pre;

                            if (last.column[1] > last.column_focus) {
                                last.column[1] = last.column_focus;
                            }

                            columnseleted = [col_index, last.column[1]];
                        } else if (last.left == col_pre) {
                            left = col_pre;
                            width = last.left + last.width - col_pre;
                            columnseleted = [col_index, last.column[0]];
                        } else {
                            left = last.left;
                            width = col - last.left - 1;

                            if (last.column[0] < last.column_focus) {
                                last.column[0] = last.column_focus;
                            }

                            columnseleted = [last.column[0], col_index];
                        }

                        let changeparam = instance.menuButton.mergeMoveMain(
                            columnseleted,
                            [0, row_index],
                            { row_focus: 0, column_focus: col_index },
                            row_pre,
                            row,
                            left,
                            width,
                        );
                        if (changeparam != null) {
                            columnseleted = changeparam[0];
                            //rowseleted= changeparam[1];
                            //top = changeparam[2];
                            //height = changeparam[3];
                            left = changeparam[4];
                            width = changeparam[5];
                        }

                        last["column"] = columnseleted;

                        last["left_move"] = left;
                        last["width_move"] = width;

                        instance.formula.func_selectedrange = last;
                    } else if (
                        event.ctrlKey &&
                        currentInstance.find("#luckysheet-rich-text-editor")
                            .find("span")
                            .last()
                            .text() != ","
                    ) {
                        //按住ctrl 选择选区时  先处理上一个选区
                        let vText = currentInstance.find("#luckysheet-rich-text-editor").text() + ",";
                        if (vText.length > 0 && vText.substr(0, 1) == "=") {
                            vText = instance.formula.functionHTMLGenerate(vText);

                            if (window.getSelection) {
                                // all browsers, except IE before version 9
                                let currSelection = window.getSelection();
                                instance.formula.functionRangeIndex = [
                                    $(currSelection.anchorNode)
                                        .parent()
                                        .index(),
                                    currSelection.anchorOffset,
                                ];
                            } else {
                                // Internet Explorer before version 9
                                let textRange = document.selection.createRange();
                                instance.formula.functionRangeIndex = textRange;
                            }

                            currentInstance.find("#luckysheet-rich-text-editor").html(vText);

                            instance.formula.canceFunctionrangeSelected();
                            instance.formula.createRangeHightlight();
                        }

                        instance.formula.rangestart = false;
                        instance.formula.rangedrag_column_start = false;
                        instance.formula.rangedrag_row_start = false;

                        currentInstance.find("#luckysheet-functionbox-cell").html(vText);
                        instance.formula.rangeHightlightselected(currentInstance.find("#luckysheet-rich-text-editor"));

                        //再进行 选区的选择
                        instance.formula.israngeseleciton();
                        instance.formula.func_selectedrange = {
                            left: left,
                            width: width,
                            top: instance.location.rowLocationByIndex(0)[0],
                            height: instance.location.rowLocationByIndex(0)[1] - instance.location.rowLocationByIndex(0)[0] - 1,
                            left_move: left,
                            width_move: width,
                            top_move: row_pre,
                            height_move: row - row_pre - 1,
                            row: [0, row_index],
                            column: columnseleted,
                            row_focus: 0,
                            column_focus: col_index,
                        };
                    } else {
                        instance.formula.func_selectedrange = {
                            left: left,
                            width: width,
                            top: instance.location.rowLocationByIndex(0)[0],
                            height: instance.location.rowLocationByIndex(0)[1] - instance.location.rowLocationByIndex(0)[0] - 1,
                            left_move: left,
                            width_move: width,
                            top_move: row_pre,
                            height_move: row - row_pre - 1,
                            row: [0, row_index],
                            column: columnseleted,
                            row_focus: 0,
                            column_focus: col_index,
                        };
                    }

                    if (
                        instance.formula.rangestart ||
                        instance.formula.rangedrag_column_start ||
                        instance.formula.rangedrag_row_start ||
                        instance.formula.israngeseleciton()
                    ) {
                        instance.formula.rangeSetValue({ row: [null, null], column: columnseleted });
                    } else if ($(`#${currentId} #luckysheet-ifFormulaGenerator-multiRange-dialog`).is(":visible")) {
                        //if公式生成器
                        let range = instance.get.getRangetxt(
                            Store.currentSheetIndex,
                            { row: [0, row_index], column: columnseleted },
                            Store.currentSheetIndex,
                        );
                        $(`#${currentId} #luckysheet-ifFormulaGenerator-multiRange-dialog input`).val(range);
                    }

                    instance.formula.rangedrag_column_start = true;
                    instance.formula.rangestart = false;
                    instance.formula.rangedrag_row_start = false;

                    currentInstance.find("#luckysheet-formula-functionrange-select")
                        .css({
                            left: left,
                            width: width,
                            top: row_pre,
                            height: row - row_pre - 1,
                        })
                        .show();
                    currentInstance.find("#luckysheet-formula-help-c").hide();

                    instance.select.luckysheet_count_show(left, row_pre, width, row - row_pre - 1, [0, row_index], columnseleted);

                    return;
                } else {
                    instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                    Store.luckysheet_cols_selected_status = true;
                }
            } else {
                Store.luckysheet_cols_selected_status = true;
            }

            if (Store.luckysheet_cols_selected_status) {
                if (event.shiftKey) {
                    //按住shift点击列索引选取范围
                    let last = $.extend(
                        true,
                        {},
                        Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1],
                    ); //选区最后一个

                    let left = 0,
                        width = 0,
                        columnseleted = [];
                    if (last.left > col_pre) {
                        left = col_pre;
                        width = last.left + last.width - col_pre;

                        if (last.column[1] > last.column_focus) {
                            last.column[1] = last.column_focus;
                        }

                        columnseleted = [col_index, last.column[1]];
                    } else if (last.left == col_pre) {
                        left = col_pre;
                        width = last.left + last.width - col_pre;
                        columnseleted = [col_index, last.column[0]];
                    } else {
                        left = last.left;
                        width = col - last.left - 1;

                        if (last.column[0] < last.column_focus) {
                            last.column[0] = last.column_focus;
                        }

                        columnseleted = [last.column[0], col_index];
                    }

                    last["column"] = columnseleted;

                    last["left_move"] = left;
                    last["width_move"] = width;

                    Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1] = last;
                } else if (event.ctrlKey) {
                    //选区添加
                    Store.luckysheet_select_save.push({
                        left: left,
                        width: width,
                        top: instance.location.rowLocationByIndex(0)[0],
                        height: instance.location.rowLocationByIndex(0)[1] - instance.location.rowLocationByIndex(0)[0] - 1,
                        left_move: left,
                        width_move: width,
                        top_move: row_pre,
                        height_move: row - row_pre - 1,
                        row: [0, row_index],
                        column: columnseleted,
                        row_focus: 0,
                        column_focus: col_index,
                        column_select: true,
                    });
                } else {
                    Store.luckysheet_select_save.length = 0;
                    Store.luckysheet_select_save.push({
                        left: left,
                        width: width,
                        top: instance.location.rowLocationByIndex(0)[0],
                        height: instance.location.rowLocationByIndex(0)[1] - instance.location.rowLocationByIndex(0)[0] - 1,
                        left_move: left,
                        width_move: width,
                        top_move: row_pre,
                        height_move: row - row_pre - 1,
                        row: [0, row_index],
                        column: columnseleted,
                        row_focus: 0,
                        column_focus: col_index,
                        column_select: true,
                    });
                }

                instance.select.selectHightlightShow();

                //允许编辑后的后台更新时
                instance.server.saveParam("mv", Store.currentSheetIndex, Store.luckysheet_select_save);
            }

            instance.select.selectHelpboxFill();

            setTimeout(function() {
                clearTimeout(Store.countfuncTimeout);
                instance.count.countfunc();
            }, 101);

            if (Store.luckysheet_cols_menu_status) {
                currentInstance.find("#luckysheet-rightclick-menu").hide();
                currentInstance.find("#luckysheet-cols-h-hover").hide();
                currentInstance.find("#luckysheet-cols-menu-btn").hide();
                Store.luckysheet_cols_menu_status = false;
            }
            event.stopPropagation();
        })
        .mousemove(function(event) {
            if (Store.luckysheet_cols_selected_status || Store.luckysheet_select_status) {
                currentInstance.find("#luckysheet-cols-h-hover").hide();
                currentInstance.find("#luckysheet-cols-menu-btn").hide();
                return;
            }

            if (Store.luckysheet_cols_menu_status || Store.luckysheet_cols_change_size) {
                return;
            }

            let mouse = instance.location.mouseposition(event.pageX, event.pageY);
            let x = mouse[0] + currentInstance.find("#luckysheet-cols-h-c").scrollLeft();

            let col_location = instance.location.colLocation(x),
                col = col_location[1],
                col_pre = col_location[0],
                col_index = col_location[2];

            currentInstance.find("#luckysheet-cols-h-hover").css({ left: col_pre, width: col - col_pre - 1, display: "block" });
            // 只读模式下首列单元格鼠标滑过时出现的小按钮隐藏
            if (!Store.isReadOnly) {
                currentInstance.find("#luckysheet-cols-menu-btn").css({ left: col - 19, display: "block" });
            } else {
                currentInstance.find("#luckysheet-cols-menu-btn").hide();
            }

            currentInstance.find("#luckysheet-cols-change-size").css({ left: col - 5 });
            if (x < col && x >= col - 5) {
                currentInstance.find("#luckysheet-cols-change-size").css({ opacity: 0 });
                currentInstance.find("#luckysheet-cols-menu-btn").hide();
            } else {
                currentInstance.find("#luckysheet-change-size-line").hide();
                currentInstance.find("#luckysheet-cols-change-size").css("opacity", 0);
            }
        })
        .mouseleave(function(event) {
            if (Store.luckysheet_cols_menu_status || Store.luckysheet_cols_change_size) {
                return;
            }

            currentInstance.find("#luckysheet-cols-h-hover").hide();
            currentInstance.find("#luckysheet-cols-menu-btn").hide();
            currentInstance.find("#luckysheet-cols-change-size").css("opacity", 0);
        })
        .mouseup(function(event) {
            if (event.which == 3) {
                // *如果禁止前台编辑，则中止下一步操作
                if (!instance.validate.checkIsAllowEdit()) {
                    return;
                }
                if (instance.validate.isEditMode()) {
                    //非编辑模式下禁止右键功能框
                    return;
                }

                Store.luckysheetRightHeadClickIs = "column";
                hideRightButton(currentId, luckysheetConfigsetting, "column", instance);
                instance.util.showrightclickmenu($("#"+currentId+" #luckysheet-rightclick-menu"), event.pageX, $(this).offset().top + 18 * Store.scale);
                Store.luckysheet_cols_menu_status = true;
                // 列宽默认值
                initWidthAndHeightColumn(instance);
            }
        });

    //表格行标题 改变行高按钮
    currentInstance.find("#luckysheet-rows-change-size").mousedown(function(event) {
        // *如果禁止前台编辑，则中止下一步操作
        if (!instance.validate.checkIsAllowEdit()) {
            return;
        }
        //有批注在编辑时
        instance.postil.removeActivePs();

        //图片 active/cropping
        if (
            currentInstance.find("#luckysheet-modal-dialog-activeImage").is(":visible") ||
            currentInstance.find("#luckysheet-modal-dialog-cropping").is(":visible")
        ) {
            instance.imageCtrl.cancelActiveImgItem(currentId);
        }

        currentInstance.find("#luckysheet-input-box").hide();
        currentInstance.find("#luckysheet-rows-change-size").css({ opacity: 1 });

        let mouse = instance.location.mouseposition(event.pageX, event.pageY);
        let y = mouse[1] + currentInstance.find("#luckysheet-rows-h").scrollTop();

        let scrollLeft = currentInstance.find("#luckysheet-cell-main").scrollLeft();
        let winW = currentInstance.find("#luckysheet-cell-main").width();

        let row_location = instance.location.rowLocation(y),
            row = row_location[1],
            row_pre = row_location[0],
            row_index = row_location[2];

        Store.luckysheet_rows_change_size = true;
        Store.luckysheet_scroll_status = true;
        currentInstance.find("#luckysheet-change-size-line").css({
            height: "1px",
            "border-width": "0 0px 1px 0",
            top: row - 3,
            left: 0,
            width: scrollLeft + winW,
            display: "block",
            cursor: "ns-resize",
        });
        currentInstance.find(`#luckysheet-sheettable, #luckysheet-rows-h, #luckysheet-rows-h canvas`).css("cursor", "ns-resize");
        Store.luckysheet_rows_change_size_start = [row_pre, row_index];
        currentInstance.find("#luckysheet-rightclick-menu").hide();
        currentInstance.find("#luckysheet-rows-h-hover").hide();
        currentInstance.find("#luckysheet-cols-menu-btn").hide();
        event.stopPropagation();
    });

    //表格列标题 改变列宽按钮
    currentInstance.find("#luckysheet-cols-change-size")
        .mousedown(function(event) {
            // *如果禁止前台编辑，则中止下一步操作
            if (!instance.validate.checkIsAllowEdit()) {
                return;
            }
            //有批注在编辑时
            instance.postil.removeActivePs();

            //图片 active/cropping
            if (
                currentInstance.find("#luckysheet-modal-dialog-activeImage").is(":visible") ||
                currentInstance.find("#luckysheet-modal-dialog-cropping").is(":visible")
            ) {
                instance.imageCtrl.cancelActiveImgItem(currentId);
            }

            currentInstance.find("#luckysheet-input-box").hide();
            currentInstance.find("#luckysheet-cols-change-size").css({ opacity: 1 });

            let mouse = instance.location.mouseposition(event.pageX, event.pageY);
            let scrollLeft = currentInstance.find("#luckysheet-cols-h-c").scrollLeft();
            let scrollTop = currentInstance.find("#luckysheet-cell-main").scrollTop();
            let winH = currentInstance.find("#luckysheet-cell-main").height();
            let x = mouse[0] + scrollLeft;

            let row_index = Store.visibledatarow.length - 1,
                row = Store.visibledatarow[row_index],
                row_pre = 0;
            let col_location = instance.location.colLocation(x),
                col = col_location[1],
                col_pre = col_location[0],
                col_index = col_location[2];

            Store.luckysheet_cols_change_size = true;
            Store.luckysheet_scroll_status = true;
            currentInstance.find("#luckysheet-change-size-line").css({
                height: winH + scrollTop,
                "border-width": "0 1px 0 0",
                top: 0,
                left: col - 3,
                width: "1px",
                display: "block",
                cursor: "ew-resize",
            });
            currentInstance.find(
                `#luckysheet-sheettable, #luckysheet-cols-h-c, .luckysheet-cols-h-cells, .luckysheet-cols-h-cells canvas`
            ).css("cursor", "ew-resize");
            Store.luckysheet_cols_change_size_start = [col_pre, col_index];
            currentInstance.find("#luckysheet-rightclick-menu").hide();
            currentInstance.find("#luckysheet-cols-h-hover").hide();
            currentInstance.find("#luckysheet-cols-menu-btn").hide();
            Store.luckysheet_cols_dbclick_times = 0;
            event.stopPropagation();
        })
        .dblclick(function() {
            luckysheetcolsdbclick(instance);
        });

    // 列标题的下拉箭头
    currentInstance.find("#luckysheet-cols-menu-btn").click(function(event) {
        // *如果禁止前台编辑，则中止下一步操作
        if (!instance.validate.checkIsAllowEdit()) {
            instance.tooltip.info("", instance.locale().pivotTable.errorNotAllowEdit);
            return;
        }
        let $menu = $("#"+currentId+" #luckysheet-rightclick-menu");
        let offset = $(this).offset();
        Store.luckysheetRightHeadClickIs = "column";
        hideRightButton(currentId, luckysheetConfigsetting, "column", instance);
        const cellRightClickConfig = luckysheetConfigsetting.cellRightClickConfig;
        if (!cellRightClickConfig.sort) {
            $$("#"+currentId+" #luckysheet-cols-rows-shift .luckysheet-menuseparator").style.display = "none";
        }

        instance.util.showrightclickmenu($menu, offset.left, offset.top + 18 * Store.scale);
        Store.luckysheet_cols_menu_status = true;
    });

    //向左增加列，向上增加行
    // currentInstance.find(`#luckysheet-add-lefttop, #luckysheet-add-lefttop_t`).click(function (event) {
    currentInstance.find("#luckysheet-top-left-add-selected").click(function(event) {
        // Click input element, don't comfirm
        if (event.target.nodeName === "INPUT") {
            return;
        }

        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        const _locale = instance.locale();
        const locale_drag = _locale.drag;
        const locale_info = _locale.info;

        if (Store.luckysheet_select_save.length > 1) {
            if (instance.validate.isEditMode()) {
                alert(locale_drag.noMulti);
            } else {
                instance.tooltip.info(locale_drag.noMulti, "");
            }

            return;
        }

        let $t = $(this),
            value = $t.find("input").val();
        if (!isRealNum(value)) {
            if (instance.validate.isEditMode()) {
                alert(locale_info.tipInputNumber);
            } else {
                instance.tooltip.info(locale_info.tipInputNumber, "");
            }

            return;
        }

        value = parseInt(value);

        if (value < 1 || value > 100) {
            if (instance.validate.isEditMode()) {
                alert(locale_info.tipInputNumberLimit);
            } else {
                instance.tooltip.info(locale_info.tipInputNumberLimit, "");
            }
            return;
        }

        let st_index = Store.luckysheet_select_save[0][Store.luckysheetRightHeadClickIs][0];

		if(!instance.method.createHookFunction("rowInsertBefore",  st_index, value, "lefttop", Store.luckysheetRightHeadClickIs)){ 
			return; 
		}
		instance.extend.luckysheetextendtable(Store.luckysheetRightHeadClickIs, st_index, value, "lefttop");

    });

    // When you right-click a cell, a row is inserted before the row by default
    currentInstance.find("#luckysheetColsRowsHandleAdd_row").click(function(event) {
        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        if (Store.allowEdit === false) {
            return;
        }

        let st_index = Store.luckysheet_select_save[0].row[0];

		if(!instance.method.createHookFunction("rowInsertBefore",  st_index, 1, "lefttop", Store.luckysheetRightHeadClickIs)){ 
			return; 
		}
        instance.extend.luckysheetextendtable('row', st_index, 1, "lefttop");


    })
    // 右键插入列点击事件
    currentInstance.find("#luckysheetColsRowsHandleAdd_column").click(function (event) {

        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        if (Store.allowEdit === false) {
            return;
        }

        let st_index = Store.luckysheet_select_save[0].column[0];
        instance.extend.luckysheetextendtable("column", st_index, 1, "lefttop");
    });

    // custom right-click a cell buttton click
    currentInstance.find(".luckysheetColsRowsHandleAdd_custom").click(function(clickEvent) {
        currentInstance.find("#luckysheet-rightclick-menu").hide();
        const cellRightClickConfig = luckysheetConfigsetting.cellRightClickConfig;
        const rowIndex = Store.luckysheet_select_save[0].row[0];
        const columnIndex = Store.luckysheet_select_save[0].column[0];
        if (cellRightClickConfig.customs[Number(clickEvent.currentTarget.dataset.index)]) {
            try {
                cellRightClickConfig.customs[Number(clickEvent.currentTarget.dataset.index)].onClick(
                    clickEvent,
                    event,
                    { rowIndex, columnIndex },
                );
            } catch (e) {
                console.error("custom click error", e);
            }
        }
    });
    // Add the row up, and click the text area to trigger the confirmation instead of clicking the confirmation button to enhance the experience
    // currentInstance.find("#luckysheet-addTopRows).click(function (event) {
    // currentInstance.find("#luckysheetColsRowsHandleAdd_sub .luckysheet-cols-menuitem:first-child").click(function (event) {

    //     // Click input element, don't comfirm
    //     if(event.target.nodeName === 'INPUT'){
    //         return;
    //     }

    //     currentInstance.find("#luckysheet-rightclick-menu").hide();
    //     instance.util.luckysheetContainerFocus();

    //     const _locale = instance.locale();
    //     const locale_drag = _locale.drag;
    //     const locale_info = _locale.info;

    //     if(Store.luckysheet_select_save.length > 1){
    //         if(instance.validate.isEditMode()){
    //             alert(locale_drag.noMulti);
    //         }
    //         else{
    //             instance.tooltip.info(locale_drag.noMulti, "");
    //         }

    //         return;
    //     }

    //     let $t = $(this), value = $t.find("input").val();
    //     if (!isRealNum(value)) {
    //         if(instance.validate.isEditMode()){
    //             alert(locale_info.tipInputNumber);
    //         }
    //         else{
    //             instance.tooltip.info(locale_info.tipInputNumber, "");
    //         }

    //         return;
    //     }

    //     value = parseInt(value);

    //     if (value < 1 || value > 100) {
    //         if(instance.validate.isEditMode()){
    //             alert(locale_info.tipInputNumberLimit);
    //         }
    //         else{
    //             instance.tooltip.info(locale_info.tipInputNumberLimit, "");
    //         }
    //         return;
    //     }

    //     let st_index = Store.luckysheet_select_save[0].row[0];
    //     instance.extend.luckysheetextendtable('row', st_index, value, "lefttop");

    //     currentInstance.find("#luckysheetColsRowsHandleAdd_sub").hide();
    // })

    // // input输入时阻止冒泡，禁止父级元素的确认事件触发
    // $("input.luckysheet-mousedown-cancel").click(function(event) {
    //     event.stopPropagation;
    // })

    // currentInstance.find("#luckysheet-addLeftCols”).click(function (event) {
    // currentInstance.find("#luckysheetColsRowsHandleAdd_sub .luckysheet-cols-menuitem:nth-child(3)").click(function (event) {

    //     // Click input element, don't comfirm
    //     if(event.target.nodeName === 'INPUT'){
    //         return;
    //     }

    //     currentInstance.find("#luckysheet-rightclick-menu").hide();
    //     instance.util.luckysheetContainerFocus();

    //     const _locale = instance.locale();
    //     const locale_drag = _locale.drag;
    //     const locale_info = _locale.info;

    //     if(Store.luckysheet_select_save.length > 1){
    //         if(instance.validate.isEditMode()){
    //             alert(locale_drag.noMulti);
    //         }
    //         else{
    //             instance.tooltip.info(locale_drag.noMulti, "");
    //         }

    //         return;
    //     }

    //     let $t = $(this), value = $t.find("input").val();
    //     if (!isRealNum(value)) {
    //         if(instance.validate.isEditMode()){
    //             alert(locale_info.tipInputNumber);
    //         }
    //         else{
    //             instance.tooltip.info(locale_info.tipInputNumber, "");
    //         }

    //         return;
    //     }

    //     value = parseInt(value);

    //     if (value < 1 || value > 100) {
    //         if(instance.validate.isEditMode()){
    //             alert(locale_info.tipInputNumberLimit);
    //         }
    //         else{
    //             instance.tooltip.info(locale_info.tipInputNumberLimit, "");
    //         }
    //         return;
    //     }

    //     let st_index = Store.luckysheet_select_save[0].column[0];
    //     instance.extend.luckysheetextendtable('column', st_index, value, "lefttop");

    //     currentInstance.find("#luckysheetColsRowsHandleAdd_sub").hide();

    // })

    //向右增加列，向下增加行
    // currentInstance.find(`#luckysheet-add-rightbottom, #luckysheet-add-rightbottom_t`).click(function (event) {
    $("#"+currentId+" #luckysheet-bottom-right-add-selected").click(function(event) {
        // Click input element, don't comfirm
        if (event.target.nodeName === "INPUT") {
            return;
        }

        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        const _locale = instance.locale();
        const locale_drag = _locale.drag;
        const locale_info = _locale.info;

        if (Store.luckysheet_select_save.length > 1) {
            if (instance.validate.isEditMode()) {
                alert(locale_drag.noMulti);
            } else {
                instance.tooltip.info(locale_drag.noMulti, "");
            }

            return;
        }

        let $t = $(this),
            value = $t.find("input").val();
        if (!isRealNum(value)) {
            if (instance.validate.isEditMode()) {
                alert(locale_info.tipInputNumber);
            } else {
                instance.tooltip.info(locale_info.tipInputNumber, "");
            }

            return;
        }

        value = parseInt(value);

        if (value < 1 || value > 100) {
            if (instance.validate.isEditMode()) {
                alert(locale_info.tipInputNumberLimit);
            } else {
                instance.tooltip.info(locale_info.tipInputNumberLimit, "");
            }

            return;
        }

        let st_index = Store.luckysheet_select_save[0][Store.luckysheetRightHeadClickIs][1];

		if(!instance.method.createHookFunction("rowInsertBefore",  st_index, value, "rightbottom", Store.luckysheetRightHeadClickIs)){
			return; 
		}

        instance.extend.luckysheetextendtable(Store.luckysheetRightHeadClickIs, st_index, value, "rightbottom");
    });

    // currentInstance.find("#luckysheet-addBottomRows").click(function (event) {
    // currentInstance.find("#luckysheetColsRowsHandleAdd_sub .luckysheet-cols-menuitem:nth-child(2)").click(function (event) {

    //      // Click input element, don't comfirm
    //      if(event.target.nodeName === 'INPUT'){
    //         return;
    //     }

    //     currentInstance.find("#luckysheet-rightclick-menu").hide();
    //     instance.util.luckysheetContainerFocus();

    //     const _locale = instance.locale();
    //     const locale_drag = _locale.drag;
    //     const locale_info = _locale.info;

    //     if(Store.luckysheet_select_save.length > 1){
    //         if(instance.validate.isEditMode()){
    //             alert(locale_drag.noMulti);
    //         }
    //         else{
    //             instance.tooltip.info(locale_drag.noMulti, "");
    //         }

    //         return;
    //     }

    //     let $t = $(this), value = $t.find("input").val();
    //     if (!isRealNum(value)) {
    //         if(instance.validate.isEditMode()){
    //             alert(locale_info.tipInputNumber);
    //         }
    //         else{
    //             instance.tooltip.info(locale_info.tipInputNumber, "");
    //         }

    //         return;
    //     }

    //     value = parseInt(value);

    //     if (value < 1 || value > 100) {
    //         if(instance.validate.isEditMode()){
    //             alert(locale_info.tipInputNumberLimit);
    //         }
    //         else{
    //             instance.tooltip.info(locale_info.tipInputNumberLimit, "");
    //         }

    //         return;
    //     }

    //     let st_index = Store.luckysheet_select_save[0].row[1];
    //     instance.extend.luckysheetextendtable('row', st_index, value, "rightbottom");

    //     currentInstance.find("#luckysheetColsRowsHandleAdd_sub").hide();

    // });
    // currentInstance.find("#luckysheet-addRightCols").click(function (event) {
    // currentInstance.find("#luckysheetColsRowsHandleAdd_sub" .luckysheet-cols-menuitem:nth-child(4)").click(function (event) {

    //     // Click input element, don't comfirm
    //     if(event.target.nodeName === 'INPUT'){
    //         return;
    //     }
    //     currentInstance.find("#luckysheet-rightclick-menu").hide();
    //     instance.util.luckysheetContainerFocus();

    //     const _locale = instance.locale();
    //     const locale_drag = _locale.drag;
    //     const locale_info = _locale.info;

    //     if(Store.luckysheet_select_save.length > 1){
    //         if(instance.validate.isEditMode()){
    //             alert(locale_drag.noMulti);
    //         }
    //         else{
    //             instance.tooltip.info(locale_drag.noMulti, "");
    //         }

    //         return;
    //     }

    //     let $t = $(this), value = $t.find("input").val();
    //     if (!isRealNum(value)) {
    //         if(instance.validate.isEditMode()){
    //             alert(locale_info.tipInputNumber);
    //         }
    //         else{
    //             instance.tooltip.info(locale_info.tipInputNumber, "");
    //         }

    //         return;
    //     }

    //     value = parseInt(value);

    //     if (value < 1 || value > 100) {
    //         if(instance.validate.isEditMode()){
    //             alert(locale_info.tipInputNumberLimit);
    //         }
    //         else{
    //             instance.tooltip.info(locale_info.tipInputNumberLimit, "");
    //         }

    //         return;
    //     }

    //     let st_index = Store.luckysheet_select_save[0].column[1];
    //     instance.extend.luckysheetextendtable('column', st_index, value, "rightbottom");

    //     currentInstance.find("#luckysheetColsRowsHandleAdd_sub").hide();

    // });

    //删除选中行列
    currentInstance.find(`#luckysheet-del-selected, #luckysheet-del-selected_t`).click(function(event) {
        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        const locale_drag = instance.locale().drag;

        if (Store.luckysheet_select_save.length > 1) {
            if (Store.luckysheetRightHeadClickIs == "row") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            } else if (Store.luckysheetRightHeadClickIs == "column") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            }
            return;
        }

        let st_index = Store.luckysheet_select_save[0][Store.luckysheetRightHeadClickIs][0],
            ed_index = Store.luckysheet_select_save[0][Store.luckysheetRightHeadClickIs][1];

        if(!instance.method.createHookFunction("rowDeleteBefore", st_index, ed_index, Store.luckysheetRightHeadClickIs)){
        	return; 

        }
        instance.extend.luckysheetdeletetable(Store.luckysheetRightHeadClickIs, st_index, ed_index);
    });
    // 删除选中行
    currentInstance.find("#luckysheet-delRows").click(function(event) {
        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        const locale_drag = instance.locale().drag;

        if (Store.luckysheet_select_save.length > 1) {
            if (Store.luckysheetRightHeadClickIs == "row") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            } else if (Store.luckysheetRightHeadClickIs == "column") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            }
            return;
        }

        let st_index = Store.luckysheet_select_save[0].row[0],
            ed_index = Store.luckysheet_select_save[0].row[1];

		if(!instance.method.createHookFunction("rowDeleteBefore", st_index, ed_index, 'row')){
			return; 
		}
        instance.extend.luckysheetdeletetable('row', st_index, ed_index);
    })
    // 删除选中列
    currentInstance.find("#luckysheet-delCols").click(function (event) {

        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        const locale_drag = instance.locale().drag;

        if (Store.luckysheet_select_save.length > 1) {
            if (Store.luckysheetRightHeadClickIs == "row") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            } else if (Store.luckysheetRightHeadClickIs == "column") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            }
            return;
        }

        let st_index = Store.luckysheet_select_save[0].column[0],
            ed_index = Store.luckysheet_select_save[0].column[1];
        instance.extend.luckysheetdeletetable("column", st_index, ed_index);
    });

    //隐藏选中行列
    $("#"+currentId+" #luckysheet-hide-selected").click(function(event) {
        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        const locale_drag = instance.locale().drag;

        if (Store.luckysheet_select_save.length > 1) {
            if (Store.luckysheetRightHeadClickIs == "row") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            } else if (Store.luckysheetRightHeadClickIs == "column") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            }
            return;
        }

        // 隐藏行
        if (Store.luckysheetRightHeadClickIs == "row") {
            if (!instance.protection.checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatRows")) {
                return;
            }

            let cfg = $.extend(true, {}, Store.config);
            if (cfg["rowhidden"] == null) {
                cfg["rowhidden"] = {};
            }

            for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                let r1 = Store.luckysheet_select_save[s].row[0],
                    r2 = Store.luckysheet_select_save[s].row[1];

                for (let r = r1; r <= r2; r++) {
                    cfg["rowhidden"][r] = 0;
                }
            }

            //保存撤销
            if (Store.clearjfundo) {
                let redo = {};
                redo["type"] = "showHidRows";
                redo["sheetIndex"] = Store.currentSheetIndex;
                redo["config"] = $.extend(true, {}, Store.config);
                redo["curconfig"] = cfg;

                Store.jfundo.length = 0;
                Store.jfredo.push(redo);
            }

            //config
            Store.config = cfg;
            Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].config = Store.config;

            instance.server.saveParam("cg", Store.currentSheetIndex, cfg["rowhidden"], { k: "rowhidden" });

            //行高、列宽 刷新
            instance.refresh.jfrefreshgrid_rhcw(Store.flowdata.length, Store.flowdata[0].length);
        }
        // 隐藏列
        else if (Store.luckysheetRightHeadClickIs == "column") {
            if (!instance.protection.checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatColumns")) {
                return;
            }

            let cfg = $.extend(true, {}, Store.config);
            if (cfg["colhidden"] == null) {
                cfg["colhidden"] = {};
            }

            for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                let c1 = Store.luckysheet_select_save[s].column[0],
                    c2 = Store.luckysheet_select_save[s].column[1];

                for (let c = c1; c <= c2; c++) {
                    cfg["colhidden"][c] = 0;
                }
            }

            //保存撤销
            if (Store.clearjfundo) {
                let redo = {};
                redo["type"] = "showHidCols";
                redo["sheetIndex"] = Store.currentSheetIndex;
                redo["config"] = $.extend(true, {}, Store.config);
                redo["curconfig"] = cfg;

                Store.jfundo.length = 0;
                Store.jfredo.push(redo);
            }

            //config
            Store.config = cfg;
            Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].config = Store.config;

            instance.server.saveParam("cg", Store.currentSheetIndex, cfg["colhidden"], { k: "colhidden" });

            //行高、列宽 刷新
            instance.refresh.jfrefreshgrid_rhcw(Store.flowdata.length, Store.flowdata[0].length);
        }
    });

    //取消隐藏选中行列
    currentInstance.find("#luckysheet-show-selected").click(function(event) {
        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        const locale_drag = instance.locale().drag;

        if (Store.luckysheet_select_save.length > 1) {
            if (Store.luckysheetRightHeadClickIs == "row") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            } else if (Store.luckysheetRightHeadClickIs == "column") {
                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noMulti);
                } else {
                    instance.tooltip.info(locale_drag.noMulti, "");
                }
            }
            return;
        }

        // 取消隐藏行
        if (Store.luckysheetRightHeadClickIs == "row") {
            if (!instance.protection.checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatRows")) {
                return;
            }

            let cfg = $.extend(true, {}, Store.config);
            if (cfg["rowhidden"] == null) {
                return;
            }

            for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                let r1 = Store.luckysheet_select_save[s].row[0],
                    r2 = Store.luckysheet_select_save[s].row[1];

                for (let r = r1; r <= r2; r++) {
                    delete cfg["rowhidden"][r];
                }
            }

            //保存撤销
            if (Store.clearjfundo) {
                let redo = {};
                redo["type"] = "showHidRows";
                redo["sheetIndex"] = Store.currentSheetIndex;
                redo["config"] = $.extend(true, {}, Store.config);
                redo["curconfig"] = cfg;

                Store.jfundo.length = 0;
                Store.jfredo.push(redo);
            }

            //config
            Store.config = cfg;
            Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].config = Store.config;

            instance.server.saveParam("cg", Store.currentSheetIndex, cfg["rowhidden"], { k: "rowhidden" });

            //行高、列宽 刷新
            instance.refresh.jfrefreshgrid_rhcw(Store.flowdata.length, Store.flowdata[0].length);
        } else if (Store.luckysheetRightHeadClickIs == "column") {
            if (!instance.protection.checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatColumns")) {
                return;
            }

            let cfg = $.extend(true, {}, Store.config);
            if (cfg["colhidden"] == null) {
                return;
            }

            for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                let c1 = Store.luckysheet_select_save[s].column[0],
                    c2 = Store.luckysheet_select_save[s].column[1];

                for (let c = c1; c <= c2; c++) {
                    delete cfg["colhidden"][c];
                }
            }

            //保存撤销
            if (Store.clearjfundo) {
                let redo = {};
                redo["type"] = "showHidCols";
                redo["sheetIndex"] = Store.currentSheetIndex;
                redo["config"] = $.extend(true, {}, Store.config);
                redo["curconfig"] = cfg;

                Store.jfundo.length = 0;
                Store.jfredo.push(redo);
            }

            //config
            Store.config = cfg;
            Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].config = Store.config;

            instance.server.saveParam("cg", Store.currentSheetIndex, cfg["colhidden"], { k: "colhidden" });

            //行高、列宽 刷新
            instance.refresh.jfrefreshgrid_rhcw(Store.flowdata.length, Store.flowdata[0].length);
        }
    });
    //隐藏、显示行
    // currentInstance.find("#luckysheet-hidRows").click(function (event) {
    //     if(!checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatRows")){
    //         return;
    //     }

    //     currentInstance.find("#luckysheet-rightclick-menu").hide();
    //     instance.util.luckysheetContainerFocus();

    //     let cfg = $.extend(true, {}, Store.config);
    //     if(cfg["rowhidden"] == null){
    //         cfg["rowhidden"] = {};
    //     }

    //     for(let s = 0; s < Store.luckysheet_select_save.length; s++){
    //         let r1 = Store.luckysheet_select_save[s].row[0],
    //             r2 = Store.luckysheet_select_save[s].row[1];

    //         for(let r = r1; r <= r2; r++){
    //             cfg["rowhidden"][r] = 0;
    //         }
    //     }

    //     //保存撤销
    //     if(Store.clearjfundo){
    //         let redo = {};
    //         redo["type"] = "showHidRows";
    //         redo["sheetIndex"] = Store.currentSheetIndex;
    //         redo["config"] = $.extend(true, {}, Store.config);
    //         redo["curconfig"] = cfg;

    //         Store.jfundo.length  = 0;
    //         Store.jfredo.push(redo);
    //     }

    //     //config
    //     Store.config = cfg;
    //     Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].config = Store.config;

    //     instance.server.saveParam("cg", Store.currentSheetIndex, cfg["rowhidden"], { "k": "rowhidden" });

    //     //行高、列宽 刷新
    //     instance.refresh.jfrefreshgrid_rhcw(Store.flowdata.length, Store.flowdata[0].length);
    // })
    // currentInstance.find("#luckysheet-showHidRows").click(function (event) {
    //     if(!checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatRows")){
    //         return;
    //     }
    //     currentInstance.find("#luckysheet-rightclick-menu").hide();
    //     instance.util.luckysheetContainerFocus();

    //     let cfg = $.extend(true, {}, Store.config);
    //     if(cfg["rowhidden"] == null){
    //         return;
    //     }

    //     for(let s = 0; s < Store.luckysheet_select_save.length; s++){
    //         let r1 = Store.luckysheet_select_save[s].row[0],
    //             r2 = Store.luckysheet_select_save[s].row[1];

    //         for(let r = r1; r <= r2; r++){
    //             delete cfg["rowhidden"][r];
    //         }
    //     }

    //     //保存撤销
    //     if(Store.clearjfundo){
    //         let redo = {};
    //         redo["type"] = "showHidRows";
    //         redo["sheetIndex"] = Store.currentSheetIndex;
    //         redo["config"] = $.extend(true, {}, Store.config);
    //         redo["curconfig"] = cfg;

    //         Store.jfundo.length  = 0;
    //         Store.jfredo.push(redo);
    //     }

    //     //config
    //     Store.config = cfg;
    //     Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].config = Store.config;

    //     instance.server.saveParam("cg", Store.currentSheetIndex, cfg["rowhidden"], { "k": "rowhidden" });

    //     //行高、列宽 刷新
    //     instance.refresh.jfrefreshgrid_rhcw(Store.flowdata.length, Store.flowdata[0].length);
    // })

    //隐藏、显示列
    // currentInstance.find("#luckysheet-hidCols").click(function (event) {
    //     if(!checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatColumns")){
    //         return;
    //     }
    //     currentInstance.find("#luckysheet-rightclick-menu").hide();
    //     instance.util.luckysheetContainerFocus();

    //     let cfg = $.extend(true, {}, Store.config);
    //     if(cfg["colhidden"] == null){
    //         cfg["colhidden"] = {};
    //     }

    //     for(let s = 0; s < Store.luckysheet_select_save.length; s++){
    //         let c1 = Store.luckysheet_select_save[s].column[0],
    //             c2 = Store.luckysheet_select_save[s].column[1];

    //         for(let c = c1; c <= c2; c++){
    //             cfg["colhidden"][c] = 0;
    //         }
    //     }

    //     //保存撤销
    //     if(Store.clearjfundo){
    //         let redo = {};
    //         redo["type"] = "showHidCols";
    //         redo["sheetIndex"] = Store.currentSheetIndex;
    //         redo["config"] = $.extend(true, {}, Store.config);
    //         redo["curconfig"] = cfg;

    //         Store.jfundo.length  = 0;
    //         Store.jfredo.push(redo);
    //     }

    //     //config
    //     Store.config = cfg;
    //     Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].config = Store.config;

    //     instance.server.saveParam("cg", Store.currentSheetIndex, cfg["colhidden"], { "k": "colhidden" });

    //     //行高、列宽 刷新
    //     instance.refresh.jfrefreshgrid_rhcw(Store.flowdata.length, Store.flowdata[0].length);
    // })
    // currentInstance.find("#luckysheet-showHidCols").click(function (event) {
    //     if(!checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatColumns")){
    //         return;
    //     }
    //     currentInstance.find("#luckysheet-rightclick-menu").hide();
    //     instance.util.luckysheetContainerFocus();

    //     let cfg = $.extend(true, {}, Store.config);
    //     if(cfg["colhidden"] == null){
    //         return;
    //     }

    //     for(let s = 0; s < Store.luckysheet_select_save.length; s++){
    //         let c1 = Store.luckysheet_select_save[s].column[0],
    //             c2 = Store.luckysheet_select_save[s].column[1];

    //         for(let c = c1; c <= c2; c++){
    //             delete cfg["colhidden"][c];
    //         }
    //     }

    //     //保存撤销
    //     if(Store.clearjfundo){
    //         let redo = {};
    //         redo["type"] = "showHidCols";
    //         redo["sheetIndex"] = Store.currentSheetIndex;
    //         redo["config"] = $.extend(true, {}, Store.config);
    //         redo["curconfig"] = cfg;

    //         Store.jfundo.length  = 0;
    //         Store.jfredo.push(redo);
    //     }

    //     //config
    //     Store.config = cfg;
    //     Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].config = Store.config;

    //     instance.server.saveParam("cg", Store.currentSheetIndex, cfg["colhidden"], { "k": "colhidden" });

    //     //行高、列宽 刷新
    //     instance.refresh.jfrefreshgrid_rhcw(Store.flowdata.length, Store.flowdata[0].length);
    // })

    //删除单元格（左移、上移）
    currentInstance.find("#luckysheet-delCellsMoveLeft").click(function(event) {
        currentInstance.find(".luckysheet-cols-menu").hide();
        instance.util.luckysheetContainerFocus();
        const locale_drag = instance.locale().drag;

        if (Store.luckysheet_select_save.length > 1) {
            if (instance.validate.isEditMode()) {
                alert(locale_drag.noMulti);
            } else {
                instance.tooltip.info(locale_drag.noMulti, "");
            }
            return;
        }

        let str = Store.luckysheet_select_save[0].row[0],
            edr = Store.luckysheet_select_save[0].row[1],
            stc = Store.luckysheet_select_save[0].column[0],
            edc = Store.luckysheet_select_save[0].column[1];

        instance.extend.luckysheetDeleteCell("moveLeft", str, edr, stc, edc);
    });
    currentInstance.find("#luckysheet-delCellsMoveUp").click(function(event) {
        currentInstance.find(".luckysheet-cols-menu").hide();
        instance.util.luckysheetContainerFocus();

        const locale_drag = instance.locale().drag;

        if (Store.luckysheet_select_save.length > 1) {
            if (instance.validate.isEditMode()) {
                alert(locale_drag.noMulti);
            } else {
                instance.tooltip.info(locale_drag.noMulti, "");
            }
            return;
        }

        let str = Store.luckysheet_select_save[0].row[0],
            edr = Store.luckysheet_select_save[0].row[1],
            stc = Store.luckysheet_select_save[0].column[0],
            edc = Store.luckysheet_select_save[0].column[1];

        instance.extend.luckysheetDeleteCell("moveUp", str, edr, stc, edc);
    });

    //清除单元格内容
    currentInstance.find("#luckysheet-delete-text").click(function() {
        if (!instance.protection.checkProtectionLockedRangeList(Store.luckysheet_select_save, Store.currentSheetIndex)) {
            return;
        }

        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        if (Store.allowEdit === false) {
            return;
        }

        if (Store.luckysheet_select_save.length > 0) {
            let d = instance.editor.deepCopyFlowData(Store.flowdata);

            let has_PartMC = false;

            for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                let r1 = Store.luckysheet_select_save[s].row[0],
                    r2 = Store.luckysheet_select_save[s].row[1];
                let c1 = Store.luckysheet_select_save[s].column[0],
                    c2 = Store.luckysheet_select_save[s].column[1];

                if (instance.validate.hasPartMC(Store.config, r1, r2, c1, c2)) {
                    has_PartMC = true;
                    break;
                }
            }

            if (has_PartMC) {
                const locale_drag = instance.locale().drag;

                if (instance.validate.isEditMode()) {
                    alert(locale_drag.noPartMerge);
                } else {
                    instance.tooltip.info(locale_drag.noPartMerge, "");
                }

                return;
            }

            const file = Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)];
            const hyperlink = file.hyperlink && $.extend(true, {}, file.hyperlink);
            let dataVerification = $.extend(true, {}, file.dataVerification);
            let hyperlinkUpdated, dataVerificationUpdated;

            for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                let r1 = Store.luckysheet_select_save[s].row[0],
                    r2 = Store.luckysheet_select_save[s].row[1];
                let c1 = Store.luckysheet_select_save[s].column[0],
                    c2 = Store.luckysheet_select_save[s].column[1];

                for (let r = r1; r <= r2; r++) {
                    for (let c = c1; c <= c2; c++) {
                        if (instance.pivotTable.isPivotRange(r, c)) {
                            continue;
                        }

                        if (getObjType(d[r][c]) == "object") {
                            delete d[r][c]["m"];
                            delete d[r][c]["v"];

                            if (d[r][c]["f"] != null) {
                                delete d[r][c]["f"];
                                instance.formula.delFunctionGroup(r, c, Store.currentSheetIndex);

                                delete d[r][c]["spl"];
                            }

                            if (d[r][c]["ct"] != null && d[r][c]["ct"].t == "inlineStr") {
                                delete d[r][c]["ct"];
                            }
                        } else {
                            d[r][c] = null;
                        }
                        // 同步清除 hyperlink
                        if (hyperlink?.[`${r}_${c}`]) {
                            delete hyperlink[`${r}_${c}`];
                            hyperlinkUpdated = true;
                        }
                        // 同步清除 数据验证
                        if (dataVerification[`${r}_${c}`]) {
                            delete dataVerification[`${r}_${c}`];
                            dataVerificationUpdated = true;
                        }
                    }
                }
            }
            let param = {};
            if (hyperlinkUpdated) {
                param.hyperlink = hyperlink;
            }
            if (dataVerificationUpdated) {
                param.dataVerification = dataVerification;
            }

            instance.refresh.jfrefreshgrid(d, Store.luckysheet_select_save, param);

            // 清空编辑框的内容
            // 备注：在functionInputHanddler方法中会把该标签的内容拷贝到 #luckysheet-functionbox-cell
            currentInstance.find("#luckysheet-rich-text-editor").html("");
        }
    });

    //行高列宽设置
    // currentInstance.find("#luckysheet-rows-cols-changesize").click(function(){
    currentInstance.find("#luckysheet-column-row-width-selected").click(function(event) {
        // Click input element, don't comfirm
        if (event.target.nodeName === "INPUT") {
            return;
        }

        currentInstance.find("#luckysheet-rightclick-menu").hide();
        instance.util.luckysheetContainerFocus();

        // let size = parseInt($(this).siblings("input[type='number']").val().trim());
        let size = parseInt(
            $(this)
                .closest(".luckysheet-cols-menuitem")
                .find("input[type='number']")
                .val()
                .trim(),
        );

        const locale_info = instance.locale().info;

        /* 对异常情况进行判断：NaN */
        if (isNaN(size)) {
            instance.tooltip.info(locale_info.tipInputNumber, "");
            return;
        }

        let cfg = $.extend(true, {}, Store.config);
        let type;
        let images = null;

        if (Store.luckysheetRightHeadClickIs == "row") {
            if (!instance.protection.checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatRows")) {
                return;
            }

            if (size < 0 || size > 545) {
                if (instance.validate.isEditMode()) {
                    alert(locale_info.tipRowHeightLimit);
                } else {
                    instance.tooltip.info(locale_info.tipRowHeightLimit, "");
                }
                return;
            }

            type = "resizeR";

            if (cfg["rowlen"] == null) {
                cfg["rowlen"] = {};
            }

            for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                let r1 = Store.luckysheet_select_save[s].row[0];
                let r2 = Store.luckysheet_select_save[s].row[1];

                for (let r = r1; r <= r2; r++) {
                    cfg["rowlen"][r] = size;

                    images = instance.imageCtrl.moveChangeSize("row", r, size);
                }
            }
        } else if (Store.luckysheetRightHeadClickIs == "column") {
            if (!instance.protection.checkProtectionAuthorityNormal(Store.currentSheetIndex, "formatColumns")) {
                return;
            }

            if (size < 0 || size > 2038) {
                if (instance.validate.isEditMode()) {
                    alert(locale_info.tipColumnWidthLimit);
                } else {
                    instance.tooltip.info(locale_info.tipColumnWidthLimit, "");
                }
                return;
            }

            type = "resizeC";

            if (cfg["columnlen"] == null) {
                cfg["columnlen"] = {};
            }

            for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
                let c1 = Store.luckysheet_select_save[s].column[0];
                let c2 = Store.luckysheet_select_save[s].column[1];

                for (let c = c1; c <= c2; c++) {
                    cfg["columnlen"][c] = size;

                    images = instance.imageCtrl.moveChangeSize("column", c, size);
                }
            }
        }

        if (Store.clearjfundo) {
            Store.jfundo.length = 0;
            Store.jfredo.push({
                type: "resize",
                ctrlType: type,
                sheetIndex: Store.currentSheetIndex,
                config: $.extend(true, {}, Store.config),
                curconfig: $.extend(true, {}, cfg),
                images: $.extend(true, {}, instance.imageCtrl.images),
                curImages: $.extend(true, {}, images),
            });
        }

        //config
        Store.config = cfg;
        Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].config = Store.config;

        //images
        Store.luckysheetfile[instance.get.getSheetIndex(Store.currentSheetIndex)].images = images;
        instance.server.saveParam("all", Store.currentSheetIndex, images, { k: "images" });
        instance.imageCtrl.images = images;
        instance.imageCtrl.allImagesShow();

        if (Store.luckysheetRightHeadClickIs == "row") {
            instance.server.saveParam("cg", Store.currentSheetIndex, cfg["rowlen"], { k: "rowlen" });
            instance.refresh.jfrefreshgrid_rhcw(Store.flowdata.length, null);
        } else if (Store.luckysheetRightHeadClickIs == "column") {
            instance.server.saveParam("cg", Store.currentSheetIndex, cfg["columnlen"], { k: "columnlen" });
            instance.refresh.jfrefreshgrid_rhcw(null, Store.flowdata[0].length);
        }
    });
}

function luckysheetcolsdbclick(instance) {
    const currentId = instance.getInstanceId();
    let Store = instance.getStore();
    let currentInstance = $(`#${currentId}`);
    Store.luckysheet_cols_change_size = false;

    currentInstance.find("#luckysheet-change-size-line").hide();
    currentInstance.find("#luckysheet-cols-change-size").css("opacity", 0);
    currentInstance.find(`#luckysheet-sheettable, #luckysheet-cols-h-c, .luckysheet-cols-h-cells, .luckysheet-cols-h-cells canvas`).css(
        "cursor",
        "default",
    );

    let mouse = instance.location.mouseposition(event.pageX, event.pageY);
    let scrollLeft = currentInstance.find("#luckysheet-cols-h-c").scrollLeft();
    let x = mouse[0] + scrollLeft;

    let colIndex = instance.location.colLocation(x)[2];
    let d = instance.editor.deepCopyFlowData(Store.flowdata);
    let canvas = currentInstance.find("#luckysheetTableContent")
        .get(0)
        .getContext("2d");

    let cfg = $.extend(true, {}, Store.config);
    if (cfg["columnlen"] == null) {
        cfg["columnlen"] = {};
    }

    let matchColumn = {};
    let scrollTop = currentInstance.find("#luckysheet-cell-main").scrollTop(),
        drawHeight = Store.luckysheetTableContentHW[1];
    let dataset_row_st = luckysheet_searcharray(Store.visibledatarow, scrollTop);
    let dataset_row_ed = luckysheet_searcharray(Store.visibledatarow, scrollTop + drawHeight);
    dataset_row_ed += dataset_row_ed - dataset_row_st;
    if (dataset_row_ed >= d.length) {
        dataset_row_ed = d.length - 1;
    }

    for (let s = 0; s < Store.luckysheet_select_save.length; s++) {
        let c1 = Store.luckysheet_select_save[s].column[0],
            c2 = Store.luckysheet_select_save[s].column[1];

        if (colIndex < c1 || colIndex > c2) {
            if (colIndex in matchColumn) {
                //此列已计算过
                continue;
            }

            let currentColLen = Store.defaultcollen;

            for (let r = dataset_row_st; r <= dataset_row_ed; r++) {
                let cell = d[r][colIndex];

                if (cell == null || (isRealNull(cell.v) && !isInlineStringCell(cell))) {
                    continue;
                }

                // let fontset = luckysheetfontformat(cell);
                // canvas.font = fontset;

                // let value = getcellvalue(r, colIndex, d, "m").toString(); //单元格文本
                // let textMetrics = instance.getRowlen.getMeasureText(value, canvas).width; //文本宽度
                let cellWidth = instance.location.colLocationByIndex(colIndex)[1] - instance.location.colLocationByIndex(colIndex)[0] - 2;
                let textInfo = instance.getRowlen.getCellTextInfo(cell, canvas, {
                    r: r,
                    c: colIndex,
                    cellWidth: cellWidth,
                });

                let computeRowlen = 0;
                // console.log("rowlen", textInfo);
                if (textInfo != null) {
                    computeRowlen = textInfo.textWidthAll;
                }

                if (computeRowlen + 6 > currentColLen) {
                    currentColLen = computeRowlen + 6;
                }
            }

            if (currentColLen != Store.defaultcollen) {
                cfg["columnlen"][colIndex] = currentColLen;
                if (cfg["customWidth"]) {
                    delete cfg["customWidth"][colIndex];
                }
            }

            matchColumn[colIndex] = 1;
        } else {
            for (let c = c1; c <= c2; c++) {
                if (c in matchColumn) {
                    //此列已计算过
                    continue;
                }

                let currentColLen = Store.defaultcollen;

                for (let r = dataset_row_st; r <= dataset_row_ed; r++) {
                    let cell = d[r][c];

                    if (cell == null || (isRealNull(cell.v) && !isInlineStringCell(cell))) {
                        continue;
                    }

                    // let fontset = luckysheetfontformat(cell);
                    // canvas.font = fontset;

                    // let value = getcellvalue(r, c, d, "m").toString(); //单元格文本
                    // let textMetrics = instance.getRowlen.getMeasureText(value, canvas).width; //文本宽度

                    // if(textMetrics + 6 > currentColLen){
                    //     currentColLen = textMetrics + 6;
                    // }

                    let cellWidth = instance.location.colLocationByIndex(c)[1] - instance.location.colLocationByIndex(c)[0] - 2;
                    let textInfo = instance.getRowlen.getCellTextInfo(cell, canvas, {
                        r: r,
                        c: c,
                        cellWidth: cellWidth,
                    });

                    let computeRowlen = 0;
                    // console.log("rowlen", textInfo);
                    if (textInfo != null) {
                        computeRowlen = textInfo.textWidthAll;
                    }

                    if (computeRowlen + 6 > currentColLen) {
                        currentColLen = computeRowlen + 6;
                    }
                }

                if (currentColLen != Store.defaultcollen) {
                    cfg["columnlen"][c] = currentColLen;
                    if (cfg["customWidth"]) {
                        delete cfg["customWidth"][c];
                    }
                }

                matchColumn[c] = 1;
            }
        }
    }

    instance.refresh.jfrefreshgridall(
        Store.flowdata[0].length,
        Store.flowdata.length,
        Store.flowdata,
        cfg,
        Store.luckysheet_select_save,
        "resizeC",
        "columnlen",
    );
}

/**
 *
 * @param {String} type:delete type,
 * @param {*} st_index
 * @param {*} ed_index
 */
// Delete row api
export function deleteRows(type, st_index, ed_index) {
    Store.luckysheetRightHeadClickIs = "column";
}

// Delete column api
export function deleteColumns() {}
