import menuButton from './menuButton';
import {luckysheetupdateCell} from './updateCell';
import { keycode } from './constant';
import { 
    luckysheetMoveHighlightCell,
} from './sheetMove';

import insertFormula from './insertFormula';
import { 
    rowLocation, 
    colLocation, 
    mouseposition 
} from '../global/location';
import { isEditMode } from '../global/validate';
import formula from '../global/formula';
import tooltip from '../global/tooltip';
import locale from '../locale/locale';
import currentSheet from './common/currentSheet';
import multiInstanceStore from '../store/multiInstance';

export function formulaBarInitial(container){
    //公式栏处理
    const _this = this;
    const instance = _this.instance;
    const containerId = instance.getInstanceId();
	let Store = instance.getStore();
    let currentInstance = $(`#${containerId}`);
    const _locale = instance.locale();
    const locale_formula= _locale.formula;

    currentInstance.find("#luckysheet-functionbox-cell").focus(function () {
        if(instance.validate.isEditMode()){//此模式下禁用公式栏
            return;
        }

        if(Store.luckysheet_select_save.length > 0){
            let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];

            let row_index = last["row_focus"], col_index = last["column_focus"];
            
            // let $input = currentInstance.find("#luckysheet-rich-text-editor"),value = $input.text();
            // if(value) {
            //     instance.formula.updatecell(row_index, col_index);
            // }
            instance.updateCell.luckysheetupdateCell(row_index, col_index, Store.flowdata, null, true);
            instance.formula.rangeResizeTo = currentInstance.find("#luckysheet-functionbox-cell");
        }
    }).keydown(function (event) {
        if(instance.validate.isEditMode()){//此模式下禁用公式栏
            return;
        }

        let ctrlKey = event.ctrlKey;
        let altKey = event.altKey;
        let shiftKey = event.shiftKey;
        let kcode = event.keyCode;
        let $inputbox = currentInstance.find("#luckysheet-input-box");

        if (kcode == keycode.ENTER && parseInt($inputbox.css("top")) > 0) {
            if (currentInstance.find("#luckysheet-formula-search-c").is(":visible") && instance.formula.searchFunctionCell != null) {
                instance.formula.searchFunctionEnter(currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item-active"));
            }
            else {
                instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
                Store.luckysheet_select_save = [{ "row": [Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[0]], "column": [Store.luckysheetCellUpdate[1], Store.luckysheetCellUpdate[1]], "row_focus": Store.luckysheetCellUpdate[0], "column_focus": Store.luckysheetCellUpdate[1] }];
                instance.sheetMove.luckysheetMoveHighlightCell("down", 1, "rangeOfSelect");
                //currentInstance.find("#luckysheet-functionbox-cell").blur();
                currentInstance.find("#luckysheet-rich-text-editor").focus();
            }
            event.preventDefault();
        }
        else if (kcode == keycode.ESC && parseInt($inputbox.css("top")) > 0) {
            instance.formula.dontupdate();
            instance.sheetMove.luckysheetMoveHighlightCell("down", 0, "rangeOfSelect");
            //currentInstance.find("#luckysheet-functionbox-cell").blur();
            currentInstance.find("#luckysheet-rich-text-editor").focus();
            event.preventDefault();
        }
        else if (kcode == keycode.F4 && parseInt($inputbox.css("top")) > 0) {
            instance.formula.setfreezonFuc(event);
            event.preventDefault();
        }
        else if (kcode == keycode.UP && parseInt($inputbox.css("top")) > 0) {
            if (currentInstance.find("#luckysheet-formula-search-c").is(":visible")) {
                let $up = currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item-active").prev();
                if ($up.length == 0) {
                    $up = currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item").last();
                }
                currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item").removeClass("luckysheet-formula-search-item-active");
                $up.addClass("luckysheet-formula-search-item-active");
                event.preventDefault();
            }
        }
        else if (kcode == keycode.DOWN && parseInt($inputbox.css("top")) > 0) {
            if (currentInstance.find("#luckysheet-formula-search-c").is(":visible")) {
                let $up = currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item-active").next();
                if ($up.length == 0) {
                    $up = currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item").first();
                }
                currentInstance.find("#luckysheet-formula-search-c").find(".luckysheet-formula-search-item").removeClass("luckysheet-formula-search-item-active");
                $up.addClass("luckysheet-formula-search-item-active");
                event.preventDefault();
            }
        }
        else if (kcode == keycode.LEFT && parseInt($inputbox.css("top")) > 0) {
            instance.formula.rangeHightlightselected(currentInstance.find("#luckysheet-functionbox-cell"));
        }
        else if (kcode == keycode.RIGHT && parseInt($inputbox.css("top")) > 0) {
            instance.formula.rangeHightlightselected(currentInstance.find("#luckysheet-functionbox-cell"));
        }
        else if (!((kcode >= 112 && kcode <= 123) || kcode <= 46 || kcode == 144 || kcode == 108 || event.ctrlKey || event.altKey || (event.shiftKey && (kcode == 37 || kcode == 38 || kcode == 39 || kcode == 40))) || kcode == 8 || kcode == 32 || kcode == 46 || (event.ctrlKey && kcode == 86)) {
            instance.formula.functionInputHanddler(currentInstance.find("#luckysheet-rich-text-editor"), currentInstance.find("#luckysheet-functionbox-cell"), kcode);
        }
    }).click(function () {
        if(instance.validate.isEditMode()){//此模式下禁用公式栏
            return;
        }

        instance.formula.rangeHightlightselected(currentInstance.find("#luckysheet-functionbox-cell"));
    });

    //公式栏 取消（X）按钮
    currentInstance.find("#luckysheet-wa-functionbox-cancel").click(function () {
        if (!$(this).hasClass("luckysheet-wa-calculate-active")) {
            return;
        }
        //若有参数弹出框，隐藏
        if($(`#${containerId} #luckysheet-search-formula-parm`).is(":visible")){
            $(`#${containerId} #luckysheet-search-formula-parm`).hide();
        }
        //若有参数选取范围弹出框，隐藏
        if($(`#${containerId} #luckysheet-search-formula-parm-select`).is(":visible")){
            $(`#${containerId} #luckysheet-search-formula-parm-select`).hide();
        }

        instance.formula.dontupdate();
        instance.sheetMove.luckysheetMoveHighlightCell("down", 0, "rangeOfSelect");
    });

    //公式栏 确认（）按钮
    currentInstance.find("#luckysheet-wa-functionbox-confirm").click(function () {
        if (!$(this).hasClass("luckysheet-wa-calculate-active")) {
            return;
        }
        //若有参数弹出框，隐藏
        if($(`#${containerId} #luckysheet-search-formula-parm`).is(":visible")){
            $(`#${containerId} #luckysheet-search-formula-parm`).hide();
        }
        //若有参数选取范围弹出框，隐藏
        if($(`#${containerId} #luckysheet-search-formula-parm-select`).is(":visible")){
            $(`#${containerId} #luckysheet-search-formula-parm-select`).hide();
        }

        instance.formula.updatecell(Store.luckysheetCellUpdate[0], Store.luckysheetCellUpdate[1]);
        instance.sheetMove.luckysheetMoveHighlightCell("down", 0, "rangeOfSelect");
    });

    //公式栏 fx按钮
    currentInstance.find("#luckysheet-wa-functionbox-fx").click(function () {
        // 非编辑模式下不允许该操作
        if(instance.validate.isEditMode()){
            return;
        }
        //点击函数查找弹出框
        if(Store.luckysheet_select_save.length == 0){
            if(instance.validate.isEditMode()){
                alert(locale_instance.formula.tipSelectCell);
            }
            else{
                instance.tooltip.info(locale_instance.formula.tipSelectCell,"");
            }

            return;
        }

        let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];

        let row_index = last["row_focus"], col_index = last["column_focus"];

        instance.updateCell.luckysheetupdateCell(row_index, col_index, Store.flowdata);
        
        let cell = Store.flowdata[row_index][col_index];
        if(cell != null && cell.f != null){
            //单元格有计算
            let functionStr = instance.formula.getfunctionParam(cell.f);
            if(functionStr.fn != null){
                //有函数公式
                instance.insertFormula.formulaParmDialog(functionStr.fn, functionStr.param, containerId);
            }
            else{
                //无函数公式
                instance.insertFormula.formulaListDialog(containerId);
            }
        }
        else{
            //单元格无计算
            currentInstance.find("#luckysheet-rich-text-editor").html('<span dir="auto" class="luckysheet-formula-text-color">=</span>');
            currentInstance.find("#luckysheet-functionbox-cell").html(currentInstance.find("#luckysheet-rich-text-editor").html());
            instance.insertFormula.formulaListDialog(containerId);
        }

        instance.insertFormula.init();
    });

    //公式选区操作
    currentInstance.find("#luckysheet-formula-functionrange").on("mousedown", ".luckysheet-copy", function (event) {
        instance.formula.rangeMove = true;
        Store.luckysheet_scroll_status = true;
        instance.formula.rangeMoveObj = $(this).parent();
        instance.formula.rangeMoveIndex = $(this).parent().attr("rangeindex");
        
        let mouse = instance.location.mouseposition(event.pageX, event.pageY);
        let x = mouse[0] + currentInstance.find("#luckysheet-cell-main").scrollLeft();
        let y = mouse[1] + currentInstance.find("#luckysheet-cell-main").scrollTop();
        currentInstance.find("#luckysheet-formula-functionrange-highlight-" + instance.formula.rangeMoveIndex).find(".luckysheet-selection-copy-hc").css("opacity", 0.13);
        
        let type = $(this).data("type");
        if (type == "top") {
            y += 3;
        }
        else if (type == "right") {
            x -= 3;
        }
        else if (type == "bottom") {
            y -= 3;
        }
        else if (type == "left") {
            x += 3;
        }

        let row_index = instance.location.rowLocation(y)[2];
        let col_index = instance.location.colLocation(x)[2];

        instance.formula.rangeMovexy = [row_index, col_index];
        currentInstance.find("#luckysheet-sheettable").css("cursor", "move");
        event.stopPropagation();
    });

    currentInstance.find("#luckysheet-formula-functionrange").on("mousedown", ".luckysheet-highlight", function (event) {
        instance.formula.rangeResize = $(this).data("type");//开始状态resize
        instance.formula.rangeResizeIndex = $(this).parent().attr("rangeindex");
        
        let mouse = instance.location.mouseposition(event.pageX, event.pageY), 
            scrollLeft = currentInstance.find("#luckysheet-cell-main").scrollLeft(), 
            scrollTop = currentInstance.find("#luckysheet-cell-main").scrollTop();
        let x = mouse[0] + scrollLeft;
        let y = mouse[1] + scrollTop;
        instance.formula.rangeResizeObj = $(this).parent();
        currentInstance.find("#luckysheet-formula-functionrange-highlight-" + instance.formula.rangeResizeIndex).find(".luckysheet-selection-copy-hc").css("opacity", 0.13);
        
        if (instance.formula.rangeResize == "lt") {
            x += 3;
            y += 3;
        }
        else if (instance.formula.rangeResize == "lb") {
            x += 3;
            y -= 3;
        }
        else if (instance.formula.rangeResize == "rt") {
            x -= 3;
            y += 3;
        }
        else if (instance.formula.rangeResize == "rb") {
            x -= 3;
            y -= 3;
        }

        let row_location = instance.location.rowLocation(y), 
            row = row_location[1], 
            row_pre = row_location[0], 
            row_index = row_location[2];
        let col_location = instance.location.colLocation(x), 
            col = col_location[1], 
            col_pre = col_location[0], 
            col_index = col_location[2];

        let position = instance.formula.rangeResizeObj.position();
        instance.formula.rangeResizexy = [
            col_pre, 
            row_pre, 
            instance.formula.rangeResizeObj.width(), 
            instance.formula.rangeResizeObj.height(), 
            position.left + scrollLeft, 
            position.top + scrollTop, col, row
        ];
        instance.formula.rangeResizeWinH = currentInstance.find("#luckysheet-cell-main")[0].scrollHeight;
        instance.formula.rangeResizeWinW = currentInstance.find("#luckysheet-cell-main")[0].scrollWidth;
        Store.luckysheet_scroll_status = true;
        event.stopPropagation();
    });
}