import createHtmlDom from "./common/createHtmlDom";

const tooltipDialog = {
    createTooltipDialog: function (tooltipContent) {
        let instance = this.instance;
        let containerId = instance.getInstanceId();
        let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);

        let content = `<div class="luckysheet-tooltip-dialog-content">
            ${tooltipContent}
        </div>`

        instance.createHtmlDom.createDialog('luckysheet-tooltip-dialog', content, containerId, '提示', ()=>{}, true, false, true, '', '' , '', true);
        currentInstance.find("#luckysheet-tooltip-dialog-close").click(() => {
            currentInstance.find("#luckysheet-tooltip-dialog").hide()
        })
        currentInstance.find("#luckysheet-tooltip-dialog-confirm").click(() => {
            currentInstance.find("#luckysheet-tooltip-dialog").hide()
        })
    },
};
export default tooltipDialog;