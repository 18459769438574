/**
 * Monitor special variables
 */
import {createProxy} from '../utils/util';
import method from '../global/method';
import { getluckysheetfile } from '../methods/get'
import { toJson } from '../global/api';

let undoTimer,redoTimer;
/**
 * 监听撤回，设置按钮是否可以点击
 * @param {*} len 长度
 * instance 当前实例
 */
function undoAccessible(len, instance) {
    let containerId = instance.getInstanceId();
    const currentInstance = $("#"+containerId); // 当前实例dom操作对象
    clearTimeout(undoTimer);
    undoTimer = setTimeout(() => {
        currentInstance.find('#luckysheet-icon-undo')[len ? 'removeClass' : 'addClass']('disabled');
    }, 10);
}
/**
 * 监听还原，设置按钮是否可以点击
 * @param {*} len 长度
 * instance 当前实例
 */
function redoAccessible(len, instance) {
    let containerId = instance.getInstanceId();
    const currentInstance = $("#"+containerId); // 当前实例dom操作对象
    clearTimeout(redoTimer);
    redoTimer = setTimeout(() => {
        currentInstance.find('#luckysheet-icon-redo')[len ? 'removeClass' : 'addClass']('disabled');
    }, 10);
}

const initListener = function(){
    // createProxy(Store,['jfredo']);
	const _this = this;
    const instance = _this.instance;
	let Store = instance.getStore();
    createProxy(Store, 'jfredo',(target, property, val, receiver)=>{
        if (property !== 'length') {
            //  钩子函数
            instance.method.createHookFunction('updated',val)
        }
        undoAccessible(Store.jfredo.length ,instance);
    } );
    createProxy(Store, 'jfundo',(target, property, val, receiver)=>{
        redoAccessible(Store.jfundo.length ,instance);
    } );
    


    createProxy(Store, 'asyncLoad', (target, property, val, receiver)=>{
        if(property === 'length' && val === 0){
            instance.method.createHookFunction('workbookCreateAfter', toJson(instance))
        }
    })
}

export {
    initListener
}