import { seriesLoadScripts, loadLinks, $$, arrayRemoveItem } from "../../utils/util";
import { generateRandomKey, replaceHtml } from "../../utils/chartUtil";
import { getdatabyselection, getcellvalue } from "../../global/getdata";
import formula from "../../global/formula";
import { luckysheet_getcelldata } from "../../function/func";
import { getSheetIndex, getRangetxt, getvisibledatacolumn, getvisibledatarow } from "../../methods/get";
import { rowLocation, colLocation, mouseposition } from "../../global/location";
import { setluckysheet_scroll_status } from "../../methods/set";
import {
    luckysheetMoveHighlightCell,
    luckysheetMoveHighlightCell2,
    luckysheetMoveHighlightRange,
    luckysheetMoveHighlightRange2,
    luckysheetMoveEndCell,
} from "../../controllers/sheetMove";
import { isEditMode } from "../../global/validate";
import luckysheetsizeauto from "../../controllers/resize";
import multiInstanceStore from "../../store/multiInstance"; // 存储多实例Store wangxiaofei 1023.9.20
import luckysheetIc50Ec50 from "../../controllers/ic50Ec50";
import { getSheet } from "../../global/api"
import updateStoreExample from "../../controllers/updateStoreExample";
import currentSheet from "../../controllers/common/currentSheet"; // 引入当前实例相关js

// 设置公共图表实例调用方法，主要处理insertToStore，设定图表列表数据
let commonChartInfo = {
    chartparam: {
    },
};

// Dynamically load dependent scripts and styles
const dependScripts = [
    // 将依赖改为本地文件 -- modify by liuzhidong 2023/08/19
    "./expendPlugins/deps/vue.min.js",
    "./expendPlugins/deps/vuex.min.js",
    "./expendPlugins/deps/element.min.js",
    "./expendPlugins/deps/echarts.min.js",
    "./expendPlugins/chart/chartmix.umd.min.js",
];

const dependLinks = [
    // "https://unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css",
    // eln 样式混乱问题修改 -- modify by liluyao 2023/08/08
    "./expendPlugins/chart/element-ui_2_13_2_lib_theme-chalk_index.css",
    "./expendPlugins/chart/chartmix.css",
    // 'http://26.26.26.1:8000/chartmix.css'
];

let currentChartId = '';

// Initialize the chart component
function chart(options, config, isDemo) {
    const instance = this.instance;
    const data = options.data;
    instance.util.loadLinks(dependLinks);

    instance.util.seriesLoadScripts(dependScripts, null, function () {
        const containerId =  instance.getInstanceId();
        let Store = instance.getStore();
        let chartInfo = Store;
        let currentInstance = $(`#${containerId}`);
        const store = new Vuex.Store();
        console.info("chartmix::", chartmix.default);

        commonChartInfo.chartparam.insertToStore = chartmix.default.insertToStore;
        Vue.use(chartmix.default, { store });
        let outDom = currentInstance[0];
        chartmix.default.initChart(outDom, chartInfo.lang);

        currentInstance.find(`.chartSetting`).css({
            top: 'unset',
            bottom: 'unset',
            position: "absolute",
            right: "0px",
            width: "350px",
            height: $(`#${Store.container}`).height(),
            background: "#fff",

            border: "1px solid #E5E5E5",
            "z-index": 1004,
            "box-shadow": "0px 2px 4px rgba(0,0,0,0.2)",
            "-webkit-box-shadow": "0px 2px 4px rgba(0,0,0,0.2)",
            "-moz-box-shadow": "0px 2px 4px rgba(0,0,0,0.2)",
            "-moz-user-select": "none",
            "-khtml-user-select": "none",
            "-webkit-user-select": "none",
            "-ms-user-select": "none",
            "user-select": "none",
            "padding-left": "30px",
            display: "none",
        });

        chartInfo.createChart = chartmix.default.createChart;
        chartInfo.highlightChart = chartmix.default.highlightChart;
        chartInfo.deleteChart = chartmix.default.deleteChart;
        chartInfo.resizeChart = chartmix.default.resizeChart;
        chartInfo.changeChartRange = chartmix.default.changeChartRange;
        chartInfo.changeChartCellData = chartmix.default.changeChartCellData;
        chartInfo.getChartJson = chartmix.default.getChartJson;
        chartInfo.chart_selection = chart_selection();
        chartInfo.chartparam.jfrefreshchartall = jfrefreshchartall;
        chartInfo.chartparam.changeChartCellData = chartmix.default.changeChartCellData;
        chartInfo.chartparam.renderChart = chartmix.default.renderChart;
        chartInfo.chartparam.getChartJson = chartmix.default.getChartJson;
        // chartInfo.chartparam.insertToStore = chartmix.default.insertToStore;        

        // Initialize the rendering chart
        for (let i = 0; i < data.length; i++) {
            // if (data[i].status == '1') {
            renderCharts(data[i].chart, isDemo, instance);
            // }
        }

        for (let i = 0; i < data.length; i++) {
            if (data[i].status == "1") {
                renderChartShow(data[i].index, instance);
            }
        }

        // After the chart is loaded, mark it
        instance.util.arrayRemoveItem(chartInfo.asyncLoad, "chart");
    });
}

// rendercharts
function renderCharts(chartLists, isDemo, instance) {
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    // no chart
    if (chartLists == undefined) {
        return;
    }

    for (let i = 0; i < chartLists.length; i++) {
        let chart = chartLists[i];

        if (isDemo) {
            commonChartInfo.chartparam.insertToStore({ chart_id: chart.chart_id, chartOptions: chart.chartOptions });
        }

        let chart_id = chart.chart_id;
        let chart_id_c = chart_id + "_c";

        let modelChartShowHTML =
            '<div id="${id}"class="luckysheet-modal-dialog luckysheet-modal-dialog-chart ${addclass}"tabindex="0"role="dialog"aria-labelledby=":41e"dir="ltr"><div class="luckysheet-modal-dialog-resize"><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-lt"data-type="lt"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-mt"data-type="mt"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-lm"data-type="lm"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-rm"data-type="rm"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-rt"data-type="rt"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-lb"data-type="lb"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-mb"data-type="mb"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-rb"data-type="rb"></div></div><div class="luckysheet-modal-dialog-controll"><span class="luckysheet-modal-controll-btn luckysheet-modal-controll-update"role="button"tabindex="0"aria-label="修改图表"title="修改图表"><i class="fa fa-pencil"aria-hidden="true"></i></span><span class="luckysheet-modal-controll-btn luckysheet-modal-controll-del"role="button"tabindex="0"aria-label="删除"title="删除"><i class="fa fa-trash"aria-hidden="true"></i></span></div><div class="luckysheet-modal-dialog-content">${content}</div></div>';

        // 防止创建重复的id
        let $t;
        if (currentInstance.find(`#luckysheet-cell-main #${chart_id_c}`).length === 0) {
            $t = $(
                replaceHtml(modelChartShowHTML, {
                    id: chart_id_c,
                    addclass: "luckysheet-data-visualization-chart",
                    title: "图表生成",
                    content: "",
                }),
            ).appendTo(currentInstance.find("#luckysheet-cell-main"));
        }
        if (!$t) return;

        setChartMoveableEffect(instance);

        $(`#${chart_id_c}`).children(".luckysheet-modal-dialog-content")[0].id = chart_id;

        let container = document.getElementById(chart_id_c);

        let chart_json;
        chart_json = chartInfo.chartparam.getChartJson(chart.chart_id);

        chartInfo.chartparam.renderChart({ chart_id: chart.chart_id, chartOptions: chart_json });
        chartInfo.currentChart = chart_json;

        //处理区域高亮框参数，当前页中，只有当前的图表的needRangShow为true,其他为false
        showNeedRangeShow(chart_id, instance);

        // delete current chart
        $(`#${chart_id}_c .luckysheet-modal-controll-del`).click(function (e) {
            delChart(chart_id, instance);
        });

        // edit current chart
        $(`#${chart_id}_c .luckysheet-modal-controll-update`).click(function (e) {
            showChartSettingComponent('', chart_id, instance);
        });

        if (chart.chart_id.split('_')[0] === 'ic50ec50') {
            let ic50Ec50Chart = echarts.getInstanceByDom($(`#${chart.chart_id}`)[0])
            ic50Ec50Chart.on('click', 'series.scatter', function (params) {
                let currChartOption = chartInfo.currentChart
                currChartOption.defaultOption.seriesData[params.seriesIndex].splice(params.dataIndex, 1)
                currChartOption.defaultOption.xData[params.seriesIndex].splice(params.dataIndex, 1)
                updateIc50Ec50Data(currChartOption, true, instance)
            })
        }

        // 把图表坐标信息更新到sheetFile中
        $(`#${chart_id}_c`).mouseup(function (e) {
            const w = $(this).css("width");
            const h = $(this).css("height");
            const t = $(this).css("top");
            const l = $(this).css("left");

            // insert chartinfo
            let sheetFile = chartInfo.luckysheetfile[instance.get.getSheetIndex(chartInfo.currentSheetIndex)];

            if (!sheetFile.chart) {
                sheetFile.chart = [];
            }

            sheetFile.chart.forEach((item) => {
                if (item.chart_id === chart_id) {
                    item.width = parseFloat(w);
                    item.height = parseFloat(h);
                    item.left = parseFloat(l);
                    item.top = parseFloat(t);
                }
            });
        });

        $t.children(".luckysheet-modal-dialog-content").mousedown(function (e) {
            if (!chartInfo.chartparam.luckysheetCurrentChartMaxState) {
                //当前图表显示区域高亮
                showNeedRangeShow(chart_id, instance);
            }
            e.stopPropagation();
        });
        $t.mousedown(function (e) {
            // move chart
            // 非编辑模式禁止此操作
            if (instance.validate.isEditMode()) {
                return
            }
            if (!chartInfo.chartparam.luckysheetCurrentChartMaxState) {
                //当前图表显示区域高亮
                showNeedRangeShow(chart_id, instance);
                instance.set.setluckysheet_scroll_status(true);

                //允许拖动渲染框
                if (
                    !$(e.target).is(".luckysheet-modal-dialog-controll") &&
                    !$(e.target).is(".luckysheet-modal-controll-btn") &&
                    !$(e.target).is("i")
                ) {
                    // Debounce
                    chartInfo.chartparam.luckysheetCurrentChartMoveTimeout = setTimeout(function () {
                        chartInfo.chartparam.luckysheetCurrentChartMove = true;
                    }, 100);
                }

                var toffset = chartInfo.chartparam.luckysheetCurrentChartMoveObj.offset();
                var tpsition = chartInfo.chartparam.luckysheetCurrentChartMoveObj.position();
                //luckysheetCurrentChartMoveXy: [鼠标点相对chart框的距离X方向，鼠标点相对chart框的距离Y方向，chart框相对cell-main的距离X方向，chart框相对cell-main的距离Y方向，水平滚动条的位置，垂直滚动条的位置]
                chartInfo.chartparam.luckysheetCurrentChartMoveXy = [
                    e.pageX - toffset.left,
                    e.pageY - toffset.top,
                    tpsition.left,
                    tpsition.top,
                    currentInstance.find("#luckysheet-scrollbar-x").scrollLeft(),
                    currentInstance.find("#luckysheet-scrollbar-y").scrollTop(),
                ];
                chartInfo.chartparam.luckysheetCurrentChartMoveWinH = currentInstance.find("#luckysheet-cell-main")[0].scrollHeight;
                chartInfo.chartparam.luckysheetCurrentChartMoveWinW = currentInstance.find("#luckysheet-cell-main")[0].scrollWidth;

                if (
                    !$(e.target).hasClass("luckysheet-mousedown-cancel") &&
                    $(e.target).filter("[class*='sp-palette']").length == 0 &&
                    $(e.target).filter("[class*='sp-thumb']").length == 0 &&
                    $(e.target).filter("[class*='sp-']").length == 0
                ) {
                    currentInstance.find("#luckysheet-rightclick-menu").hide();
                    currentInstance.find("#luckysheet-cols-h-hover").hide();
                    currentInstance.find("#luckysheet-cols-menu-btn").hide();
                    currentInstance.find(`#luckysheet-sheet-list, #luckysheet-rightclick-sheet-menu, #luckysheet-user-menu`).hide();
                    currentInstance.find(
                        `.luckysheet-filter-menu, .luckysheet-filter-submenu, .luckysheet-cols-menu`
                    ).hide();
                }

                e.stopPropagation();
            }
        })
            .find(".luckysheet-modal-dialog-resize-item")
            .mousedown(function (e) {
                // 非编辑模式禁止此操作
                if (instance.validate.isEditMode()) {
                    return
                }
                if (chartInfo.chartparam.luckysheetCurrentChartActive) {
                    chartInfo.chartparam.luckysheetCurrentChartResize = $(this).data("type"); //开始状态resize

                    var mouse = instance.location.mouseposition(e.pageX, e.pageY),
                        scrollLeft = currentInstance.find("#luckysheet-scrollbar-x").scrollLeft(),
                        scrollTop = currentInstance.find("#luckysheet-scrollbar-y").scrollTop();
                    var x = mouse[0] + scrollLeft;
                    var y = mouse[1] + scrollTop;
                    var position = chartInfo.chartparam.luckysheetCurrentChartResizeObj.position();
                    //参数：x,y:鼠标位置，$t.width(), $t.height(): chart框宽高， position.left + scrollLeft, position.top + scrollTop ：chart框位置 ，scrollLeft, scrollTop：滚动条位置
                    chartInfo.chartparam.luckysheetCurrentChartResizeXy = [
                        x,
                        y,
                        $t.width(),
                        $t.height(),
                        position.left + scrollLeft,
                        position.top + scrollTop,
                        scrollLeft,
                        scrollTop,
                    ];
                    chartInfo.chartparam.luckysheetCurrentChartResizeWinH = currentInstance.find("#luckysheet-cell-main")[0].scrollHeight;
                    chartInfo.chartparam.luckysheetCurrentChartResizeWinW = currentInstance.find("#luckysheet-cell-main")[0].scrollWidth;

                    chartInfo.chartparam.luckysheetCurrentChart = chart_id;

                    e.stopPropagation();
                }
            });

        let width = chart.width;
        let height = chart.height;
        let left = chart.left;
        let top = chart.top;
        container.style.width = width + "px";
        container.style.height = height + "px";
        container.style.position = "absolute";
        container.style.background = "#fff";
        container.style.left = left + "px";
        container.style.top = top + "px";
        container.style.zIndex = chartInfo.zIndex ? chartInfo.zIndex : 15;
        chartInfo.zIndex++;
    }
}

function jfrefreshchartall(flowdata1, r_st, r_ed, c_st, c_ed, instance) {
    // let chart = chartInfo.currentChart;
    // if (!chart) {
    //     return;
    // }
    // if (chart.rangeArray.length == 1) {
    //     var row = chart.rangeArray[0].row;
    //     var column = chart.rangeArray[0].column;
    //     //不在范围内的不更新
    //     if (r_st > row[1] || r_ed < row[0] || c_st > column[1] || c_ed < column[0]) {
    //         return;
    //     }
    //     // 根据原有的范围取得数据
    //     var luckysheetgetcellrange = instance.formula.getcellrange(chart.rangeTxt);
    //     var sheetIndex = luckysheetgetcellrange.sheetIndex == -1 ? 0 : luckysheetgetcellrange.sheetIndex; //sheetIndex为-1时，转化为0
    //     var sheetName = Store.luckysheetfile.find((item) => item.index === sheetIndex).name;
    //     var sheetName;
    //     Store.luckysheetfile.forEach((item) => {
    //         if (item.chart && item.chart.length) {
    //             item.chart.forEach((ele) => {
    //                 if (ele.chart_id === chart.chart_id) {
    //                     sheetName = item.name;
    //                 }
    //             });
    //         }
    //     });
    //     var selection = {
    //         row: luckysheetgetcellrange.row,
    //         column: luckysheetgetcellrange.column,
    //         dataSheetIndex: sheetIndex,
    //     }; //数组
    //     var getcelldata = instance.func.luckysheet_getcelldata(sheetName + "!" + chart.rangeTxt);
    //     if (typeof getcelldata === "object" && getcelldata.length != 0 && getcelldata.data.length != null) {
    //         //getcelldata有值，且不为空数组 && getcelldata.data为二维数组
    //         var chartData = getcelldata.data;
    //         chartInfo.chartparam.changeChartCellData(chart.chart_id, chartData);
    //         if (getSheet().index === chart.sheetIndex) {
    //             if (chart.chart_id.split('_')[0] === 'ic50ec50') {
    //                 updateIc50Ec50Data(chartInfo.currentChart, false)
    //             }
    //         }
    //     }
    // }
    instance = instance || this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    const sheetInfo = Store.luckysheetfile.find((item) => item.index == Store.currentSheetIndex);
    const charts = sheetInfo.chart;
    charts &&
        charts.forEach((item) => {
            var row = item.chartOptions.rangeArray[0].row;
            var column = item.chartOptions.rangeArray[0].column;
            //不在范围内的不更新
            if (r_st > row[1] || r_ed < row[0] || c_st > column[1] || c_ed < column[0]) {
                return;
            }
            var getcelldata = instance.func.luckysheet_getcelldata(sheetInfo.name + "!" + item.chartOptions.rangeTxt);
            if (typeof getcelldata === "object" && getcelldata.length != 0 && getcelldata.data.length != null) {
                //getcelldata有值，且不为空数组 && getcelldata.data为二维数组
                // 解决数据删除之后，有时图表横轴的显示有问题[Object Object]
                var chartData = getcelldata.data.map(row => {
                    return row.map(col => {
                        if (col) {
                            col.v = col.v || ''
                            col.m = col.m || ''
                        }
                        return col
                    })
                });
                chartInfo.chartparam.changeChartCellData(item.chart_id, chartData);
                // luckysheet多实例图表选区数据更新图表更新数据不对 --modify by liluyao 2023/9/4
                item.chartOptions.chartData = chartData
                item.chartOptions.defaultOption.seriesData = []
                item.chartOptions.defaultOption.xData = []
                item.chartOptions.chartData[0].forEach((col, j) => {
                    if (j !== 0) {
                        let tempXData = []
                        let tempSerieData = []
                        item.chartOptions.chartData.forEach((row, i) => {
                            if (i !== 0) {
                                tempXData.push(row[0] ? Number(row[0].v) : NaN)
                                tempSerieData.push(row[j] ? Number(row[j].v) : NaN)
                            }
                        })
                        if (item.chart_id.split('_')[2] === 'logx') {
                            tempXData = tempXData.map(d => Math.log10(d))
                        }
                        item.chartOptions.defaultOption.xData.push(tempXData)
                        item.chartOptions.defaultOption.seriesData.push(tempSerieData)
                    }
                })
                if (item.chart_id.split('_')[0] === 'ic50ec50') {
                    updateIc50Ec50Data(item.chartOptions, false, instance)
                }
            }
        });
}

/**
 * 全部重新绘制
 * @param {*} chartLists 
 */
function redrawAllIC50EC50Chart(chartLists) {
    const instance = this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
	let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    // no chart
    if (chartLists == undefined) {
        return;
    }

    for (let i = 0; i < chartLists.length; i++) {
        let chart = chartLists[i];
        let chart_id = chart.chart_id;
        let chart_json = chartInfo.chartparam.getChartJson(chart.chart_id);
        // 全部重新绘制数据处理
        if (chart_id.split('_')[0] === 'ic50ec50') {
            chart_json.defaultOption.seriesData = []
            chart_json.defaultOption.xData = []
            chart_json.chartData[0].forEach((col, j) => {
                if (j !== 0) {
                    let tempXData = []
                    let tempSerieData = []
                    chart_json.chartData.forEach((row, i) => {
                        if (i !== 0) {
                            tempXData.push(row[0] ? Number(row[0].v) : NaN)
                            tempSerieData.push(row[j] ? Number(row[j].v) : NaN)
                        }
                    })
                    if (chart_id.split('_')[2] === 'logx') {
                        tempXData = tempXData.map(item => Math.log10(item))
                    }
                    chart_json.defaultOption.xData.push(tempXData)
                    chart_json.defaultOption.seriesData.push(tempSerieData)
                }
            })
            updateIc50Ec50Data(chart_json, false, instance)
        }
    }
}

/**
 * 
 * @param {*} a 
 * @param {*} isDeleteScatter  true false
 * @param {*} instance 实例this
 */
function updateIc50Ec50Data(a, isDeleteScatter, instance) {
    // 使用echarts-stat拟合折线图数据 start todo
    let chartInfo = instance.getStore();
    // 使用echarts-stat拟合折线图数据 end todo
    if (a.chart_id.split('_')[0] === 'ic50ec50') {
        a.defaultOption.axis.xAxisDown.type = 'value'
        if (!a.rangeColCheck.exits) {
            a.rangeColCheck.exits = true
            a.rangeSplitArray.coltitle = {
                column: [0, 0],
                row: [0, a.rangeSplitArray.range.row[1] - a.rangeSplitArray.range.row[0]],
            }
            a.rangeSplitArray.content = {
                row: [1, a.rangeSplitArray.range.row[1] - a.rangeSplitArray.range.row[0]],
                column: [1, a.rangeSplitArray.range.column[1] - a.rangeSplitArray.range.column[0]],
            }
            a.defaultOption.seriesData.splice(0, 1)
            // x轴
            a.rangeSplitArray.title = {
                column: [0, 0],
                row: [0, 0],
            }
            a.rangeSplitArray.type = 'normal'
            delete a.chartDataCache.title
            // 系列
            a.chartDataCache.label.splice(0, 1)
            a.chartDataSeriesOrder = {}
            a.chartDataCache.label.forEach((label, i) => {
                a.chartDataSeriesOrder[i] = i
            })
            a.defaultOption.legend.data.splice(0, 1)
        }
        let scatterSerieData = JSON.parse(JSON.stringify(a.defaultOption.series.find(serie => serie.type === 'scatter')))
        let lineSerieData = JSON.parse(JSON.stringify(a.defaultOption.series.find(item => item.type === 'line')))
        let xData = []
        if (isDeleteScatter) {
            xData = a.defaultOption.xData
        } else {
            a.defaultOption.xData = []
            a.defaultOption.seriesData.forEach(item => {
                let xDataTemp = []
                a.chartData.forEach((row, i) => {
                    if (i !== 0) {
                        xDataTemp.push(row[0] ? Number(row[0].v) : NaN)
                    }
                })
                if (a.chart_id.split('_')[2] === 'logx') {
                    xDataTemp = xDataTemp.map(d => Math.log10(d))
                }
                a.defaultOption.xData.push(xDataTemp)
            })
            xData = a.defaultOption.xData
        }
        let newSeries = []
        if (a.rangeSplitArray.rowtitle) {
            for (let ci = 0; ci < a.rangeSplitArray.rowtitle.column[1]; ci++) {
                let tempScatterSerieData = JSON.parse(JSON.stringify(scatterSerieData))
                tempScatterSerieData.name = a.chartData[0][ci + 1] ? a.chartData[0][ci + 1].v : ''
                tempScatterSerieData.data = []
                // 散点图数据
                for (let ri = 0; ri < xData[ci].length; ri++) {
                    tempScatterSerieData.data.push([xData[ci][ri], a.defaultOption.seriesData[ci][ri]])
                }
                newSeries.push(tempScatterSerieData)
            }
            a.defaultOption.series = newSeries
        }
        // 折线图数据
        for (let ci = 0; ci < a.defaultOption.seriesData.length; ci++) {
        // for (let ci = 0; ci < a.rangeSplitArray.rowtitle.column[1]; ci++) {
            // 使用echarts-stat拟合折线图数据 start todo
            let tempLineSerieData = JSON.parse(JSON.stringify(lineSerieData))
            tempLineSerieData.name = a.chartData[0][ci + 1] ? a.chartData[0][ci + 1].v : ''
            tempLineSerieData.type = 'line'
            tempLineSerieData.symbol = 'none'
            tempLineSerieData.smooth = 0.6
            let ecStatData = []
            // 折线图数据
            for (let ri = 0; ri < xData[ci].length; ri++) {
                ecStatData.push([xData[ci][ri], a.defaultOption.seriesData[ci][ri]])
            }
            let regression = ecStat.regression('polynomial', ecStatData, 4)
            regression.points.sort(function(a, b) {
                return a[0] - b[0];
            });
            tempLineSerieData.data = regression.points
            newSeries.push(tempLineSerieData)
            // 使用echarts-stat拟合折线图数据 end todo

            // 使用鹰谷接口计算IC50EC50数据（暂不使用）todo
            // computeIc50Ec50(xData[ci].join(','), a.defaultOption.seriesData[ci].join(','), a.ic50ec50Data.typeCode, a.ic50ec50Data.type, a.defaultOption.series[ci], lineSerieData, a, ci, instance)
        }
        // 使用echarts-stat拟合折线图数据 start todo
        a.defaultOption.series = newSeries
        chartInfo.chartparam.renderChart({ chart_id: a.chart_id, chartOptions: a });
        // 使用echarts-stat拟合折线图数据 end todo
    }
}

function computeIc50Ec50(px, py, typeCode, type, item, lineSerieData, chartOption, index, instance) {
    let data = {
        px: px,
        py: py,
        type: typeCode,
    }
    $.post({
        url: "http://chemicalservice.ineln.com/chemical/ic-fifty",
        data: JSON.stringify(data),
        success: (res) => {
            if (res.status == 1 && !Array.isArray(res.data)) {
                res.data = {
                    ...res.data,
                    Top: res.data.Top || 100,
                    Bottom: res.data.Bottom || 0,
                    hillScope: res.data.hillScope || -1,
                }
                getLineChartData(res.data, item, type, typeCode, lineSerieData, chartOption, index, instance)
                let itemValueArr = type.split('-')
                let rangeEndCol = chartOption.rangeArray[0].column[1]
                let rangeStartRow = chartOption.rangeArray[0].row[0]
                if (itemValueArr[0] === 'ic50') {
                    let resultData = []
                    let IC30 = computeICECxx(res.data, 30, type, typeCode)
                    let IC80 = computeICECxx(res.data, 80, type, typeCode)
                    let IC90 = computeICECxx(res.data, 90, type, typeCode)
                    let IC95 = computeICECxx(res.data, 95, type, typeCode)
                    let value = {
                        ...res.data,
                        name: item.name,
                        IC30: IC30,
                        IC80: IC80,
                        IC90: IC90,
                        IC95: IC95,
                    }
                    Object.keys(value).forEach(key => {
                        if (key !== 'name') {
                            value[key] = value[key].tofixed(4)
                        }
                    })
                    if (index === 0) {
                        resultData.push(['Title', 'IC50', 'LogIC50', 'HillSlope', 'Top', 'Bottom', 'IC30', 'IC80', 'IC90', 'IC95'])
                    }
                    resultData.push([value.name, value.IC50, value.LogIC50, value.hillScope, value.Top, value.Bottom, value.IC30, value.IC80, value.IC90, value.IC95])
                    if (index === 0) {
                        instance.api.setRangeValue(resultData, { range: { column: [rangeEndCol + 2, rangeEndCol + 11], row: [rangeStartRow + index, rangeStartRow + index + 1] } })
                    } else {
                        instance.api.setRangeValue(resultData, { range: { column: [rangeEndCol + 2, rangeEndCol + 11], row: [rangeStartRow + index + 1, rangeStartRow + index + 1] } })
                    }
                } else if (itemValueArr[0] === 'ec50') {
                    let resultData = []
                    let EC30 = computeICECxx(res.data, 30, type, typeCode)
                    let EC80 = computeICECxx(res.data, 80, type, typeCode)
                    let EC90 = computeICECxx(res.data, 90, type, typeCode)
                    let EC95 = computeICECxx(res.data, 95, type, typeCode)
                    let value = {
                        ...res.data,
                        name: item.name,
                        EC30: EC30,
                        EC80: EC80,
                        EC90: EC90,
                        EC95: EC95,
                    }
                    Object.keys(value).forEach(key => {
                        if (key !== 'name') {
                            value[key] = value[key].tofixed(4)
                        }
                    })
                    if (index === 0) {
                        resultData.push(['Title', 'EC50', 'LogEC50', 'HillSlope', 'Top', 'Bottom', 'EC30', 'EC80', 'EC90', 'EC95'])
                    }
                    resultData.push([value.name, value.EC50, value.LogEC50, value.hillScope, value.Top, value.Bottom, value.EC30, value.EC80, value.EC90, value.EC95])
                    if (index === 0) {
                        instance.api.setRangeValue(resultData, { range: { column: [rangeEndCol + 2, rangeEndCol + 11], row: [rangeStartRow + index, rangeStartRow + index + 1] } })
                    } else {
                        instance.api.setRangeValue(resultData, { range: { column: [rangeEndCol + 2, rangeEndCol + 11], row: [rangeStartRow + index + 1, rangeStartRow + index + 1] } })
                    }
                }
            }
        }
    })
}

function getLineChartData(data, item, type, typeCode, lineSerieData, chartOption, index, instance) {
    let Store = instance.getStore();
	let chartInfo = Store;
    let top = data.Top
    let bottom = data.Bottom
    let logIC50 = data.LogIC50
    let IC50 = data.IC50
    let hillScope = data.hillScope
    // x轴开始和结尾分成40份，绘制折线图
    let xData = chartOption.defaultOption.xData[index]
    let xLineData = JSON.parse(JSON.stringify(xData))
    let oneSplitPart = (xData[xData.length - 1] - xData[0]) / 40
    for (let i = 0; i < 40; i++) {
        xLineData.push(xData[0] + oneSplitPart * i)
    }
    xLineData.sort((a, b) => a - b)
    if (typeCode == 2 || typeCode == 4) {
        top = 100
        bottom = 0
    }
    if (typeCode == 5 || typeCode == 6) {
        // hillScope = -1
        // bottom = 0
    }
    let lineData = []
    for (let i = 0; i < xLineData.length; i++) {
        let y = 0
        if (typeCode === 1 || typeCode === 2 || typeCode === 5) {
            y = bottom + (top - bottom) / (1 + Math.pow(IC50 / xLineData[i], hillScope))
        } else {
            y = bottom + (top - bottom) / (1 + Math.pow(10, (logIC50 - xLineData[i]) * hillScope))
        }
        lineData.push([xLineData[i], y])
    }
    let tempLineSerieData = JSON.parse(JSON.stringify(lineSerieData))
    tempLineSerieData.data = lineData
    tempLineSerieData.name = item.name
    tempLineSerieData.symbol = 'none'
    tempLineSerieData.smooth = 0.6
    chartOption.defaultOption.series.push(tempLineSerieData)
    chartInfo.chartparam.renderChart({ chart_id: chartOption.chart_id, chartOptions: chartOption });
}

// 计算IC30、IC80、IC90、IC95
function computeICECxx(data, x, type, typeCode) {
    if (typeCode == 2 || typeCode == 4) {
        data.Top = 100
        data.Bottom = 0
    }
    if (typeCode == 5 || typeCode == 6) {
        // data.hillScope = -1
        // data.Bottom = 0
    }
    let result = ''
    let typeArr = type.split('-')
    if (typeArr[0] === 'ic50') {
        if (typeCode === 1 || typeCode === 2 || typeCode === 5) {
            result = data.IC50 / Math.pow((data.Top - data.Bottom) / (x - data.Bottom) - 1, 1 / data.hillScope)
        } else {
            result = Math.pow(10, (data.LogIC50 - Math.log10((data.Top - data.Bottom) / (x - data.Bottom) - 1)) / data.hillScope)
        }
    }
    if (typeArr[0] === 'ec50') {
        if (typeCode === 1 || typeCode === 2 || typeCode === 5) {
            result = data.EC50 / Math.pow((data.Top - data.Bottom) / (x - data.Bottom) - 1, 1 / data.hillScope)
        } else {
            result = Math.pow(10, (data.LogEC50 - Math.log10((data.Top - data.Bottom) / (x - data.Bottom) - 1)) / data.hillScope)
        }
    }
    return result
}

function chart_selection() {
    return {
        create: function (t) {
            const instance = t || this.instance;
            const containerId =  instance.getInstanceId();
            let Store = instance.getStore();
            let chartInfo = Store;
            let currentInstance = $(`#${containerId}`);
            var chart_json = chartInfo.currentChart;

            if (chart_json.rangeArray.length > 1) {
                return;
            }

            currentInstance.find("#luckysheet-chart-rangeShow").empty();
            currentInstance.find("#luckysheet-cell-selected-boxs").hide();
            currentInstance.find("#luckysheet-cell-selected-focus").hide();
            currentInstance.find("#luckysheet-rows-h-selected").empty();
            currentInstance.find("#luckysheet-cols-h-selected").empty();
            currentInstance.find("#luckysheet-row-count-show").hide();
            currentInstance.find("#luckysheet-column-count-show").hide();

            var st_r = chart_json.rangeArray[0].row[0];
            var st_c = chart_json.rangeArray[0].column[0];

            var rangeSplitArray = chart_json.rangeSplitArray;

            //首行是否标题
            var rangeRowCheck = chart_json.rangeRowCheck;

            if (rangeRowCheck.exits) {
                var chart_rowtitle_html = getRangeShowHtml(
                    "rowtitle",
                    rangeSplitArray.rowtitle.row[0] + st_r,
                    rangeSplitArray.rowtitle.row[1] + st_r,
                    rangeSplitArray.rowtitle.column[0] + st_c,
                    rangeSplitArray.rowtitle.column[1] + st_c,
                );
            } else {
                var chart_rowtitle_html = "";
            }

            //首列是否标题
            var rangeColCheck = chart_json.rangeColCheck;

            if (rangeColCheck.exits) {
                var chart_coltitle_html = getRangeShowHtml(
                    "coltitle",
                    rangeSplitArray.coltitle.row[0] + st_r,
                    rangeSplitArray.coltitle.row[1] + st_r,
                    rangeSplitArray.coltitle.column[0] + st_c,
                    rangeSplitArray.coltitle.column[1] + st_c,
                );
            } else {
                var chart_coltitle_html = "";
            }

            //内容块
            var chart_content_html = getRangeShowHtml(
                "content",
                rangeSplitArray.content.row[0] + st_r,
                rangeSplitArray.content.row[1] + st_r,
                rangeSplitArray.content.column[0] + st_c,
                rangeSplitArray.content.column[1] + st_c,
            );

            currentInstance.find("#luckysheet-chart-rangeShow").append(chart_rowtitle_html + chart_coltitle_html + chart_content_html);

            function getRangeShowHtml(type, r1, r2, c1, c2) {
                var visibledatarow = instance.get.getvisibledatarow();
                var visibledatacolumn = instance.get.getvisibledatacolumn();

                var row = visibledatarow[r2],
                    row_pre = r1 - 1 == -1 ? 0 : visibledatarow[r1 - 1];
                var col = visibledatacolumn[c2],
                    col_pre = c1 - 1 == -1 ? 0 : visibledatacolumn[c1 - 1];

                if (type == "rowtitle") {
                    var color = "#C65151";
                }

                if (type == "coltitle") {
                    var color = "#9667C0";
                }

                if (type == "content") {
                    var color = "#4970D1";
                }

                var html =
                    '<div id="luckysheet-chart-rangeShow-' +
                    type +
                    '" style="left: ' +
                    col_pre +
                    "px;width: " +
                    (col - col_pre - 1) +
                    "px;top: " +
                    row_pre +
                    "px;height: " +
                    (row - row_pre - 1) +
                    'px;border: none;margin: 0;position: absolute;z-index: 14;">' +
                    '<div class="luckysheet-chart-rangeShow-move" data-type="top" style="height: 2px;border-top: 2px solid #fff;border-bottom: 2px solid #fff;background: ' +
                    color +
                    ';position: absolute;left: 0;right: 0;top: -2px;z-index: 18;opacity: 0.9;cursor: move;"></div>' +
                    '<div class="luckysheet-chart-rangeShow-move" data-type="right" style="width: 2px;border-left: 2px solid #fff;border-right: 2px solid #fff;background: ' +
                    color +
                    ';position: absolute;top: 0;bottom: 0;right: -2px;z-index: 18;opacity: 0.9;cursor: move;"></div>' +
                    '<div class="luckysheet-chart-rangeShow-move" data-type="bottom" style="height: 2px;border-top: 2px solid #fff;border-bottom: 2px solid #fff;background: ' +
                    color +
                    ';position: absolute;left: 0;right: 0;bottom: -2px;z-index: 18;opacity: 0.9;cursor: move;"></div>' +
                    '<div class="luckysheet-chart-rangeShow-move" data-type="left" style="width: 2px;border-left: 2px solid #fff;border-right: 2px solid #fff;background: ' +
                    color +
                    ';position: absolute;top: 0;bottom: 0;left: -2px;z-index: 18;opacity: 0.9;cursor: move;"></div>' +
                    '<div style="border: 2px solid #85c0fc;background: ' +
                    color +
                    ';position: absolute;top: 0;right: 0;bottom: 0;left: 0;z-index: 15;opacity: 0.1;"></div>' +
                    '<div class="luckysheet-chart-rangeShow-resize" data-type="lt" style="width: 6px;height: 6px;border: 1px solid #fff;background: ' +
                    color +
                    ';position: absolute;left: -3px;top: -3px;z-index: 19;cursor: se-resize;"></div>' +
                    '<div class="luckysheet-chart-rangeShow-resize" data-type="rt" style="width: 6px;height: 6px;border: 1px solid #fff;background: ' +
                    color +
                    ';position: absolute;right: -3px;top: -3px;z-index: 19;cursor: ne-resize;"></div>' +
                    '<div class="luckysheet-chart-rangeShow-resize" data-type="lb" style="width: 6px;height: 6px;border: 1px solid #fff;background: ' +
                    color +
                    ';position: absolute;left: -3px;bottom: -3px;z-index: 19;cursor: ne-resize;"></div>' +
                    '<div class="luckysheet-chart-rangeShow-resize" data-type="rb" style="width: 6px;height: 6px;border: 1px solid #fff;background: ' +
                    color +
                    ';position: absolute;right: -3px;bottom: -3px;z-index: 19;cursor: se-resize;"></div>' +
                    "</div>";

                return html;
            }
        },
        rangeMove: false,
        rangeMovexy: null,
        rangeMoveIndex: null,
        rangeMoveObj: null,
        rangeMoveDraging: function (event, sheetBarHeight, statisticBarHeight, t) {
            const instance = t || this.instance;
            const containerId =  instance.getInstanceId();
            let Store = instance.getStore();
            let chartInfo = Store;
            let currentInstance = $(`#${containerId}`);
            var chart_json = chartInfo.currentChart;
            var st_r = chart_json.rangeArray[0].row[0];
            var st_c = chart_json.rangeArray[0].column[0];
            var rangeRowCheck = chart_json.rangeRowCheck;
            var rangeColCheck = chart_json.rangeColCheck;
            var rangeSplitArray = chart_json.rangeSplitArray;

            var mouse = instance.location.mouseposition(event.pageX, event.pageY);
            var scrollLeft = currentInstance.find("#luckysheet-cell-main").scrollLeft();
            var scrollTop = currentInstance.find("#luckysheet-cell-main").scrollTop();

            var x = mouse[0] + scrollLeft;
            var y = mouse[1] + scrollTop;

            var winH = $(window).height() + scrollTop - sheetBarHeight - statisticBarHeight,
                winW = $(window).width() + scrollLeft;

            var rowLocation = instance.location.rowLocation(y),
                row_index = rowLocation[2];
            var colLocation = instance.location.colLocation(x),
                col_index = colLocation[2];

            var visibledatarow = instance.get.getvisibledatarow();
            var visibledatacolumn = instance.get.getvisibledatacolumn();

            var $id = chartInfo.chart_selection.rangeMoveObj.attr("id");

            if ($id == "luckysheet-chart-rangeShow-content") {
                //行
                var row_s =
                    chartInfo.chart_selection.rangeMoveIndex[0] - chartInfo.chart_selection.rangeMovexy[0] + row_index;

                if (rangeRowCheck.exits) {
                    if (row_s < st_r + rangeRowCheck.range[1] + 1 || y < 0) {
                        row_s = st_r + rangeRowCheck.range[1] + 1;
                    }
                } else {
                    if (row_s < 0 || y < 0) {
                        row_s = 0;
                    }
                }

                var row_e = rangeSplitArray.content.row[1] - rangeSplitArray.content.row[0] + row_s;

                if (row_e >= visibledatarow.length - 1 || y > winH) {
                    row_s = visibledatarow.length - 1 - rangeSplitArray.content.row[1] + rangeSplitArray.content.row[0];
                    row_e = visibledatarow.length - 1;
                }

                //列
                var col_s =
                    chartInfo.chart_selection.rangeMoveIndex[1] - chartInfo.chart_selection.rangeMovexy[1] + col_index;
                if (rangeColCheck.exits) {
                    if (col_s < st_c + rangeColCheck.range[1] + 1 || x < 0) {
                        col_s = st_c + rangeColCheck.range[1] + 1;
                    }
                } else {
                    if (col_s < 0 || x < 0) {
                        col_s = 0;
                    }
                }

                var col_e = rangeSplitArray.content.column[1] - rangeSplitArray.content.column[0] + col_s;

                if (col_e >= visibledatacolumn.length - 1 || x > winW) {
                    col_s =
                        visibledatacolumn.length -
                        1 -
                        rangeSplitArray.content.column[1] +
                        rangeSplitArray.content.column[0];
                    col_e = visibledatacolumn.length - 1;
                }

                //更新
                if (rangeRowCheck.exits && rangeColCheck.exits) {
                    chart_json.rangeArray = [{ row: [st_r, row_e], column: [st_c, col_e] }];
                    chart_json.rangeSplitArray.range = {
                        row: [st_r, row_e],
                        column: [st_c, col_e],
                    };

                    chart_json.rangeSplitArray.content = {
                        row: [row_s - st_r, row_e - st_r],
                        column: [col_s - st_c, col_e - st_c],
                    };

                    chart_json.rangeSplitArray.rowtitle = {
                        row: chart_json.rangeSplitArray.rowtitle.row,
                        column: [col_s - st_c, col_e - st_c],
                    };

                    chart_json.rangeSplitArray.coltitle = {
                        row: [row_s - st_r, row_e - st_r],
                        column: chart_json.rangeSplitArray.coltitle.column,
                    };
                } else if (rangeRowCheck.exits) {
                    chart_json.rangeArray = [{ row: [st_r, row_e], column: [col_s, col_e] }];
                    chart_json.rangeSplitArray.range = {
                        row: [st_r, row_e],
                        column: [col_s, col_e],
                    };

                    chart_json.rangeSplitArray.content = {
                        row: [row_s - st_r, row_e - st_r],
                        column: chart_json.rangeSplitArray.content.column,
                    };
                } else if (rangeColCheck.exits) {
                    chart_json.rangeArray = [{ row: [row_s, row_e], column: [st_c, col_e] }];
                    chart_json.rangeSplitArray.range = {
                        row: [row_s, row_e],
                        column: [st_c, col_e],
                    };

                    chart_json.rangeSplitArray.content = {
                        row: chart_json.rangeSplitArray.content.row,
                        column: [col_s - st_c, col_e - st_c],
                    };
                } else {
                    chart_json.rangeArray = [{ row: [row_s, row_e], column: [col_s, col_e] }];
                    chart_json.rangeSplitArray.range = {
                        row: [row_s, row_e],
                        column: [col_s, col_e],
                    };
                }
            } else if ($id == "luckysheet-chart-rangeShow-rowtitle") {
                //列
                var col_s =
                    chartInfo.chart_selection.rangeMoveIndex[1] - chartInfo.chart_selection.rangeMovexy[1] + col_index;

                if (rangeColCheck.exits) {
                    if (col_s < st_c + rangeColCheck.range[1] + 1 || x < 0) {
                        col_s = st_c + rangeColCheck.range[1] + 1;
                    }
                } else {
                    if (col_s < 0 || x < 0) {
                        col_s = 0;
                    }
                }

                var col_e = rangeSplitArray.rowtitle.column[1] - rangeSplitArray.rowtitle.column[0] + col_s;

                if (col_e >= visibledatacolumn.length - 1 || x > winW) {
                    col_s =
                        visibledatacolumn.length -
                        1 -
                        rangeSplitArray.rowtitle.column[1] +
                        rangeSplitArray.rowtitle.column[0];
                    col_e = visibledatacolumn.length - 1;
                }

                //更新
                if (rangeColCheck.exits) {
                    chart_json.rangeArray = [{ row: chart_json.rangeArray[0].row, column: [st_c, col_e] }];
                    chart_json.rangeSplitArray.range = {
                        row: chart_json.rangeArray[0].row,
                        column: [st_c, col_e],
                    };

                    chart_json.rangeSplitArray.rowtitle = {
                        row: chart_json.rangeSplitArray.rowtitle.row,
                        column: [col_s - st_c, col_e - st_c],
                    };
                    chart_json.rangeSplitArray.content = {
                        row: chart_json.rangeSplitArray.content.row,
                        column: [col_s - st_c, col_e - st_c],
                    };
                } else {
                    chart_json.rangeArray = [{ row: chart_json.rangeArray[0].row, column: [col_s, col_e] }];
                    chart_json.rangeSplitArray.range = {
                        row: chart_json.rangeArray[0].row,
                        column: [col_s, col_e],
                    };
                }
            } else if ($id == "luckysheet-chart-rangeShow-coltitle") {
                //行
                var row_s =
                    chartInfo.chart_selection.rangeMoveIndex[0] - chartInfo.chart_selection.rangeMovexy[0] + row_index;
                if (rangeRowCheck.exits) {
                    if (row_s < st_r + rangeRowCheck.range[1] + 1 || y < 0) {
                        row_s = st_r + rangeRowCheck.range[1] + 1;
                    }
                } else {
                    if (row_s < 0 || y < 0) {
                        row_s = 0;
                    }
                }

                //更新
                var row_e = rangeSplitArray.coltitle.row[1] - rangeSplitArray.coltitle.row[0] + row_s;

                if (row_e >= visibledatarow.length - 1 || y > winH) {
                    row_s =
                        visibledatarow.length - 1 - rangeSplitArray.coltitle.row[1] + rangeSplitArray.coltitle.row[0];
                    row_e = visibledatarow.length - 1;
                }

                if (rangeRowCheck.exits) {
                    chart_json.rangeArray = [{ row: [st_r, row_e], column: chart_json.rangeArray[0].column }];
                    chart_json.rangeSplitArray.range = {
                        row: [st_r, row_e],
                        column: chart_json.rangeArray[0].column,
                    };

                    chart_json.rangeSplitArray.coltitle = {
                        row: [row_s - st_r, row_e - st_r],
                        column: chart_json.rangeSplitArray.coltitle.column,
                    };
                    chart_json.rangeSplitArray.content = {
                        row: [row_s - st_r, row_e - st_r],
                        column: chart_json.rangeSplitArray.content.column,
                    };
                } else {
                    chart_json.rangeArray = [{ row: [row_s, row_e], column: chart_json.rangeArray[0].column }];
                    chart_json.rangeSplitArray.range = {
                        row: [row_s, row_e],
                        column: chart_json.rangeArray[0].column,
                    };
                }
            }

            chartInfo.chart_selection.create(instance);
        },
        rangeMoveDragged: function (t) {
            const instance = t || this.instance;
            const containerId =  instance.getInstanceId();
            let Store = instance.getStore();
	        let chartInfo = Store;
            let currentInstance = $(`#${containerId}`);
            chartInfo.chart_selection.rangeMove = false;

            var updateJson = chartInfo.currentChart;

            updateJson.rangeTxt = instance.get.getRangetxt(
                chartInfo.currentSheetIndex,
                updateJson.rangeArray[0],
                chartInfo.currentSheetIndex,
            );
            updateJson.chartData = instance.getdata.getdatabyselection(updateJson.rangeArray[0], chartInfo.currentSheetIndex);
            // 渲染
            chartInfo.changeChartRange(
                updateJson.chart_id,
                updateJson.chartData,
                updateJson.rangeArray,
                updateJson.rangeTxt,
            );
            chartInfo.luckysheetfile.forEach((sheet, i) => {
                if (sheet.index == chartInfo.currentSheetIndex) {
                    sheet.chart.forEach((item, j) => {
                        if (item.chart_id === updateJson.chart_id) {
                            chartInfo.luckysheetfile[i].chart[j].chartOptions = chartInfo.currentChart
                        }
                    })
                }
            })
            if (updateJson.chart_id.split('_')[0] === 'ic50ec50') {
                updateIc50Ec50Data(updateJson, false, instance)
            }
        },
        rangeResize: false,
        rangeResizexy: null,
        rangeResizeIndex: null,
        rangeResizeObj: null,
        rangeResizeDraging: function (event, sheetBarHeight, statisticBarHeight, t) {
            const instance = t || this.instance;
            const containerId =  instance.getInstanceId();
            let Store = instance.getStore();
            let chartInfo = Store;
            let currentInstance = $(`#${containerId}`);
            var chart_json = chartInfo.currentChart;

            var st_r = chart_json.rangeArray[0].row[0];
            var st_c = chart_json.rangeArray[0].column[0];
            var rangeRowCheck = chart_json.rangeRowCheck;
            var rangeColCheck = chart_json.rangeColCheck;
            var rangeSplitArray = chart_json.rangeSplitArray;

            var mouse = instance.location.mouseposition(event.pageX, event.pageY);
            var scrollLeft = currentInstance.find("#luckysheet-cell-main").scrollLeft();
            var scrollTop = currentInstance.find("#luckysheet-cell-main").scrollTop();

            var x = mouse[0] + scrollLeft;
            var y = mouse[1] + scrollTop;

            var winH = $(window).height() + scrollTop - sheetBarHeight - statisticBarHeight,
                winW = $(window).width() + scrollLeft;

            var rowLocation = instance.location.rowLocation(y),
                row_index = rowLocation[2];
            var colLocation = instance.location.colLocation(x),
                col_index = colLocation[2];

            var visibledatarow = instance.get.getvisibledatarow();
            var visibledatacolumn = instance.get.getvisibledatacolumn();

            var $id = chartInfo.chart_selection.rangeResizeObj.attr("id");

            if ($id == "luckysheet-chart-rangeShow-content") {
                var r1, r2, c1, c2;

                if (chartInfo.chart_selection.rangeResize == "lt") {
                    r1 = chartInfo.chart_selection.rangeResizeIndex.row[0];
                    c1 = chartInfo.chart_selection.rangeResizeIndex.column[0];

                    r2 = chartInfo.chart_selection.rangeResizeIndex.row[1];
                    c2 = chartInfo.chart_selection.rangeResizeIndex.column[1];
                } else if (chartInfo.chart_selection.rangeResize == "lb") {
                    r1 = chartInfo.chart_selection.rangeResizeIndex.row[1];
                    c1 = chartInfo.chart_selection.rangeResizeIndex.column[0];

                    r2 = chartInfo.chart_selection.rangeResizeIndex.row[0];
                    c2 = chartInfo.chart_selection.rangeResizeIndex.column[1];
                } else if (chartInfo.chart_selection.rangeResize == "rt") {
                    r1 = chartInfo.chart_selection.rangeResizeIndex.row[0];
                    c1 = chartInfo.chart_selection.rangeResizeIndex.column[1];

                    r2 = chartInfo.chart_selection.rangeResizeIndex.row[1];
                    c2 = chartInfo.chart_selection.rangeResizeIndex.column[0];
                } else if (chartInfo.chart_selection.rangeResize == "rb") {
                    r1 = chartInfo.chart_selection.rangeResizeIndex.row[1];
                    c1 = chartInfo.chart_selection.rangeResizeIndex.column[1];

                    r2 = chartInfo.chart_selection.rangeResizeIndex.row[0];
                    c2 = chartInfo.chart_selection.rangeResizeIndex.column[0];
                }

                //行
                if (rangeRowCheck.exits) {
                    var row_s = r1 - chartInfo.chart_selection.rangeResizexy[0] + row_index;

                    if (row_s < st_r + rangeRowCheck.range[1] + 1 || y < 0) {
                        row_s = st_r + rangeRowCheck.range[1] + 1;
                    } else if (row_s >= visibledatarow.length - 1 || y > winH) {
                        row_s = visibledatarow.length - 1;
                    }
                } else {
                    var row_s = st_r - chartInfo.chart_selection.rangeResizexy[0] + row_index;

                    if (row_s < 0 || y < 0) {
                        row_s = 0;
                    } else if (row_s >= visibledatarow.length - 1 || y > winH) {
                        row_s = visibledatarow.length - 1;
                    }
                }

                //列
                if (rangeColCheck.exits) {
                    var col_s = c1 - chartInfo.chart_selection.rangeResizexy[1] + col_index;

                    if (col_s < st_c + rangeColCheck.range[1] + 1 || x < 0) {
                        col_s = st_c + rangeColCheck.range[1] + 1;
                    } else if (col_s >= visibledatacolumn.length - 1 || x > winW) {
                        col_s = visibledatacolumn.length - 1;
                    }
                } else {
                    var col_s = st_c - chartInfo.chart_selection.rangeResizexy[1] + col_index;

                    if (col_s < 0 || x < 0) {
                        col_s = 0;
                    } else if (col_s >= visibledatacolumn.length - 1 || x > winW) {
                        col_s = visibledatacolumn.length - 1;
                    }
                }

                var obj_r1, obj_r2, obj_c1, obj_c2;

                if (row_s > r2) {
                    obj_r1 = r2;
                    obj_r2 = row_s;
                } else {
                    obj_r1 = row_s;
                    obj_r2 = r2;
                }

                if (col_s > c2) {
                    obj_c1 = c2;
                    obj_c2 = col_s;
                } else {
                    obj_c1 = col_s;
                    obj_c2 = c2;
                }

                if (!rangeRowCheck.exits && !rangeColCheck.exits) {
                    chart_json.rangeArray = [{ row: [obj_r1, obj_r2], column: [obj_c1, obj_c2] }];
                    chart_json.rangeSplitArray.range = {
                        row: [obj_r1, obj_r2],
                        column: [obj_c1, obj_c2],
                    };
                } else {
                    chart_json.rangeArray = [{ row: [st_r, obj_r2], column: [st_c, obj_c2] }];
                    chart_json.rangeSplitArray.range = {
                        row: [st_r, obj_r2],
                        column: [st_c, obj_c2],
                    };

                    chart_json.rangeSplitArray.content = {
                        row: [obj_r1 - st_r, obj_r2 - st_r],
                        column: [obj_c1 - st_c, obj_c2 - st_c],
                    };

                    if (rangeRowCheck.exits) {
                        chart_json.rangeSplitArray.rowtitle = {
                            row: chart_json.rangeSplitArray.rowtitle.row,
                            column: [obj_c1 - st_c, obj_c2 - st_c],
                        };
                    }

                    if (rangeColCheck.exits) {
                        chart_json.rangeSplitArray.coltitle = {
                            row: [obj_r1 - st_r, obj_r2 - st_r],
                            column: chart_json.rangeSplitArray.coltitle.column,
                        };
                    }
                }
            } else if ($id == "luckysheet-chart-rangeShow-rowtitle") {
                var c1, c2;

                if (chartInfo.chart_selection.rangeResize == "lt" || chartInfo.chart_selection.rangeResize == "lb") {
                    c1 = chartInfo.chart_selection.rangeResizeIndex.column[0];
                    c2 = chartInfo.chart_selection.rangeResizeIndex.column[1];
                } else if (
                    chartInfo.chart_selection.rangeResize == "rt" ||
                    chartInfo.chart_selection.rangeResize == "rb"
                ) {
                    c1 = chartInfo.chart_selection.rangeResizeIndex.column[1];
                    c2 = chartInfo.chart_selection.rangeResizeIndex.column[0];
                }

                //列
                if (rangeColCheck.exits) {
                    var col_s = c1 - chartInfo.chart_selection.rangeResizexy[1] + col_index;

                    if (col_s < st_c + rangeColCheck.range[1] + 1 || x < 0) {
                        col_s = st_c + rangeColCheck.range[1] + 1;
                    } else if (col_s >= visibledatacolumn.length - 1 || x > winW) {
                        col_s = visibledatacolumn.length - 1;
                    }
                } else {
                    var col_s = st_c - chartInfo.chart_selection.rangeResizexy[1] + col_index;

                    if (col_s < 0 || x < 0) {
                        col_s = 0;
                    } else if (col_s >= visibledatacolumn.length - 1 || x > winW) {
                        col_s = visibledatacolumn.length - 1;
                    }
                }

                var obj_c1, obj_c2;

                if (col_s > c2) {
                    obj_c1 = c2;
                    obj_c2 = col_s;
                } else {
                    obj_c1 = col_s;
                    obj_c2 = c2;
                }

                //更新
                if (!rangeColCheck.exits) {
                    chart_json.rangeArray = [{ row: chart_json.rangeArray[0].row, column: [obj_c1, obj_c2] }];
                    chart_json.rangeSplitArray.range = {
                        row: chart_json.rangeArray[0].row,
                        column: [obj_c1, obj_c2],
                    };
                } else {
                    chart_json.rangeArray = [{ row: chart_json.rangeArray[0].row, column: [st_c, obj_c2] }];
                    chart_json.rangeSplitArray.range = {
                        row: chart_json.rangeArray[0].row,
                        column: [st_c, obj_c2],
                    };

                    chart_json.rangeSplitArray.rowtitle = {
                        row: chart_json.rangeSplitArray.rowtitle.row,
                        column: [obj_c1 - st_c, obj_c2 - st_c],
                    };
                    chart_json.rangeSplitArray.content = {
                        row: chart_json.rangeSplitArray.content.row,
                        column: [obj_c1 - st_c, obj_c2 - st_c],
                    };
                }
            } else if ($id == "luckysheet-chart-rangeShow-coltitle") {
                var r1, r2;

                if (chartInfo.chart_selection.rangeResize == "lt" || chartInfo.chart_selection.rangeResize == "rt") {
                    r1 = chartInfo.chart_selection.rangeResizeIndex.row[0];
                    r2 = chartInfo.chart_selection.rangeResizeIndex.row[1];
                } else if (
                    chartInfo.chart_selection.rangeResize == "lb" ||
                    chartInfo.chart_selection.rangeResize == "rb"
                ) {
                    r1 = chartInfo.chart_selection.rangeResizeIndex.row[1];
                    r2 = chartInfo.chart_selection.rangeResizeIndex.row[0];
                }

                //行
                if (rangeRowCheck.exits) {
                    var row_s = r1 - chartInfo.chart_selection.rangeResizexy[0] + row_index;

                    if (row_s < st_r + rangeRowCheck.range[1] + 1 || y < 0) {
                        row_s = st_r + rangeRowCheck.range[1] + 1;
                    } else if (row_s >= visibledatarow.length - 1 || y > winH) {
                        row_s = visibledatarow.length - 1;
                    }
                } else {
                    var row_s = st_r - chartInfo.chart_selection.rangeResizexy[0] + row_index;

                    if (row_s < 0 || y < 0) {
                        row_s = 0;
                    } else if (row_s >= visibledatarow.length - 1 || y > winH) {
                        row_s = visibledatarow.length - 1;
                    }
                }

                var obj_r1, obj_r2;

                if (row_s > r2) {
                    obj_r1 = r2;
                    obj_r2 = row_s;
                } else {
                    obj_r1 = row_s;
                    obj_r2 = r2;
                }

                //更新
                if (!rangeRowCheck.exits) {
                    chart_json.rangeArray = [{ row: [obj_r1, obj_r2], column: chart_json.rangeArray[0].column }];
                    chart_json.rangeSplitArray.range = {
                        row: [obj_r1, obj_r2],
                        column: chart_json.rangeArray[0].column,
                    };
                } else {
                    chart_json.rangeArray = [{ row: [st_r, obj_r2], column: chart_json.rangeArray[0].column }];
                    chart_json.rangeSplitArray.range = {
                        row: [st_r, obj_r2],
                        column: chart_json.rangeArray[0].column,
                    };

                    chart_json.rangeSplitArray.coltitle = {
                        row: [obj_r1 - st_r, obj_r2 - st_r],
                        column: chart_json.rangeSplitArray.coltitle.column,
                    };
                    chart_json.rangeSplitArray.content = {
                        row: [obj_r1 - st_r, obj_r2 - st_r],
                        column: chart_json.rangeSplitArray.content.column,
                    };
                }
            }

            chartInfo.chart_selection.create(instance);
        },
        rangeResizeDragged: function (t) {
            const instance = t || this.instance;
            const containerId =  instance.getInstanceId();
            let Store = instance.getStore();
	        let chartInfo = Store;
            let currentInstance = $(`#${containerId}`);
            chartInfo.chart_selection.rangeResize = null;
            var updateJson = chartInfo.currentChart;

            updateJson.rangeTxt = instance.get.getRangetxt(
                chartInfo.currentSheetIndex,
                updateJson.rangeArray[0],
                chartInfo.currentSheetIndex,
            );
            updateJson.chartData = instance.getdata.getdatabyselection(updateJson.rangeArray[0], chartInfo.currentSheetIndex);
            // 渲染
            chartInfo.changeChartRange(
                updateJson.chart_id,
                updateJson.chartData,
                updateJson.rangeArray,
                updateJson.rangeTxt,
            );
            chartInfo.luckysheetfile.forEach((sheet, i) => {
                if (sheet.index == chartInfo.currentSheetIndex) {
                    sheet.chart.forEach((item, j) => {
                        if (item.chart_id === updateJson.chart_id) {
                            chartInfo.luckysheetfile[i].chart[j].chartOptions = chartInfo.currentChart
                        }
                    })
                }
            })
            if (updateJson.chart_id.split('_')[0] === 'ic50ec50') {
                updateIc50Ec50Data(updateJson, false, instance)
            }
        },
    };
}

// create chart
function createLuckyChart(width, height, left, top, type, itemvalue, updateChart) {
    const instance = this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    // 当前luckysheet实例调用
    const nowSheet = luckysheet[containerId];
    //如果只选中一个单元格，则自动填充选取
    var jfgird_select_save = instance.get.getluckysheet_select_save();
    if (
        jfgird_select_save.length == 1 &&
        jfgird_select_save[0].row[0] == jfgird_select_save[0].row[1] &&
        jfgird_select_save[0].column[0] == jfgird_select_save[0].column[1]
    ) {
        instance.sheetMove.luckysheetMoveHighlightRange2("right", "rangeOfSelect");

        instance.sheetMove.luckysheetMoveHighlightRange2("down", "rangeOfSelect");

        jfgird_select_save = instance.get.getluckysheet_select_save();
    }
    //处理右边的空白单元格，自动略过并修改选区 ---------------start
    var shiftpositon_row = -1;

    var row_ed = jfgird_select_save[0]["row"][1] - jfgird_select_save[0]["row"][0];
    for (var r = jfgird_select_save[0]["row"][0]; r <= jfgird_select_save[0]["row"][1]; r++) {
        for (var c = jfgird_select_save[0]["column"][0]; c <= jfgird_select_save[0]["column"][1]; c++) {
            var value = instance.getdata.getcellvalue(r, c, nowSheet.flowdata());
            //console.log("value,r,c",value,r,c);
            if (value != null && value.toString().length > 0) {
                shiftpositon_row = r;
                break;
            }
        }

        if (shiftpositon_row !== -1) {
            break;
        }
    }

    if (shiftpositon_row == -1) {
        shiftpositon_row = 0;
    }

    jfgird_select_save[0]["row"] = [shiftpositon_row, shiftpositon_row];
    jfgird_select_save[0].row_focus = shiftpositon_row;
    instance.set.setluckysheet_select_save(jfgird_select_save);

    chartInfo.luckysheet_shiftpositon = $.extend(true, {}, jfgird_select_save[0]);
    instance.sheetMove.luckysheetMoveEndCell("down", "range", false, row_ed);
    jfgird_select_save = instance.get.getluckysheet_select_save();

    var shiftpositon_col = -1;
    var column_ed = jfgird_select_save[0]["column"][1] - jfgird_select_save[0]["column"][0];
    for (var c = jfgird_select_save[0]["column"][0]; c <= jfgird_select_save[0]["column"][1]; c++) {
        for (var r = jfgird_select_save[0]["row"][0]; r <= jfgird_select_save[0]["row"][1]; r++) {
            var value = instance.getdata.getcellvalue(r, c, nowSheet.flowdata());
            if (value != null && value.toString().length > 0) {
                shiftpositon_col = c;
                break;
            }
        }

        if (shiftpositon_col !== -1) {
            break;
        }
    }

    if (shiftpositon_col == -1) {
        shiftpositon_col = 0;
    }

    jfgird_select_save[0]["column"] = [shiftpositon_col, shiftpositon_col];
    jfgird_select_save[0].column_focus = shiftpositon_col;
    instance.set.setluckysheet_select_save(jfgird_select_save);

    chartInfo.luckysheet_shiftpositon = $.extend(true, {}, jfgird_select_save[0]);
    instance.sheetMove.luckysheetMoveEndCell("right", "range", false, column_ed);
    jfgird_select_save = instance.get.getluckysheet_select_save();

    var rangeArray = $.extend(true, [], jfgird_select_save);

    var rangeTxt = instance.get.getRangetxt(chartInfo.currentSheetIndex, rangeArray[0], chartInfo.currentSheetIndex);

    let chartData = instance.getdata.getdatabyselection();
    // console.dir(chartData);

    // let chart_id = generateRandomKey("chart");
    let chart_id = ''
    if (updateChart) {
        chart_id = updateChart.chart_id
    } else {
        chart_id = generateRandomKey("chart")+"---"+Store.container+"---";
        if (type == 'ic50ec50') {
            let itemValueArr = itemvalue.split('-')
            chart_id = `ic50ec50_${itemValueArr[0]}_${itemValueArr[1]}_${itemValueArr[2]}_${chart_id}`
        }
    }

    let chart_id_c = chart_id + "_c";

    let modelChartShowHTML =
        '<div id="${id}"class="luckysheet-modal-dialog luckysheet-modal-dialog-chart ${addclass}"tabindex="0"role="dialog"aria-labelledby=":41e"dir="ltr"><div class="luckysheet-modal-dialog-resize"><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-lt"data-type="lt"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-mt"data-type="mt"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-lm"data-type="lm"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-rm"data-type="rm"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-rt"data-type="rt"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-lb"data-type="lb"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-mb"data-type="mb"></div><div class="luckysheet-modal-dialog-resize-item luckysheet-modal-dialog-resize-item-rb"data-type="rb"></div></div><div class="luckysheet-modal-dialog-controll"><span class="luckysheet-modal-controll-btn luckysheet-modal-controll-update"role="button"tabindex="0"aria-label="修改图表"title="修改图表"><i class="fa fa-pencil"aria-hidden="true"></i></span><span class="luckysheet-modal-controll-btn luckysheet-modal-controll-del"role="button"tabindex="0"aria-label="删除"title="删除"><i class="fa fa-trash"aria-hidden="true"></i></span></div><div class="luckysheet-modal-dialog-content">${content}</div></div>';

    if (updateChart) {
        delChart(updateChart.chart_id, instance)
    }

    let $t = $(
        replaceHtml(modelChartShowHTML, {
            id: chart_id_c,
            addclass: "luckysheet-data-visualization-chart",
            title: "图表生成",
            content: "",
        }),
    ).appendTo(currentInstance.find("#luckysheet-cell-main"));

    let container = document.getElementById(chart_id_c);

    let { render, chart_json } = chartInfo.createChart(
        $(`#${chart_id_c}`).children(".luckysheet-modal-dialog-content")[0],
        chartData,
        chart_id,
        rangeArray,
        rangeTxt,
    );
    // chartInfo.currentChart = chart_json.chartOptions
    // console.dir(JSON.stringify(chart_json));

    width = width ? width : 400;
    height = height ? height : 250;
    left = left ? left : 0;
    top = top ? top : 0;
    container.style.width = width + "px";
    container.style.height = height + "px";
    container.style.position = "absolute";
    container.style.background = "#fff";
    container.style.left = left + "px";
    container.style.top = top + "px";
    render.style.width = "100%";
    render.style.height = "100%";
    container.style.zIndex = chartInfo.zIndex ? chartInfo.zIndex : 15;
    chartInfo.zIndex++;

    // insert chartinfo
    let sheetFile = chartInfo.luckysheetfile[instance.get.getSheetIndex(chartInfo.currentSheetIndex)];

    if (!sheetFile.chart) {
        sheetFile.chart = [];
    }
    let chartOption = chart_json.chartOptions

    if (type == 'ic50ec50') {
        // set number 1 column as title
        chartOption.rangeColCheck.exits = true
        chartOption.rangeRowCheck.exits = true
        chartOption.rangeSplitArray.rowtitle = {
            row: [0, 0],
            column: [0, column_ed],
        }
        chartOption.rangeSplitArray.coltitle = {
            row: [0, row_ed],
            column: [0, 0],
        }
        chartOption.rangeSplitArray.content = {
            row: [1, row_ed],
            column: [1, column_ed],
        }
        // x轴
        chartOption.rangeSplitArray.title = {
            column: [0, 0],
            row: [0, 0],
        }
        chartOption.rangeSplitArray.type = 'normal'
        delete chartOption.chartDataCache.title
        // 散点图
        chartOption.chartAllType = 'echarts|scatter|default'
        chartOption.chartType = 'scatter'
        chartOption.defaultOption.axis.xAxisDown.type = 'value'
        chartOption.defaultOption.axis.xAxisUp.type = 'value'
        if (chartOption.defaultOption.axis.xAxisDown.axisLine) {
            chartOption.defaultOption.axis.xAxisDown.axisLine.onZero = false
        } else {
            chartOption.defaultOption.axis.xAxisDown.axisLine = {
                onZero: false,
            }
        }
        if (chartOption.defaultOption.axis.xAxisUp.axisLine) {
            chartOption.defaultOption.axis.xAxisUp.axisLine.onZero = false
        } else {
            chartOption.defaultOption.axis.xAxisUp.axisLine = {
                onZero: false,
            }
        }
        // 系列
        chartOption.chartDataCache.label.splice(0, 1)
        chartOption.chartDataSeriesOrder = {}
        chartOption.chartDataCache.label.forEach((label, i) => {
            chartOption.chartDataSeriesOrder[i] = i
        })
        chartOption.defaultOption.legend.data.splice(0, 1)
        // 图表数据
        let lineSerieData = JSON.parse(JSON.stringify(chartOption.defaultOption.series.find(item => item.type === 'line')))
        // 散点图数据
        chartOption.defaultOption.seriesData = []
        chartOption.defaultOption.xData = []
        chartOption.chartData[0].forEach((col, j) => {
            if (j !== 0) {
                let tempXData = []
                let tempSerieData = []
                chartOption.chartData.forEach((row, i) => {
                    if (i !== 0) {
                        tempXData.push(row[0] ? Number(row[0].v) : NaN)
                        tempSerieData.push(row[j] ? Number(row[j].v) : NaN)
                    }
                })
                if (chart_id.split('_')[2] === 'logx') {
                    tempXData = tempXData.map(item => Math.log10(item))
                }
                chartOption.defaultOption.xData.push(tempXData)
                chartOption.defaultOption.seriesData.push(tempSerieData)
            }
        })
        let newSeries = []
        let xData = chartOption.defaultOption.xData
        if (chartOption.rangeSplitArray.rowtitle) {
            for (let ci = 0; ci < chartOption.rangeSplitArray.rowtitle.column[1]; ci++) {
                let tempScatterSerieData = JSON.parse(JSON.stringify(lineSerieData))
                tempScatterSerieData.name = chartOption.chartData[0][ci + 1] ? chartOption.chartData[0][ci + 1].v : ''
                tempScatterSerieData.type = 'scatter'
                tempScatterSerieData.itemStyle.borderWidth = 0
                tempScatterSerieData.data = []
                // 散点图数据
                for (let ri = 0; ri < xData[ci].length; ri++) {
                    tempScatterSerieData.data.push([xData[ci][ri], chartOption.defaultOption.seriesData[ci][ri]])
                }
                newSeries.push(tempScatterSerieData)
            }
            // 拟合折线图数据
            // 使用echarts-stat拟合折线图数据 start todo
            for (let ci = 0; ci < chartOption.rangeSplitArray.rowtitle.column[1]; ci++) {
                let tempLineSerieData = JSON.parse(JSON.stringify(lineSerieData))
                tempLineSerieData.name = chartOption.chartData[0][ci + 1] ? chartOption.chartData[0][ci + 1].v : ''
                tempLineSerieData.type = 'line'
                tempLineSerieData.symbol = 'none'
                tempLineSerieData.smooth = 0.6
                let ecStatData = []
                // 折线图数据
                for (let ri = 0; ri < xData[ci].length; ri++) {
                    ecStatData.push([xData[ci][ri], chartOption.defaultOption.seriesData[ci][ri]])
                }
                let regression = ecStat.regression('polynomial', ecStatData, 4)
                regression.points.sort(function(a, b) {
                    return a[0] - b[0];
                });
                tempLineSerieData.data = regression.points
                newSeries.push(tempLineSerieData)
            }
            // 使用echarts-stat拟合折线图数据 end todo
            chartOption.defaultOption.series = newSeries
        }
        
        // 计算IC50
        const typeObj = {
            'ic50-logx-4': 3,
            'ic50-logx-3': 6,
            'ic50-logx-2': 4,
            'ic50-x-4': 1,
            'ic50-x-3': 5,
            'ic50-x-2': 2,
            'ec50-logx-4': 3,
            'ec50-logx-3': 6,
            'ec50-logx-2': 4,
            'ec50-x-4': 1,
            'ec50-x-3': 5,
            'ec50-x-2': 2,
        }
        let itemValueArr = itemvalue.split('-')
        let ic50Data = {}
        let ec50Data = {}
        let rangeEndCol = chartOption.rangeArray[0].column[1]
        let rangeStartRow = chartOption.rangeArray[0].row[0]
        // 使用鹰谷接口计算IC50EC50数据（暂不使用）todo
        // chartOption.defaultOption.seriesData.forEach((item, index) => {
        //     let data = {
        //         px: xData[index].join(','),
        //         py: item.join(','),
        //         type: typeObj[itemvalue],
        //     }
        //     $.post({
        //         url: "http://chemicalservice.ineln.com/chemical/ic-fifty",
        //         data: JSON.stringify(data),
        //         success: (res) => {
        //             if (res.status == 1 && !Array.isArray(res.data)) {
        //                 res.data = {
        //                     ...res.data,
        //                     Top: res.data.Top || 100,
        //                     Bottom: res.data.Bottom || 0,
        //                     hillScope: res.data.hillScope || -1,
        //                 }
        //                 if (itemValueArr[0] === 'ic50') {
        //                     ic50Data[chartOption.defaultOption.series[index].name] = res.data

        //                 } else if (itemValueArr[0] === 'ec50') {
        //                     ec50Data[chartOption.defaultOption.series[index].name] = res.data
        //                 }
        //                 getLineChartData(res.data, chartOption.defaultOption.series[index], xData[index])
        //                 if (itemValueArr[0] === 'ic50') {
        //                     let resultData = []
        //                     let IC30 = computeICECxx(res.data, 30, itemvalue, typeObj[itemvalue])
        //                     let IC80 = computeICECxx(res.data, 80, itemvalue, typeObj[itemvalue])
        //                     let IC90 = computeICECxx(res.data, 90, itemvalue, typeObj[itemvalue])
        //                     let IC95 = computeICECxx(res.data, 95, itemvalue, typeObj[itemvalue])
        //                     ic50Data[chartOption.defaultOption.series[index].name] = {
        //                         ...ic50Data[chartOption.defaultOption.series[index].name],
        //                         name: chartOption.defaultOption.series[index].name,
        //                         IC30: IC30,
        //                         IC80: IC80,
        //                         IC90: IC90,
        //                         IC95: IC95,
        //                     }
        //                     Object.keys(ic50Data[chartOption.defaultOption.series[index].name]).forEach(key => {
        //                         if (key !== 'name') {
        //                             ic50Data[chartOption.defaultOption.series[index].name][key] = ic50Data[chartOption.defaultOption.series[index].name][key].tofixed(4)
        //                         }
        //                     })
        //                     if (index === 0) {
        //                         resultData.push(['Title', 'IC50', 'LogIC50', 'HillSlope', 'Top', 'Bottom', 'IC30', 'IC80', 'IC90', 'IC95'])
        //                     }
        //                     let value = ic50Data[chartOption.defaultOption.series[index].name]
        //                     resultData.push([value.name, value.IC50, value.LogIC50, value.hillScope, value.Top, value.Bottom, value.IC30, value.IC80, value.IC90, value.IC95])
        //                     if (index === 0) {
        //                         instance.api.setRangeValue(resultData, { range: { column: [rangeEndCol + 2, rangeEndCol + 11], row: [rangeStartRow + index, rangeStartRow + index + 1] } })
        //                     } else {
        //                         instance.api.setRangeValue(resultData, { range: { column: [rangeEndCol + 2, rangeEndCol + 11], row: [rangeStartRow + index + 1, rangeStartRow + index + 1] } })
        //                     }
        //                 } else if (itemValueArr[0] === 'ec50') {
        //                     let resultData = []
        //                     let EC30 = computeICECxx(res.data, 30, itemvalue, typeObj[itemvalue])
        //                     let EC80 = computeICECxx(res.data, 80, itemvalue, typeObj[itemvalue])
        //                     let EC90 = computeICECxx(res.data, 90, itemvalue, typeObj[itemvalue])
        //                     let EC95 = computeICECxx(res.data, 95, itemvalue, typeObj[itemvalue])
        //                     ec50Data[chartOption.defaultOption.series[index].name] = {
        //                         ...ec50Data[chartOption.defaultOption.series[index].name],
        //                         name: chartOption.defaultOption.series[index].name,
        //                         EC30: EC30,
        //                         EC80: EC80,
        //                         EC90: EC90,
        //                         EC95: EC95,
        //                     }
        //                     Object.keys(ec50Data[chartOption.defaultOption.series[index].name]).forEach(key => {
        //                         if (key !== 'name') {
        //                             ec50Data[chartOption.defaultOption.series[index].name][key] = ec50Data[chartOption.defaultOption.series[index].name][key].tofixed(4)
        //                         }
        //                     })
        //                     if (index === 0) {
        //                         resultData.push(['Title', 'EC50', 'LogEC50', 'HillSlope', 'Top', 'Bottom', 'EC30', 'EC80', 'EC90', 'EC95'])
        //                     }
        //                     let value = ec50Data[chartOption.defaultOption.series[index].name]
        //                     resultData.push([value.name, value.EC50, value.LogEC50, value.hillScope, value.Top, value.Bottom, value.EC30, value.EC80, value.EC90, value.EC95])
        //                     if (index === 0) {
        //                         instance.api.setRangeValue(resultData, { range: { column: [rangeEndCol + 2, rangeEndCol + 11], row: [rangeStartRow + index, rangeStartRow + index + 1] } })
        //                     } else {
        //                         instance.api.setRangeValue(resultData, { range: { column: [rangeEndCol + 2, rangeEndCol + 11], row: [rangeStartRow + index + 1, rangeStartRow + index + 1] } })
        //                     }
        //                 }
        //             }
        //         }
        //     })
        // })
        // 折线图数据
        const getLineChartData = function (data, item, xData) {
            let top = data.Top
            let bottom = data.Bottom
            let logIC50 = data.LogIC50
            let IC50 = data.IC50
            let hillScope = data.hillScope
            // x轴开始和结尾分成40份，绘制折线图
            let xLineData = JSON.parse(JSON.stringify(xData))
            let oneSplitPart = (xData[xData.length - 1] - xData[0]) / 40
            for (let i = 0; i < 40; i++) {
                xLineData.push(xData[0] + oneSplitPart * i)
            }
            xLineData.sort((a, b) => a - b)
            if (typeObj[itemvalue] == 2 || typeObj[itemvalue] == 4) {
                top = 100
                bottom = 0
            }
            if (typeObj[itemvalue] == 5 || typeObj[itemvalue] == 6) {
                hillScope = -1
            }
            let lineData = []
            for (let i = 0; i < xLineData.length; i++) {
                let y = 0
                if (typeObj[itemvalue] === 1 || typeObj[itemvalue] === 2 || typeObj[itemvalue] === 5) {
                    y = bottom + (top - bottom) / (1 + Math.pow(IC50 / xLineData[i], hillScope))
                } else {
                    y = bottom + (top - bottom) / (1 + Math.pow(10, (logIC50 - xLineData[i]) * hillScope))
                }
                lineData.push([xLineData[i], y])
            }
            let tempLineSerieData = JSON.parse(JSON.stringify(lineSerieData))
            tempLineSerieData.data = lineData
            tempLineSerieData.name = item.name
            tempLineSerieData.symbol = 'none'
            tempLineSerieData.smooth = 0.6
            chartOption.defaultOption.series.push(tempLineSerieData)
        }
        // 计算IC30、IC80、IC90、IC95
        const computeICECxx = function (data, x, type, typeCode) {
            let result = ''
            let typeArr = type.split('-')
            if (typeArr[0] === 'ic50') {
                if (typeCode === 1 || typeCode === 2 || typeCode === 5) {
                    result = data.IC50 / Math.pow((data.Top - data.Bottom) / (x - data.Bottom) - 1, 1 / data.hillScope)
                } else {
                    result = Math.pow(10, (data.LogIC50 - Math.log10((data.Top - data.Bottom) / (x - data.Bottom) - 1)) / data.hillScope)
                }
            }
            if (typeArr[0] === 'ec50') {
                if (typeCode === 1 || typeCode === 2 || typeCode === 5) {
                    result = data.EC50 / Math.pow((data.Top - data.Bottom) / (x - data.Bottom) - 1, 1 / data.hillScope)
                } else {
                    result = Math.pow(10, (data.LogEC50 - Math.log10((data.Top - data.Bottom) / (x - data.Bottom) - 1)) / data.hillScope)
                }
            }
            return result
        }
        chartOption.ic50ec50Data = {
            type: itemvalue,
            typeCode: typeObj[itemvalue],
            ic50Data: ic50Data,
            ec50Data: ec50Data,
        }
    }
    chartOption.sheetIndex = sheetFile.index
    sheetFile.chart.push({
        chart_id,
        width,
        height,
        left,
        top,
        chartOptions: chartOption,
        sheetIndex: sheetFile.index,
    });
    // chartInfo.chartparam.renderChart({ chart_id: chartOption.chart_id, chartOptions: chartOption });

    //处理区域高亮框参数，当前页中，只有当前的图表的needRangShow为true,其他为false
    showNeedRangeShow(chart_id, instance);

    // delete current chart
    $(`#${chart_id}_c .luckysheet-modal-controll-del`).click(function (e) {
        delChart(chart_id, instance);
    });

    setChartMoveableEffect(instance);

    // edit current chart
    $(`#${chart_id}_c .luckysheet-modal-controll-update`).click(function (e) {
        showChartSettingComponent('', chart_id, instance);
    });

    // IC50/EC50 散点图点击事件
    if (chart_id.split('_')[0] === 'ic50ec50') {
        let ic50Ec50Chart = echarts.getInstanceByDom($(`#${chart_id}`)[0])
        ic50Ec50Chart.on('click', 'series.scatter', function (params) {
            let currChartOption = chartInfo.currentChart
            currChartOption.defaultOption.seriesData[params.seriesIndex].splice(params.dataIndex, 1)
            currChartOption.defaultOption.xData[params.seriesIndex].splice(params.dataIndex, 1)
            updateIc50Ec50Data(currChartOption, true, instance)
        })
    }

    $t.children(".luckysheet-modal-dialog-content").mousedown(function (e) {
        // let currentTargetId = e.currentTarget.id;
        // let container = currentTargetId.split('---')[1];
        // updateStoreExample.assignStore(container);
        // if (currentInstance.find(`.chartSetting`).is(":visible")) {
        //     let chartId = $(e.currentTarget).attr('id')
        //     if (container !== currentChartId.split('---')[1]) {
        //         updateStoreExample.assignStore(currentChartId.split('---')[1])
        //         hideChartSettingComponent(false, instance)
        //         setTimeout(() => {
        //             updateStoreExample.assignStore(container)
        //             showChartSettingComponent('', chartId, instance)
        //         }, 200);
        //     } else {
        //         showChartSettingComponent('', chartId, instance)
        //     }
        // }
        // luckysheetsizeauto()
        if (!chartInfo.chartparam.luckysheetCurrentChartMaxState) {
            //当前图表显示区域高亮
            showNeedRangeShow(chart_id, instance);
        }
        e.stopPropagation();
    });
    $t.mousedown(function (e) {
        //move chart
        // 非编辑模式禁止此操作
        if (instance.validate.isEditMode()) {
            return
        }
        if (!chartInfo.chartparam.luckysheetCurrentChartMaxState) {
            //当前图表显示区域高亮
            showNeedRangeShow(chart_id, instance);
            instance.set.setluckysheet_scroll_status(true);

            //允许拖动渲染框
            if (
                !$(e.target).is(".luckysheet-modal-dialog-controll") &&
                !$(e.target).is(".luckysheet-modal-controll-btn") &&
                !$(e.target).is("i")
            ) {
                // Debounce
                chartInfo.chartparam.luckysheetCurrentChartMoveTimeout = setTimeout(function () {
                    chartInfo.chartparam.luckysheetCurrentChartMove = true;
                }, 100);
            }

            var toffset = chartInfo.chartparam.luckysheetCurrentChartMoveObj.offset();
            var tpsition = chartInfo.chartparam.luckysheetCurrentChartMoveObj.position();
            //luckysheetCurrentChartMoveXy: [鼠标点相对chart框的距离X方向，鼠标点相对chart框的距离Y方向，chart框相对cell-main的距离X方向，chart框相对cell-main的距离Y方向，水平滚动条的位置，垂直滚动条的位置]
            chartInfo.chartparam.luckysheetCurrentChartMoveXy = [
                e.pageX - toffset.left,
                e.pageY - toffset.top,
                tpsition.left,
                tpsition.top,
                currentInstance.find("#luckysheet-scrollbar-x").scrollLeft(),
                currentInstance.find("#luckysheet-scrollbar-y").scrollTop(),
            ];
            chartInfo.chartparam.luckysheetCurrentChartMoveWinH = currentInstance.find("#luckysheet-cell-main")[0].scrollHeight;
            chartInfo.chartparam.luckysheetCurrentChartMoveWinW = currentInstance.find("#luckysheet-cell-main")[0].scrollWidth;

            if (
                !$(e.target).hasClass("luckysheet-mousedown-cancel") &&
                $(e.target).filter("[class*='sp-palette']").length == 0 &&
                $(e.target).filter("[class*='sp-thumb']").length == 0 &&
                $(e.target).filter("[class*='sp-']").length == 0
            ) {
                currentInstance.find("#luckysheet-rightclick-menu").hide();
                currentInstance.find("#luckysheet-cols-h-hover").hide();
                currentInstance.find("#luckysheet-cols-menu-btn").hide();
                currentInstance.find(`#luckysheet-sheet-list, #luckysheet-rightclick-sheet-menu, #luckysheet-user-menu`).hide();
                currentInstance.find(
                    ".luckysheet-filter-menu, .luckysheet-filter-submenu, .luckysheet-cols-menu",
                ).hide();
            }

            e.stopPropagation();
        }
    })
        .find(".luckysheet-modal-dialog-resize-item")
        .mousedown(function (e) {
            // 非编辑模式禁止此操作
            if (instance.validate.isEditMode()) {
                return
            }
            if (chartInfo.chartparam.luckysheetCurrentChartActive) {
                chartInfo.chartparam.luckysheetCurrentChartResize = $(this).data("type"); //开始状态resize

                var mouse = instance.location.mouseposition(e.pageX, e.pageY),
                    scrollLeft = currentInstance.find("#luckysheet-scrollbar-x").scrollLeft(),
                    scrollTop = currentInstance.find("#luckysheet-scrollbar-y").scrollTop();
                var x = mouse[0] + scrollLeft;
                var y = mouse[1] + scrollTop;
                var position = chartInfo.chartparam.luckysheetCurrentChartResizeObj.position();
                //参数：x,y:鼠标位置，$t.width(), $t.height(): chart框宽高， position.left + scrollLeft, position.top + scrollTop ：chart框位置 ，scrollLeft, scrollTop：滚动条位置
                chartInfo.chartparam.luckysheetCurrentChartResizeXy = [
                    x,
                    y,
                    $t.width(),
                    $t.height(),
                    position.left + scrollLeft,
                    position.top + scrollTop,
                    scrollLeft,
                    scrollTop,
                ];
                chartInfo.chartparam.luckysheetCurrentChartResizeWinH = currentInstance.find("#luckysheet-cell-main")[0].scrollHeight;
                chartInfo.chartparam.luckysheetCurrentChartResizeWinW = currentInstance.find("#luckysheet-cell-main")[0].scrollWidth;

                chartInfo.chartparam.luckysheetCurrentChart = chart_id;

                e.stopPropagation();
            }
        });

    // 将图表的坐标信息更新到sheetFile中
    $(`#${chart_id}_c`).mouseup(function (e) {
        const w = $(this).css("width");
        const h = $(this).css("height");
        const t = $(this).css("top");
        const l = $(this).css("left");

        // insert chartinfo
        let sheetFile = chartInfo.luckysheetfile[instance.get.getSheetIndex(chartInfo.currentSheetIndex)];

        if (!sheetFile.chart) {
            sheetFile.chart = [];
        }

        sheetFile.chart.forEach((item) => {
            if (item.chart_id === chart_id) {
                item.width = parseFloat(w);
                item.height = parseFloat(h);
                item.left = parseFloat(l);
                item.top = parseFloat(t);
            }
        });
    });
    // 添加图表时走钩子函数updated
    let newCtr = {
        "type": "addChart",
        "chart_id": chart_id,
        "sheetIndex": sheetFile.index,
    }
    instance.method.createHookFunction('updated', newCtr)
}

/**
 * 设置图表可拖动区域高亮效果，鼠标经过可拖动区域时鼠标显示“十字”，不可拖动区域显示箭头
 * @param {JQuery} instance 当前实例
 */
function setChartMoveableEffect(instance) {
    const containerId =  instance.getInstanceId();
    let $container = $(`#${containerId}`);
    // 非编辑模式禁止此操作
    if (instance.validate.isEditMode()) {
        return
    }
    $container.find(".luckysheet-modal-dialog-content").hover(
        function () {
            $container.removeClass("chart-moveable");
        },
        function () {
            $container.addClass("chart-moveable");
        },
    );

    $container.hover(
        function () {
            $container.addClass("chart-moveable");
        },
        function () {
            $container.removeClass("chart-moveable");
        },
    );
}
// delete chart
function delChart(chart_id, instance) {
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    // delete container
    currentInstance.find(`#luckysheet-cell-main #${chart_id}_c`).remove();

    // Hide selected range
    hideAllNeedRangeShow(instance);

    // delete storage
    let sheetFile = chartInfo.luckysheetfile[instance.get.getSheetIndex(chartInfo.currentSheetIndex)];
    let index = sheetFile.chart.findIndex((item) => item.chart_id == chart_id);
    sheetFile.chart.splice(index, 1);
    // api call
    chartInfo.deleteChart(chart_id);
    // 删除图表时走钩子函数updated
    let newCtr = {
        "type": "deleteChart",
        "chart_id": chart_id,
        "sheetIndex": sheetFile.index,
    }
    instance.method.createHookFunction('updated', newCtr)
}

//设置某个图表的高亮区域状态为显示,处理当前页的所有图表，只取一个图表设置为显示，其他隐藏，其他页不管
function showNeedRangeShow(chart_id, instance) {
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    // 非编辑模式禁止此操作
    if (instance.validate.isEditMode()) {
        return
    }
    currentChartId = chart_id
    let chartLists = chartInfo.luckysheetfile[instance.get.getSheetIndex(chartInfo.currentSheetIndex)].chart;

    for (let chartId in chartLists) {
        // if (chartLists[chartId].sheetIndex == chartInfo.currentSheetIndex) {
        //当前sheet的图表先设置为false
        chartLists[chartId].needRangeShow = false;
        if (chartLists[chartId].chart_id == chart_id) {
            chartLists[chartId].needRangeShow = true;
            // 设置当前高亮值存在图表setting中
            commonChartInfo.chartparam.insertToStore({ chart_id: chart_id, chartOptions: chartLists[chartId].chartOptions });
            chartInfo.currentChart = chartInfo.getChartJson(chart_id);
        }
        // }
    }

    //操作DOM当前图表选择区域高亮
    selectRangeBorderShow(chart_id, instance);
}
//隐藏当前sheet所有的图表高亮区域
function hideAllNeedRangeShow(t) {
    const instance = t || this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
	let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    let chartLists = chartInfo.luckysheetfile[instance.get.getSheetIndex(chartInfo.currentSheetIndex)].chart;
    for (let chartId in chartLists) {
        // if (chartLists[chartId].sheetIndex == chartInfo.currentSheetIndex) {
        //当前sheet的图表设置为false
        chartLists[chartId].needRangeShow = false;
        // }
    }

    //操作DOM 当前图表选择区域隐藏
    selectRangeBorderHide(false, instance);
}

//选择区域高亮
function selectRangeBorderShow(chart_id, t) {
    const instance = t || this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    // 非编辑模式禁止此操作
    if (instance.validate.isEditMode()) {
        return
    }
    let $t = $("#" + chart_id + "_c");

    // Highlight of data range
    chartInfo.chart_selection.create(instance);

    chartInfo.chartparam.luckysheetCurrentChartActive = true;
    chartInfo.chartparam.luckysheetCurrentChartMoveObj = $t;
    chartInfo.chartparam.luckysheetCurrentChartResizeObj = $t;
    chartInfo.chartparam.luckysheetCurrentChart = chart_id;

    //luckysheet取cell-main，后续扩展到其他的用户自定义元素
    currentInstance.find("#luckysheet-cell-main")
        .find(".luckysheet-modal-dialog-chart .luckysheet-modal-dialog-resize")
        .hide();
    currentInstance.find("#luckysheet-cell-main")
        .find(".luckysheet-modal-dialog-chart .luckysheet-modal-dialog-controll")
        .hide();

    $t.css("z-index", chartInfo.chartparam.luckysheetCurrentChartZIndexRank++);
    $t.find(".luckysheet-modal-dialog-resize").show();
    $t.find(".luckysheet-modal-dialog-controll").show();

    if (
        (currentInstance.find(`.chartSetting`).is(":visible") || chartInfo.chartparam.luckysheet_chart_redo_click) &&
        chart_id != chartInfo.chartparam.luckysheetCurrentChart
    ) {
        // TODO: 第一次创建图表时候需要初始化数据选择框 qkSelection
        // generator.ini(chartMixConfig)
        $(`body #${containerId} .luckysheet-cols-menu`).hide();
    }

    // 切换到当前图表设置项
    chartInfo.currentChart = chartInfo.highlightChart(chart_id);
    if (currentInstance.find(`.chartSetting`).is(":visible")) {
        if (chart_id.split('_')[0] === 'ic50ec50') {
            currentInstance.find(`.chartSetting #tab-data`).removeClass('is-active')
            currentInstance.find(`.chartSetting #tab-data`).css('display', 'none')
            currentInstance.find(`.chartSetting #tab-1`).addClass('is-active')
            currentInstance.find(`.chartSetting #pane-data`).css('display', 'none')
            currentInstance.find(`.chartSetting #pane-1`).css('display', 'block')
        } else {
            currentInstance.find(`.chartSetting #tab-data`).addClass('is-active')
            currentInstance.find(`.chartSetting #tab-data`).css('display', 'inline-block')
            currentInstance.find(`.chartSetting #tab-1`).removeClass('is-active')
            currentInstance.find(`.chartSetting #pane-data`).css('display', 'block')
            currentInstance.find(`.chartSetting #pane-1`).css('display', 'none')
        }
    }
}

//选择区域高亮隐藏
function selectRangeBorderHide(settingShow, t) {
    const instance = t || this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let currentInstance = $(`#${containerId}`);
    let chartInfo = Store;
    currentInstance.find(
        `#luckysheet-cell-main .luckysheet-modal-dialog-chart .luckysheet-modal-dialog-resize, #luckysheet-cell-main .luckysheet-modal-dialog-chart .luckysheet-modal-dialog-controll`
    ).hide();
    currentInstance.find("#luckysheet-cell-main")
        .find(".luckysheet-datavisual-selection-set div")
        .remove();
    chartInfo.chartparam.luckysheetCurrentChartActive = false;

    currentInstance.find("#luckysheet-chart-rangeShow").empty();

    //标识：是否处理设置界面
    if (!settingShow && currentInstance.find(`.chartSetting`).is(":visible") && !instance.validate.isEditMode()) {
        hideChartSettingComponent(false, instance);
    }
}

// 显示图表设置界面
function showChartSettingComponent(refresh, chart_id, t) {
    const instance = t || this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    if (!currentInstance.find(`.chartSetting`).is(":visible")) {
        currentInstance.find(`.chartSetting`).css({
            top: 'unset',
            bottom: 'unset',
            position: "absolute",
            right: "0px",
            width: "350px",
            height: $(`#${Store.container}`).height(),
            background: "#fff",

            border: "1px solid #E5E5E5",
            "z-index": 1004,
            "box-shadow": "0px 2px 4px rgba(0,0,0,0.2)",
            "-webkit-box-shadow": "0px 2px 4px rgba(0,0,0,0.2)",
            "-moz-box-shadow": "0px 2px 4px rgba(0,0,0,0.2)",
            "-moz-user-select": "none",
            "-khtml-user-select": "none",
            "-webkit-user-select": "none",
            "-ms-user-select": "none",
            "user-select": "none",
            "padding-left": "30px",
            display: "none",
        });
        //显示设置界面
        currentInstance.find(`.chartSetting`).show();

        currentInstance.find("#luckysheet-cell-main")
            .find(".luckysheet-datavisual-selection-set div")
            .show();
        chartInfo.chartparam.luckysheetCurrentChartActive = true;
        if (chart_id.split('_')[0] === 'ic50ec50') {
            currentInstance.find(`.chartSetting #tab-data`).removeClass('is-active')
            currentInstance.find(`.chartSetting #tab-data`).css('display', 'none')
            currentInstance.find(`.chartSetting #tab-1`).addClass('is-active')
            currentInstance.find(`.chartSetting #pane-data`).css('display', 'none')
            currentInstance.find(`.chartSetting #pane-1`).css('display', 'block')
        } else { // 图表设置data bug --modfy by liluyao 2023/08/08
            currentInstance.find(`.chartSetting #tab-data`).addClass('is-active')
            currentInstance.find(`.chartSetting #tab-data`).css('display', 'inline-block')
            currentInstance.find(`.chartSetting #tab-1`).removeClass('is-active')
            currentInstance.find(`.chartSetting #pane-data`).css('display', 'block')
            currentInstance.find(`.chartSetting #pane-1`).css('display', 'none')
        }
        // 解决“在线表格-修改图表右侧的配置栏 style tab页下面的配置项展开后，关闭配置栏，再打开，切换style tab页没展示对应的内容”问题 --modify by liluyao 2023/08/14
        currentInstance.find('.chartSetting .el-tabs__item').click(function () {
            if ($(this).attr('id') === 'tab-data') {
                currentInstance.find(`.chartSetting #pane-data`).css('display', 'block')
                currentInstance.find(`.chartSetting #pane-1`).css('display', 'none')
            } else if ($(this).attr('id') === 'tab-1') {
                currentInstance.find(`.chartSetting #pane-data`).css('display', 'none')
                currentInstance.find(`.chartSetting #pane-1`).css('display', 'block')
            }
        });
        setTimeout(function () {
            instance.resize.luckysheetsizeauto();
        }, 0);
    }
}

// 隐藏图表设置界面
function hideChartSettingComponent(refresh, t) {
    const instance = t || this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    if (currentInstance.find(`.chartSetting`).is(":visible")) {
        //隐藏设置界面
        currentInstance.find(`.chartSetting`).hide();
        //.luckysheet-modal-dialog-resize为图表显示框的缩放框，.luckysheet-modal-dialog-controll为显示框右边的控制按钮
        currentInstance.find(
            `#luckysheet-cell-main .luckysheet-modal-dialog-chart .luckysheet-modal-dialog-resize, #luckysheet-cell-main .luckysheet-modal-dialog-chart .luckysheet-modal-dialog-controll`
        ).hide();

        currentInstance.find("#luckysheet-cell-main")
            .find(".luckysheet-datavisual-selection-set div")
            .remove();

        chartInfo.chartparam.luckysheetCurrentChartActive = false;
        if (!instance.validate.isEditMode() && !refresh) {
            setTimeout(function () {
                instance.resize.luckysheetsizeauto();
            }, 0);
        }
    }
}

// 隐藏其他sheet的图表，显示当前sheet的图表 chartMix 切换sheet页显示隐藏图表
function renderChartShow(index, t) {
    const instance = t || this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
	let chartInfo = Store;
    let currentInstance = $(`#${containerId}`);
    //传入index，图表显示隐藏
    // selectRangeBorderHide("true", instance); //隐藏数据高亮区域，随意传入一个字符串，表示不处理chartSetting界面
    selectRangeBorderHide(true, instance)

    const luckysheetfile = chartInfo.luckysheetfile;
    luckysheetfile.forEach((file) => {
        //切换当前页的所有图表都显示出来
        if (file.index == index) {
            const chartLists = file.chart || [];

            chartLists.forEach((chart) => {
                chart.isShow = true;
                currentInstance.find("#" + chart.chart_id + "_c").show();
                chartInfo.resizeChart(chart.chart_id);

                if (chart.needRangeShow == true) {
                    //一个sheet页只有一个图表高亮显示,//重要！因为在store了做了存储，所以能在此处找到对应图表设置显示隐藏
                    //操作DOM当前图表选择区域高亮
                    chartInfo.currentChart = chartInfo.getChartJson(chart.chart_id);
                    // 设置当前高亮值存在图表setting中
                    commonChartInfo.chartparam.insertToStore({ chart_id: chart.chart_id, chartOptions: chart.chartOptions });
                    selectRangeBorderShow(chart.chart_id, instance);
                    if (currentInstance.find(`.chartSetting`).is(":visible")) {
                        if (chart.chart_id.split('_')[0] === 'ic50ec50') {
                            currentInstance.find(`.chartSetting #tab-data`).removeClass('is-active')
                            currentInstance.find(`.chartSetting #tab-data`).css('display', 'none')
                            currentInstance.find(`.chartSetting #tab-1`).addClass('is-active')
                            currentInstance.find(`.chartSetting #pane-data`).css('display', 'none')
                            currentInstance.find(`.chartSetting #pane-1`).css('display', 'block')
                        } else { // 图表设置data bug --modfy by liluyao 2023/08/08
                            currentInstance.find(`.chartSetting #tab-data`).addClass('is-active')
                            currentInstance.find(`.chartSetting #tab-data`).css('display', 'inline-block')
                            currentInstance.find(`.chartSetting #tab-1`).removeClass('is-active')
                            currentInstance.find(`.chartSetting #pane-data`).css('display', 'block')
                            currentInstance.find(`.chartSetting #pane-1`).css('display', 'none')
                        }
                    }
                }
            });
        }

        // 隐藏其他页的图表
        else {
            const chartLists = file.chart || [];

            chartLists.forEach((chart) => {
                chart.isShow = false;
                currentInstance.find("#" + chart.chart_id + "_c").hide();
            });
        }
    });
}

export { chart, renderCharts, createLuckyChart, redrawAllIC50EC50Chart, hideAllNeedRangeShow, renderChartShow, chart_selection, selectRangeBorderHide };
