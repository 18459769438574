import exampleStore from "../store/example";
import Store from "../store";
import { initListener } from "./listener";

const updateStoreExample = {
    assignStore(container) {
        Object.keys(exampleStore[container]).forEach((key) => {
            if (typeof exampleStore[container][key] == "object") {
                if (Array.isArray(exampleStore[container][key])) {
                    Store[key] = JSON.parse(JSON.stringify(exampleStore[container][key]))
                } else {
                    Store[key] = exampleStore[container][key]
                }
            } else {
                Store[key] = exampleStore[container][key]
            }
        })
        // listener里的撤销、重做监听不起作用，重新声明 --modify by liluyao 2023/9/5
        initListener();
    },
};
export default updateStoreExample;