/**
 * 序列查找js文件
 * wangxiaofei 2023.8.28
 */
import sequenceCommon from "./sequenceCommon"; // 序列操作公共js方法

const sequenceLookup = {
    /**
     * 重复序列查找
     */
    repeatSequenceSearch() {
        let _this = this;
        const instance = _this.instance;
        const containerId = instance.getInstanceId();
        const currentInstance = $(`#${containerId}`);
        const maskDialog =  currentInstance.find("#luckysheet-modal-dialog-mask");
        let _locale = instance.locale();
        const locale_sequenceLookup = _locale.sequenceLookup;
        let content = `<div class="proteinanalysis-setting-block">
            <div class="subtemplate_wrap">
                <div id="sub_module_body" class="sub_module_body">
                    <div id="save_as_box" class="save_as_box">
                        <div class="save_part">
                            <div class="range-input">
                                <span>${locale_sequenceLookup.selectSequence}</span>
                                <input type="text" class="sequenceRange1" placeholder="${locale_sequenceLookup.sequenceSelect}" style="height:25px" />
                                <i class="range-input-icon"></i>
                            </div>
                            <div class="range-input">
                                <span>${locale_sequenceLookup.selectOput}</span>
                                <input type="text" class="sequenceRange3" placeholder="${locale_sequenceLookup.selectOputTip}" style="height:25px" value="A2"/>
                                <i class="range-input-icon-output"></i>
                            </div>
                            <div class="minvalue-input">
                                <span>${locale_sequenceLookup.minRepeatLength}</span>
                                <input type="text" class="minvalue" placeholder="${locale_sequenceLookup.minvalue}" style="height:25px; width:171px" value="3"/>
                            </div>
                            <div class="maxvalue-input">
                                <span>${locale_sequenceLookup.maxRepeatLength}</span>
                                <input type="text" class="maxvalue" placeholder="${locale_sequenceLookup.maxvalue}" style="height:25px; width:171px" value="10"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
        
        const id = 'luckysheet-sequenceLookup-dialog';
        const title = locale_sequenceLookup.repeatSequenceSearch;
        // 弹窗确定回调
        let confirmCallback = function(target, instance) {
            let seqDialog = currentInstance.find(`#${id}`);
            let e = seqDialog.find(".range-input input").val()
              , t = parseInt(seqDialog.find(".minvalue").val())
              , r = parseInt(seqDialog.find(".maxvalue").val())
              , o = seqDialog.find(".sequenceRange3").val();
            s = sequenceCommon.computeRange(o, instance)[0] - 1,
            a = sequenceCommon.computeRange(o, instance)[1] - 1;
            const c = locale_sequenceLookup.Tipmessage.repeatTip1
              , u = locale_sequenceLookup.Tipmessage.repeatTip2
              , l = locale_sequenceLookup.Tipmessage.repeatTip3;
            // 设置重复序列统计位置
            instance.api.setCellValue(a, s, c, { isRefresh: false}),
            instance.api.setCellValue(a, s + 1, u, { isRefresh: false}),
            instance.api.setCellValue(a, s + 2, l, { isRefresh: false});
            let f, h = "";
            for (let d = 0; d < e.length; d++) {
                !0 !== /[#]+/.test(e[d]) && (!0 === sequenceCommon.checkSequence(e[d]) && (h += e[d].toUpperCase()))
            }
            if (h.length >= 1)
                if (0 === t || t > h.length)
                    seqDialog.hide(),
                    instance.tooltip.info('', locale_sequenceLookup.Errormessage.errorMinvalue);
                else
                    for (let p = function(e) {
                        let r = (f = _this.searchRepeatSequence(h, e))[0]
                          , i = f[1]
                          , o = 0;
                        i.forEach((function(e, i) {
                            e >= t && (instance.api.setCellValue(a + o + 1, s, r.get(i), { isRefresh: false}),
                            instance.api.setCellValue(a + o + 1, s + 1, r.get(i).length, { isRefresh: false}),
                            instance.api.setCellValue(a + o + 1, s + 2, e, { isRefresh: false}),
                            o++)
                        }
                        )),
                        a += o
                    }, m = t; m <= r; m++)
                        p(m);
            instance.api.refresh();
            seqDialog.hide();
            currentInstance.find("#luckysheet-modal-dialog-mask").hide();
        };
        instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
        let seqDialog = currentInstance.find(`#${id}`);
        // 选择序列单元格
        seqDialog.find("i").click(function() {
            const rangeSelect = "luckysheet-range-selector-dialog";
            let initVal = $(this).parent().find('input').val();
            let rangeValue = $(this).attr('class') === 'range-input-icon' ? 'range-value':''; // 判断如果是选择序列返回范围值，不是返回单元格坐标
            seqDialog.hide();
            instance.createHtmlDom.createRangeSelectorDialog( rangeSelect, rangeValue);
            rangeDialog = currentInstance.find(`#${rangeSelect}`);
            rangeDialog.find('range-val').val(initVal);
            rangeDialog.find('.cancel-icon').click(() => {
                rangeDialog.hide();
                maskDialog.show();
                seqDialog.show();
            });
            rangeDialog.find('.ok-icon').click(() => {
                rangeDialog.hide();
                maskDialog.show();
                seqDialog.show();
                let rangeSelectorVal = rangeDialog.find('.range-val').val();
                $(this).parent().find('input').val(rangeSelectorVal);
            });
        });
    },

    /**
     * 查找重复序列
     * @param {*} e 
     * @param {*} t 
     * @returns 
     */
    searchRepeatSequence(e, t) {
        for (var n = new Map, r = new Map, i = t, o = e.length, a = 0; a < o; a++) {
            var s = e.slice(a, a + i);
            if (s.length === i) {
                for (var c = 0, u = 0; u < s.length; u++) {
                    var l = parseInt(s[u].charCodeAt());
                    l >= 65 && l <= 90 ? c = (l - 65 + 1 + 26 * c) % 299792458 : l >= 97 && l <= 122 && (c = (l - 97 + 1 + 26 * c) % 299792458)
                }
                n.get(c.toString()) > 0 ? (r.set(c.toString(), s),
                n.set(c.toString(), n.get(c.toString()) + 1)) : (r.set(c.toString(), s),
                n.set(c.toString(), 1))
            }
        }
        return [r, n]
    },

    /**
     * 片段查找
     */
    fragmentSearch(){
        let _this = this;
        const instance = _this.instance;
        const containerId = instance.getInstanceId();
        const currentInstance = $(`#${containerId}`);
        const maskDialog =  currentInstance.find("#luckysheet-modal-dialog-mask");
        let _locale = instance.locale();
        const locale_sequenceLookup = _locale.sequenceLookup;
        let content = `<div class="proteinanalysis-setting-block">
            <div class="subtemplate_wrap">
                <div id="sub_module_body" style="height:80px;">
                    <div id="save_as_box" class="save_as_box">
                        <div class="save_part">
                            <div class="module-input">
                                <span>${locale_sequenceLookup.moduleSearch}</span>
                                <input type="text" class="module" placeholder="${locale_sequenceLookup.fragmentSearchTip}" style="height:25px" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
        
        const id = 'luckysheet-fragmentSearch-dialog';
        const title = locale_sequenceLookup.fragmentSearch;
        // 弹窗确定回调
        let confirmCallback = function(target, instance) {
            let seqDialog = currentInstance.find(`#${id}`);
            let range = instance.api.getRange();
            let column = range[range.length - 1].column;
            let row = range[range.length - 1].row;
            let sri = row.length > 0 ? row[0] : 0, 
            eri = row.length > 0 ? row[1] : 0, 
            sci = column.length > 0 ? column[0] : 0;
            let seq = "", module, tempModule = seqDialog.find(".module-input input").val();
            if (!1 === _this.isReg(tempModule)) {
                let cmd = "/".concat(tempModule, "/g");
                module = eval(cmd);
            } else
                module = eval(tempModule);
            let res = [], resIndex = [];
            for (let i = sri; i <= eri; i++)
                seq += sequenceCommon.readSelectedInformation(i, !0, "string", instance);
            if (seq.length >= 1) {
                let patternTemp = seq.match(module)
                  , pattern = [];
                if (null === patternTemp) {
                    return;
                }
                for (let j = 0; j < patternTemp.length; j++)
                    -1 === pattern.indexOf(patternTemp[j]) && pattern.push(patternTemp[j]);
                for (let _i40 = 0; _i40 < pattern.length; _i40++)
                    for (let _j15 = 0; _j15 < seq.length; )
                        if (pattern[_i40] === seq.slice(_j15, _j15 + pattern[_i40].length)) {
                            for (let temp = _j15; temp < _j15 + pattern[_i40].length; ) {
                                let match = seq[temp];
                                resIndex.push(temp);
                                temp += 1,
                                res.push(match)
                            }
                            _j15 += pattern[_i40].length
                        } else
                            res.push(seq[_j15]),
                            _j15 += 1;
                let count = 0;
                for (instance.api.insertRow(sri + 1); count < res.length; )
                    resIndex.findIndex(t => t === count) > -1 ? instance.api.setCellValue( sri + 1, sci + count, {v: res[count], m: res[count], bg: "#00fdfd", fc: "#ff0000"} , { isRefresh: false}): instance.api.setCellValue( sri + 1, sci + count, res[count], { isRefresh: false}),
                    count += 1
            }
            instance.api.setRangeShow(range)
            instance.api.refresh();
            seqDialog.hide();
            maskDialog.hide();
        };
        instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
    },

    /**
     * 校验正则表达式
     * @param {*} reg 
     * @returns 
     */
    isReg(reg) {
        var isReg;
        try {
            isReg = eval(reg) instanceof RegExp
        } catch (e) {
            isReg = !1
        }
        return isReg
    },

    /**
     * 定位查找
     */
    indexSearch(){
        let _this = this;
        const instance = _this.instance;
        const containerId = instance.getInstanceId();
        const currentInstance = $(`#${containerId}`);
        const maskDialog =  currentInstance.find("#luckysheet-modal-dialog-mask");
        let _locale = instance.locale();
        const locale_sequenceLookup = _locale.sequenceLookup;
        let content = `<div class="proteinanalysis-setting-block">
            <div class="subtemplate_wrap">
                <div id="sub_module_body" style="height:120px;">
                    <div id="save_as_box" class="save_as_box">
                        <div class="save_part">
                            <div class="comment">
                                <label>${locale_sequenceLookup.indexTips}</label>
                            </div>
                            <div class="range-input">
                                <span>${locale_sequenceLookup.start}</span>
                                <input type="text" class="startValue" />
                                <span>&nbsp;&nbsp;&nbsp;${locale_sequenceLookup.end}</span>
                                <input type="text" class="endValue" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
        
        const id = 'luckysheet-indexSearch-dialog';
        const title = locale_sequenceLookup.indexSearch;
        // 弹窗确定回调
        let confirmCallback = function(target, instance) {
            let seqDialog = currentInstance.find(`#${id}`);
            let range = instance.api.getRange();
            let column = range[range.length - 1].column;
            let row = range[range.length - 1].row;
            let r = row.length > 0 ? row[0] : 0, 
            i = (row.length > 0 ? row[1] : 0,
                column.length > 0 ? column[0] : 0);
            let c, u = "", l = r + 1, f = -1;
            let e = parseInt(seqDialog.find(".startValue").val())
              , n = parseInt(seqDialog.find(".endValue").val());
            c = n;
            let ncolor;
            for (let o = 0; o <= i + 100 * (e + n); o++) {
                if ("S1" === instance.api.getCellValue(l, o)) {
                    f = o;
                    break
                }
            }
            if (-1 === f) {
                instance.tooltip.info('', locale_sequenceLookup.Errormessage.indexError);
            } else {
                for (let s = f + e - 1; s < f + n; s++) {
                    let h = instance.api.getCellValue(r, s);
                    h && void 0 !== h && "-" !== h ? (u += h,
                    instance.api.setCellValue(r, s, {
                        bg: "#00fdfd"
                    })) : s > e && (c = s, ncolor = '#ff0000')
                }
                let d = locale_sequenceLookup.Tipmessage.indexTip1
                  , p = locale_sequenceLookup.Tipmessage.indexTip2
                  , m = locale_sequenceLookup.Tipmessage.indexTip3
                  , y = locale_sequenceLookup.Tipmessage.indexTip4;
                  instance.api.insertRow(r + 2, {number: 2 }),
                instance.api.setCellValue(r + 2, f, d, { isRefresh: false}),
                instance.api.setCellValue(r + 2, f + 1, p, { isRefresh: false}),
                instance.api.setCellValue(r + 2, f + 3, m, { isRefresh: false}),
                instance.api.setCellValue(r + 2, f + 2, y, { isRefresh: false}),
                instance.api.setCellValue(r + 3, f, e, { isRefresh: false}),
                ncolor ? instance.api.setCellValue(r + 3, f + 1, {v: n, m: n, fc: ncolor}, { isRefresh: false}) : instance.api.setCellValue(r + 3, f + 1, n, { isRefresh: false}),
                instance.api.setCellValue(r + 3, f + 3, u, { isRefresh: false}),
                instance.api.setCellValue(r + 3, f + 2, c - e + 1, { isRefresh: false})
            }
            instance.api.setRangeShow(range)
            instance.api.refresh();
            seqDialog.hide();
            maskDialog.hide();
        };
        instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
    }
    
};
export default sequenceLookup;