import multiInstanceStore from "../store/multiInstance"; // 存储多实例Store wangxiaofei 1023.9.20
import { toolbarIdMap } from "../controllers/toolbar";

const handleToolbarIdMap = {
    handleToolbarIds() {
	    let Store = this.instance.getStore();
        let result = {};
        Object.keys(toolbarIdMap).forEach((key, index) => {
            if (Array.isArray(toolbarIdMap[key])) {
                result[key] = []
                toolbarIdMap[key].forEach(item => {
                    result[key].push(`${item}---${Store.container}`)
                });
            } else if (typeof toolbarIdMap[key] === 'string') {
                result[key] = `${toolbarIdMap[key]}---${Store.container}`
            }
        })
        return result;
    }
};
export default handleToolbarIdMap;