/**
 * 序列操作js文件
 */
import sequenceCommon from "./sequenceCommon"; // 序列操作公共js方法

const sequenceImportDialog = {
    colIndex: null, // 序列导入使用的公共变量，行索引。为了多文件上传使用
    createSequenceImportDialog: function () {
        let _this = this;
        const instance = _this.instance;
        const containerId = instance.getInstanceId();
        const currentInstance = $(`#${containerId}`);
        let _locale = instance.locale();
        const locale_sequenceDialog = _locale.sequenceDialog;
        let t = 0;
        let content = `<div class="sequence-import-setting-block">
            <div class="subtemplate_wrap">
                <div id="sub_module_body">
                    <div id="save_as_box">
                        <div class="save_part">
                            <div class="select-import-ways" id="select-import-ways">
                                <ul>
                                    <li id="protein_tab_li" class="active"> <a>${locale_sequenceDialog.proteinInp}</a></li>
                                    <li id="dna_tab_li"><a>${locale_sequenceDialog.dnaImport}</a></li>
                                    <li id="rna_tab_li"><a>${locale_sequenceDialog.rnaImport}</a></li>
                                </ul>
                                <hr>
                            </div>
                            <div class="bioInp">
                                <textarea class="textinp" id="sequenceTextInput" placeholder="${locale_sequenceDialog.copySequence}"></textarea>
                            </div>
                            <div class="drop-zone" id="drag-area">
                                <ul id="card">
                                    <li id="protein_file_li" class="active">
                                        <span>${locale_sequenceDialog.importTip1}
                                            <a class="file">
                                                ${locale_sequenceDialog.importTip2}
                                                <input type="file" class="fileinp" id="proteinfileinput" accept=".fasta, .xml"
                                                    multiple="">
                                            </a>
                                            <span class="showFileName"></span>
                                        </span>
                                    </li>
                                    <li id="dna_file_li">
                                        <span>${locale_sequenceDialog.importTip1}
                                            <a class="file">
                                                ${locale_sequenceDialog.importTip2}
                                                <input type="file" class="dnafileinp" id="dnafileinput"
                                                    accept=".fasta, .genbank, .gb" multiple="">
                                            </a>
                                            <span class="showFileName"></span>
                                        </span>
                                    </li>
                                    <li id="rna_file_li">
                                        <span>${locale_sequenceDialog.importTip1}
                                            <a class="file">
                                                ${locale_sequenceDialog.importTip2}
                                                <input type="file" class="dnafileinp" id="rnafileinput" accept=".fasta"
                                                    multiple="">
                                            </a>
                                            <span class="showFileName"></span>
                                        </span>
                                    </li>
                                </ul>
                                <span class="seqName">${locale_sequenceDialog.addSequenceName}:
                                    <textarea class="nameInp" id="nameInp"></textarea>
                                </span>
                                <label class="select-label">${locale_sequenceDialog.proteinaxistip}:
                                    <select class="select_steps">
                                        <option value="0">${locale_sequenceDialog.notSetCoords}</option>
                                        <option value="1" selected="">1</option>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
        const id = 'luckysheet-sequenceImport-dialog';
        const title = locale_sequenceDialog.proteinImport;
        let confirmCallback = function(target, instance) {
            let seqDialog = currentInstance.find(`#${id}`);
            // 序列号
            let textValue = seqDialog.find("#sequenceTextInput").val().replace(" ", "");
            // 序列名称
            let nameValue = seqDialog.find("#nameInp").val().replace(" ", "");
            // 步长
            let steps = seqDialog.find(".select_steps").val();
            let stepsNum = parseInt(steps);
            let sequenceValue = "";
            let range = instance.api.getRange();
            let rowIndex = range.length > 0 ?  range[0].column[0] : 0;
            _this.colIndex = range.length > 0 ?  range[0].row[0] : 0;
            let e = []
              , r = seqDialog.find("#dnafileinput")[0].files
              , o = seqDialog.find("#rnafileinput")[0].files
              , u = seqDialog.find("#proteinfileinput")[0].files;
            if (r.length > 0 || u.length > 0 || o.length > 0) {
                for (let p = 0; p < r.length; p++)
                    e.push(r[p]);
                for (let m = 0; m < u.length; m++)
                    e.push(u[m]);
                for (let y = 0; y < o.length; y++)
                    e.push(o[y])
            }
            if (nameValue) {
                nameValue = '#'+nameValue;
                instance.api.setCellValue(_this.colIndex, rowIndex, nameValue);
            }
            if (e.length > 0){ // 点击上传文件处理
                for (let v = 0; v < e.length; v++)
                    _this.handleFile(rowIndex + 1, e[v], stepsNum);
            }else if (0 !== t) {// 拖拽上传文件处理
                for (let b = 0; b < t.length; b++)
                    _this.handleFile(rowIndex + 1, t[b], stepsNum);
            } else if (textValue.length > 0) { // 直接填写序列号时处理
                for (let w = 0; w < textValue.length; w++)
                !0 === sequenceCommon.checkSequence(textValue[w]) && (sequenceValue += textValue[w]);
                _this.insertCreateSequence(_this.colIndex, rowIndex + 1, sequenceValue, stepsNum);
            }
            currentInstance.find("#luckysheet-sequenceImport-dialog").hide();
            currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
        }
        instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);      
        let seqDialog = currentInstance.find(`#${id}`);  
        // 蛋白质、DNA、RNA序列导入切换
        seqDialog.find(`#select-import-ways ul li`).click(function () {
            $(this).addClass('active')
            $(this).siblings().removeClass('active')
            let clickLiId = $(this).attr('id')
            seqDialog.find(`#drag-area #card #${clickLiId.split('_')[0]}_file_li`).addClass('active')
            seqDialog.find(`#drag-area #card #${clickLiId.split('_')[0]}_file_li`).siblings().removeClass('active')
        });
        seqDialog.find(".file").on("change", "input[type='file']", (function() {
            let e = $(this).val();
            if (e.length > 0) {
                $(".fileerrorTip").html("").hide();
                let t = e.split("\\")
                  , n = "未选择文件...";
                n = e.length <= 20 ? t[t.length - 1] : t[t.length - 1].slice(0, 20) + "...",
                seqDialog.find(".showFileName").html(n);
            }
        }
        ));
        // 监听拖拽
        seqDialog.find("#drag-area").on("dragover", function(e){
            // e.originalEvent.stopPropagation(),
            e.originalEvent.preventDefault();
        });
        seqDialog.find("#drag-area").on("drop", function(e){
            t = e.originalEvent.dataTransfer.files,
            e.originalEvent.preventDefault();
            let n = t[0].name;
            n.split(".");
            seqDialog.find(".showFileName").html(n);
        });
    },

    /**
     * 插入转化的序列
     * @param  e 行
     * @param  t 列
     * @param  n 序列号
     * @param  r 步长
     */
    insertCreateSequence: function (e, t, n, r) {
        let _this = this;
        const instance = _this.instance;
        let range = instance.api.getRange();
        let o = n.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "").replace(/[\n\r\s]/g, "");
        let sheet = instance.api.getSheet();
        let row = e - sheet.row;
        let column = t + o.length - sheet.column;
        if (0 !== r ) row = row + 2; // 因为待坐标为当前长度+1且增加长度为1，因此需要+2
        if (row > 0) {
            sequenceCommon.insertSheetRow(sheet.row, row, instance);
            sheet.row = sheet.row + row;
        }
        if (column > 0) {
            sequenceCommon.insertSheetColumn(sheet.column, column, instance);
            sheet.column = sheet.column + column;
        }
        0 !== r && instance.api.setCellValue(e + 1, t - 1, "Axis",{ isRefresh: false});
        // 还原选区，因为插入行列后插入的行列回默认选中
        if(row > 0 || column > 0) instance.api.setRangeShow(range);
        for (let a = 0; a < o.length; a++)
            instance.api.setCellValue(e, t + a, o[a], { isRefresh: false}),
            0 !== r && ((a + 1) % r == 0 && instance.api.setCellValue(e + 1, t + a, "S".concat(a + 1),{ isRefresh: false}),
            0 === a && instance.api.setCellValue(e + 1, t + a, "S1", { isRefresh: false}),
            a === o.length - 1 && instance.api.setCellValue(e + 1, t + a, "S".concat(a + 1), { isRefresh: false}));
            instance.api.refresh();
    },

    /**
     * 处理序列文件
     * @param  s 列
     * @param  e 文件
     * @param  t 步长
     */
    handleFile(s , e, t) {
        let self = this;
        const instance = self.instance;
        let _locale = instance.locale();
        const locale_sequenceDialog = _locale.sequenceDialog;
        if ("xml" === e.name.split(".")[1])
            !function(e, t) {
                let n = new FileReader;
                n.readAsText(e, "utf8"),
                n.onload = function() {
                    let e = [], r = n.result.split("\n");
                    for (let o = 0; o < r.length; )
                        "</entry>" === r[o] && (e.push(r.slice(0, o)),
                        r = r.slice(o + 1),
                        o = 0),
                        ++o === r.length - 1 && e.push(r);
                    for (let c = 0; c < e.length; c++)
                        uXmlFile(e[c]);
                    function uXmlFile(e) {
                        let  n = [], r = [], o = [], c = [], u = [], l = "";
                        for (let h = 0; h < e.length; h++) {
                            let p = e[h].split(" ").filter(function (s) {
                                return s && s.trim(); 
                            })
                              , m = [];
                            if ("<dbReference" === p[0]) {
                                if ('type="GO"' === p[1]) {
                                    let y = p[2].slice(4, -3);
                                    n.push(y)
                                } else if ('type="Gene3D"' === p[1]) {
                                    let g = p[2].slice(4, -3);
                                    c.push(g)
                                } else if ('type="InterPro"' === p[1]) {
                                    let v = p[2].slice(4, -3);
                                    o.push(v)
                                } else if ('type="Pfam"' === p[1]) {
                                    let b = p[2].slice(4, -3);
                                    r.push(b)
                                }
                            } else if ("<feature" === p[0]) {
                                let x = p.join("").split('"'), w = /description/, _ = /type/;
                                for (let S = 0; S < x.length; S++)
                                    !0 === _.test(x[S]) && m.push(x[S + 1]),
                                    !0 === w.test(x[S]) ? m.push(x[S + 1]) : S === x.length - 1 && 1 === m.length && m.push("No description");
                                let O = e[h + 2].split("=")
                                  , E = e[h + 3].split("=");
                                (O[1] || E[1]) && (O[1] && m.push(O[1].slice(1, -3)),
                                E[1] && m.push(E[1].slice(1, -3)),
                                u.push(m)),
                                h += 3
                            } else if ("<sequence" === p[0]) {
                                l += p[p.length - 1].replace(">", "?").replace("<", "?").split("?")[1]
                            } else if ("<protein>" === p[0]) {
                                let C = e[h - 1].split(">");
                                f = C[1].slice(0, -6)
                            }
                        }
                        if ("" !== l) {
                            instance.api.setCellValue(self.colIndex + 2, s, "GO id", { isRefresh: false});
                            for (let k = 0; k < n.length; k++)
                                instance.api.setCellValue(self.colIndex + 3 + k, s, n[k], { isRefresh: false});
                                instance.api.setCellValue(self.colIndex + 2, s + 1, "Pfam id", { isRefresh: false});
                            for (let M = 0; M < r.length; M++)
                                instance.api.setCellValue(self.colIndex + 3 + M, s + 1, r[M], { isRefresh: false});
                                instance.api.setCellValue(self.colIndex + 2, s + 2, "InterPro id", { isRefresh: false});
                            for (let T = 0; T < o.length; T++)
                                instance.api.setCellValue(self.colIndex + 3 + T, s + 2, o[T], { isRefresh: false});
                                instance.api.setCellValue(self.colIndex + 2, s + 3, "Features Type", { isRefresh: false}),
                                instance.api.setCellValue(self.colIndex + 2, s + 4, "Description", { isRefresh: false}),
                                instance.api.setCellValue(self.colIndex + 2, s + 5, "Begin Site", { isRefresh: false}),
                                instance.api.setCellValue(self.colIndex + 2, s + 6, "End Site", { isRefresh: false});
                            for (let A = 0; A < u.length; A++)
                                instance.api.setCellValue(self.colIndex + 3 + A, s + 3, u[A][0], { isRefresh: false}),
                                instance.api.setCellValue(self.colIndex + 3 + A, s + 4, u[A][1], { isRefresh: false}),
                                u[A][2] && instance.api.setCellValue(self.colIndex + 3 + A, s + 5, u[A][2].replace('"', ""), { isRefresh: false}),
                                u[A][3] && instance.api.setCellValue(self.colIndex + 3 + A, s + 6, u[A][3].replace('"', ""), { isRefresh: false});
                                self.insertCreateSequence(self.colIndex , s , l, t);
                        }
                        self.colIndex = self.colIndex + 3 + Math.max.apply(null, [n.length, o.length, u.length, r.length])
                    }
                }
            }(e, t);
        else if ("fasta" === e.name.split(".")[1])
            !function(e, t) {
                let n = ""
                  , r = 1
                  , i = new FileReader;
                i.readAsText(e, "utf8"),
                i.onload = function() {
                    for (let e = i.result.split("\n"); r < e.length; r++)
                        n += e[r];
                    self.insertCreateSequence(self.colIndex , s , n, t),
                    self.colIndex = self.colIndex + 2
                }
            }(e, t);
        else if ("genbank" === e.name.split(".")[1])
            self.handleGBFile( s , e, t);
        else if ("gb" === e.name.split(".")[1])
            self.handleGBFile( s , e, t);
        else {
            instance.tooltip.info('', locale_sequenceDialog.wrongFormat);
        }
    },

    /**
     * 处理genbank/gb文件
     * @param  s 列
     * @param  e 文件
     * @param  t 步长
     */
    handleGBFile(s , e, t) {
        let self = this;
        const instance = self.instance;
        let n = ""
          , r = 0
          , o = /^\d+$/
          , c = /^ORIGIN/
          , u = new FileReader;
        u.readAsText(e, "utf8"),
        u.onload = function() {
            let e = u.result.split("\n")
            for (let l = 0; l < e.length; l++)
                !0 === c.test(e[l]) && (r = l);
            for (r += 1; r < e.length; r++)
                for (let f = e[r].split(" "), h = 0; h < f.length; h++)
                    f[h].length > 0 && !1 === o.test(f[h]) && "//" !== f[h] && "//\r" !== f[h] && (n += f[h]);
            let p = [];
            for (let m = 0; m < e.length; m++) {
                let y = []
                  , g = e[m].replace(/\s+/g, "");
                if ("CDS" === g.slice(0, 3)) {
                    let v = g.slice(3).split("..");
                    y.push(parseInt(v[0].replace("<", "").replace("complement(", ""))),
                    y.push(parseInt(v[1].replace(")", ""))),
                    y.push("CDS"),
                    p.push(y)
                }
            }
            instance.api.setCellValue(self.colIndex + 2, s - 1, "CDS", { isRefresh: false}),
            instance.api.setCellValue(self.colIndex + 2, s , "Start Site", { isRefresh: false}),
            instance.api.setCellValue(self.colIndex + 2, s + 1, "End Site", { isRefresh: false});
            for (let b = 0; b < p.length; b++)
                instance.api.setCellValue(self.colIndex + 3 + b, s , p[b][0], { isRefresh: false}),
                instance.api.setCellValue(self.colIndex + 3 + b, s + 1, p[b][1], { isRefresh: false});
            self.insertCreateSequence(self.colIndex , s , n, t),
            self.colIndex = self.colIndex + p.length + 3;
        }
    },

    /**
     * 生成坐标
     */
    createProteinAxis(){
        let _this = this;
        const instance = _this.instance;
        const containerId = instance.getInstanceId();
        const currentInstance = $(`#${containerId}`);
        let _locale = instance.locale();
        const locale_proteinAxisDialog = _locale.proteinAxisDialog;
        let content = `<div class="protein-axis-setting-block">
            <div class="subtemplate_wrap" >
                <div id="sub_module_body" class="sub_module_body">
                    <div id="save_as_box" class="save_as_box">
                        <div class="save_part">
                            <label>
                                ${locale_proteinAxisDialog.proteinaxistip}：
                            </label>
                            <select class="select_span">
                                <option value="1" selected="">1</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
        const id = "luckysheet-proteinAxis-dialog";
        const title = locale_proteinAxisDialog.proteinaxis;
        let confirmCallback = function(target, instance) {
            const axisDialog = currentInstance.find("#luckysheet-proteinAxis-dialog");
            // 步长
            let steps = axisDialog.find(".select_span").val();
            let stepsNum = parseInt(steps);
            let range = instance.api.getRange();
            let column = range[range.length - 1].column;
            let e, 
                c = column.length > 0 ? column[0] : 0, 
                u = column.length > 0 ? column[1] : 0, 
                l = [], 
                f = [], 
                h = [];
                let data = _this.getConstituencyData(); // 获取选区数据
                l = data.l;
                f = data.f;
                h = data.h;

                for (let x = 0; x < l.length; x++) {
                    let w = h[x][0] + x;
                    _this.insertD(w + 1, 1 , c, u),
                    // f[x][0].length > 0 && this.applyMerge(f[x][0], f[x][1], w, c), 暂时先去掉，以及该在插入时合并单元格
                    e = sequenceCommon.checkTripleOrSingle(l[x].replace(/[\*]/g, "")),
                    _(l[x], e, h[x], w, f[x])
                }
                instance.api.setRangeShow(range);
                instance.api.refresh();
                function _(e, t, r, o, a) {
                    e.length > 0 && instance.api.setCellValue(o + 1, c + r[1], "S1", { isRefresh: false});
                    let s = 1;
                    !0 === t && (s = 3);
                    let u = 0, l = 0;
                    for (let f = 0; f < e.length; ) {
                        let h = void 0;
                        h = "*" !== e[f] ? e.slice(f, f + s) : e[f];
                        for (let d = 0; d < a.length; d++)
                            c + r[1] + u + l - 1 === a[1][d] && (u = u + a[0][d].cs - 1),
                            instance.api.setCellValue(o, c + r[1] + l + u, h, { isRefresh: false}),
                            (l + 1) % stepsNum == 0 && instance.api.setCellValue(o + 1, c + r[1] + l + u, "S".concat(l + 1), { isRefresh: false});
                        f += h.length,
                        l += 1;
                    }
                }
            axisDialog.hide();
            currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
        }
        instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
    },

    /**
     * 获取选区范围内数据起始行数组，数据数组，合并单元格数组
     */
    getConstituencyData(){
        let instance = this.instance;
        let range = instance.api.getRange();
        let column = range[range.length - 1].column;
        let row = range[range.length - 1].row;
        let a = row.length > 0 ? row[0] : 0, 
            s = row.length > 0 ? row[1] : 0, 
            c = column.length > 0 ? column[0] : 0, 
            u = column.length > 0 ? column[1] : 0, 
            l = [], // 数据
            f = [], // 合并单元格数组
            h = []; // 每行开始索引
        for (let d = a; d <= s; d++) {
            let p = !1, m = "", y = "";
            for (let g = c; g <= u; g++) {
                if (m = instance.api.getCellValue(d, g) === null ? '': instance.api.getCellValue(d, g).toString().replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "").replace(/[\n\r]/g, ""),
                !1 === p) {
                    if ("" === m)
                        continue;
                    for (let v = 0; v < m.length; v++)
                        if (!0 === sequenceCommon.checkSequence(m[v])) {
                            p = !0,
                            h.push([d, g - c]);
                            break
                        }
                }
                for (let b = 0; b < m.length; b++) {
                    !0 === sequenceCommon.checkSequence(m[b]) && (y += m[b])
                }
            }
            y.length > 0 && (!1 === p && h.push([d, 0]),
            l.push(y),
            f.push(sequenceCommon.checkMerge(d, c, u, instance)))
        }
        let obj = {
            h,
            l,
            f,
        }
        return obj;
    },

    /**
     * 插入坐标行,并设置合并
     * @param  e 插入行索引
     * @param  t 行数量
     * @param col 列索引
     * @param colLength 长度
     * @returns 
     */
    insertD(e, t , col , colLength) {
        let instance = this.instance;
        instance.api.insertRow(e , {number: t });
        let sheet = instance.api.getSheet();
        for(let c = col ; c < colLength ; c++ ){
            let data = sheet.data[e - 1][c];
            if (data && data.mc && data.mc.cs) {
                instance.api.setRangeShow({row:[e, data.mc.rs], column:[c, data.mc.cs + c - 1]})
                instance.api.setRangeMerge("all");
            }
        }
    },
    
    /**
     * 序列合并
     */
    sequenceMerge() {
        let instance = this.instance;
        let range = instance.api.getRange();
        let column = range[range.length - 1].column;
        let row = range[range.length - 1].row;
        let e = [], 
            o = row.length > 0 ? row[0] : 0, 
            a = row.length > 0 ? row[1] : 0, 
            s = column.length > 0 ? column[0] : 0, 
            c = column.length > 0 ? column[1] : 0
          , u = a - o;
        function l(e, t, n) {
            let r = instance.api.getSheet().data, a = "" , s = /[#]+/;
            for (let c = t; c <= n; c += 1) {
                let u = r[e][c];
                if (u && void 0 !== u.v && "-" !== u.v && ">" !== u.v && "<" !== u.v && !0 !== s.test(u.v)) {
                    // let l = u && u.fc; 不处理字体颜色
                    a += u.v;
                }else if (u && u.ct && u.ct.s && u.ct.s.length > 0 ) {
                    a = a.concat(u.ct.s);
                    u.ct.s.array.forEach(element => {
                        a += element.v;
                    });
                }
            }
            return a;
        }
        for (let f = o; f <= a; f += 1) {
            let textValue = l(f, s, c);
            e.push(textValue);
        }
        instance.api.insertColumn(s , {number: 1 });
        for (let h = 0; h < u + 1; h++){ 
            instance.api.setCellValue(o + h, s, e[h], { isRefresh: false});
        }
        // 因在选中列前面插入一列，选中数据要后移一列
        instance.api.setRangeShow({column: [s+1 , c+1], row: row});
        instance.api.refresh();
    },
    
    /**
     * 切换大小写
     */
    switchCase() {
        let instance = this.instance;
        let range = instance.api.getRange();
        let column = range[range.length - 1].column;
        let row = range[range.length - 1].row;
        r = row.length > 0 ? row[0] : 0, 
        i = row.length > 0 ? row[1] : 0, 
        o = column.length > 0 ? column[0] : 0, 
        a = [], 
        s = [];
        let data = this.getConstituencyData(), // 获取选区数据
        fList = data.f;
        hList = data.h;
        colList = []; // 存储序列所在行
        for ( let c = r; c <= i; c++) {
            let u = sequenceCommon.readSelectedInformation(c, !0, "string", instance);
            u.length > 0 && a.push(u) && colList.push(c);
        }
        for (let l = 0; l < a.length; l++) {
            let f = a[l].toString();
            let fItem = fList[l];
            let hItem = hList[l];
            for (let h = 0; h < f.length; h++)
                f[h] === f[h].toLowerCase() ? s[h] = f[h].toUpperCase() : s[h] = f[h].toLowerCase();
            let u = 0;
            for (let d = 0; d < f.length; d++){
                for(let a = 0; a < fItem.length ; a++)
                    o + hItem[1] + u + d - 1 === fItem[1][a] && (u = u + fItem[0][a].cs - 1),
                    instance.api.setCellValue( colList[l], o + hItem[1] + u + d, s[d], { isRefresh: false});
            }     
        }
        instance.api.refresh();
    },
    
    /**
     * 创建注释
     */
    createComments(container) {
        let _this = this;
        const instance = _this.instance;
        const containerId = instance.getInstanceId();
        const currentInstance = $(`#${containerId}`);
        let _locale = instance.locale();
        const locale_sequenceComments = _locale.sequenceComments;
        let content = `<div class="comments-setting-block">
            <div class="subtemplate_wrap" >
                <div id="sub_module_body" class="sub_module_body">
                    <div id="save_as_box" class="save_as_box">
                        <div class="save_part">
                            <div class="annotateContent">
                                <label>
                                    ${locale_sequenceComments.annotationContent}：
                                </label>
                                <input type="text" class="annotateContentText"/>
                            </div>
                            <div class="annotateDirection">
                                <label>
                                    ${locale_sequenceComments.annotateDirection}：
                                </label>
                                <select class="annotateDirectionSelect">
                                    <option value="forward" selected>${locale_sequenceComments.positive}</option>
                                    <option value="reverse">${locale_sequenceComments.oppsite}</option>
                                </select>
                                <br/>
                            </div>
                            <div class="annotateColor">
                                <label>
                                    ${locale_sequenceComments.annotationColor}：
                                </label>
                                <div id="displayColor" style="display: inline-block;">
                                    <span name="select-color" class="annotateColorSelect" style="background-color: orangered;"></span>
                                    <span name="select-color" class="annotateColorSelect" style="background-color: #F7FE2E;"></span>
                                    <span name="select-color" class="annotateColorSelect" style="background-color: #ffb22b;"></span>
                                    <span name="select-color" class="annotateColorSelect" style="background-color: #00e335;"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`

        const id = "luckysheet-comments-dialog";
        const title = locale_sequenceComments.createAnnotation;
        let selectColor = ""; // 选中颜色值
        // 设置注释弹窗回调
        let confirmCallback = function(target, instance) {
            const commentsDialog = $(`#${containerId} #${id}`);
            let range = instance.api.getRange();
            let column = range[range.length - 1].column;
            let row = range[range.length - 1].row;
            let t = commentsDialog.find(".annotateContent input").val()
              , r = commentsDialog.find(".annotateDirection select").val()
              , c = (row.length > 0 ? row[0] : 0, row.length > 0 ? row[1] : 0)
              , l = column.length > 0 ? column[0] : 0;
            instance.api.insertRow(c + 1);
            instance.api.setRangeShow({row:[c + 1, c + 1], column: column}); // 合并单元格行列
            instance.api.setRangeMerge("all");
            let value = "forward" === r ? "/*> ".concat(t, " >*/") : "/*< ".concat(t, " <*/");
            selectColor ? instance.api.setCellValue( c + 1, l, {ht: 0, v: value, m: value, bg: selectColor}) : instance.api.setCellValue( c + 1, l, {ht: 0, v: value, m: value}),
            instance.api.setRangeShow(range);
            commentsDialog.hide();
            currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
        }
        instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
        // 创建完弹窗后设置注释颜色切换方法
        const commentsDialog = $(`#${containerId} #${id}`);
        // 注释颜色切换
        commentsDialog.find(`#displayColor span`).click(function() {
            $(this).parent().find("span").css("border", "1px solid transparent"),
            selectColor = $(this).css("background-color"),
            $(this).css("border", "1px solid #045FB4");
        });
    },
    
    /**
     * DNA序列翻转
     */
    sequenceFlipping() {
        let instance = this.instance;
        let _locale = instance.locale();
        const locale_sequenceFlipping = _locale.DNASequenceFlipping;
        let range = instance.api.getRange();
        let column = range[range.length - 1].column;
        let row = range[range.length - 1].row;
            r = row.length > 0 ? row[0] : 0, 
            i = row.length > 0 ? row[1] : 0, 
            o = column.length > 0 ? column[0] : 0, 
            a = (column.length > 0 ? column[1] : 0,[]),
            u = [];
        let data = this.getConstituencyData(), // 获取选区数据
        fList = data.f.slice().reverse(); // 数组倒序
        hList = data.h.slice().reverse(); // 数组倒序
        for (l = r; l <= i; l++)
            0 !== sequenceCommon.readSelectedInformation(l, !0, "array", instance).length && (a.push(sequenceCommon.readSelectedInformation(l, !0, "array", instance)),
            u.push(l));
        function f(e) {
            for (let t = e.length, n = e; e.length > 0; ) {
                for (let r = 0; r < parseInt(t / 2); r++) {
                    let i = n[r];
                    n[r] = n[t - r - 1],
                    n[t - r - 1] = i
                }
                return n
            }
        }
        if (u = f(u), a.length > 0 && a.length <= 2)
            for (let h = 0; h < a.length; h++){
                let fItem = fList[h];
                let hItem = hList[h];
                let ui = 0;
                for (let d = f(a[h]).join(""), p = 0; p < d.length; p++)
                    for(let i = 0; i < fItem.length ; i++)
                    o + p + hItem[1] + ui - 1 === fItem[1][i] && (ui = ui + fItem[0][i].cs - 1),
                    instance.api.setCellValue(parseInt(u[h]), o + p + hItem[1] + ui, d[p], { isRefresh: false});
            }  
            instance.api.refresh();
        if (a.length >= 3 || 0 === a) {
            instance.tooltip.info('', locale_sequenceFlipping.amountTips);
        }
    },
    
};
export default sequenceImportDialog;