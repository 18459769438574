
import locale from '../../locale/locale';
import { modelHTML } from "../../controllers/constant";
import { replaceHtml } from '../../utils/util';
import tooltip from '../../global/tooltip';
import { getSheetIndex } from '../../methods/get';
import Store from '../../store';
import multiInstanceStore from '../../store/multiInstance';
import createHtmlDom from '../../controllers/common/createHtmlDom';

// Initialize the export xlsx api
function exportXlsx(options, config, isDemo) {

}

function downloadXlsx(data, filename) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
}

/**
 * 
 * @param {*} url 
 * @param {*} success 
 * @param {*} fail 
 */
function fetchAndDownloadXlsx({url,order}, success, fail, t) {
    let instance = t || this.instance;
    let containerId = instance.getInstanceId();
    const luckyJson = luckysheet[containerId].toJson();
    luckysheet[containerId].getAllChartsBase64((chartMap) => {
        luckyJson.chartMap = chartMap
        luckyJson.devicePixelRatio = window.devicePixelRatio
        luckyJson.exportXlsx = {
            order
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(luckyJson)
        })
            .then((response) => response.blob())
            .then((blob) => {
                if (blob.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    const filename = luckyJson.title + '.xlsx';
                    downloadXlsx(blob, filename);
                    success && success()
                } else {
                    fail && fail()
                }

            })
            .catch((error) => {
                console.error('fetch error:', error);
                fail && fail()
            });
    })


}

function createExportDialog(url) {
    let instance = this.instance;
    const containerId = instance.getInstanceId();
	let Store = instance.getStore();
    let currentInstance = $(`#${containerId}`);
    currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
    var xlsxContainer = currentInstance.find("#luckysheet-export-xlsx");

    if (xlsxContainer.length === 0) {

        const _locale = instance.locale();
        const locale_exportXlsx = _locale.exportXlsx;
        const locale_button = _locale.button;

        let content = `<div class="luckysheet-export-xlsx-content" style="padding: 10px 10px 10px 0;">
                <span>${locale_exportXlsx.range}</span>
                <select class="luckysheet-export-xlsx-select-area">
                    <option value="allSheets" selected="selected">${locale_exportXlsx.allSheets}</option>
                    <option value="currentSheet">${locale_exportXlsx.currentSheet}</option>
                </select>
        </div>`;

        instance.createHtmlDom.createDialog('luckysheet-export-xlsx', content, containerId, locale_exportXlsx.title, ()=>{}, false, false, true, '', '', '', true);

        selectedOption = 'allSheets'

        // init event
        currentInstance.find("#luckysheet-export-xlsx #luckysheet-export-xlsx-confirm").on('click',()=>{
            luckysheet[containerId].showLoadingProgress()

            var order = 'all'
            if(selectedOption === 'currentSheet'){
                order = instance.get.getSheetIndex(Store.currentSheetIndex)
            }
            fetchAndDownloadXlsx({url,order},()=>{
                luckysheet[containerId].hideLoadingProgress()
            },()=>{
                luckysheet[containerId].hideLoadingProgress()
                instance.tooltip.info(_locale.exportXlsx.serverError, "");
            }, instance)
            currentInstance.find("#luckysheet-export-xlsx").hide()
        })

        currentInstance.find("#luckysheet-export-xlsx .luckysheet-export-xlsx-select-area").change(function() {
            selectedOption = $(this).val();
          });

    }

}

export { exportXlsx, downloadXlsx, fetchAndDownloadXlsx, createExportDialog }
