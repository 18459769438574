export default function luckysheetcreatesheet(colwidth, rowheight, data, cfg, active, instance) {
    let Store = instance.getStore();
    if(active == null){
        active = true;
    }

    Store.visibledatarow = [];
    Store.visibledatacolumn = [];
    Store.ch_width = 0;
    Store.rh_height = 0;
    Store.zoomRatio = 1;

    if(cfg != null){
        Store.config = cfg;
    }
    else{
        Store.config = {};
    }

    if (data.length == 0) {
        Store.flowdata = instance.getdata.datagridgrowth(data, rowheight, colwidth);
    }
    else if (data.length < rowheight && data[0].length < colwidth) {
        Store.flowdata = instance.getdata.datagridgrowth(data, rowheight - data.length, colwidth - data[0].length);
    }
    else if (data.length < rowheight) {
        Store.flowdata = instance.getdata.datagridgrowth(data, rowheight - data.length, 0);
    }
    else if (data[0].length < colwidth) {
        Store.flowdata = instance.getdata.datagridgrowth(data, 0, colwidth - data[0].length);
    }
    else {
        Store.flowdata = data;
    }

    instance.editor.webWorkerFlowDataCache(Store.flowdata);//worker存数据

    instance.rhchInit(rowheight, colwidth);

    if(active){
        instance.sheetmanage.showSheet();

        setTimeout(function () {
            instance.sheetmanage.restoreCache();
            instance.formula.execFunctionGroup();
            instance.sheetmanage.restoreSheetAll(Store.currentSheetIndex);
            instance.refresh.luckysheetrefreshgrid();
        }, 1);
    }
}
