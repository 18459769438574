/**
 * 蛋白质分析js文件
 * wangxiaofei 2023.9.5
 */
import sequenceCommon from "./sequenceCommon"; // 序列操作公共js方法

const proteinAnalysis = {
  // 单序列三序列map
  exchangeMapTo1 : new Map([["ala", "A"], ["arg", "R"], ["asn", "N"], ["asp", "D"], ["cys", "C"], ["glu", "E"], ["gln", "Q"], ["gly", "G"], ["his", "H"], ["ile", "I"], ["leu", "L"], ["lys", "K"], ["met", "M"], ["phe", "F"], ["pro", "P"], ["ser", "S"], ["thr", "T"], ["trp", "W"], ["tyr", "Y"], ["val", "V"], ["any", "X"], ["sec", "U"], ["*", "*"]]),
  numberingMethod :"", // 抗体编号中编号方法
  /**
   * 等电点
   */
  isoelectricPointFunc() {
    let _this = this;
    const instance = _this.instance;
    const containerId = instance.getInstanceId();
    const currentInstance = $(`#${containerId}`);
    const maskDialog =  currentInstance.find("#luckysheet-modal-dialog-mask");
    const _locale = instance.locale();
    const proteinanalysismenuText = _locale.proteinanalysismenu;
    let content = `<div class="isoelectricPoint-setting-block">
      <div class="subtemplate_wrap">
        <div id="sub_module_body" class="sub_module_body">
          <div id="save_as_box" class="save_as_box">
            <div class="save_part">
              <label>${proteinanalysismenuText.proteinPK}：</label>
              <select class="select_PK" >
                <option value="dtaselect" selected>DTASelect</option>
                <option value="emboss" >EMBOSS</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>`;

    const id = 'luckysheet-isoelectricPoint-dialog';
    const title = proteinanalysismenuText.proteinPKtitle;
    let confirmCallback = function(target, instance) {
      let seqDialog = currentInstance.find(`#${id}`);
      let e = seqDialog.find(".select_PK").val();
      let range = instance.api.getRange();
      let column = range[range.length - 1].column;
      let row = range[range.length - 1].row;
      let r = row.length > 0 ? row[0] : 0, 
      i = row.length > 0 ? row[1] : 0, 
      o = column.length > 0 ? column[0] : 0, 
      s = [],
      c = r + 1,
      f = [],
      y = i - r + 1;
      for (let u = r; u <= i; u++) {
        let l = sequenceCommon.readSelectedInformation(u, !0, "string", instance);
        s.push(l)
      }
      for (let  h = 0; h < s.length; h++) {
          let d = p(s[h]);
          f.push(d)
      }
      function p(t) {
          let n, r, i, o, a, s, c, u, l, f = 7, h = 3.5, d = 0, p = 0;
          "dtaselect" === e ? (n = 8,
          r = 10,
          i = 12,
          o = 6.5,
          a = 4.4,
          s = 4.4,
          c = 8.5,
          u = 10,
          l = 3.1) : (n = 8.6,
          r = 10.8,
          i = 12.5,
          o = 6.5,
          a = 3.9,
          s = 4.1,
          c = 8.5,
          u = 10.1,
          l = 3.6);
          let y = 0;
          -1 !== t.search(/k/i) && (y = t.match(/k/gi).length);
          let g = 0;
          -1 !== t.search(/r/i) && (g = t.match(/r/gi).length);
          let v = 0;
          -1 !== t.search(/h/i) && (v = t.match(/h/gi).length);
          let b = 0;
          -1 !== t.search(/d/i) && (b = t.match(/d/gi).length);
          let x = 0;
          -1 !== t.search(/e/i) && (x = t.match(/e/gi).length);
          let w = 0;
          -1 !== t.search(/c/i) && (w = t.match(/c/gi).length);
          let _ = 0;
          for (-1 !== t.search(/y/i) && (_ = t.match(/y/gi).length); (d = m(n, f) + y * m(r, f) + g * m(i, f) + v * m(o, f) - b * m(f, a) - x * m(f, s) - w * m(f, c) - _ * m(f, u) - m(f, l)).toFixed(2) !== (100 * p).toFixed(2); )
            d > 0 ? f += h : f -= h,
            h /= 2,
            p = d;
          return f = f.toFixed(2)
      }
      function m(e, t) {
        let n = Math.pow(10, e - t);
        return n / (n + 1)
      }
      for (let g = i + 1; g >= r + 1; g--)
        instance.api.insertRow(g);
      for (let v = 0; v < y; v++)
        s[v].length > 0 && instance.api.setCellValue(c + 2 * v, o, "pI=".concat(f[v]), { isRefresh: false});
      instance.api.refresh();
      instance.api.setRangeShow(range);
      seqDialog.hide();
      maskDialog.hide();
    };
    instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
  },

  /**
   * 计算选中蛋白质序列分子量
   */
  proteinMwFunc() {
    let _this = this;
    const instance = _this.instance;
    let range = instance.api.getRange();
    let column = range[range.length - 1].column;
    let row = range[range.length - 1].row;
    let t = row.length > 0 ? row[0] : 0, 
    n = row.length > 0 ? row[1] : 0, 
    r = column.length > 0 ? column[0] : 0, 
    a = [],
    s = t + 1,
    l = [],
    d = n - t + 1;
    for (let c = t; c <= n; c++) {
      let u = sequenceCommon.readSelectedInformation(c, !0, "string", instance);
      a.push(u.replace(/[\*]/g, ""))
    }
    for (let f = 0; f < a.length; f++) {
      let h = _this.writeProtMw(a[f]);
      l.push(h)
    }
    for (let p = n + 1; p >= t + 1; p--)
      instance.api.insertRow(p);
    for (let m = 0; m < d; m++)
      l[m].length > 0 && instance.api.setCellValue(s + 2 * m, r, "".concat(l[m].toString(), "kDa"), { isRefresh: false});
    instance.api.refresh();
    instance.api.setRangeShow(range);
  },

  /**
   * 计算蛋白质序列分子量
   * @param {*} proteinSequence 蛋白质序列对象
   * @returns 
   */
  writeProtMw(proteinSequence) {
    let arrayOfMwTriple = ["/Ala/ (A)71.08", "/Cys/ (C)103.14", "/Asp/ (D)115.09", "/Glu/ (E)129.12", " /Phe/ (F)147.18", "/Gly/ (G)57.06", "/His/ (H)137.15", "/Ile/ (I)113.17", "/Lys/ (K)128.18", "/Leu/ (L)113.17", "/Met/ (M)131.21", "/Asn/ (N)114.11", "/Pro/ (P)97.12", "/Gln/ (Q)128.41", "/Arg/ (R)156.20", "/Ser/ (S)87.08", "/Thr/ (T)101.11", "/Val/ (V)99.14", "/Trp/ (W)186.21", "/Tyr/ (Y)163.18"]
      , arrayOfMw = ["/A/ (A)71.08", "/C/ (C)103.14", "/D/ (D)115.09", "/E/ (E)129.12", " /F/ (F)147.18", "/G/ (G)57.06", "/H/ (H)137.15", "/I/ (I)113.17", "/K/ (K)128.18", "/L/ (L)113.17", "/M/ (M)131.21", "/N/ (N)114.11", "/P/ (P)97.12", "/Q/ (Q)128.41", "/R/ (R)156.20", "/S/ (S)87.08", "/T/ (T)101.11", "/V/ (V)99.14", "/W/ (W)186.21", "/Y/ (Y)163.18"]
      , triple = sequenceCommon.checkTripleOrSingle(proteinSequence)
      , water = 18.015
      , result = 0;
    if (!1 === triple)
        for (let j = 0; j < arrayOfMw.length; j++) {
            let tempNumber = 0
              , matchExp = "".concat(arrayOfMw[j].match(/\/[^\/]+\//), "gi");
            matchExp = eval(matchExp),
            -1 !== proteinSequence.search(matchExp) && (tempNumber = proteinSequence.match(matchExp).length),
            result += tempNumber * parseFloat(arrayOfMw[j].match(/[\d\.]+/).toString())
        }
    else
        for (let _j = 0; _j < arrayOfMwTriple.length; _j += 1) {
            let _tempNumber = 0
              , _matchExp = "".concat(arrayOfMwTriple[_j].match(/\/[^\/]+\//), "g");
            _matchExp = eval(_matchExp),
            -1 !== proteinSequence.search(_matchExp) && (_tempNumber = proteinSequence.match(_matchExp).length),
            result += _tempNumber * parseFloat(arrayOfMw[_j].match(/[\d\.]+/).toString())
        }
    return 0 === result || (result += water,
    result /= 1e3,
    result = result.toFixed(2)),
    result
  },

  /**
   * 氨基酸疏水性
   */
  Hydrophobicity() {
    let _this = this;
    const instance = _this.instance;
    const containerId = instance.getInstanceId();
    const currentInstance = $(`#${containerId}`);
    const maskDialog =  currentInstance.find("#luckysheet-modal-dialog-mask");
    const _locale = instance.locale();
    const proteinanalysismenuText = _locale.proteinanalysismenu;
    let content = `<div class="Hydrophobicity-setting-block">
      <div class="subtemplate_wrap">
        <div id="sub_module_body">
          <div id="save_as_box" class="save_as_box">
            <ul>
              <li>${proteinanalysismenuText.phnote}</li>
              <li><a href="https://doi.org/10.1016/0021-9673(94)00371-8" target = "_blank">${proteinanalysismenuText.ph7note}</a></li>
              <li><a href="https://doi.org/10.1002/psc.310010507" target = "_blank">${proteinanalysismenuText.ph2note}</a></li>        
            </ul>
            <div class="save_part">
              <label>${proteinanalysismenuText.selectPh}：</label>
              <select class="select_ph" >
                <option value="ph7" selected>PH=7</option>
                <option value="ph2" >PH=2</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>`;
    const id = 'luckysheet-Hydrophobicity-dialog';
    const title = proteinanalysismenuText.Hydrophobicity;
    let confirmCallback = function(target, instance) {
      let seqDialog = currentInstance.find(`#${id}`);
      let e = new Map([["A", [41, 9]], ["R", [-14, 15]], ["N", [-28, 18]], ["D", [-55, 20]], ["C", [49, 8]], ["E", [-31, 19]], ["Q", [-10, 14]], ["G", [0, 12]], ["H", [8, 11]], ["I", [99, 2]], ["L", [97, 3]], ["K", [-23, 17]], ["M", [74, 6]], ["F", [100, 1]], ["P", [-16, 16]], ["S", [-5, 13]], ["T", [13, 10]], ["W", [97, 3]], ["Y", [63, 7]], ["V", [76, 5]]])
      , t = new Map([["A", [47, 9]], ["R", [-26, 16]], ["N", [-41, 18]], ["D", [-18, 15]], ["C", [52, 7]], ["E", [8, 11]], ["Q", [-18, 15]], ["G", [0, 12]], ["H", [-42, 19]], ["I", [100, 1]], ["L", [100, 1]], ["K", [-37, 17]], ["M", [74, 6]], ["F", [92, 3]], ["P", [-46, 20]], ["S", [-7, 13]], ["T", [13, 10]], ["W", [84, 4]], ["Y", [49, 8]], ["V", [79, 5]]])
      , l = ["#ff0000", "#ff5e00", "#ff8100", "#ffa500", "#ffe000", "#ffeb00", "#e4ff00", "#b4ff00", "#27ff00", "#00ffdc", "#00f3ff", "#00d0ff", "#00adff", "#0072ff", "#002bff", "#2700ff", "#1200a7", "#0e026f", "#1f0363", "#000000"];
      let range = instance.api.getRange();
      let column = range[range.length - 1].column;
      let row = range[range.length - 1].row;
      let a = row.length > 0 ? row[0] : 0, 
        s = row.length > 0 ? row[1] : 0, 
        c = column.length > 0 ? column[0] : 0, 
        u = column.length > 0 ? column[1] : 0,
        n = [];
      let r, o, f = [], d = [], p = [], m = seqDialog.find(".select_ph").val(), y = a;
      for (let g = a; g <= s; g++) {
        let v = sequenceCommon.readSelectedInformation(g, !1, "string", instance);
        v.length > 0 && (f.push(v),
        n.push(sequenceCommon.checkMerge(g, c, u, instance)))
      }
      function b(e) {
        let t = [], n = [], r = [];
        for (let i = 0; i < f.length; i++) {
          let o = []
            , a = []
            , s = []
            , c = f[i]
            , u = 1;
          !0 === sequenceCommon.checkTripleOrSingle(c.replace(/\?/g, "")) && (u = 3);
          for (let l = 0; l < c.length; ) {
            let h = c.slice(l, l + u);
            if ("?" === c[l] || "*" === c[l])
              o.push("-99999"),
              a.push("#ffffff"),
              s.push(c[l]),
              l += 1;
            else {
              let d = _this.exchangeMapTo1.get(h.toLowerCase())
                , p = 3 === u ? d : h
                , m = e.get(p.toUpperCase())[0]
                , y = e.get(p.toUpperCase())[1];
              o.push(m.toString()),
              a.push(y.toString()),
              s.push(h),
              l += u
            }
          }
          t.push(o),
          n.push(a),
          r.push(s)
        }
        return [t, n, r]
      }
      o = (d = b("ph7" === m ? e : t))[0],
      r = d[1],
      p = d[2];
      for (let x = 0; x < o.length; x++) {
        if (0 === p[x].join().replace(/\,/g, "").replace(/\?/g, "").length)
          y += 1;
        else {
          if (instance.api.insertRow(y + 1, {number: 2 }),
          0 === n[x][0].length)
            for (let w = 0; w < o[x].length; w++)
              "-99999" !== o[x][w] ? (instance.api.setCellValue(y, c + w, p[x][w], { isRefresh: false}),
              instance.api.setCellValue(y + 1, c + w, o[x][w], { isRefresh: false}),
              instance.api.setCellValue(y + 2, c + w, {
                bg: l[r[x][w] - 1]
              }, { isRefresh: false})) : "*" === p[x][w] && instance.api.setCellValue(y, c + w, p[x][w], { isRefresh: false});
          else {
            // applyMerge(n[x][0], n[x][1], y, c),
            // applyMerge(n[x][0], n[x][1], y + 1, c);
            for (let _ = 0; _ < o[x].length; _++)
              "-99999" !== o[x][_] ? (instance.api.setCellValue(y + 1, c + _, o[x][_], { isRefresh: false}),
              instance.api.setCellValue(y + 1, c + _, {
                ht: 0
              }, { isRefresh: false}), // 设置居中
              instance.api.setCellValue(y + 2, c + _, {
                bg: l[r[x][_] - 1]
              }, { isRefresh: false})) : "*" === p[x][_] && instance.api.setCellValue(y, c + _, p[x][_], { isRefresh: false})
          }
          y += 3
        }
      }
      instance.api.refresh();
      instance.api.setRangeShow(range);
      seqDialog.hide();
      maskDialog.hide();
    };
    instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
  },

  /**
   * 蛋白质不稳定系数
   */
  instabilityIndexFunc() {
    const self = this;
    const instance = self.instance;
    const _locale = instance.locale();
    const proteinanalysismenuText = _locale.proteinanalysismenu;
    const range = instance.api.getRange();
    const column = range[range.length - 1].column;
    const row = range[range.length - 1].row;
    let t = row.length > 0 ? row[0] : 0, 
    n = row.length > 0 ? row[1] : 0, 
    r = column.length > 0 ? column[0] : 0, 
    o = [],
    a = t + 1;
    for (let s = t; s <= n; s++) {
      let c = sequenceCommon.readSelectedInformation(s, !0, "string", instance).replace(/\?/g, "");
      o.push(c.replace(/[\*]/g, ""))
    }
    function u(e) {
        if (0 === e.length)
          return "???";
        let t = "";
        if (!0 === sequenceCommon.checkTripleOrSingle(e))
          for (let n = 0; n < e.length; n += 3)
            t += self.exchangeMapTo1.get(e.slice(n, n + 3).toLowerCase());
        else
          t = e;
        let r = [[1, 1, 24.68, 24.68, 1, 1, 1, 13.34, 1, 1, 1, 1, -14.03, 1, 1, -7.49, 1, -9.37, -14.03, 13.34], [24.68, 1, 33.6, 33.6, 1, 1, -6.54, 1, 1, 1, 20.26, 20.26, 33.6, 1, 1, -6.54, 1, 1, 1, 20.26], [1, 1, -1.88, 58.28, 24.68, 1, -6.54, 1, 1, -6.54, 1, 44.94, -1.88, 1, 1, 1, 44.94, 1, 13.34, 1], [-1.88, 1, 1, 1, 44.94, -9.37, 1, 24.68, 44.94, 1, 1, -1.88, -6.54, 24.68, 1, 1, 1, -9.37, 1, 1], [-9.37, 1, 44.94, 13.34, 13.34, 1, 1, 1, 1, -15.91, 24.68, 13.34, -7.49, 1, -6.54, 1, 1, -7.49, 24.68, 1], [1, 1, 1, 1, 33.6, 1, 1, 1, 1, 1, 13.34, 20.26, 1, -14.03, 1, 1, 1, 1, 1, 1], [1, -6.54, 1, 1, -6.54, -6.54, 20.26, 1, 1, 1, 20.26, 20.26, 1, 1, 20.26, -6.54, 44.94, 1, 1, 1], [-9.37, -1.88, 1, 1, 1, -14.03, -6.54, 1, 44.94, 1, 1, -1.88, -7.49, 24.68, 1, 1, 1, -14.03, 1, 1], [1, 1, 1, 13.34, 1, 1, 1, 1, 1, 1, 1, -1.88, 1, -7.49, 44.97, -7.49, 1, 1, 1, 20.26], [58.28, 1, 1, 20.26, -6.54, 1, 20.26, 13.34, 1, 58.28, 1, 20.26, 1, 1, 1, 1, 44.94, -7.49, 1, 1], [1, 1, 1, 1, 1, -6.54, 1, 1, 1, -6.54, 1, 1, -14.03, -7.49, 1, 1, 20.26, 1, 1, 1], [-1.88, -6.54, -6.54, 1, 1, 20.26, 20.26, 1, 1, -6.54, -6.54, 20.26, 1, 1, 18.38, 20.26, 20.26, 1, 20.26, 1], [-14.03, 1, 1, 1, 1, 13.34, -6.54, -14.03, 1, 1, 1, 1, 1, 1, 20.26, 1, 1, -7.49, 1, 1], [1, 1, 33.6, 1, 1, 1, 24.68, 1, -7.49, 33.6, 1, -6.54, 1, 1, 1, -7.49, 1, -7.49, 1, -7.49], [-14.03, 44.94, 1, -6.54, 1, 1, 20.26, 1, 20.26, 1, 20.26, 20.26, 1, 1, 33.6, 1, 20.26, 1, 1, 1], [1, 1, 1, 1, -6.54, 1, 1, 1, 1, 1, -14.03, 20.26, -7.49, -1.88, 1, 1, 1, -7.49, 1, 1], [1, 33.6, 1, 1, 1, 1, 20.26, 1, 1, 20.26, 1, 44.94, 1, 1, 20.26, 1, 20.26, 1, 1, 1], [13.34, 1, 1, 1, -7.49, 1, 1, -7.49, -7.49, 1, 1, 1, -7.49, -7.49, -6.54, 1, 1, 13.34, -7.49, 1], [1, 44.94, 1, -7.49, 1, 1, 1, 1, 1, 1, -7.49, 20.26, 1, 1, 1, 1, 1, 1, 1, 1], [24.68, 1, 1, 1, 1, 1, 33.6, 1, 1, 20.26, 1, 20.26, 1, -7.49, 1, 1, 1, 1, 1, 1]], i = {
          W: 0,
          C: 1,
          M: 2,
          H: 3,
          Y: 4,
          F: 5,
          Q: 6,
          N: 7,
          I: 8,
          R: 9,
          D: 10,
          P: 11,
          T: 12,
          K: 13,
          E: 14,
          V: 15,
          S: 16,
          G: 17,
          A: 18,
          L: 19
        }, o = 0;
        for (let a = 0; a < t.length - 1; a++) {
          void 0 === i[t[a]] && instance.tooltip.info('',proteinanalysismenuText.instabilitywarn);
          o += r[i[t[a]]][i[t[a + 1]]];
        }
        return 10 / t.length * o
    }
    let l = [],  p = n - t + 1;
    for (let f = 0; f < o.length; f++) {
      let h = u(o[f]);
      l.push(h)
    }
    let d = "unstable";
    l <= 40 && (d = "stable");
    for (let m = 0; m < p; m++)
    instance.api.insertRow(n + 1 - m, {number: 2 });
    for (let y = 0; y < p; y++)
      "???" !== l[y] && (instance.api.setCellValue(a + 3 * y, r, "The instability index (II) is computed to be ".concat(l[y]), { isRefresh: false}),
      instance.api.setCellValue(a + 3 * y + 1, r, "This classifies the protein as ".concat(d, "."), { isRefresh: false}));
    instance.api.refresh();
    instance.api.setRangeShow(range);
  },

  /**
   * 蛋白质消光系数
   */
  extinctionCoefficient(){
    let _this = this;
    const instance = _this.instance;
    const containerId = instance.getInstanceId();
    const currentInstance = $(`#${containerId}`);
    const maskDialog =  currentInstance.find("#luckysheet-modal-dialog-mask");
    const _locale = instance.locale();
    const proteinanalysismenuText = _locale.proteinanalysismenu;
    let content = `<div class="proteinanalysis-setting-block">
      <div class="subtemplate_wrap">
        <div id="sub_module_body" style="height:60px;">
          <div id="save_as_box" class="save_as_box">
            <div class="save_part">
              <label>${proteinanalysismenuText.Cysways}：</label>
              <select class="select_EC" >
                <option value="yes" selected>${proteinanalysismenuText.yes}</option>
                <option value="no" >${proteinanalysismenuText.no}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>`;
  
    const id = 'luckysheet-extinction-dialog';
    const title = proteinanalysismenuText.proteinextinctioncoefficient;
    let confirmCallback = function(target, instance) {
      let seqDialog = currentInstance.find(`#${id}`);
      let range = instance.api.getRange();
      let column = range[range.length - 1].column;
      let row = range[range.length - 1].row;
      let o = row.length > 0 ? row[0] : 0, 
      a = row.length > 0 ? row[1] : 0, 
      s = column.length > 0 ? column[0] : 0,
      n = [],
      r = [],
      l = seqDialog.find(".select_EC").val() ;
      for (let f = o; f <= a; f++) {
          let h = sequenceCommon.readSelectedInformation(f, !0, "string", instance);
          -1 === h.indexOf("Axis") && n.push(h)
        }
        for (let d = 0; d < n.length; d++) {
          let p = 0
            , m = 0
            , y = []
            , g = void 0
            , v = void 0
            , b = void 0
            , x = void 0;
          0 !== n[d].length ? (e = sequenceCommon.checkTripleOrSingle(n[d]),
          g = 1e3 * _this.writeProtMw(n[d]),
          !1 === e ? (v = null === n[d].match(/y/gi) ? 0 : n[d].match(/y/gi).length,
          b = null === n[d].match(/w/gi) ? 0 : n[d].match(/w/gi).length,
          x = null === n[d].match(/c/gi) ? 0 : n[d].match(/c/gi).length) : (v = null === n[d].match(/Tyr/gi) ? 0 : n[d].match(/Tyr/gi).length,
          b = null === n[d].match(/Trp/gi) ? 0 : n[d].match(/Trp/gi).length,
          x = null === n[d].match(/Cys/gi) ? 0 : n[d].match(/Cys/gi).length),
          m = "yes" === l ? (p = 1490 * v + 5500 * b + 125 * x) / g : (p = 1490 * v + 5500 * b) / g) : (p = -1,
          m = -1),
          y.push(p),
          y.push(m),
          r.push(y)
        }
        for (let w = o, _ = 1; _ <= r.length; _++)
          if (-1 !== r[_ - 1][1]) {
            instance.api.insertRow(w + 1, {number: 2 }),
            instance.api.setCellValue(w + 1, s, "".concat(proteinanalysismenuText.extinctioncoefficient, ":"), { isRefresh: false}),
            instance.api.setCellValue(w + 1, s + 1, r[_ - 1][0].toString(), { isRefresh: false}),
            instance.api.setCellValue(w + 1, s + 2, "M-1·cm-1", { isRefresh: false}), // 设置上标，后续处理M<sup>-1</sup>·cm<sup>-1</sup>
            instance.api.setCellValue(w + 2, s, "".concat(proteinanalysismenuText.absorptioncoefficient, ":"), { isRefresh: false}),
            instance.api.setCellValue(w + 2, s + 1, r[_ - 1][1].toFixed(2), { isRefresh: false}),
            w = w + 2 + 1
          } else
            w += 1;
      instance.api.refresh();
      instance.api.setRangeShow(range);
      seqDialog.hide();
      maskDialog.hide();
    };
    instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);  
  },

  /**
   * 抗体编号计算
   */
  AntibodyNumber(){
    let _this = this;
    const instance = _this.instance;
    const containerId = instance.getInstanceId();
    const currentInstance = $(`#${containerId}`);
    const maskDialog =  currentInstance.find("#luckysheet-modal-dialog-mask");
    const _locale = instance.locale();
    const proteinanalysismenuText = _locale.proteinanalysismenu;

    let content = `<div class="proteinanalysis-setting-block">
      <div class="subtemplate_wrap" style="height: 100px;">
        <div id="sub_module_body" style="height:60px;">
          <div id="save_as_box" class="save_as_box">
            <div class="save_part">
              <label>${proteinanalysismenuText.numberingmethod}：</label>
              <select class="select_methods" >
                <option value="i" selected>IMGT</option>
                <option value="k">Kabat</option>
                <option value="m">Martin</option>
                <option value="c">Chothia</option>
                <option value="a">Aho</option>
              </select>
              <label>${proteinanalysismenuText.species}：</label>
              <select class="select_species" >
                <option value="human" selected>${proteinanalysismenuText.human}</option>
                <option value="alpaca">${proteinanalysismenuText.alpaca}</option>
                <option value="rabbit">${proteinanalysismenuText.rabbit}</option>
                <option value="rhesus">${proteinanalysismenuText.rhesus}</option>
                <option value="rat">${proteinanalysismenuText.rat}</option>
                <option value="mouse">${proteinanalysismenuText.mouse}</option>
                <option value="pig">${proteinanalysismenuText.pig}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>`;

    const id = "luckysheet-AntibodyNumber-dialog";
    const title = proteinanalysismenuText.antibodyNumbering;
    let confirmCallback = function(target, instance) {
      let seqDialog = currentInstance.find(`#${id}`);
      const range = instance.api.getRange();
      const column = range[range.length - 1].column;
      const row = range[range.length - 1].row;
      let i = row.length > 0 ? row[0] : 0, 
      o = row.length > 0 ? row[1] : 0, 
      a = column.length > 0 ? column[0] : 0,
      s = column.length > 0 ? column[1] : 0,
      sheet = instance.api.getSheet();
      u = sheet.column;
      let e = seqDialog.find(".select_methods").val(), 
      n = seqDialog.find(".select_species").val(),
      l = [],
      f = [],
      h = "";
      _this.numberingMethod = e;
      for (let d = i; d <= o; d += 1) {
        for (let p = a; p <= s; p++)
          h += instance.api.getCellValue(d, p)=== null ? '': instance.api.getCellValue(d, p).toString().replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "").replace(/[\n\r]/g, "");
        h && (l.push(h),
        f.push(d + 1))
      }
      let data = {
        seqArr: l,
        numMethod: e,
        useSpecies: n
      },
      m = [],
      y = [];
      return;
      // 抗体编号计算，待开发
      $.post({
        url: "http://chemicalservice.ineln.com/bio/get-antibody-seq",
        data: JSON.stringify(data),
        success: (res) => {
          if (res.status == 1 && !Array.isArray(res.data)) {
            m = res.data.site,
            y = res.data.allAa;
            let t = a + 1;
            instance.api.insertRow(i),
            u.len += m.length;
            for (let n = 0; n < m.length; n += 1)
            instance.api.setCellValue(f[0] - 1, t + n, m[n], { isRefresh: false});

            for (let r = 0; r < y.length; r += 1)
              for (let o = y[r], s = 0; s < o.length; s += 1)
              instance.api.setCellValue(f[r], t + s, o[s], { isRefresh: false});
              instance.api.refresh();
              instance.api.setRangeShow(range);
              seqDialog.hide();
              maskDialog.hide();
          }
        }
      });
    };
    instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
  },

  /**
   * 互补决定区注释
   */
  cdrMark(){
    let _this = this;
    const instance = _this.instance;
    const containerId = instance.getInstanceId();
    const currentInstance = $(`#${containerId}`);
    const maskDialog =  currentInstance.find("#luckysheet-modal-dialog-mask");
    const _locale = instance.locale();
    const proteinanalysismenuText = _locale.proteinanalysismenu;
    const container = $("#"+containerId);
    let content = `<div class="proteinanalysis-setting-block cdrMark-block">
      <div class="subtemplate_wrap">
        <div id="sub_module_body">
          <div id="save_as_box" class="save_as_box">
            <ul>
              <li>${proteinanalysismenuText.cdrnote1}</li>
              <li>${proteinanalysismenuText.cdrtipkabat}</li>
              <li>${proteinanalysismenuText.cdrtipchothia}</li>
              <li>${proteinanalysismenuText.cdrtipcontact}</li>
              <li>${proteinanalysismenuText.cdrnote2}</li>
              <li><a href="https://www.frontiersin.org/article/10.3389/fimmu.2018.02278" target = "_blank">${proteinanalysismenuText.cdrnote3}</a></li>
            </ul>
            <div class="save_part">
              <label>${proteinanalysismenuText.cdrmethods}：</label>
              <select class="select_annotationmethods" >
                <option value="i" >IMGT</option>
                <option value="k" selected>Kabat</option>
                <option value="contact">Contact</option>
                <option value="c">Chothia</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>`;

    const id = "luckysheet-cdrMark-dialog";
    const title = proteinanalysismenuText.cdr;
    let confirmCallback = function(target, instance) {
      let seqDialog = currentInstance.find(`#${id}`);
      let e = seqDialog.find(".select_annotationmethods").val(), 
      numberingMethod = _this.numberingMethod;
      if ("" !== numberingMethod) {
        const range = instance.api.getRange();
        const column = range[range.length - 1].column;
        const row = range[range.length - 1].row;
        let r = row.length > 0 ? row[0] : 0, 
        i = row.length > 0 ? row[1] : 0, 
        o = column.length > 0 ? column[0] : 0,
        a = column.length > 0 ? column[1] : 0;
        if ("contact" === e)
          if ("k" === numberingMethod || "i" === numberingMethod || "a" === numberingMethod){
            instance.tooltip.info('', proteinanalysismenuText.cdrtip2);
            return;
          }
        let s = {
          H: [32, 40, 55, 74, 107, 117],
          L: [24, 40, 56, 69, 105, 117]
        }, c = {
          H: [27, 37, 57, 64, 107, 117],
          L: [26, 38, 56, 65, 107, 116]
        }, u = {
          H: [31, 40, 52, 66, 107, 116],
          L: [30, 41, 52, 68, 105, 117]
        }, l = {
          H: [27, 38, 56, 65, 105, 117],
          L: [27, 38, 56, 65, 105, 117]
        }, f = {
          H: [32, 42, 57, 76, 109, 138],
          L: [24, 42, 58, 71, 107, 138]
        }, h = {
          H: [27, 39, 59, 66, 109, 138],
          L: [26, 40, 58, 67, 109, 137]
        }, d = {
          H: [31, 42, 54, 68, 109, 137],
          L: [30, 43, 54, 70, 107, 138]
        }, p = {
          H: [27, 40, 58, 67, 107, 138],
          L: [27, 40, 58, 67, 107, 138]
        }, m = {
          H: [31, 35, 50, 65, 95, 102],
          L: [24, 34, 50, 56, 89, 97]
        }, y = {
          H: [26, 35, 52, 56, 95, 102],
          L: [26, 32, 50, 52, 91, 96]
        }, g = {
          H: [30, 35, 47, 58, 95, 101],
          L: [27, 35, 46, 55, 89, 97]
        }, v = {
          H: [26, 35, 51, 57, 93, 138],
          L: [27, 32, 50, 52, 89, 97]
        }, b = {
          H: [31, 35, 50, 65, 95, 102],
          L: [24, 34, 50, 56, 89, 97]
        }, x = {
          H: [26, 32, 52, 56, 95, 102],
          L: [26, 32, 50, 52, 91, 96]
        }, w = {
          H: [30, 35, 47, 58, 95, 101],
          L: [30, 35, 46, 55, 89, 97]
        }, _ = {
          H: [26, 33, 51, 57, 93, 102],
          L: [27, 32, 49, 60, 89, 97]
        };
        if (a - o == 0) {
          let O = instance.api.getCellValue(siteCor[0], siteCor[1])
            , E = {};
          "i" === numberingMethod ? "i" === e ? E = l : "k" === e ? E = s : "contact" === e ? E = u : "c" === e && (E = c) : "a" === numberingMethod ? "i" === e ? E = p : "k" === e ? E = f : "contact" === e ? E = d : "c" === e && (E = h) : "k" === numberingMethod ? "i" === e ? E = v : "k" === e ? E = m : "contact" === e ? E = g : "c" === e && (E = y) : "c" !== numberingMethod && "m" !== numberingMethod || ("i" === e ? E = _ : "k" === e ? E = b : "contact" === e ? E = w : "c" === e && (E = x));
          let C = E[O[0]];
          for (let k = 0; k < siteLength; k++) {
            let M = instance.api.getCellValue(siteCor[0], siteCor[1] + k)
              , T = ~~M.replace(/[^0-9]/gi, "")
              , A = {};
            if (T >= C[0] && T <= C[1]) {
              A.fc = "red";
              for (let D = 0; D < i - r + 1; D++) {
                instance.api.setCellValue(siteCor[0] + D + 1, siteCor[1] + k, A);
              }
            } else if (T >= C[2] && T <= C[3]) {
              A.fc = "blue";
              for (let I = 0; I < i - r + 1; I++) {
                instance.api.setCellValue(siteCor[0] + I + 1, siteCor[1] + k, A);
              }
            } else if (T >= C[4] && T <= C[5]) {
              A.fc = "green";
              for (let N = 0; N < i - r + 1; N++) {
                instance.api.setCellValue(siteCor[0] + N + 1, siteCor[1] + k, A);
              }
            }
          }
        } else {
          let j = instance.api.getCellValue(r, o);
          if (!j || "H" !== j[0] && "L" !== j[0]) {
            instance.tooltip.info('', proteinanalysismenuText.cdrtip3);
            return;
          } else {
            let R = {};
            "i" === numberingMethod ? "i" === e ? R = l : "k" === e ? R = s : "contact" === e ? R = u : "c" === e && (R = c) : "a" === numberingMethod ? "i" === e ? R = p : "k" === e ? R = f : "contact" === e ? R = d : "c" === e && (R = h) : "k" === numberingMethod ? "i" === e ? R = v : "k" === e ? R = m : "contact" === e ? R = g : "c" === e && (R = y) : "c" !== numberingMethod && "m" !== numberingMethod || ("i" === e ? R = _ : "k" === e ? R = b : "contact" === e ? R = w : "c" === e && (R = x));
            let P = R[j[0]];
            for (let L = o; L <= a; L++) {
              let B = instance.api.getCellValue(r, L)
                , F = ~~B.replace(/[^0-9]/gi, "")
                , z = {};
              if (F >= P[0] && F <= P[1]) {
                z.fc = "red";
                for (let U = r + 1; U <= i; U++) {
                  instance.api.setCellValue(U, L, z);
                }
              } else if (F >= P[2] && F <= P[3]) {
                z.fc = "blue";
                for (let V = r + 1; V <= i; V++) {
                  instance.api.setCellValue(V, L, z);
                }
              } else if (F >= P[4] && F <= P[5]) {
                z.fc = "green";
                for (let q = r + 1; q <= i; q++) {
                  instance.api.setCellValue(q, L, z);
                }
              }
            }
            // instance.api.refresh();
          }
        }
        instance.api.setRangeShow(range);
        seqDialog.hide();
        maskDialog.hide();
      } else {
        instance.tooltip.info('', proteinanalysismenuText.cdrtip);
      }
    };
    instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
  },

  /**
   * 蛋白质双序列比对
   */
  doubleAlignment(){
    let _this = this;
    const instance = _this.instance;
    const containerId = instance.getInstanceId();
    const currentInstance = $(`#${containerId}`);
    const maskDialog =  currentInstance.find("#luckysheet-modal-dialog-mask");
    const _locale = instance.locale();
    const proteinanalysismenuText = _locale.proteinanalysismenu;
    
    let content = `<div class="proteinanalysis-setting-block blastp-block">
      <div class="subtemplate_wrap">
        <div id="sub_module_body">
          <div id="save_as_box" class="save_as_box">
            <div class="range-input">
              <span>${proteinanalysismenuText.sequence1}</span>
              <input type="text" class="sequenceRange1" placeholder="${proteinanalysismenuText.placeholder.sequenceSelect}" />
              <i class="range-input-icon"></i>
            </div>
            <div class="range-input">
              <span>${proteinanalysismenuText.sequence2}</span>
              <input type="text" class="sequenceRange2" placeholder="${proteinanalysismenuText.placeholder.sequenceSelect1}" />
              <i class="range-input-icon"></i>
            </div>
            <div class="range-input">
              <span>${proteinanalysismenuText.selectOput}</span>
              <input type="text" class="sequenceRange3" placeholder="${proteinanalysismenuText.placeholder.selectOputTip}" value="A3"/>
              <i class="range-input-icon-output"></i>
            </div>
            <div class="save_part">
              <label>${proteinanalysismenuText.matrix}：</label>
              <select class="select_matrix" >
                <option value="BLOSUM62" selected>BLOSUM62</option>
                <option value="BLOSUM45">BLOSUM45</option>
                <option value="BLOSUM80">BLOSUM80</option>
                <option value="PAM70">PAM70</option>
                <option value="PAM30">PAM30</option>
              </select>
            </div>
            <div class="save_part">
              <label>${proteinanalysismenuText.gapcosts}：</label>
              <select class="select_gapcosts" >
                <option value="10,2">Existence:10 Extension:2</option>
                <option value="11,2">Existence:11 Extension:2</option>
                <option value="7,2">Existence:7 Extension:2</option>
                <option value="9,2">Existence:9 Extension:2</option>
                <option value="8,2">Existence:8 Extension:2</option>
                <option value="6,2">Existence:6 Extension:2</option>
                <option value="13,1">Existence:13 Extension:1</option>
                <option value="12,1">Existence:12 Extension:1</option>
                <option value="11,1" selected>Existence:11 Extension:1</option>
                <option value="10,1">Existence:10 Extension:1</option>
                <option value="9,1">Existence:9 Extension:1</option>
              </select>
              <span>${proteinanalysismenuText.placeholder.compareTips}</span>
            </div>
          </div>
        </div>
      </div>
    </div>`;
  
    const id = "luckysheet-blastp-dialog";
    const title = proteinanalysismenuText.blastp;
    let confirmCallback = function(target, instance) {
      let seqDialog = currentInstance.find(`#${id}`);
      const e = ["A", "R", "N", "D", "C", "Q", "E", "G", "H", "I", "L", "K", "M", "F", "P", "S", "T", "W", "Y", "V"]
      const range = instance.api.getRange();
      let matrixValue = seqDialog.find(".select_matrix").val(), 
      c = seqDialog.find(".select_gapcosts").val().split(","),
      u = -parseFloat(c[0]),
      h = -parseFloat(c[1]),
      d = seqDialog.find(".sequenceRange1").val().split(":"),
      m = seqDialog.find(".sequenceRange2").val().split(":"),
      y = seqDialog.find(".sequenceRange3").val(),
      g = 0,
      v = g;
      g = sequenceCommon.computeRange(y, instance)[0] - 1,
      a = sequenceCommon.computeRange(y, instance)[1] - 1;
      let b = _this.getSubstitutionMatrix(matrixValue);
      let S = []
        , O = []
        , E = !1;
      if (d.length > 0 && m.length >= 0) {
        let C = _this.rangeEasilyOutput(d),
        k = _this.rangeEasilyOutput(m),
        M = /[#]/;
        for (let T = C[0]; T <= C[2]; T++) {
          let A = "";
          for (let D = C[1]; D <= C[3]; D++) {
            let I = instance.api.getCellValue(T, D)=== null ? '': instance.api.getCellValue(T, D,).toString().replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "").replace(/[\n\r]/g, "");
            !1 === M.test(I) && "" !== I && (A += I)
          }
          "" !== A && S.push(A)
        }
        for (let N = k[0]; N <= k[2]; N++) {
          let  j = "";
          for (let R = k[1]; R <= k[3]; R++) {
            let P = instance.api.getCellValue(N, R)=== null ? '': instance.api.getCellValue(N, R).replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "").replace(/[\n\r]/g, "").replace(/\*/g, "");
            !1 === M.test(P) && "" !== P && (j += P)
          }
          "" !== j && S.push(j)
        }
      } else
        E = !0;
      for (let L = 0; L < S.length; L++) {
        let B = "";
        if (!0 === sequenceCommon.checkTripleOrSingle(S[L])) {
          for (let F = 0; F < S[L].length; F += 3)
            B += _this.exchangeMapTo1.get(S[L].slice(F, F + 3).toLowerCase());
          O.push(B)
        } else
          O.push(S[L])
      }
      if (E) {
        instance.tooltip.info('', proteinanalysismenuText.blasttip);
      } else {
        let z = [];
        if (O.length >= 2)
            for (let U = 1; U < O.length; U++) {
              let V = _this.slicedToArray(_this.getGapList(O[0], O[U], u, h, b), 3),
              q = V[0],
              H = V[1],
              W = V[2],
              G = _this.slicedToArray(_this.flipping(O[0], O[U], q, H, W, b), 2),
              Y = G[0],
              X = G[1],
              $ = W[O[U].length][O[0].length],
              K = [],
              Z = 0,
              Q = 0,
              J = 0;
              for (let ee = 0; ee < Y.length; ee++) {
                let te = 0;
                if ("_" !== Y[ee] && "_" !== X[ee]) {
                    let ne = e.indexOf(Y[ee].toUpperCase(), 0)
                      , re = e.indexOf(X[ee].toUpperCase(), 0);
                    te = b[ne][re]
                }
                Y[ee] === X[ee] ? (K += Y[ee],
                Q += 1,
                Z += 1) : "_" === Y[ee] || "_" === X[ee] ? (K += "-",
                J += 1) : te > 0 ? (K += "+",
                Z += 1) : K += "/"
              }
              let ie = "".concat(Q, "/").concat(Y.length.toString(), "(").concat(Math.floor(Q / Y.length * 100).toString(), "%)")
                , oe = "".concat(Z, "/").concat(Y.length.toString(), "(").concat(Math.floor(Z / Y.length * 100).toString(), "%)")
                , ae = "".concat(J, "/").concat(Y.length.toString(), "(").concat(Math.floor(J / Y.length * 100).toString(), "%)")
                , se = "".concat($);
              z.push([Y, X, K, se, ie, oe, ae])
            }
        let ce = a + 2
          , ue = v
          , le = ["NW score", "Identities", "Positives", "Gaps"] ;
        // _.len += z[0][0].length;
        for (let fe = 0; fe < le.length; fe++)
            instance.api.setCellValue(a, v + fe, le[fe]);
        for (let he = 0; he < z.length; he++) {
          instance.api.insertRow(a + 1 , {number: 5 });
          let de = ue;
          for (let pe = 3; pe < 7; pe++)
            instance.api.setCellValue(a + 1, de, z[he][pe]),
            de += 1;
          for (let me = 0; me < z[he][0].length; me++) {
            instance.api.setCellValue(a, de, z[he][0][me]),
            instance.api.setCellValue(a + 2, de, z[he][1][me]),
            z[he][1][me].toUpperCase() === z[he][0][me].toUpperCase() && instance.api.setCellValue(a + 1, de, "|"),
            "/" === z[he][2][me] && instance.api.setCellValue(a + 2, de, {
                bg: "#ff0000"
            });
            let ye = {};
            let nowCell = instance.api.getCellValue(ce, de).toString();
            if ("+" === nowCell)
              ye.fc = "green";
            else if ("-" === nowCell) {
              ye.fc = "red";
            } else if ("/" === nowCell) {
              ye.fc = "blue";
            }
            instance.api.setCellValue(ce, de, ye);
            de += 1
          }
          ce += 4
        }
      }
      instance.api.setRangeShow(range);
      seqDialog.hide();
      maskDialog.hide();
    };
    instance.createHtmlDom.createDialog(id, content, containerId, title, confirmCallback, true);
    let seqDialog = currentInstance.find(`#${id}`);
    // 选择序列单元格
    seqDialog.find("i").click(function() {
      const rangeSelect = "luckysheet-range-selector-dialog";
      let initVal = $(this).parent().find('input').val();
      let rangeValue = $(this).attr('class') === ''; // 判断如果是选择序列返回范围值，不是返回单元格坐标
      seqDialog.hide();
      instance.createHtmlDom.createRangeSelectorDialog(rangeValue);
      rangeDialog = currentInstance.find(`#${rangeSelect}`);
      rangeDialog.find('range-val').val(initVal);
      rangeDialog.find('.cancel-icon').click(() => {
        rangeDialog.hide();
        maskDialog.show();
        seqDialog.show();
      });
      rangeDialog.find('.ok-icon').click(() => {
        rangeDialog.hide();
        maskDialog.show();
        seqDialog.show();
        let rangeSelectorVal = rangeDialog.find('.range-val').val();
        $(this).parent().find('input').val(rangeSelectorVal);
      });
    });
  },

  /**
   * 获取取代矩阵
   * @param {*} matrix 选择的模板
   * @returns 
   */
  getSubstitutionMatrix(matrix){
    const t = [[4, -1, -2, -2, 0, -1, -1, 0, -2, -1, -1, -1, -1, -2, -1, 1, 0, -3, -2, 0], [-1, 5, 0, -2, -3, 1, 0, -2, 0, -3, -2, 2, -1, -3, -2, -1, -1, -3, -2, -3], [-2, 0, 6, 1, -3, 0, 0, 0, 1, -3, -3, 0, -2, -3, -2, 1, 0, -4, -2, -3], [-2, -2, 1, 6, -3, 0, 2, -1, -1, -3, -4, -1, -3, -3, -1, 0, -1, -4, -3, -3], [0, -3, -3, -3, 9, -3, -4, -3, -3, -1, -1, -3, -1, -2, -3, -1, -1, -2, -2, -1], [-1, 1, 0, 0, -3, 5, 2, -2, 0, -3, -2, 1, 0, -3, -1, 0, -1, -2, -1, -2], [-1, 0, 0, 2, -4, 2, 5, -2, 0, -3, -3, 1, -2, -3, -1, 0, -1, -3, -2, -2], [0, -2, 0, -1, -3, -2, -2, 6, -2, -4, -4, -2, -3, -3, -2, 0, -2, -2, -3, -3], [-2, 0, 1, -1, -3, 0, 0, -2, 8, -3, -3, -1, -2, -1, -2, -1, -2, -2, 2, -3], [-1, -3, -3, -3, -1, -3, -3, -4, -3, 4, 2, -3, 1, 0, -3, -2, -1, -3, -1, 3], [-1, -2, -3, -4, -1, -2, -3, -4, -3, 2, 4, -2, 2, 0, -3, -2, -1, -2, -1, 1], [-1, 2, 0, -1, -3, 1, 1, -2, -1, -3, -2, 5, -1, -3, -1, 0, -1, -3, -2, -2], [-1, -1, -2, -3, -1, 0, -2, -3, -2, 1, 2, -1, 5, 0, -2, -1, -1, -1, -1, 1], [-2, -3, -3, -3, -2, -3, -3, -3, -1, 0, 0, -3, 0, 6, -4, -2, -2, 1, 3, -1], [-1, -2, -2, -1, -3, -1, -1, -2, -2, -3, -3, -1, -2, -4, 7, -1, -1, -4, -3, -2], [1, -1, 1, 0, -1, 0, 0, 0, -1, -2, -2, 0, -1, -2, -1, 4, 1, -3, -2, -2], [0, -1, 0, -1, -1, -1, -1, -2, -2, -1, -1, -1, -1, -2, -1, 1, 5, -2, -2, 0], [-3, -3, -4, -4, -2, -2, -3, -2, -2, -3, -2, -3, -1, 1, -4, -3, -2, 11, 2, -3], [-2, -2, -2, -3, -2, -1, -2, -3, 2, -1, -1, -2, -1, 3, -3, -2, -2, 2, 7, -1], [0, -3, -3, -3, -1, -2, -2, -3, -3, 3, 1, -2, 1, -1, -2, -2, 0, -3, -1, 4]]
    , n = [[5, -2, -1, -2, -1, -1, -1, 0, -2, -1, -1, -1, -1, -2, -1, 1, 0, -2, -2, 0], [-2, 7, 0, -1, -3, 1, 0, -2, 0, -3, -2, 3, -1, -2, -2, -1, -1, -2, -1, -2], [-1, 0, 6, 2, -2, 0, 0, 0, 1, -2, -3, 0, -2, -2, -2, 1, 0, -4, -2, -3], [-2, -1, 2, 7, -3, 0, 2, -1, 0, -4, -3, 0, -3, -4, -1, 0, -1, -4, -2, -3], [-1, -3, -2, -3, 12, -3, -3, -3, -3, -3, -2, -3, -2, -2, -4, -1, -1, -5, -3, -1], [-1, 1, 0, 0, -3, 6, 2, -2, 1, -2, -2, 1, 0, -4, -1, 0, -1, -2, -1, -3], [-1, 0, 0, 2, -3, 2, 6, -2, 0, -3, -2, 1, -2, -3, 0, 0, -1, -3, -2, -3], [0, -2, 0, -1, -3, -2, -2, 7, -2, -4, -3, -2, -2, -3, -2, 0, -2, -2, -3, -3], [-2, 0, 1, 0, -3, 1, 0, -2, 10, -3, -2, -1, 0, -2, -2, -1, -2, -3, 2, -3], [-1, -3, -2, -4, -3, -2, -3, -4, -3, 5, 2, -3, 2, 0, -2, -2, -1, -2, 0, 3], [-1, -2, -3, -3, -2, -2, -2, -3, -2, 2, 5, -3, 2, 1, -3, -3, -1, -2, 0, 1], [-1, 3, 0, 0, -3, 1, 1, -2, -1, -3, -3, 5, -1, -3, -1, -1, -1, -2, -1, -2], [-1, -1, -2, -3, -2, 0, -2, -2, 0, 2, 2, -1, 6, 0, -2, -2, -1, -2, 0, 1], [-2, -2, -2, -4, -2, -4, -3, -3, -2, 0, 1, -3, 0, 8, -3, -2, -1, 1, 3, 0], [-1, -2, -2, -1, -4, -1, 0, -2, -2, -2, -3, -1, -2, -3, 9, -1, -1, -3, -3, -3], [1, -1, 1, 0, -1, 0, 0, 0, -1, -2, -3, -1, -2, -2, -1, 4, 2, -4, -2, -1], [0, -1, 0, -1, -1, -1, -1, -2, -2, -1, -1, -1, -1, -1, -1, 2, 5, -3, -1, 0], [-2, -2, -4, -4, -5, -2, -3, -2, -3, -2, -2, -2, -2, 1, -3, -4, -3, 15, 3, -3], [-2, -1, -2, -2, -3, -1, -2, -3, 2, 0, 0, -1, 0, 3, -3, -2, -1, 3, 8, -1], [0, -2, -3, -3, -1, -3, -3, -3, -3, 3, 1, -2, 1, 0, -3, -1, 0, -3, -1, 5]]
    , r = [[5, -2, -2, -2, -1, -1, -1, 0, -2, -2, -2, -1, -1, -3, -1, 1, 0, -3, -2, 0], [-2, 6, -1, -2, -4, 1, -1, -3, 0, -3, -3, 2, -2, -4, -2, -1, -1, -4, -3, -3], [-2, -1, 6, 1, -3, 0, -1, -1, 0, -4, -4, 0, -3, -4, -3, 0, 0, -4, -3, -4], [-2, -2, 1, 6, -4, -1, 1, -2, -2, -4, -5, -1, -4, -4, -2, -1, -1, -6, -4, -4], [-1, -4, -3, -4, 9, -4, -5, -4, -4, -2, -2, -4, -2, -3, -4, -2, -1, -3, -3, -1], [-1, 1, 0, -1, -4, 6, 2, -2, 1, -3, -3, 1, 0, -4, -2, 0, -1, -3, -2, -3], [-1, -1, -1, 1, -5, 2, 6, -3, 0, -4, -4, 1, -2, -4, -2, 0, -1, -4, -3, -3], [0, -3, -1, -2, -4, -2, -3, 6, -3, -5, -4, -2, -4, -4, -3, -1, -2, -4, -4, -4], [-2, 0, 0, -2, -4, 1, 0, -3, 8, -4, -3, -1, -2, -2, -3, -1, -2, -3, 2, -4], [-2, -3, -4, -4, -2, -3, -4, -5, -4, 5, 1, -3, 1, -1, -4, -3, -1, -3, -2, 3], [-2, -3, -4, -5, -2, -3, -4, -4, -3, 1, 4, -3, 2, 0, -3, -3, -2, -2, -2, 1], [-1, 2, 0, -1, -4, 1, 1, -2, -1, -3, -3, 5, -2, -4, -1, -1, -1, -4, -3, -3], [-1, -2, -3, -4, -2, 0, -2, -4, -2, 1, 2, -2, 6, 0, -3, -2, -1, -2, -2, 1], [-3, -4, -4, -4, -3, -4, -4, -4, -2, -1, 0, -4, 0, 6, -4, -3, -2, 0, 3, -1], [-1, -2, -3, -2, -4, -2, -2, -3, -3, -4, -3, -1, -3, -4, 8, -1, -2, -5, -4, -3], [1, -1, 0, -1, -2, 0, 0, -1, -1, -3, -3, -1, -2, -3, -1, 5, 1, -4, -2, -2], [0, -1, 0, -1, -1, -1, -1, -2, -2, -1, -2, -1, -1, -2, -2, 1, 5, -4, -2, 0], [-3, -4, -4, -6, -3, -3, -4, -4, -3, -3, -2, -4, -2, 0, -5, -4, -4, 11, 2, -3], [-2, -3, -3, -4, -3, -2, -3, -4, 2, -2, -2, -3, -2, 3, -4, -2, -2, 2, 7, -2], [0, -3, -4, -4, -1, -3, -3, -4, -4, 3, 1, -3, 1, -1, -3, -2, 0, -3, -2, 4]]
    , i = [[5, -4, -2, -1, -4, -2, -1, 0, -4, -2, -4, -4, -3, -6, 0, 1, 1, -9, -5, -1], [-4, 8, -3, -6, -5, 0, -5, -6, 0, -3, -6, 2, -2, -7, -2, -1, -4, 0, -7, -5], [-2, -3, 6, 3, -7, -1, 0, -1, 1, -3, -5, 0, -5, -6, -3, 1, 0, -6, -3, -5], [-1, -6, 3, 6, -9, 0, 3, -1, -1, -5, -8, -2, -7, -10, -4, -1, -2, -10, -7, -5], [-4, -5, -7, -9, 9, -9, -9, -6, -5, -4, -10, -9, -9, -8, -5, -1, -5, -11, -2, -4], [-2, 0, -1, 0, -9, 7, 2, -4, 2, -5, -3, -1, -2, -9, -1, -3, -3, -8, -8, -4], [-1, -5, 0, 3, -9, 2, 6, -2, -2, -4, -6, -2, -4, -9, -3, -2, -3, -11, -6, -4], [0, -6, -1, -1, -6, -4, -2, 6, -6, -6, -7, -5, -6, -7, -3, 0, -3, -10, -9, -3], [-4, 0, 1, -1, -5, 2, -2, -6, 8, -6, -4, -3, -6, -4, -2, -3, -4, -5, -1, -4], [-2, -3, -3, -5, -4, -5, -4, -6, -6, 7, 1, -4, 1, 0, -5, -4, -1, -9, -4, 3], [-4, -6, -5, -8, -10, -3, -6, -7, -4, 1, 6, -5, 2, -1, -5, -6, -4, -4, -4, 0], [-4, 2, 0, -2, -9, -1, -2, -5, -3, -4, -5, 6, 0, -9, -4, -2, -1, -7, -7, -6], [-3, -2, -5, -7, -9, -2, -4, -6, -6, 1, 2, 0, 10, -2, -5, -3, -2, -8, -7, 0], [-6, -7, -6, -10, -8, -9, -9, -7, -4, 0, -1, -9, -2, 8, -7, -4, -6, -2, 4, -5], [0, -2, -3, -4, -5, -1, -3, -3, -2, -5, -5, -4, -5, -7, 7, 0, -2, -9, -9, -3], [1, -1, 1, -1, -1, -3, -2, 0, -3, -4, -6, -2, -3, -4, 0, 5, 2, -3, -5, -3], [1, -4, 0, -2, -5, -3, -3, -3, -4, -1, -4, -1, -2, -6, -2, 2, 6, -8, -4, -1], [-9, 0, -6, -10, -11, -8, -11, -10, -5, -9, -4, -7, -8, -2, -9, -3, -8, 13, -3, -10], [-5, -7, -3, -7, -2, -8, -6, -9, -1, -4, -4, -7, -7, 4, -9, -5, -4, -3, 9, -5], [-1, -5, -5, -5, -4, -4, -4, -3, -4, 3, 0, -6, 0, -5, -3, -3, -1, -10, -5, 6]]
    , o = [[6, -7, -4, -3, -6, -4, -2, -2, -7, -5, -6, -7, -5, -8, -2, 0, -1, -13, -8, -2], [-7, 8, -6, -10, -8, -2, -9, -9, -2, -5, -8, 0, -4, -9, -4, -3, -6, -2, -10, -8], [-4, -6, 8, 2, -11, -3, -2, -3, 0, -5, -7, -1, -9, -9, -6, 0, -2, -8, -4, -8], [-3, -10, 2, 8, -14, -2, 2, -3, -4, -7, -12, -4, -11, -15, -8, -4, -5, -15, -11, -8], [-6, -8, -11, -14, 10, -14, -14, -9, -7, -6, -15, -14, -13, -13, -8, -3, -8, -15, -4, -6], [-4, -2, -3, -2, -14, 8, 1, -7, 1, -8, -5, -3, -4, -13, -3, -5, -5, -13, -12, -7], [-2, -9, -2, 2, -14, 1, 8, -4, -5, -5, -9, -4, -7, -14, -5, -4, -6, -17, -8, -6], [-2, -9, -3, -3, -9, -7, -4, 6, -9, -11, -10, -7, -8, -9, -6, -2, -6, -15, -14, -5], [-7, -2, 0, -4, -7, 1, -5, -9, 9, -9, -6, -6, -10, -6, -4, -6, -7, -7, -3, -6], [-5, -5, -5, -7, -6, -8, -5, -11, -9, 8, -1, -6, -1, -2, -8, -7, -2, -14, -6, 2], [-6, -8, -7, -12, -15, -5, -9, -10, -6, -1, 7, -8, 1, -3, -7, -8, -7, -6, -7, -2], [-7, 0, -1, -4, -14, -3, -4, -7, -6, -6, -8, 7, -2, -14, -6, -4, -3, -12, -9, -9], [-5, -4, -9, -11, -13, -4, -7, -8, -10, -1, 1, -2, 11, -4, -8, -5, -4, -13, -11, -1], [-8, -9, -9, -15, -13, -13, -14, -9, -6, -2, -3, -14, -4, 9, -10, -6, -9, -4, 2, -8], [-2, -4, -6, -8, -8, -3, -5, -6, -4, -8, -7, -6, -8, -10, 8, -2, -4, -14, -13, -6], [0, -3, 0, -4, -3, -5, -4, -2, -6, -7, -8, -4, -5, -6, -2, 6, 0, -5, -7, -6], [-1, -6, -2, -5, -8, -5, -6, -6, -7, -2, -7, -3, -4, -9, -4, 0, 7, -13, -6, -3], [-13, -2, -8, -15, -15, -13, -17, -15, -7, -14, -6, -12, -13, -4, -14, -5, -13, 13, -5, -15], [-8, -10, -4, -11, -4, -12, -8, -14, -3, -6, -7, -9, -11, 2, -13, -7, -6, -5, 10, -7], [-2, -8, -8, -8, -6, -7, -6, -5, -6, 2, -2, -9, -1, -8, -6, -6, -3, -15, -7, 7]];
    let matrixArray = [[]];
    switch (matrix) {
      case "BLOSUM62":
        matrixArray = t;
        break;
      case "BLOSUM45":
        matrixArray = n;
        break;
      case "BLOSUM80":
        matrixArray = r;
        break;
      case "PAM70":
        matrixArray = i;
        break;
      case "PAM30":
        matrixArray = o;
        break;
    }
    return matrixArray;
  },

  /**
   * 范围输出
   * @param {*} e 
   * @returns 
   */
  rangeEasilyOutput(e) {
    const instance = this.instance;
    let t, n, r, i;
    return 2 === e.length ? (t = sequenceCommon.computeRange(e[0], instance)[0] - 1,
    n = sequenceCommon.computeRange(e[0], instance)[1] - 1,
    r = sequenceCommon.computeRange(e[1], instance)[0] - 1,
    i = sequenceCommon.computeRange(e[1], instance)[1] - 1) : (i = n = sequenceCommon.computeRange(e[0], instance)[1] - 1,
    r = t = sequenceCommon.computeRange(e[0], instance)[0] - 1),
    [n, t, i, r]
  },

  /**
   * 是否为矩阵数组
   * @param {*} e 
   * @param {*} t 
   * @returns 
   */
  slicedToArray(e, t) {
    if (Array.isArray(e))
      return e;
    let n = []
      , r = !0
      , i = !1
      , o = void 0;
    try {
      let a;  
      for (let s = e[Symbol.iterator](); !(r = (a = s.next()).done) && (n.push(a.value),
        !t || n.length !== t); r = !0)
        ;
    } catch (e) {
      i = !0,
      o = e
    } finally {
      try {
        r || null == s.return || s.return()
      } finally {
        if (i)
          throw o
      }
    }
    return n
  },

  /**
   * gap罚分项计算，返回对应的二维矩阵t
   * @param {*} e Query链
   * @param {*} t Subject链
   * @param {*} n gap罚分项1
   * @param {*} r gap罚分项2
   * @param {*} i 取代矩阵
   * @returns 
   */
  getGapList(e, t, n, r, i) {
    const self = this;
    function s(e, t, n, r) {
      return e > 0 && 0 === t ? -1 / 0 : t > 0 ? n + r * t : 0
    }
    function c(e, t, n, r) {
      return t > 0 && 0 === e ? -1 / 0 : e > 0 ? n + r * e : 0
    }
    function u(e, t) {
      return 0 === t && 0 === e ? 0 : 0 === t || 0 === e ? -1 / 0 : 0
    }
    let o = t.length + 1,
    l = e.length + 1,
    f = [],
    h = [],
    d = [];
    for (let p = 0; p < o; p++) {
      f[p] = [],
      h[p] = [],
      d[p] = [];
      for (let m = 0; m < l; m++)
        f[p][m] = s(p, m, n, r),
        h[p][m] = c(p, m, n, r),
        d[p][m] = u(p, m)
    }
    for (let y = 1; y < l; y++)
      for (let g = 1; g < o; g++)
        f[g][y] = Math.max.apply(null, [n + r + d[g][y - 1], r + f[g][y - 1]]),
        h[g][y] = Math.max.apply(null, [n + r + d[g - 1][y], r + h[g - 1][y]]),
        d[g][y] = Math.max.apply(null, [self.findMatrixIndex(e, t, g, y, i) + d[g - 1][y - 1], f[g][y], h[g][y]]);
    return [f, h, d]
  },

  /**
   * 获取Query链和Subject链翻转序列二维数组
   * @param {*} e Query链
   * @param {*} t Subject链
   * @param {*} n 罚分矩阵1
   * @param {*} r 罚分矩阵2
   * @param {*} i 罚分矩阵3
   * @param {*} o 取代矩阵
   * @returns 
   */
  flipping(e, t, n, r, i, o) {
    const self = this;
    let s = "",
    c = "",
    u = t.length;
    for (let l = e.length; u > 0 || l > 0; )
      u > 0 && l > 0 && i[u][l] === i[u - 1][l - 1] + self.findMatrixIndex(e, t, u, l, o) ? (s += e[l - 1],
      c += t[u - 1],
      u -= 1,
      l -= 1) : u > 0 && i[u][l] === r[u][l] ? (s += "_",
      c += t[u - 1],
      u -= 1) : l > 0 && i[u][l] === n[u][l] && (s += e[l - 1],
      c += "_",
      l -= 1);
    return [s.split("").reverse().join(""), c.split("").reverse().join("")]
  },

  /**
   * 查找Query链和Subject链索引下蛋白质的矩阵位置
   * @param {*} t  Query链
   * @param {*} n Subject链
   * @param {*} r Query链索引
   * @param {*} i Subject链索引
   * @param {*} o 取代矩阵
   * @returns 
   */
  findMatrixIndex(t, n, r, i, o) {
    const e = ["A", "R", "N", "D", "C", "Q", "E", "G", "H", "I", "L", "K", "M", "F", "P", "S", "T", "W", "Y", "V"];
    let a = e.indexOf(n[r - 1].toUpperCase(), 0)
      , s = e.indexOf(t[i - 1].toUpperCase(), 0);
    return o[a][s]
  },

  /**
   * BLAST序列比对
   */
  BLASTAlignment(){
    const instance = this.instance;
    const _locale = instance.locale();
    const proteinanalysismenuText = _locale.proteinanalysismenu;
    const range = instance.api.getRange();
    const column = range[range.length - 1].column;
    const row = range[range.length - 1].row;
    let n = row.length > 0 ? row[0] : 0, 
    r = row.length > 0 ? row[1] : 0, 
    i = (column.length > 0 ? column[0] : 0, column.length > 0 ? column[1] : 0, []);
    for (let o = n; o <= r; o++) {
      let a = sequenceCommon.readSelectedInformation(o, !0, "string", instance);
      a.length > 0 && i.push(a)
    }
    if (1 === i.length) {
      let c = "https://blast.ncbi.nlm.nih.gov/Blast.cgi?PROGRAM=blastn&PAGE_TYPE=BlastSearch&BLAST_SPEC=&LINK_LOC=blasttab&QUERY=".concat(i[0]);
      window.open(c)
    }
    if (2 === i.length) {
      let u = "https://blast.ncbi.nlm.nih.gov/Blast.cgi?PAGE=Proteins&PROGRAM=blastn&BLAST_PROGRAMS=blastp&PAGE_TYPE=BlastSearch&BLAST_SPEC=blast2seq&DATABASE=n/a&QUERY=".concat(i[0], "&SUBJECTS=").concat(i[1]);
      window.open(u)
    }
    if (i.length >= 3) {
      let l = "https://blast.ncbi.nlm.nih.gov/Blast.cgi?PROGRAM=blastn&PAGE_TYPE=BlastSearch&BLAST_SPEC=&LINK_LOC=blasttab&QUERY=",
      f = "";
      for (let h = 1; h < i.length + 1; h++)
        f = "".concat(f, ">").concat(h.toString(), "%0A").concat(i[h - 1], "%0A");
      l += f,
      window.open(l)
    }
    0 === i.length && instance.tooltip.info('', proteinanalysismenuText.blasttip);
  },
};
export default proteinAnalysis;