import { modelHTML, luckysheetchartpointconfigHTML, luckysheetToolHTML } from '../controllers/constant';
import browser from './browser';
import { replaceHtml } from '../utils/util';
import locale from '../locale/locale';
import server from '../controllers/server';
import createHtmlDom from '../controllers/common/createHtmlDom';
import multiInstanceStore from '../store/multiInstance';

const tooltip = {
    info: function (title, content) {
        const instance = this.instance;
        let containerId = instance.getInstanceId();

        let _locale = instance.locale();
        let locale_button = _locale.button;

        instance.createHtmlDom.createDialog('luckysheet-info', content, containerId, title, () => {}, true, true, false, '', '');
    },
    confirm: function (title, content, func1, func2) {
        const instance = this.instance;
        const containerId = instance.getInstanceId();
        let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);

        const _locale = instance.locale();
        const locale_button = _locale.button;

        let $t = currentInstance.find("#luckysheet-confirm").find(".luckysheet-modal-dialog-content").css("min-width", 300).end();
        
        instance.createHtmlDom.createDialog('luckysheet-confirm', content, containerId, title, ()=>{}, true, true, true, '', '');

        currentInstance.off(`click.luckysheetConfirmDialogConfirmBtn-${containerId}`).on(`click.luckysheetConfirmDialogConfirmBtn-${containerId}`, `#luckysheet-confirm #luckysheet-confirm-confirm`, function() {
            if (typeof func1 == 'function') {
                func1();
            }
            instance.server.keepHighLightBox();
            currentInstance.find("#luckysheet-confirm").hide();
            currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();  
        });
        currentInstance.off(`click.luckysheetConfirmDialogCloseBtn-${containerId}`).on(`click.luckysheetConfirmDialogCloseBtn-${containerId}`, `#luckysheet-confirm #luckysheet-confirm-close`, function() {
            if (typeof func2 == 'function') {
                func2();
            }
            currentInstance.find("#luckysheet-confirm").hide();
            currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
        });
    },
    screenshot: function (title, content, imgurl) {
        const instance = this.instance;
        const containerId = instance.getInstanceId();
        let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);
        const _locale =instance.locale();
        const locale_screenshot = _locale.screenshot;
        
        let customButton = '<a style="text-decoration:none;color:#fff;" class="download btn btn-primary luckysheet-screenshot-dialog-download-btn">&nbsp;&nbsp;'+ locale_screenshot.downLoadBtn +'&nbsp;&nbsp;</a>&nbsp;&nbsp;<button class="btn btn-primary luckysheet-screenshot-dialog-copy-btn">&nbsp;&nbsp;'+ locale_screenshot.downLoadCopy +'&nbsp;&nbsp;</button>';
        let appendImg = function(t) {
            let image = new Image();
            image.src = imgurl;
            image.style.width = "100%";
            image.style.height = "100%";
            currentInstance.find("#luckysheet-screenshot-dialog-screenshot-save").append(image);
        }
        instance.createHtmlDom.createDialog('luckysheet-screenshot-dialog', content, containerId, title, ()=>{}, true, true, false, customButton, appendImg);
        
        currentInstance.off(`click.screenShotDownload-${containerId}`).on(`click.screenShotDownload-${containerId}`, `#luckysheet-screenshot-dialog .luckysheet-screenshot-dialog-download-btn`, function() {
            if(browser.isIE() == "1"){
                alert(locale_screenshot.browserNotTip);
            }
            else{
                if (!!window.ActiveXObject || "ActiveXObject" in window){
                    if (currentInstance.find(`#IframeReportImg`).length === 0){
                        $('<iframe style="display:none;" id="IframeReportImg" name="IframeReportImg" onload="downloadImg();" width="0" height="0" src="about:blank"></iframe>').appendTo($(`${containerId}`));
                    }
                    if (currentInstance.find(`#IframeReportImg`).attr("src") != imgurl) {
                        currentInstance.find(`#IframeReportImg`).attr("src",imgurl);
                    } else {
                        if (currentInstance.find(`#IframeReportImg`).src != "about:blank") {
                            window.frames[`IframeReportImg`].document.execCommand("SaveAs");
                        }
                    }
                }  
            }
        });
        currentInstance.off(`click.screenShotClose-${containerId}`).on(`click.screenShotClose-${containerId}`, `#luckysheet-screenshot-dialog #luckysheet-screenshot-dialog-close`, function() {
            currentInstance.find("#luckysheet-screenshot-dialog").hide();
            currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
        });

        currentInstance.find(`#luckysheet-screenshot-dialog .luckysheet-screenshot-dialog-copy-btn`).click(function(){
            let dt = new clipboard.DT();
            dt.setData("text/html", "<img src='"+ imgurl +"'>");
            if(browser.isIE() == "1"){
                alert(locale_screenshot.rightclickTip);
            }
            else{
                clipboard.write(dt);
                alert(locale_screenshot.successTip);  
            }
        });
    },
    chartPointConfig: function (id, savefunc1, closefunc2) {
        const instance = this.instance;
        const containerId = instance.getInstanceId();
        let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);
        let customButton = '<button class="btn btn-danger luckysheet-model-save-btn">&nbsp;&nbsp;保存设置&nbsp;&nbsp;</button>';
        instance.createHtmlDom.createDialog(id, luckysheetchartpointconfigHTML, containerId, "数据点批量设置", ()=>{}, false, true, false, customButton, '');
        // currentInstance.find("#" + id).find(".luckysheet-chart-point-config").css("height", currentInstance.height() - 160);
        currentInstance.find("#" + id).find(".luckysheet-model-save-btn").click(function () {
            if (typeof savefunc1 == 'function') {
                savefunc1();
            }

            currentInstance.find("#" + id).hide();
            currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
        });

        currentInstance.find("#" + id).find(".luckysheet-model-save-btn").click(function () {
            if (typeof closefunc2 == 'function') {
                closefunc2();
            }

            currentInstance.find("#" + id).hide();
            currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
        });
    },
    sheetConfig: function () {

    },
    hoverTipshowState: false,
    hoverTipshowTimeOut: null,
    createHoverTip: function (obj, to) {
        let _this = this;
        const instance = this.instance;
        const containerId = instance.getInstanceId();
        let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);

        $(obj).on("mouseover", to, function (e) {
            if (_this.hoverTipshowState) {
                return;
            }

            clearTimeout(_this.hoverTipshowTimeOut);
            _this.hoverTipshowTimeOut = setTimeout(function(){
                let $t = $(e.currentTarget), 
                    toffset = $t.offset(), 
                    $toolup = currentInstance.find("#luckysheet-tooltip-up");
                
                let tips = $t.data("tips");
                if (tips == null || tips.length == 0) {
                    tips = $t.prev().data("tips");

                    if (tips == null || tips.length == 0) {
                        return;
                    }
                }

                if ($toolup.length == 0) {
                    $(`#${containerId}`).append(luckysheetToolHTML);
                    $toolup = currentInstance.find("#luckysheet-tooltip-up");
                }

                $toolup.removeClass("jfk-tooltip-hide").find("div.jfk-tooltip-contentId").html(tips);
                let toolwidth = $toolup.outerWidth();
                $toolup.find("div.jfk-tooltip-arrow").css("left", (toolwidth) / 2);

                let toolleft = (toffset.left - currentInstance.offset().left) / Store.scale + ($t.outerWidth() - toolwidth) / 2;
                if(toolleft < 2){
                    toolleft = 2;
                    $toolup.find("div.jfk-tooltip-arrow").css("left", ($t.outerWidth()) / 2);
                }

                $toolup.css({ "top": (toffset.top - currentInstance.offset().top) / Store.scale + $t.outerHeight() + 1, "left": toolleft });
            }, 300);

        }).on("mouseout", to, function (e) {
            _this.hoverTipshowState = false;
            clearTimeout(_this.hoverTipshowTimeOut);
            currentInstance.find("#luckysheet-tooltip-up").addClass("jfk-tooltip-hide");
        }).on("click", to, function (e) {
            _this.hoverTipshowState = true;
            clearTimeout(_this.hoverTipshowTimeOut);
            currentInstance.find("#luckysheet-tooltip-up").addClass("jfk-tooltip-hide");
        });
    },
    popover: function(content, position, close, style, btntxt, exitsFuc){
        const instance = this.instance;
        let _locale = instance.locale();
        let locale_button = _locale.button;
        let locale_paint = _locale.paint;
        const containerId = instance.getInstanceId();
        let Store = instance.getStore();
        let $container = $("#" + containerId );

        if(btntxt == null){
            btntxt = locale_button.close;
        }

        let htmldiv = '<div id="luckysheetpopover" class="luckysheetpopover"><div class="luckysheetpopover-content">'+locale_paint.start+'</div><div class="luckysheetpopover-btn">'+ btntxt +'</div></div>';
        $container.find("#luckysheetpopover").remove();
        $container.append(htmldiv);
        let $popover = $container.find("#luckysheetpopover");
        $popover.find(".luckysheetpopover-content").html(content);

        let w = $popover.outerWidth(),
            h = $popover.outerHeight();
        let pcss = {};

        if(position == 'topLeft'){
            pcss.top = "20px";
            pcss.left = "20px";
        }
        else if(position == 'topCenter'){
            pcss.top = "20px";
            pcss.left = "50%";
            pcss["margin-left"] = -w/2;
        }
        else if(position == 'topRight'){
            pcss.top = "20px";
            pcss.right = "20px";
        }
        else if(position == 'midLeft'){
            pcss.top = "50%";
            pcss["margin-top"] = -h/2;
            pcss.left = "20px";
        }
        else if(position == 'center'){
            pcss.top = "50%";
            pcss["margin-top"] = -h/2;
            pcss.left = "50%";
            pcss["margin-left"] = -w/2;
        }
        else if(position == 'midRight'){
            pcss.top = "50%";
            pcss["margin-top"] = -h/2;
            pcss.right = "20px";
        }
        else if(position == 'bottomLeft'){
            pcss.bottom = "20px";
            pcss.left = "20px";
        }
        else if(position == 'bottomCenter'){
            pcss.bottom = "20px";
            pcss.left = "50%";
            pcss["margin-left"] = -w/2;
        }
        else if(position == 'bottomRight'){
            pcss.bottom = "20px";
            pcss.right = "20px";
        }
        else{
            pcss.top = "20px";
            pcss.left = "50%";
            pcss["margin-left"] = -w/2;
        }

        if(style == "white"){
            pcss.background = "rgba(255, 255, 255, 0.65)";
            pcss.color = "#000";
            $popover.find(".luckysheetpopover-btn").css({"border": "1px solid #000"});
        }

        setTimeout(function(){
            $popover.find(".luckysheetpopover-content").css({"margin-left": -$popover.find(".luckysheetpopover-btn").outerWidth()/2});
        }, 1);
        $popover.css(pcss).fadeIn();
        $popover.find(".luckysheetpopover-btn").click(function(){
            if(typeof(exitsFuc) == "function"){
                exitsFuc();
            }
        });

        if(close != null && typeof(close) == "number"){
            setTimeout(function(){
                $popover.fadeOut().remove();
                if(typeof(exitsFuc) == "function"){
                    exitsFuc();
                }
            }, close);
        }
    }
}

export default tooltip;