import { replaceHtml } from "../utils/util";
import { modelHTML } from "./constant";
import locale from "../locale/locale";
import multiInstanceStore from "../store/multiInstance"; // 存储多实例Store wangxiaofei 1023.9.20
const dnaAnalysisUtils = {
  /**
   * 翻译为正链
   */
  translateProteinForwardStrand: function(){
    let instance = this.instance;
    let containerId = instance.getInstanceId();
    const abbreviation = { // 定义正确的翻译后内容
      ATA: "I",ATC: "I",ATT: "I",
      ATG: "M",
      ACA: "T",ACC: "T",ACG: "T",ACT: "T",
      AAC: "N",AAT: "N",
      AAA: "K",AAG: "K",
      AGC: "S",AGT: "S",
      AGA: "R",AGG: "R",
      CTA: "L",CTC: "L",CTG: "L",CTT: "L",
      CCA: "P",CCC: "P",CCG: "P",CCT: "P",
      CAC: "H",CAT: "H",
      CAA: "Q",CAG: "Q",
      CGA: "R",CGC: "R",CGG: "R",CGT: "R",
      GTA: "V",GTC: "V",GTG: "V",GTT: "V",
      GCA: "A",GCC: "A",GCG: "A",GCT: "A",
      GAC: "D",GAT: "D",
      GAA: "E",GAG: "E",
      GGA: "G",GGC: "G",GGG: "G",GGT: "G",
      TCA: "S",TCC: "S",TCG: "S",TCT: "S",
      TTC: "F",TTT: "F",
      TTA: "L",TTG: "L",
      TAC: "Y",TAT: "Y",
      TAA: "*",TAG: "*",TGA: "*",
      TGC: "C",TGT: "C",
      TGG: "W"
    }
    let range = luckysheet[containerId].getRange()
    let rowIndex = range[range.length - 1].row // 行第一次取值
    let colIndex = range[range.length - 1].column // 列第一次取值
    let selectedRangeArr = luckysheet[containerId].getRangeArray('twoDimensional')
    let l = [],f = [], h = []; // l为存储翻译后内容 f为内容长度
    if(selectedRangeArr.length > 0){ // 如果选中行有内容
      for(let i = 0; i < selectedRangeArr.length; i++){ // 循环读取选中行
        let p = selectedRangeArr[i].filter(item => item).join('').toUpperCase() // 读取当前行的全部内容转为字符串
        "" !== p ? l.push(p) : l.push("*") // 如果当前行为空字符串则赋值为*
        let m = 0, y = 0;
        for ( let g = 0; g < selectedRangeArr[i].length; g++ ) { //循环读取为正确规则的字符
          let v = selectedRangeArr[i][g];
          v && void 0 !== v || 0 !== y || m++,
          v && y++
        }
        var b = colIndex[1] - colIndex[0] + 1 - m - y;
        f.push(m),
        h.push(b)
      }
      let x = 0
      for (let w = 0; w < rowIndex[1] - rowIndex[0] + 1; w++){ // 判断是否为第一列，都则赋值X为1用于插入列
        0 === colIndex[0] && 0 === f[w] && (x = 1);
      }
      let row = rowIndex[0]                  
      for (let S = 0; S < rowIndex[1] - rowIndex[0] + 1; S++) {
        D(rowIndex[0] + 2 * S + 1, 1); // 插入行
        // 累加列数，用于插入数据
        let O = colIndex[0] - 1 + f[S]
          , C = O + l[S].length + 1;
        1 === x && (0 === S && M(colIndex[0]),
        O++,
        C++),
        luckysheet[containerId].setRangeFormat("bg", "#f8e0e6", { range: { column: [O, O], row: [rowIndex[0] + 2 * S + 1, rowIndex[0] + 2 * S + 1] } }) // 插入开始背景色
        luckysheet[containerId].setRangeValue([['>']], { range: { column: [O, O], row: [rowIndex[0] + 2 * S + 1, rowIndex[0] + 2 * S + 1] } }) // 插入正链开始标识字符
        luckysheet[containerId].setRangeFormat("bg", "#f8e0e6", { range: { column: [C, C], row: [rowIndex[0] + 2 * S + 1, rowIndex[0] + 2 * S + 1] } }) // 插入结束背景色
        luckysheet[containerId].setRangeValue([['>']], { range: { column: [C, C], row: [rowIndex[0] + 2 * S + 1, rowIndex[0] + 2 * S + 1] } }) //插入正链 结束标识字符
        let col = O
        for (let k = 0; k < l[S].length - 2; k += 3) { // 合并列并填入翻译后数据
            let T = l[S][k].toUpperCase()
              , A = l[S][k + 1].toUpperCase()
              , I = l[S][k + 2].toUpperCase();
            if (void 0 !== T && void 0 !== A && void 0 !== I && T && A && I && "-" !== (T || A || I) && _(T) && _(A) && _(I)) {
                let N = [T, A, I].join("").toUpperCase();
                if (N && void 0 !== N) {
                    let j = abbreviation[N]
                    luckysheet[containerId].cancelRangeMerge({ range: { column:[col+1, col+3], row: [row + 1, row + 1]} }) // 避免bug，先取消当前单元格的合并列
                    luckysheet[containerId].setRangeMerge("all",{ range: { column:[col+1, col+3], row: [row + 1, row + 1]} }) // 合并单元格
                    luckysheet[containerId].setRangeValue([[j]], { range: { column: [col+1, col+1], row: [row + 1, row + 1] } }) //填入数据
                    col = col + 3
                }
            }
        }
        if(l[S] === "*"){ // 如果当前行为空数据，填入*数据
          luckysheet[containerId].setRangeValue([[l[S]]], { range: { column: [col+1, col+1], row: [row, row] } })
        }
        row = row + 2
      }
    }
    /**
     * 插入新列
     * @param {插入列} start 
     */
    function M(start){
      luckysheet[containerId].insertColumn(start)
    }
    /**
     * 插入新行
     * @param {插入行} start 
     * @param {插入的条数} num 
     */
    function D(start, num){
      luckysheet[containerId].insertRow(start,{number:num})
    }
  },

  /**
   * 翻译为负链
   */
  translateProteinReverseStrand: function(){
    let instance = this.instance;
    let containerId = instance.getInstanceId();
    // 定义正确的翻译后内容
    const abbreviation = {
      ATA: "I",ATC: "I",ATT: "I",
      ATG: "M",
      ACA: "T",ACC: "T",ACG: "T",ACT: "T",
      AAC: "N",AAT: "N",
      AAA: "K",AAG: "K",
      AGC: "S",AGT: "S",
      AGA: "R",AGG: "R",
      CTA: "L",CTC: "L",CTG: "L",CTT: "L",
      CCA: "P",CCC: "P",CCG: "P",CCT: "P",
      CAC: "H",CAT: "H",
      CAA: "Q",CAG: "Q",
      CGA: "R",CGC: "R",CGG: "R",CGT: "R",
      GTA: "V",GTC: "V",GTG: "V",GTT: "V",
      GCA: "A",GCC: "A",GCG: "A",GCT: "A",
      GAC: "D",GAT: "D",
      GAA: "E",GAG: "E",
      GGA: "G",GGC: "G",GGG: "G",GGT: "G",
      TCA: "S",TCC: "S",TCG: "S",TCT: "S",
      TTC: "F",TTT: "F",
      TTA: "L",TTG: "L",
      TAC: "Y",TAT: "Y",
      TAA: "*",TAG: "*",TGA: "*",
      TGC: "C",TGT: "C",
      TGG: "W"
    }
    const baseComplementarity = { // 符合互补链规则的字母
      A: 'T',
      T: 'A',
      C: 'G',
      G: 'C',
    }
    let range = luckysheet[containerId].getRange()
    let rowIndex = range[range.length - 1].row // 行第一次取值
    let colIndex = range[range.length - 1].column // 列第一次取值
    let selectedRangeArr = luckysheet[containerId].getRangeArray('twoDimensional')
    let l = [],f = [], h = []; // l为存储翻译后内容 f为内容长度
    if(selectedRangeArr.length > 0){ // 如果选中行有内容
      for(let i = 0; i < selectedRangeArr.length; i++){ // 循环读取选中行
        let p = selectedRangeArr[i].filter(item => item).join('').toUpperCase() // 读取当前行的全部内容转为字符串
        "" !== p ? l.push(p) : l.push("*") // 如果当前行为空字符串则赋值为*
        let m = 0, y = 0;
        for ( let g = 0; g < selectedRangeArr[i].length; g++ ) { //循环读取为正确规则的字符
          let v = selectedRangeArr[i][g];
          v && void 0 !== v || 0 !== y || m++,
          v && y++
        }
        var b = colIndex[1] - colIndex[0] + 1 - m - y;
        f.push(m),
        h.push(b)
      }
      let x = 0
      for (let w = 0; w < rowIndex[1] - rowIndex[0] + 1; w++){ // 判断是否为第一列，都则赋值X为1用于插入列
        0 === colIndex[0] && 0 === f[w] && (x = 1);
      }
      let row = rowIndex[0]                  
      for (let S = 0; S < rowIndex[1] - rowIndex[0] + 1; S++) {
        D(rowIndex[0] + 2 * S + 1, 1); // 插入行
        // 累加列数，用于插入数据
        let O = colIndex[0] - 1 + f[S]
          , C = O + l[S].length + 1;
        1 === x && (0 === S && M(colIndex[0]),
        O++,
        C++),
        luckysheet[containerId].setRangeFormat("bg", "#E0F8E0", { range: { column: [O, O], row: [rowIndex[0] + 2 * S + 1, rowIndex[0] + 2 * S + 1] } }) // 插入开始背景色
        luckysheet[containerId].setRangeValue([['<']], { range: { column: [O, O], row: [rowIndex[0] + 2 * S + 1, rowIndex[0] + 2 * S + 1] } }) // 插入负链开始标识字符
        luckysheet[containerId].setRangeFormat("bg", "#E0F8E0", { range: { column: [C, C], row: [rowIndex[0] + 2 * S + 1, rowIndex[0] + 2 * S + 1] } }) // 插入结束背景色
        luckysheet[containerId].setRangeValue([['<']], { range: { column: [C, C], row: [rowIndex[0] + 2 * S + 1, rowIndex[0] + 2 * S + 1] } }) //插入负链 结束标识字符
        let col = C
        for (let k = 0; k < l[S].length - 2; k += 3) { // 合并列并填入翻译后数据
            let T = l[S][l[S].length - k - 1].toUpperCase()
              , A = l[S][l[S].length - k - 2].toUpperCase()
              , I = l[S][l[S].length - k - 3].toUpperCase()
            if(baseComplementarity[T]!==undefined && baseComplementarity[A]!==undefined && baseComplementarity[I]!==undefined){ // 转义为负链 
              T = baseComplementarity[T]
              A = baseComplementarity[A]
              I = baseComplementarity[I]
            }
            if (void 0 !== T && void 0 !== A && void 0 !== I && T && A && I && _(T) && _(A) && _(I)) {
                let N = [T, A, I].join("").toUpperCase();;
                if (N && void 0 !== N) {
                    let j = abbreviation[N]
                    luckysheet[containerId].cancelRangeMerge({ range: { column:[col-3, col-1], row: [row + 1, row + 1]} }) // 避免bug，先取消当前单元格的合并列
                    luckysheet[containerId].setRangeMerge("all",{ range: { column:[col-3, col-1], row: [row + 1, row + 1]} }) // 合并单元格
                    luckysheet[containerId].setRangeValue([[j]], { range: { column: [col-3, col-3], row: [row + 1, row + 1] } }) //填入数据
                    col = col - 3
                }
            }
        }
        if(l[S] === "*"){ // 如果当前行为空数据，填入*数据
          luckysheet[containerId].setRangeValue([[l[S]]], { range: { column: [col-1, col-1], row: [row, row] } })
        }
        row = row + 2
      }
    }
    /**
     * 插入新列
     * @param {插入列} start 
     */
    function M(start){
      luckysheet[containerId].insertColumn(start)
    }
     /**
     * 插入新行
     * @param {插入行} start 
     * @param {插入的条数} num 
     */
    function D(start, num){
      luckysheet[containerId].insertRow(start,{number:num})
    }
  },
  /**
   * DNA双序列对比
   */
  doubleSequenceComparison: function(){
    let instance = this.instance;
    let containerId = instance.getInstanceId();
    let currentInstance = $(`#${containerId}`);
    let Store = instance.getStore();
    let _locale = instance.locale();
    const locale_DNASequenceComparisonDialog = _locale.DNASequenceComparisonDialog;
    let t = 0;
    // 弹框内容
    let content = `<div class="DNA-sequenceComparison-setting-block">
        <div class="subtemplate_wrap">
            <div id="sub_module_body">
                <div id="save_as_box">
                    <div class="save_part">
                        <div class="drop-zone">
                            <label class="dialog-inline-label">
                                <p>${locale_DNASequenceComparisonDialog.minORFLength}：</p>
                                <input value="3" id="min_ORF_length"></input>
                                <p class="error" id="min_ORF_length_error"></p>
                            </label>
                            <label class="dialog-inline-label">
                                <p>${locale_DNASequenceComparisonDialog.calculateFrameWork}：</p>
                                <select class="select_steps" id="calculate_frame_work">
                                    <option value=${locale_DNASequenceComparisonDialog.frameWorkArray[0].key} selected="">${locale_DNASequenceComparisonDialog.frameWorkArray[0].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.frameWorkArray[1].key}>${locale_DNASequenceComparisonDialog.frameWorkArray[1].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.frameWorkArray[2].key}>${locale_DNASequenceComparisonDialog.frameWorkArray[2].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.frameWorkArray[3].key}>${locale_DNASequenceComparisonDialog.frameWorkArray[3].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.frameWorkArray[4].key}>${locale_DNASequenceComparisonDialog.frameWorkArray[4].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.frameWorkArray[5].key}>${locale_DNASequenceComparisonDialog.frameWorkArray[5].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.frameWorkArray[6].key}>${locale_DNASequenceComparisonDialog.frameWorkArray[6].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.frameWorkArray[7].key}>${locale_DNASequenceComparisonDialog.frameWorkArray[7].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.frameWorkArray[8].key}>${locale_DNASequenceComparisonDialog.frameWorkArray[8].value}</option>
                                </select>
                            </label>
                            <label class="dialog-inline-label">
                                <p>${locale_DNASequenceComparisonDialog.initiatorCodon}：</p>
                                <select class="select_steps" id="initiator_codon">
                                    <option value=${locale_DNASequenceComparisonDialog.codonArray[0].key} selected="">${locale_DNASequenceComparisonDialog.codonArray[0].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.codonArray[1].key}>${locale_DNASequenceComparisonDialog.codonArray[1].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.codonArray[2].key}>${locale_DNASequenceComparisonDialog.codonArray[2].value}</option>
                                    <option value=${locale_DNASequenceComparisonDialog.codonArray[3].key}>${locale_DNASequenceComparisonDialog.codonArray[3].value}</option>
                                </select>
                            </label>
                            <label class="dialog-inline-label">
                                <p>${locale_DNASequenceComparisonDialog.minusStrand}：</p>
                                <button class="" id="calculate_minus_strand" value="0">是</button>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>`
    // 计算弹窗确定 回调函数
    let confirmCallback = function(target, instance) {

    }
    instance.createHtmlDom.createDialog('luckysheet-DNASequenceComparison-dialog', content, containerId, locale_DNASequenceComparisonDialog.calculateORF, confirmCallback, true, true, true, '', '', '', true);
    
    const forwardEnd = ['AAT','GAT','AGT'], //正链
        reverseEnd = ['TAA','TAG','TGA'], // 负链
        calculateType = [3,2,1,-1,-2,-3], // 计算框架
        forwardType = [3,2,1], // 正向计算框架
        reverseType = [-1,-2,-3]; //负向计算框架
        baseComplementarity = { // 符合互补链规则的字母
            A: 'T',
            T: 'A',
            C: 'G',
            G: 'C',
            a: 't',
            t: 'a',
            c: 'g',
            g: 'c',
        }

    // 计算负链选择
    currentInstance.find("#calculate_minus_strand").click(() => {
        if(currentInstance.find("#calculate_minus_strand").hasClass('active')){
            currentInstance.find("#calculate_minus_strand").removeClass('active');
            currentInstance.find("#calculate_minus_strand").val(0);
        }else{
            currentInstance.find("#calculate_minus_strand").addClass('active');
            currentInstance.find("#calculate_minus_strand").val(1);
        }
    });
  },
  /**
   * BLAST序列对比
   */
  BLASTSequenceComparison: function(){
    window.open('https://blast.ncbi.nlm.nih.gov/Blast.cgi?PROGRAM=blastn&PAGE_TYPE=BlastSearch&BLAST_SPEC=&LINK_LOC=blasttab&QUERY=AATGATGTAA')
  }
}
export default dnaAnalysisUtils;