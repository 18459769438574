import luckysheetFreezen from '../controllers/freezen';
import { luckysheet_searcharray } from '../controllers/sheetSearch';
import { luckysheetrefreshgrid } from '../global/refresh';
import multiInstanceStore from "../store/multiInstance"; // 存储多实例Store wangxiaofei 1023.9.20mport Store from '../store';
import method from '../global/method'

let scrollRequestAnimationFrameIni = true,scrollRequestAnimationFrame = false, scrollTimeOutCancel=null;

function execScroll(){
    const instance = this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let currentInstance = $(`#${containerId}`);
    let scrollLeft = currentInstance.find("#luckysheet-scrollbar-x").scrollLeft(), 
        scrollTop = currentInstance.find("#luckysheet-scrollbar-y").scrollTop();
    instance.refresh.luckysheetrefreshgrid(scrollLeft, scrollTop);
    scrollRequestAnimationFrame = window.requestAnimationFrame(execScroll);
}

//全局滚动事件
export default function luckysheetscrollevent(isadjust) {
    const instance = this.instance;
    const containerId =  instance.getInstanceId();
    let Store = instance.getStore();
    let currentInstance = $(`#${containerId}`);
    let $t = currentInstance.find("#luckysheet-cell-main");
    let scrollLeft = currentInstance.find("#luckysheet-scrollbar-x").scrollLeft(), 
        scrollTop = currentInstance.find("#luckysheet-scrollbar-y").scrollTop(),
        canvasHeight = currentInstance.find("#luckysheetTableContent").height(); // canvas高度

    // clearTimeout(scrollTimeOutCancel);

    // scrollTimeOutCancel = setTimeout(() => {
    //     scrollRequestAnimationFrameIni  = true;
    //     window.cancelAnimationFrame(scrollRequestAnimationFrame);
    // }, 500);

    // if (!!isadjust) {
    //     let scrollHeight = $t.get(0).scrollHeight;
    //     let windowHeight = $t.height();
    //     let scrollWidth = $t.get(0).scrollWidth;
    //     let windowWidth = $t.width();

    //     let maxScrollLeft = scrollWidth - windowWidth;
    //     let maxScrollTop = scrollHeight - windowHeight;

    //     let visibledatacolumn_c = Store.visibledatacolumn, visibledatarow_c = Store.visibledatarow;

    //     if (instance.freezen.freezenhorizontaldata != null) {
    //         visibledatarow_c = instance.freezen.freezenhorizontaldata[3];
    //     }

    //     if (instance.freezen.freezenverticaldata != null) {
    //         visibledatacolumn_c = instance.freezen.freezenverticaldata[3];
    //     }

    //     let col_ed = luckysheet_searcharray(visibledatacolumn_c, scrollLeft);
    //     let row_ed = luckysheet_searcharray(visibledatarow_c, scrollTop);

    //     let refreshLeft = scrollLeft , refreshTop = scrollTop;

    //     if (col_ed <= 0) {
    //         scrollLeft = 0;
    //     }
    //     else {
    //         scrollLeft = visibledatacolumn_c[col_ed - 1];
    //     }

    //     if (row_ed <= 0) {
    //         scrollTop = 0;
    //     }
    //     else {
    //         scrollTop = visibledatarow_c[row_ed - 1];
    //     }
    // }

    if (instance.freezen.freezenhorizontaldata != null) {
        if (scrollTop < instance.freezen.freezenhorizontaldata[2]) {
            scrollTop = instance.freezen.freezenhorizontaldata[2];
            currentInstance.find("#luckysheet-scrollbar-y").scrollTop(scrollTop);
            return;
        }
    }

    if (instance.freezen.freezenverticaldata != null) {
        if (scrollLeft < instance.freezen.freezenverticaldata[2]) {
            scrollLeft = instance.freezen.freezenverticaldata[2];
            currentInstance.find("#luckysheet-scrollbar-x").scrollLeft(scrollLeft);
            return;
        }
    }

    currentInstance.find("#luckysheet-cols-h-c").scrollLeft(scrollLeft);//列标题
    currentInstance.find("#luckysheet-rows-h").scrollTop(scrollTop);//行标题
    
    $t.scrollLeft(scrollLeft).scrollTop(scrollTop);

    currentInstance.find("#luckysheet-input-box-index").css({
        "left": currentInstance.find("#luckysheet-input-box").css("left"), 
        "top": (parseInt(currentInstance.find("#luckysheet-input-box").css("top")) - 20) + "px", 
        "z-index": currentInstance.find("#luckysheet-input-box").css("z-index")
    }).show();

    // if(scrollRequestAnimationFrameIni && Store.scrollRefreshSwitch){
    //     execScroll();
    //     scrollRequestAnimationFrameIni = false;
    // }

    instance.refresh.luckysheetrefreshgrid(scrollLeft, scrollTop);
    

    currentInstance.find("#luckysheet-bottom-controll-row").css("left", scrollLeft);

    //有选区且有冻结时，滚动适应
    if(instance.freezen.freezenhorizontaldata != null || instance.freezen.freezenverticaldata != null){
        instance.freezen.scrollAdapt();
    }

    if(!instance.method.createHookFunction("scroll", {scrollLeft, scrollTop, canvasHeight})){ return; }

}