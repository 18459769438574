
import { modelHTML } from './constant';

import { selectHightlightShow } from './select';
import {checkProtectionAuthorityNormal} from './protection';
import { 
    replaceHtml,
    chatatABC, 
} from '../utils/util';
import { rowlenByRange } from '../global/getRowlen';
import {  isEditMode } from '../global/validate';
import cleargridelement from '../global/cleargridelement';
import { 
    jfrefreshgrid, 
} from '../global/refresh';
import { getcellvalue } from '../global/getdata';
import { orderbydata,  sortColumnSeletion } from '../global/sort';
import tooltip from '../global/tooltip';
import editor from '../global/editor';
import { isdatatype } from '../global/datecontroll';
import multiInstanceStore from "../store/multiInstance"; // 存储多实例Store wangxiaofei 1023.9.20
import locale from '../locale/locale';
import createHtmlDom from './common/createHtmlDom';

/**
 * 排序
 * @param {*} currentId 当前实例id
 */
export function orderByInitial(){
    const _this = this;
    const instance = _this.instance;
    const _locale = instance.locale();
    const currentId = instance.getInstanceId();
    // 获取当前实例 wangxiaofei 2023914
    const currentInstance = $("#"+currentId);
	let Store = instance.getStore();
    //菜单栏 排序按钮
    currentInstance.find(`#luckysheetorderbyasc, #luckysheetorderbyasc_t`).mousedown(function (event) {
        instance.initSheet.cleargridelement(event);
        instance.sort.sortColumnSeletion(Store.orderbyindex, true);
        instance.select.selectHightlightShow();
    });

    currentInstance.find(`#luckysheetorderbydesc, #luckysheetorderbydesc_t`).click(function (event) {
        instance.initSheet.cleargridelement(event);
        instance.sort.sortColumnSeletion(Store.orderbyindex, false);
        instance.select.selectHightlightShow();
    }); 

    
    currentInstance.find("#luckysheetorderby").click(function () {
        //排序事件
        let luckysheet_sort_initial = true;
        if(!instance.protection.checkProtectionAuthorityNormal(Store.currentSheetIndex, "sort")){
            return;
        }

        $(`body #${currentId} .luckysheet-cols-menu`).hide();
        const locale_sort = _locale.sort;
        if(Store.luckysheet_select_save.length > 1){
            if(instance.validate.isEditMode()){
                alert(locale_sort.noRangeError);
            }
            else{
                instance.tooltip.info(locale_sort.noRangeError, ""); 
            }
            return;
        }

        let last = Store.luckysheet_select_save[0];
        let r1 = last["row"][0], r2 = last["row"][1];
        let c1 = last["column"][0], c2 = last["column"][1];

        if (luckysheet_sort_initial) {
            luckysheet_sort_initial = false;
            
            let content = `<div style="overflow: hidden;" class="luckysheet-sort-modal"><div><label><input type="checkbox" id="luckysheet-sort-haveheader"/><span>${locale_sort.hasTitle}</span></label></div><div style="overflow-y:auto;" id="luckysheet-sort-dialog-tablec"><table data-itemcount="0" cellspacing="0"> <tr><td>${locale_sort.hasTitle} <select name="sort_0"> <option value="1">1</option> <option value="2">2</option> <option value="3">3</option> <option value="4">4</option> </select> </td> <td> <div><label><input value="asc" type="radio" checked="checked" name="sort_0"><span>${locale_sort.asc}A-Z</span></label></div> <div><label><input value="desc" type="radio" name="sort_0"><span>${locale_sort.desc}Z-A</span></label></div></td></tr></table></div><div style="background: #e5e5e5;border-top: 1px solid #f5f5f5; height: 1px; width: 100%;margin:2px 0px;margin-bottom:10px;"></div> <div> <span style="font-weight: bold; text-decoration: underline;text-align:center;color: blue;cursor: pointer;" class="luckysheet-sort-dialog-additem">+ ${locale_sort.addOthers}</span> </div> </div>`;
            instance.createHtmlDom.createDialog('luckysheet-sort-dialog', content, currentId, _locale.sort.sortTitle, () => {}, true, true, true, '', '');

            currentInstance.find("#luckysheet-sort-dialog-tablec").css("max-height", currentInstance.height() > 178 ? currentInstance.height() - 178 : 'unset');

            currentInstance.find("#luckysheet-sort-dialog .luckysheet-sort-dialog-additem").click(function () {
                let last = Store.luckysheet_select_save[0];
                let r1 = last["row"][0], r2 = last["row"][1];
                let c1 = last["column"][0], c2 = last["column"][1];

                let option = "", i = currentInstance.find("#luckysheet-sort-dialog table").data("itemcount") + 1;
                let t = currentInstance.find("#luckysheet-sort-haveheader").is(':checked');

                for (let c = c1; c <= c2; c++) {
                    if (t) {
                        let v = instance.getdata.getcellvalue(r1, c, Store.flowdata, "m");

                        if(v == null){
                            v = locale_sort.columnOperation + (c - c1 + 1); 
                        }

                        option += '<option value="' + c + '">' + v + '</option>';
                    }
                    else {
                        option += '<option value="' + c + '">' + chatatABC(c) + '</option>';
                    }
                }

                currentInstance.find("#luckysheet-sort-dialog table").append(`
                    <tr class="luckysheet-sort-dialog-tr">
                        <td><span class="luckysheet-sort-item-close" onclick="$(this).parent().parent().remove();"><i class="fa fa-times"
                                    aria-hidden="true"></i></span>${locale_sort.secondaryTitle} <select
                                name="sort_${i}">${option}</select> </td>
                        <td>
                            <div><label><input value="asc" type="radio" checked="checked"
                                        name="sort_${i}"><span>${locale_sort.asc}A-Z</span></label></div>
                            <div><label><input value="desc" type="radio" name="sort_${i}"><span>${locale_sort.desc}Z-A</span></label>
                            </div>
                        </td>
                    </tr>
                `);
                currentInstance.find("#luckysheet-sort-dialog table").data("itemcount", i);
                instance.createHtmlDom.setDialogPosition(currentId, 'luckysheet-sort-dialog');
            });

            currentInstance.find("#luckysheet-sort-haveheader").change(function () {
                let last = Store.luckysheet_select_save[0];
                let r1 = last["row"][0], r2 = last["row"][1];
                let c1 = last["column"][0], c2 = last["column"][1];

                let t = $(this).is(':checked');
                let option = "";

                for (let c = c1; c <= c2; c++) {
                    if (t) {
                        let v = instance.getdata.getcellvalue(r1, c, Store.flowdata, "m");
                        
                        if(v == null){
                            v = locale_sort.columnOperation + (c - c1 + 1); 
                        }

                        option += '<option value="' + c + '">' + v + '</option>';
                    }
                    else {
                        option += '<option value="' + c + '">' + chatatABC(c) + '</option>';
                    }
                }

                currentInstance.find("#luckysheet-sort-dialog tr select").each(function () {
                    $(this).html(option);
                });
            });

            //Custom sort
            currentInstance.find("#luckysheet-sort-dialog-confirm").click(function () {
                if(Store.luckysheet_select_save.length > 1){
                    if(instance.validate.isEditMode()){
                        alert(locale_sort.noRangeError);
                    }
                    else{
                        instance.tooltip.info(locale_sort.noRangeError, "");
                    }

                    return;
                }

                let d = instance.editor.deepCopyFlowData(Store.flowdata);

                let last = Store.luckysheet_select_save[0];
                let r1 = last["row"][0], r2 = last["row"][1];
                let c1 = last["column"][0], c2 = last["column"][1];

                //Data has header row
                let t = currentInstance.find("#luckysheet-sort-haveheader").is(':checked');

                let str;
                if(t){
                    str = r1 + 1;
                }
                else{
                    str = r1;
                }

                let hasMc = false; //Whether the sort selection has merged cells

                let data = [];

                for(let r = str; r <= r2; r++){
                    let data_row = [];

                    for(let c = c1; c <= c2; c++){
                        if(d[r][c] != null && d[r][c].mc != null){
                            hasMc = true;
                            break;
                        }

                        data_row.push(d[r][c]);
                    }

                    data.push(data_row);
                }

                if(hasMc){
                    if(instance.validate.isEditMode()){
                        alert(locale_sort.mergeError);
                    }
                    else{
                        instance.tooltip.info(locale_sort.mergeError, "");
                    }

                    return;
                }
                
                $(currentInstance.find("#luckysheet-sort-dialog table tr").toArray().reverse()).each(function () {
                    let i = $(this).find("select").val(), 
                        asc = $(this).find('input:radio:checked').val();
                    
                    i -= c1;
                    
                    if (asc == "asc") {
                        asc = true;
                    }
                    else {
                        asc = false;
                    }

                    data = orderbydata([].concat(data), i, asc);
                });

                for(let r = str; r <= r2; r++){
                    for(let c = c1; c <= c2; c++){
                        d[r][c] = data[r - str][c - c1];
                    }
                }

                let allParam = {};
                if(Store.config["rowlen"] != null){
                    let cfg = $.extend(true, {}, Store.config);
                    cfg = instance.getRowlen.rowlenByRange(d, str, r2, cfg);

                    allParam = {
                        "cfg": cfg,
                        "RowlChange": true
                    }
                }

                instance.refresh.jfrefreshgrid(d, [{ "row": [str, r2], "column": [c1, c2] }], allParam);

                currentInstance.find("#luckysheet-sort-dialog").hide();
                currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
            });
        }

        let option = "";
        for (let c = c1; c <= c2; c++) {
            option += '<option value="' + c + '">' + chatatABC(c) + '</option>';
        }

        currentInstance.find("#luckysheet-sort-dialog select").html(option);

        currentInstance.find("#luckysheet-sort-dialog .luckysheet-sort-dialog-tr").remove();

        currentInstance.find("#luckysheet-sort-haveheader").prop("checked", false);
        currentInstance.find("#luckysheet-sort-dialog input:radio:first").prop("checked", "checked");

        currentInstance.find("#luckysheet-sort-dialog .luckysheet-modal-dialog-title-text").html(locale_sort.sortRangeTitle+"<span>" + chatatABC(c1) + (r1 + 1) + "</span>"+ locale_sort.sortRangeTitleTo +"<span>" + chatatABC(c2) + (r2 + 1) + "</span>");

        // let $t = currentInstance.find("#luckysheet-sort-dialog"), myh = $t.outerHeight(), myw = $t.outerWidth();
        // let winw = $(window).width(), winh = $(window).height();
        // let scrollLeft = $(document).scrollLeft(), scrollTop = $(document).scrollTop();

        // currentInstance.find("#luckysheet-sort-dialog-tablec").css("max-height", (winh - myh) / 2);
        // currentInstance.find("#luckysheet-sort-dialog").css({ "left": (winw + scrollLeft - myw) / 2, "top": (winh + scrollTop - myh) / 2 }).show();
        // currentInstance.find("#luckysheet-sort-dialog").css({ "left": (winw - myw) / 2 + scrollLeft, "top": (winh - myh) / 2 + scrollTop }).show();
        currentInstance.find(`#luckysheet-modal-dialog-mask`).show();

        if (r1 < r2) {
            setTimeout(function () {
                let flowrowdata1 = Store.flowdata[r1], 
                    flowrowdata2 = Store.flowdata[r1 + 1], 
                    hastitle = false;
                
                for (let i = c1; i <= c2; i++) {
                    let isdatatype_r1 = isdatatype(flowrowdata1[i]), 
                        isdatatype_r2 = isdatatype(flowrowdata2[i]);
                    
                    if (isdatatype_r1 != isdatatype_r2) {
                        hastitle = true;
                    }
                }

                if (hastitle) {
                    currentInstance.find("#luckysheet-sort-haveheader").prop("checked", true).change();
                }
            }, 10);
        }
    });
}