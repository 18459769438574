import Store from "./index"; 
import { deepCopy } from '../utils/chartUtil'; // 引入深度拷贝文件
/**
 * 多实例存储js
 */
const multiInstanceStore = {
  multiInstance: {}, // 多实例
  currentId: {}, // 当前实例id
  /**
   * 创建实例
   * @param {*} containerId 实例id
   */
  createInstance(containerId){
    let instance = deepCopy(Store);
    this.multiInstance[containerId] = instance;
    return instance;
  },

  /**
   * 获取实例
   * @param {*} containerId 实例id
   */
  getInstance(containerId){
    return this.multiInstance[containerId];
  },
  
  /**
   * 更新实例
   * @param {*} containerId 实例id
   */
  setInstance(containerId , instance){
    this.multiInstance[containerId] = instance;
  },

  /**
   * 获取当前实例Id
   */
  getCurrentId(){
    return this.currentId;
  },

  /**
   * 更新当前实例id
   * @param {*} containerId 实例id
   */
  setCurrentId(containerId ){
    this.currentId= containerId;
  },

  /**
   * 获取所有实例id
   */
  getAllInstanceId() {
    return Object.keys(this.multiInstance);
  },

  /**
   * 更新实例中key值
   * @param {*} containerId 实例id
   */
  setInstanceKey(containerId , key, value){
    this.multiInstance[containerId][key] = value;
  },

    
}

export default multiInstanceStore;