/**
 * DNA分析 开放阅读框js文件
 */
import { replaceHtml } from "../utils/util";
import { modelHTML } from "./constant";
import locale from "../locale/locale";
import createHtmlDom from "./common/createHtmlDom";
// import tooltipDialog from "./tooltipDialog";

const calculateORFDialog = {
    /**
     * 创建开放阅读框计算的弹框
     */
  createCalculateORFDialog: function(){
    let _this = this;
    const instance = _this.instance;
    let containerId = instance.getInstanceId();
    let currentInstance = $(`#${containerId}`);
    let _locale =instance.locale();
    const locale_calculateORFDialog = _locale.calculateORFDialog;
    let t = 0;
    // 弹框内容
    let content = `<div class="calculate-ORF-setting-block">
        <div class="subtemplate_wrap">
            <div id="sub_module_body">
                <div id="save_as_box">
                    <div class="save_part">
                        <div class="drop-zone">
                            <label class="dialog-inline-label">
                                <p>${locale_calculateORFDialog.minORFLength}：</p>
                                <input value="3" id="min_ORF_length"></input>
                                <p class="error" id="min_ORF_length_error"></p>
                            </label>
                            <label class="dialog-inline-label">
                                <p>${locale_calculateORFDialog.calculateFrameWork}：</p>
                                <select class="select_steps" id="calculate_frame_work">
                                    <option value=${locale_calculateORFDialog.frameWorkArray[0].key} selected="">${locale_calculateORFDialog.frameWorkArray[0].value}</option>
                                    <option value=${locale_calculateORFDialog.frameWorkArray[1].key}>${locale_calculateORFDialog.frameWorkArray[1].value}</option>
                                    <option value=${locale_calculateORFDialog.frameWorkArray[2].key}>${locale_calculateORFDialog.frameWorkArray[2].value}</option>
                                    <option value=${locale_calculateORFDialog.frameWorkArray[3].key}>${locale_calculateORFDialog.frameWorkArray[3].value}</option>
                                    <option value=${locale_calculateORFDialog.frameWorkArray[4].key}>${locale_calculateORFDialog.frameWorkArray[4].value}</option>
                                    <option value=${locale_calculateORFDialog.frameWorkArray[5].key}>${locale_calculateORFDialog.frameWorkArray[5].value}</option>
                                    <option value=${locale_calculateORFDialog.frameWorkArray[6].key}>${locale_calculateORFDialog.frameWorkArray[6].value}</option>
                                    <option value=${locale_calculateORFDialog.frameWorkArray[7].key}>${locale_calculateORFDialog.frameWorkArray[7].value}</option>
                                    <option value=${locale_calculateORFDialog.frameWorkArray[8].key}>${locale_calculateORFDialog.frameWorkArray[8].value}</option>
                                </select>
                            </label>
                            <label class="dialog-inline-label">
                                <p>${locale_calculateORFDialog.initiatorCodon}：</p>
                                <select class="select_steps" id="initiator_codon">
                                    <option value=${locale_calculateORFDialog.codonArray[0].key} selected="">${locale_calculateORFDialog.codonArray[0].value}</option>
                                    <option value=${locale_calculateORFDialog.codonArray[1].key}>${locale_calculateORFDialog.codonArray[1].value}</option>
                                    <option value=${locale_calculateORFDialog.codonArray[2].key}>${locale_calculateORFDialog.codonArray[2].value}</option>
                                    <option value=${locale_calculateORFDialog.codonArray[3].key}>${locale_calculateORFDialog.codonArray[3].value}</option>
                                </select>
                            </label>
                            <label class="dialog-inline-label">
                                <p>${locale_calculateORFDialog.minusStrand}：</p>
                                <button class="" id="calculate_minus_strand" value="0">是</button>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>`
    const forwardEnd = ['AAT','GAT','AGT'], //正链
        reverseEnd = ['TAA','TAG','TGA'], // 负链
        calculateType = [3,2,1,-1,-2,-3], // 计算框架
        forwardType = [3,2,1], // 正向计算框架
        reverseType = [-1,-2,-3]; //负向计算框架
        baseComplementarity = { // 符合互补链规则的字母
            A: 'T',
            T: 'A',
            C: 'G',
            G: 'C',
            a: 't',
            t: 'a',
            c: 'g',
            g: 'c',
        }
    // 序列弹窗确定
    let confirmCallback = function(target, instance) {
        console.log("✩start compute✩");
        let selectedRangeArr = instance.api.getRangeArray('twoDimensional')
        // 取弹框填写及选中的数据
        let minORFLengthValue = $(`#${containerId} #min_ORF_length`).val().replace(" ", ""), // 最小阅读框长度
            calculateFrameValue = $(`#${containerId} #calculate_frame_work`).val().replace(" ", ""), // 计算框架
            initiatorCodonValue = $(`#${containerId} #initiator_codon`).val().replace(" ",""), // 起始密码子
            calculateMinusStrand = $(`#${containerId} #calculate_minus_strand`).val().replace(" ",""), // 计算负链
            newSelectedRangeArr = [];
        let minORFLengthFlag = true;
        console.log(minORFLengthValue, calculateFrameValue, initiatorCodonValue, calculateMinusStrand)
        if(isNaN(Number(minORFLengthValue,10))){ // 判断最小阅读框长度是否为数字
            $(`#${containerId} #min_ORF_length_error`).text('此处必须为数字')
            minORFLengthFlag = false;
        }else{
            if(Number(minORFLengthValue,10) % 3 != 0){ // 判断最小阅读框长度是否为3的倍数
                $(`#${containerId} #min_ORF_length_error`).text('此处必须为3的倍数')
                minORFLengthFlag = false;
            } else {
                currentInstance.find("#min_ORF_length_error").text('');
            }
        }
        if(selectedRangeArr.length>0 && minORFLengthFlag !== false){ //如果有选中行 并且 满足上述判断条件
            if(parseInt(calculateMinusStrand) === 0){ // 判断是否为计算正链
                for(let j = 0; j < selectedRangeArr.length; j++){ // 转为正常的数组，区别一个单元格填写多个字母的情况
                    newSelectedRangeArr.push(selectedRangeArr[j].filter(item => item).join('').toUpperCase());
                }
                
            }else{ // 计算是否为负链
                for(let j = 0; j < selectedRangeArr.length; j++){ //循环拿到选中的数据
                    let data = []
                    let item = selectedRangeArr[j].filter(item => item).join('').toUpperCase().split('') //转为数组后用于转义为负链
                    for( let i =0; i< item.length; i++){
                        if ( baseComplementarity[item[i]]!==undefined ) { // 不在互补规则内则不为列增加信息
                            data.push(baseComplementarity[item[i]])
                        }else {
                            return
                        }
                    }
                    newSelectedRangeArr.push(data.join('').toUpperCase());
                }
            }
            let range = instance.api.getRange()
            let rowIndex = range[range.length - 1].row[0] // 行第一次取值
                let colIndex = range[range.length - 1].column[0] // 列第一次取值
            for(let M = 0; M < newSelectedRangeArr.length; M++){ // 多行选中情况
                switch (calculateFrameValue) {
                    
                    case "all": // 如果计算框架为全部
                        z(rowIndex,colIndex,newSelectedRangeArr[M],calculateType,initiatorCodonValue,minORFLengthValue,$(this),parseInt(calculateMinusStrand))
                        0 !== newSelectedRangeArr[M].length ? rowIndex += 7 : rowIndex += 1;
                        break;
                    case "positive": // 如果计算框架为正向5-3
                        z(rowIndex,colIndex,newSelectedRangeArr[M],forwardType,initiatorCodonValue,minORFLengthValue,$(this),parseInt(calculateMinusStrand))
                        0 !== newSelectedRangeArr[M].length ? rowIndex += 4 : rowIndex += 1;
                        break;
                    case "opposite": // 如果计算框架为反向3-5
                        z(rowIndex,colIndex,newSelectedRangeArr[M],reverseType,initiatorCodonValue,minORFLengthValue,$(this),parseInt(calculateMinusStrand))
                        0 !== newSelectedRangeArr[M].length ? rowIndex += 4 : rowIndex += 1;
                        break;
                    case "3": // 如果计算框架为3
                        z(rowIndex,colIndex,newSelectedRangeArr[M],[3],initiatorCodonValue,minORFLengthValue,$(this),parseInt(calculateMinusStrand))
                        0 !== newSelectedRangeArr[M].length ? rowIndex += 2 : rowIndex += 1;
                        break;
                    case "2": // 如果计算框架为2
                        z(rowIndex,colIndex,newSelectedRangeArr[M],[2],initiatorCodonValue,minORFLengthValue,$(this),parseInt(calculateMinusStrand))
                        0 !== newSelectedRangeArr[M].length ? rowIndex += 2 : rowIndex += 1;
                        break;
                    case "1": // 如果计算框架为1
                        z(rowIndex,colIndex,newSelectedRangeArr[M],[1],initiatorCodonValue,minORFLengthValue,$(this),parseInt(calculateMinusStrand))
                        0 !== newSelectedRangeArr[M].length ? rowIndex += 2 : rowIndex += 1;
                        break;
                    case "-1": // 如果计算框架为-1
                        z(rowIndex,colIndex,newSelectedRangeArr[M],[-1],initiatorCodonValue,minORFLengthValue,$(this),parseInt(calculateMinusStrand))
                        0 !== newSelectedRangeArr[M].length ? rowIndex += 2 : rowIndex += 1;
                        break;
                    case "-2": // 如果计算框架为-2
                        z(rowIndex,colIndex,newSelectedRangeArr[M],[-2],initiatorCodonValue,minORFLengthValue,$(this),parseInt(calculateMinusStrand))
                        0 !== newSelectedRangeArr[M].length ? rowIndex += 2 : rowIndex += 1;
                        break;
                    case "-3": // 如果计算框架为-3
                        z(rowIndex,colIndex,newSelectedRangeArr[M],[-3],initiatorCodonValue,minORFLengthValue,$(this),parseInt(calculateMinusStrand))
                        0 !== newSelectedRangeArr[M].length ? rowIndex += 2 : rowIndex += 1;
                }
            }
            
            
        }
        if(minORFLengthFlag !== false){
            $(`#${containerId} #luckysheet-calculateORF-dialog`).hide();
            currentInstance.find(`#luckysheet-modal-dialog-mask`).hide();
        }
    }
    // 调用创建弹窗公共方法创建开放阅读框计算的弹框 --modify by liluyao 2023/9/15
    instance.createHtmlDom.createDialog('luckysheet-calculateORF-dialog', content, containerId, locale_calculateORFDialog.calculateORF, confirmCallback, false, true, true, '', '')

    // 计算负链选择
    currentInstance.on('click.calculate_minus_strand', `#calculate_minus_strand`, function() {
        if($(`#${containerId} #calculate_minus_strand`).hasClass('active')){
            $(`#${containerId} #calculate_minus_strand`).removeClass('active');
            $(`#${containerId} #calculate_minus_strand`).val(0);
        }else{
            $(`#${containerId} #calculate_minus_strand`).addClass('active');
            $(`#${containerId} #calculate_minus_strand`).val(1);
        }
    });

    function z(e,colIndex,t,n,initiatorCodonValue,minORFLengthValue,_self,calculateMinusStrand){
        if (calculateMinusStrand === 0) // 如果为计算正链
            instance.api.setRangeValue([t.split('')], { range: { column: [colIndex, colIndex+t.length-1], row: [e, e] } })
        else { // 如果为计算负链
            let text = []
            t.split('').forEach(item => {
                text.push(baseComplementarity[item])
            });
            instance.api.setRangeValue([text], { range: { column: [colIndex, colIndex+t.length-1], row: [e, e] } })
        }
        let col = colIndex;
        if(0 !== t.length){
            let o = e;
            for(let a = 0; a < n.length; a++){ 
                let s,c=[];
                // 判断条件及公式，具体请查看对应的方法
                (s = L(P(t, n[a]),initiatorCodonValue,n[a]))[0].length >= 1 && s[1].length >= 1 && (c = B(s, parseInt(n[a]), minORFLengthValue)),
                F(c, n[a], o, col),
                o += 1
            }
        }
    } 

    /**
     * 遍历获取正确符合规则的数据
     * @param {*} e 
     * @param {*} t 
     * @returns 
     */
    function P(e,t){
        let n, r, i, o = [];
        if (t < 0) {
            n = e.slice(0, e.length + t),
            r = e.slice(e.length + t, e.length),
            o.push(r),
            i = -t;
            for (let a = e.length - i; a > 0; a -= 3) {
                let s = n.slice(a - 3, a);
                a >= 3 ? o.push(s) : o.push(n.slice(0, a))
            }
            o = o.reverse()
        } else {
            i = t,
            n = e.slice(i),
            o.push(n.slice(0, i));
            for (let c = 0; c < e.length - i; c += 3) {
                let u = n.slice(c, c + 3);
                u.length > 0 && o.push(u)
            }
        }
        return o
    }

    /**
     * 根据计算框架组装二维数组
     * @param {*} n 
     * @param {*} r 
     * @param {*} i 
     * @returns 
     */
    function L(n, r, i) {
        let o = []
          , a = []
          , s = 0;
        if (i > 0)
            for (let c = 0; c < n.length; c++)
                n[c] === r && o.push(s),
                reverseEnd.indexOf(n[c]) >= 0 && a.push(s),
                s += n[c].length;
        else
            for (let u = r.split("").reverse().join(""), l = 0; l < n.length; l++)
                n[l] === u && a.push(s),
                forwardEnd.indexOf(n[l]) >= 0 && o.push(s),
                s += n[l].length;
        return [o, a]
    }

    /**
     * 
     * @param {*} e 
     * @param {*} t 
     * @param {*} p 
     * @returns 
     */
    function B(e, t, p) {
        let n = [], r = [];
        for (let i = 0; i < e[0].length; i++)
            for (let o = 0; o < e[1].length; o++) {
                let a = [];
                e[1][o] - e[0][i] >= p && (a.push(e[0][i]),
                a.push(e[1][o])),
                2 === a.length && r.push(a)
            }
        let s = new Array(r.length).fill(!0);
        for (let c = 0; c < r.length; c++)
            if (t > 0)
                for (let u = r[c][1], l = c + 1; l < r.length; l++) {
                    if (r[l][0] < u && r[l][1] > u && (s[l] = !1),
                    r[l][0] < u && r[l][1] < u) {
                        s[c] = !1;
                        break
                    }
                    if (r[c][0] > r[l][0] && r[c][0] < r[l][1] && u > r[l][1]) {
                        s[c] = !1;
                        break
                    }
                }
            else
                for (let f = r[c][0], h = r[c][1], d = c + 1; d < r.length; d++) {
                    if (f > r[d][0] && f < r[d][1] && (s[d] = !1),
                    f <= r[d][0] && f <= r[d][1] && h >= r[d][1]) {
                        s[c] = !1;
                        break
                    }
                    if (h <= r[d][1] && h > r[d][0] && f < r[d][0]) {
                        s[c] = !1;
                        break
                    }
                }
        for (let m = 0; m < s.length; m++)
            !0 === s[m] && n.push(r[m]);
        return n
    }

    /**
     * 填入数据，合并单元格及更改背景色
     * @param {*} e 
     * @param {*} t 
     * @param {*} n 
     * @param {*} col 
     */
    function F(e, t, n, col) {
        debugger
        let i, o, a = [], result = [], content = [];
        t > 0 ? (i = "->",
        o = "-|",
        a = ["#77ce00", "#77ce004d", "#fd9801"]) : (i = "|-",
        o = "<-",
        a = ["#fd9801", "#77ce004d", "#77ce00"]);
        instance.api.insertRow(n + 1)
        if(e.length === 0){
            instance.api.setRangeValue([['']], { range: { column: [col, col], row: [n + 1, n + 1] } })
        }
        for (let s = 0; s < e.length; s++) {
            // instance.api.setRangeValue([data], { range: { column: [colIndex[0]-1, colIndex[0] + data.length - 2], row: [rowIndex + 1, rowIndex + 1] } })
            instance.api.setRangeFormat("bg", a[0], { range: { column: [col+e[s][0], col+e[s][0]+2], row: [n + 1, n + 1] } })
            instance.api.setRangeFormat("bg", a[2], { range: { column: [col+e[s][1], col+e[s][1]+2], row: [n + 1, n + 1] } })
            instance.api.setRangeFormat("bg", a[1], { range: { column: [col+e[s][0]+3, col+e[s][1]-1], row: [n + 1, n + 1] } })
            instance.api.setRangeValue([[i]], { range: { column: [col+e[s][0]+1, col+e[s][0]+1], row: [n + 1, n + 1] } })
            instance.api.setRangeValue([[o]], { range: { column: [col+e[s][1]+1, col+e[s][1]+1], row: [n + 1, n + 1] } })
        }
        for(let m = 0;m < e.length; m++){
            instance.api.cancelRangeMerge({ range: { column:[col+e[m][0], col+e[m][0]+2], row: [n + 1, n + 1]} })
            instance.api.cancelRangeMerge({ range: { column:[col+e[m][1], col+e[m][1]+2], row: [n + 1, n + 1]} })
            instance.api.setRangeMerge("all",{ range: { column:[col+e[m][0], col+e[m][0]+2], row: [n + 1, n + 1]} })
            instance.api.setRangeMerge("all",{ range: { column:[col+e[m][1], col+e[m][1]+2], row: [n + 1, n + 1]} })
        }
    }   
  } 
}
export default calculateORFDialog;