import formula from '../global/formula';
import editor from '../global/editor';
import {luckysheetupdateCell} from './updateCell';
import { modelHTML } from './constant';
import { replaceHtml } from '../utils/util';
import locale from '../locale/locale';
import currentSheet from './common/currentSheet';
import multiInstanceStore from '../store/multiInstance';
import createHtmlDom from './common/createHtmlDom';

//if公式生成器
const ifFormulaGenerator = {
    singleRangeFocus: false,
    init: function(container){
        let _this = this;
        const instance = _this.instance;
        const _locale = instance.locale();
        const locale_formula = _locale.formula;
        const locale_button = _locale.button;
        const containerId = instance.getInstanceId();
	    let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);
        //点击选择单元格
        currentInstance.off(`focus.IFcompareValue-${containerId}`).on(`focus.IFcompareValue-${containerId}`, `#luckysheet-ifFormulaGenerator-dialo #compareValue`, function(){
        // $(document).off(`focus.IFcompareValue-${containerId}`).on(`focus.IFcompareValue-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-dialo #compareValue`, function(){
            $(`#${containerId} #luckysheet-modal-dialog-mask`).hide();
            _this.singleRangeFocus = true;
        });
        currentInstance.off(`click.IFsingRange-${containerId}`).on(`click.IFsingRange-${containerId}`, `#luckysheet-ifFormulaGenerator-dialog .singRange`, function(){
        // $(document).off(`click.IFsingRange-${containerId}`).on(`click.IFsingRange-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-dialog .singRange`, function(){
            let value = $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog #compareValue`).val().trim();

            if(instance.formula.iscelldata(value)){
                _this.singleRangeDialog(value, containerId);
            }
            else{
                _this.singleRangeDialog('', containerId);
            }
        });
        currentInstance.off(`click.IFsingRangeConfirm-${containerId}`).on(`click.IFsingRangeConfirm-${containerId}`, `#luckysheet-ifFormulaGenerator-singleRange-dialog-confirm`, function(){
        // $(document).off(`click.IFsingRangeConfirm-${containerId}`).on(`click.IFsingRangeConfirm-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-singleRange-dialog-confirm`, function(){
            currentInstance.find("#luckysheet-formula-functionrange-select").hide();

            $(`#${containerId} #luckysheet-ifFormulaGenerator-singleRange-dialog`).hide();
            $(`#${containerId} #luckysheet-modal-dialog-mask`).show();
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).show();

            let value = $(this).parents(`#${containerId} #luckysheet-ifFormulaGenerator-singleRange-dialog`).find("input").val().trim();
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog #compareValue`).val(value);

            _this.singleRangeFocus = false;                 
        });
        currentInstance.off(`click.IFsingRangeCancel-${containerId}`).on(`click.IFsingRangeCancel-${containerId}`, `#luckysheet-ifFormulaGenerator-singleRange-dialog-close`, function(){
        // $(document).off(`click.IFsingRangeCancel-${containerId}`).on(`click.IFsingRangeCancel-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-singleRange-dialog-close`, function(){
            currentInstance.find("#luckysheet-formula-functionrange-select").hide();

            $(`#${containerId} #luckysheet-ifFormulaGenerator-singleRange-dialog`).hide();
            $(`#${containerId} #luckysheet-modal-dialog-mask`).show();
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).show();

            _this.singleRangeFocus = false;                 
        });
        currentInstance.off(`click.IFsingRangeClose-${containerId}`).on(`click.IFsingRangeClose-${containerId}`, `#luckysheet-ifFormulaGenerator-singleRange-dialog .luckysheet-modal-dialog-title-close`, function(){
        // $(document).off(`click.IFsingRangeClose-${containerId}`).on(`click.IFsingRangeClose-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-singleRange-dialog .luckysheet-modal-dialog-title-close`, function(){
            currentInstance.find("#luckysheet-formula-functionrange-select").hide();

            $(`#${containerId} #luckysheet-modal-dialog-mask`).show();
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).show();

            _this.singleRangeFocus = false;
        });

        //点击选择范围
        currentInstance.off(`click.IFmultiRange-${containerId}`).on(`click.IFmultiRange-${containerId}`, `#luckysheet-ifFormulaGenerator-dialog .multiRange`, function(){
        // $(document).off(`click.IFmultiRange-${containerId}`).on(`click.IFmultiRange-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-dialog .multiRange`, function(){
            _this.multiRangeDialog(containerId);

            _this.singleRangeFocus = false;
        });
        currentInstance.off(`click.IFmultiRangeConfirm-${containerId}`).on(`click.IFmultiRangeConfirm-${containerId}`, `#luckysheet-ifFormulaGenerator-multiRange-dialog-confirm`, function(){
        // $(document).off(`click.IFmultiRangeConfirm-${containerId}`).on(`click.IFmultiRangeConfirm-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-multiRange-dialog-confirm`, function(){
            currentInstance.find("#luckysheet-formula-functionrange-select").hide();
            currentInstance.find("#luckysheet-row-count-show").hide();
            currentInstance.find("#luckysheet-column-count-show").hide();

            $(`#${containerId} #luckysheet-ifFormulaGenerator-multiRange-dialog`).hide();
            $(`#${containerId} #luckysheet-modal-dialog-mask`).show();
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).show();

            let value = $(this).parents(`#${containerId} #luckysheet-ifFormulaGenerator-multiRange-dialog`).find("input").val().trim();
            let cellrange = instance.formula.getcellrange(value);
            let str_r = cellrange["row"][0], 
                end_r = cellrange["row"][1], 
                str_c = cellrange["column"][0], 
                end_c = cellrange["column"][1];
            let d = instance.editor.deepCopyFlowData(Store.flowdata);//取数据
            let arr = [];
            
            //获取范围内所有数值
            for(let r = str_r; r <= end_r; r++){
                for(let c = str_c; c <= end_c; c++){
                    if(d[r] != null && d[r][c] != null && d[r][c]["ct"] != null && d[r][c]["ct"]["t"] == "n"){
                        arr.push(d[r][c]["v"]);
                    }
                }
            }

            //从大到小排序
            for(let j = 0; j < arr.length; j++){
                for(let k = 0; k < arr.length - 1 - j; k++){
                    if(arr[k] < arr[k + 1]){
                        let temp = arr[k];
                        arr[k] = arr[k + 1];
                        arr[k + 1] = temp;
                    }
                }
            }

            let largeNum = arr[0];
            let smallNum = arr[arr.length - 1];
            
            //赋值
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog #smallRange`).val(smallNum);
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog #largeRange`).val(largeNum);
        });
        currentInstance.off(`click.IFmultiRangeCancel-${containerId}`).on(`click.IFmultiRangeCancel-${containerId}`, `#luckysheet-ifFormulaGenerator-multiRange-dialog-close`, function(){
        // $(document).off(`click.IFmultiRangeCancel-${containerId}`).on(`click.IFmultiRangeCancel-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-multiRange-dialog-close`, function(){
            currentInstance.find("#luckysheet-formula-functionrange-select").hide();
            currentInstance.find("#luckysheet-row-count-show").hide();
            currentInstance.find("#luckysheet-column-count-show").hide();

            $(`#${containerId} #luckysheet-ifFormulaGenerator-multiRange-dialog`).hide();
            $(`#${containerId} #luckysheet-modal-dialog-mask`).show();
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).show();
        });
        currentInstance.off(`click.IFmultiRangeClose-${containerId}`).on(`click.IFmultiRangeClose-${containerId}`, `#luckysheet-ifFormulaGenerator-multiRange-dialog .luckysheet-modal-dialog-title-close`, function(){
        // $(document).off(`click.IFmultiRangeClose-${containerId}`).on(`click.IFmultiRangeClose-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-multiRange-dialog .luckysheet-modal-dialog-title-close`, function(){
            currentInstance.find("#luckysheet-formula-functionrange-select").hide();
            currentInstance.find("#luckysheet-row-count-show").hide();
            currentInstance.find("#luckysheet-column-count-show").hide();

            $(`#${containerId} #luckysheet-modal-dialog-mask`).show();
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).show();
        });

        //选择 划分方式
        currentInstance.off(`change.DivisionMethod-${containerId}`).on(`change.DivisionMethod-${containerId}`, `#DivisionMethod`, function(){
        // $(document).off(`change.DivisionMethod-${containerId}`).on(`change.DivisionMethod-${containerId}`, `#${containerId} #DivisionMethod`, function(){
            let value = $(this).find("option:selected").val();

            if(value == "2"){
                $(`#${containerId} #DivisionMethodVal`).hide();
            }
            else{
                $(`#${containerId} #DivisionMethodVal`).show();   
            }

            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog .ifList`).empty();
        });

        //点击 生成 按钮
        currentInstance.off(`click.IFcreateBtn-${containerId}`).on(`click.IFcreateBtn-${containerId}`, `#luckysheet-ifFormulaGenerator-dialog #createBtn`, function(){
        // $(document).off(`click.IFcreateBtn-${containerId}`).on(`click.IFcreateBtn-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-dialog #createBtn`, function(){
            let compareValue = $(this).parents(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).find("#compareValue").val().trim();
            if(compareValue == ""){
                _this.info( locale_formula.ifGenTipNotNullValue, containerId);
                return;
            }

            let method = $(this).parents(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).find(`#DivisionMethod option:selected`).val();
            if(method == "2"){
                let itemHtml =  '<div class="item">'+
                                    '<input type="number" class="smallNum formulaInputFocus"/>'+
                                    '<select class="operator">'+
                                        '<option value="0"> <= </option>'+
                                        '<option value="1"> < </option>'+
                                    '</select>'+
                                    '<span class="compareValue">'+ compareValue +'</span>'+
                                    '<select class="operator2">'+
                                        '<option value="0"> <= </option>'+
                                        '<option value="1" selected="selected"> < </option>'+
                                    '</select>'+
                                    '<input type="number" class="largeNum formulaInputFocus"/>'+
                                    '<span>'+ locale_formula.ifGenTipLableTitile+'：</span>'+
                                    '<input type="text" class="markText formulaInputFocus" value="">'+
                                    '<i class="fa fa-remove" aria-hidden="true"></i>'+
                                '</div>';
                $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`+" .ifList").append(itemHtml);                
            }
            else{
                let smallRange = $(this).parents(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).find("#smallRange").val().trim();
                let largeRange = $(this).parents(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).find("#largeRange").val().trim();
                let DivisionMethodVal = $(this).parents(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).find("#DivisionMethodVal").val().trim();
                
                if(smallRange == "" || largeRange == ""){
                    _this.info( locale_formula.ifGenTipRangeNotforNull, containerId);
                    return;
                }
                else if(DivisionMethodVal == ""){
                    _this.info( locale_formula.ifGenTipCutValueNotforNull, containerId);
                    return;
                }

                _this.getIfList(compareValue, smallRange, largeRange, method, DivisionMethodVal, containerId);
            }
        });

        //点击 删除条件
        currentInstance.off(`click.removeFa-${containerId}`).on(`click.removeFa-${containerId}`, `luckysheet-ifFormulaGenerator-dialog .item .fa-remove`, function(){
        // $(document).off(`click.removeFa-${containerId}`).on(`click.removeFa-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-dialog .item .fa-remove`, function(){
            $(this).parents(".item").remove();
        });

        //点击 确认 按钮
        currentInstance.off(`click.IFconfirmBtn-${containerId}`).on(`click.IFconfirmBtn-${containerId}`, `#luckysheet-ifFormulaGenerator-dialog-confirm`, function(){
        // $(document).off(`click.IFconfirmBtn-${containerId}`).on(`click.IFconfirmBtn-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-dialog-confirm`, function(){
            let $item = $(this).parents(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).find(".ifList .item");
            let str = '';

            $($item.toArray().reverse()).each(function(i, e){
                let smallNum = $(e).find(".smallNum").val().trim();
                let largeNum = $(e).find(".largeNum").val().trim();
                let operator = $(e).find(".operator option:selected").val();
                let operator2 = $(e).find(".operator2 option:selected").val();
                let compareValue = $(e).find(".compareValue").text();

                let markText = $(e).find(".markText").val().trim();
                if(markText == ""){
                    markText =  locale_formula.ifGenTipLableTitile + (i + 1);
                }

                if(smallNum == "" && largeNum == ""){
                    return true;
                }

                let s;
                if(operator == "0"){
                    s = compareValue + ">=" + smallNum;
                }
                else{
                    s = compareValue + ">" + smallNum;
                }

                let l;
                if(operator2 == "0"){
                    l = compareValue + "<=" + largeNum;
                }
                else{
                    l = compareValue + "<" + largeNum;
                }

                let a;
                if(i == 0 && largeNum == ""){
                    a = s;
                }
                else if(i == ($item.length - 1) && smallNum == ""){
                    a = l;
                }
                else{
                    a = "and("+s+","+l+")";
                }

                if(i == 0){
                    str = 'if('+ a +',"'+ markText +'")';
                }
                else{
                    str = 'if('+ a +',"'+ markText +'",'+ str +')';
                }
            })
            
            if(str.length == 0){
                _this.info( locale_formula.ifGenTipNotGenCondition, containerId);
                return;
            }

            $(`#${containerId} #luckysheet-modal-dialog-mask`).hide();
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).hide();

            let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];
            let row_index = last["row_focus"], 
                col_index = last["column_focus"];
            
            instance.updateCell.luckysheetupdateCell(row_index, col_index, Store.flowdata);

            currentInstance.find("#luckysheet-rich-text-editor").html("=" + str);
            currentInstance.find("#luckysheet-functionbox-cell").html(currentInstance.find("#luckysheet-rich-text-editor").html());

            currentInstance.find("#luckysheet-wa-functionbox-confirm").click();
        });

        //info
        currentInstance.off(`click.ifFGInfo-close-${containerId}`).on(`click.ifFGInfo-close-${containerId}`, `#luckysheet-ifFormulaGenerator-info .luckysheet-model-close-btn`, function(){
        // $(document).off(`click.ifFGInfo-close-${containerId}`).on(`click.ifFGInfo-close-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-info .luckysheet-model-close-btn`, function(){
            $(`#${containerId} #luckysheet-modal-dialog-mask`).show();
        });
        currentInstance.off(`click.ifFGInfo.title-close-${containerId}`).on(`click.ifFGInfo.title-close-${containerId}`, `#luckysheet-ifFormulaGenerator-info .luckysheet-modal-dialog-title-close`, function(){
        // $(document).off(`click.ifFGInfo.title-close-${containerId}`).on(`click.ifFGInfo.title-close-${containerId}`, `#${containerId} #luckysheet-ifFormulaGenerator-info .luckysheet-modal-dialog-title-close`, function(){
            $(`#${containerId} #luckysheet-modal-dialog-mask`).show();
        });
    },
    ifFormulaDialog: function(fp, containerId){
        let _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
	    let Store = instance.getStore();

        const _locale = instance.locale();
        const locale_formula = _locale.formula;
        const locale_button = _locale.button;

        let compareValue = "";
        let ifListHtml = '';

        if(!!fp){
            let arr = fp.split("if(");

            for(let i = 1; i < arr.length; i++){
                let txt = arr[i].replace("and(","").replace(/\)/g,"").replace(/\"/g,"");
                let arr2 = txt.split(",");
                arr2 = _this.clearArr(arr2);
                
                compareValue = _this.splitTxt(arr2[0])[0];

                let smallNum, largeNum, markText;
                if(arr2.length == 3){
                    smallNum = _this.splitTxt(arr2[0])[1];
                    largeNum = _this.splitTxt(arr2[1])[2];
                    markText = arr2[2];
                }
                else{
                    smallNum = _this.splitTxt(arr2[0])[1];
                    largeNum = _this.splitTxt(arr2[0])[2];
                    markText = arr2[1];
                }

                let itemHtml =  '<div class="item">'+
                                    '<input type="number" class="smallNum formulaInputFocus" value="'+ smallNum +'"/>'+
                                    '<select class="operator">'+
                                        '<option value="0"> <= </option>'+
                                        '<option value="1"> < </option>'+
                                    '</select>'+
                                    '<span class="compareValue">'+ compareValue +'</span>'+
                                    '<select class="operator2">'+
                                        '<option value="0"> <= </option>'+
                                        '<option value="1" selected="selected"> < </option>'+
                                    '</select>'+
                                    '<input type="number" class="largeNum formulaInputFocus" value="'+ largeNum +'"/>'+
                                    '<span>'+locale_formula.ifGenTipLableTitile+'：</span>'+
                                    '<input type="text" class="markText formulaInputFocus" value="'+ markText +'">'+
                                    '<i class="fa fa-remove" aria-hidden="true"></i>'+
                                '</div>'; 
                ifListHtml += itemHtml;                             
            }
        }

        let content = '<div class="ifAttr">'+
                        '<div class="attrBox">'+
                            '<label for="compareValue"> '+  locale_formula.ifGenCompareValueTitle +' </label>'+
                            '<div class="inpBox">'+
                                '<input id="compareValue" class="formulaInputFocus" value="'+ compareValue +'"/>'+
                                '<i class="singRange fa fa-table" aria-hidden="true" title="'+  locale_formula.ifGenSelectCellTitle +'"></i>'+
                            '</div>'+
                        '</div>'+
                        '<div class="attrBox">'+
                            '<label for="smallRange"> '+  locale_formula.ifGenRangeTitle +' </label>'+
                            '<input type="number" id="smallRange" class="formulaInputFocus"/>'+
                            '<span class="text"> '+  locale_formula.ifGenRangeTo +' </span>'+
                            '<input type="number" id="largeRange" class="formulaInputFocus"/>'+
                            '<div id="rangeAssess">'+
                                '<span> '+  locale_formula.ifGenRangeEvaluate +' </span>'+
                                '<i class="multiRange fa fa-table" aria-hidden="true" title="'+  locale_formula.ifGenSelectRangeTitle +'"></i>'+
                            '</div>'+
                        '</div>'+
                        '<div class="attrBox">'+
                            '<label for="DivisionMethod"> '+  locale_formula.ifGenCutWay +' </label>'+
                            '<select id="DivisionMethod">'+
                                '<option value="0"> '+  locale_formula.ifGenCutSame +' </option>'+
                                '<option value="1"> '+  locale_formula.ifGenCutNpiece +' </option>'+
                                '<option value="2"> '+  locale_formula.ifGenCutCustom +' </option>'+
                            '</select>'+
                            '<input id="DivisionMethodVal" class="formulaInputFocus"/>'+
                            '<div id="createBtn"> '+  locale_formula.ifGenCutSame +' </div>'+
                        '</div>'+
                      '</div>'+
                      '<div class="ifList">'+ifListHtml+'</div>';

        instance.createHtmlDom.createDialog('luckysheet-ifFormulaGenerator-dialog', content, containerId,  locale_formula.ifGenerate, () => {}, true, true, true, '', '');
    },
    clearArr: function(arr){
        for(let i = 0; i < arr.length; i++){
            if(arr[i] == "" || arr[i] == null || arr[i] == undefined){
                arr.splice(i, 1);
            }
        }

        return arr;
    },
    splitTxt: function(txt){
        let compareValue, smallNum, largeNum;
        
        if(txt.indexOf(">=") != -1){
            compareValue = txt.split(">=")[0];
            smallNum = txt.split(">=")[1];

            return [compareValue, smallNum, largeNum];
        }
        else if(txt.indexOf(">") != -1){
            compareValue = txt.split(">")[0];
            smallNum = txt.split(">")[1];

            return [compareValue, smallNum, largeNum];
        }
        else if(txt.indexOf("<=") != -1){
            compareValue = txt.split("<=")[0];
            largeNum = txt.split("<=")[1];

            return [compareValue, smallNum, largeNum];
        }
        else if(txt.indexOf("<") != -1){
            compareValue = txt.split("<")[0];
            largeNum = txt.split("<")[1];

            return [compareValue, smallNum, largeNum];
        }
    },
    singleRangeDialog: function(value, containerId){
        let _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
	    let Store = instance.getStore();
        $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).hide();

        const _locale = instance.locale();
        const locale_formula = _locale.formula;
        const locale_button = _locale.button;

        if(value == null){
            value = "";
        }
        let content = '<input readonly="readonly" placeholder="'+ locale_formula.ifGenTipSelectCellPlace+'" value="'+ value +'">';
        instance.createHtmlDom.createDialog('luckysheet-ifFormulaGenerator-singleRange-dialog', content, containerId,  locale_formula.ifGenTipSelectCell, () => {}, false, false, true, '', '');
    },
    multiRangeDialog: function(containerId){
        let _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
	    let Store = instance.getStore();
        $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`).hide();

        const _locale = instance.locale();
        const locale_formula = _locale.formula;
        const locale_button = _locale.button;

        let content = '<input readonly="readonly" placeholder="'+ locale_formula.ifGenTipSelectRangePlace+'" value="">';
        instance.createHtmlDom.createDialog('luckysheet-ifFormulaGenerator-multiRange-dialog', content, containerId,  locale_formula.ifGenTipSelectRange, () => {}, false, false, true, '', '');
    },
    getIfList: function(compareValue, smallRange, largeRange, method, methodVal, containerId){
        let _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
	    let Store = instance.getStore();
        const locale_formula = instance.locale().formula;

        $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`+" .ifList").empty();

        smallRange = parseInt(smallRange);
        largeRange = parseInt(largeRange);
        methodVal = parseInt(methodVal);

        let arr = [];

        if(method == "0"){
            let len = Math.ceil((largeRange - smallRange) / methodVal); 
            for(let i = 0; i <= len; i++){
                let num = smallRange + methodVal * i;
                if(i == 0 || num >= largeRange){
                    arr.push("");
                }
                else{
                    arr.push(num);
                }
            }
            
        }
        else if(method == "1"){
            let addnum = Math.ceil((largeRange - smallRange) / methodVal);
            for(let i = 0; i <= methodVal; i++){
                let num = smallRange + addnum * i;
                if(i == 0 || num >= largeRange){
                    arr.push("");
                }
                else{
                    arr.push(num);
                }
            }
        }
        for(let j = 0; j < arr.length - 1; j++){
            let markText;
            if(j == 0){
                markText = "小于" + arr[j + 1];
            }
            else if(j == arr.length - 2){
                markText = "大于等于" + arr[j];
            }
            else{
                markText = arr[j] + "到" + arr[j + 1];
            }

            let itemHtml =  '<div class="item">'+
                                '<input type="number" class="smallNum formulaInputFocus" value="'+ arr[j] +'"/>'+
                                '<select class="operator">'+
                                    '<option value="0"> <= </option>'+
                                    '<option value="1"> < </option>'+
                                '</select>'+
                                '<span class="compareValue">'+ compareValue +'</span>'+
                                '<select class="operator2">'+
                                    '<option value="0"> <= </option>'+
                                    '<option value="1" selected="selected"> < </option>'+
                                '</select>'+
                                '<input type="number" class="largeNum formulaInputFocus" value="'+ arr[j + 1] +'"/>'+
                                '<span>'+ locale_formula.ifGenTipLableTitile+'：</span>'+
                                '<input type="text" class="markText formulaInputFocus" value="'+ markText +'">'+
                                '<i class="fa fa-remove" aria-hidden="true"></i>'+
                            '</div>';
            $(`#${containerId} #luckysheet-ifFormulaGenerator-dialog`+" .ifList").append(itemHtml);
        }
    },
    info: function(title, containerId){
        let _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
	    let Store = instance.getStore();

        const _locale = instance.locale();
        const locale_button = _locale.button;

        instance.createHtmlDom.createDialog('luckysheet-ifFormulaGenerator-info', '', containerId, title, () => {}, false, true, false, '', '');
    }
}

export default ifFormulaGenerator;