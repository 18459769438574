import defaultSetting from "./config.js";
import { common_extend } from "./utils/util";
import multiInstanceStore from "./store/multiInstance"; // 存储多实例Store wangxiaofei9.18
import { locales } from "./locale/locale";
import server from "./controllers/server";
import luckysheetConfigsetting from "./controllers/luckysheetConfigsetting";
import sheetmanage from "./controllers/sheetmanage";
import luckysheetsizeauto from "./controllers/resize";
import luckysheetHandler from "./controllers/handler";
import { initialFilterHandler } from "./controllers/filter";
import { initialMatrixOperation } from "./controllers/matrixOperation";
import { initialSheetBar } from "./controllers/sheetBar";
import { formulaBarInitial } from "./controllers/formulaBar";
import { rowColumnOperationInitial } from "./controllers/rowColumnOperation";
import { keyboardInitial } from "./controllers/keyboard";
import { orderByInitial } from "./controllers/orderBy";
import { initPlugins } from "./controllers/expendPlugins";
import { getluckysheetfile, getluckysheet_select_save, getconfig, getConditionFormatCells } from "./methods/get";
import { setluckysheet_select_save } from "./methods/set";
import { luckysheetrefreshgrid, jfrefreshgrid } from "./global/refresh";
import functionlist from "./function/functionlist";
import { luckysheetlodingHTML } from "./controllers/constant";
import { getcellvalue, getdatabyselection } from "./global/getdata";
import { setcellvalue } from "./global/setdata";
import { selectHightlightShow } from "./controllers/select";
import { zoomInitial } from "./controllers/zoom";
// import { printInitial } from "./controllers/print";
import method from "./global/method";
import luckysheetReadOrEdit from './controllers/luckysheetReadOrEdit';

import * as api from "./global/api";

import flatpickr from "flatpickr";
import Mandarin from "flatpickr/dist/l10n/zh.js";
import { initListener } from "./controllers/listener";
import { hideloading, showloading } from "./global/loading.js";
import { luckysheetextendData } from "./global/extend.js";
import { initChat } from './demoData/chat.js'
import currentSheet from "./controllers/common/currentSheet.js";
import createInit from "./controllers/init/createInit.js";

let luckysheet = {};
// mount api
// luckysheet.api = api;
// Object.assign(luckysheet, api);
//创建luckysheet表格
luckysheet.create = function (setting) {
    // 若创建的luckysheet存在先销毁
    if (luckysheet[setting.container]) {
        luckysheet[setting.container].destroy();
    }
    const newInstance = new createInit(setting);
    newInstance.getInstanceId()
    // Store original parameters for api: toJson
    let toJsonOptions = {};
    for (let c in setting) {
        if (c !== "data") {
            toJsonOptions[c] = setting[c];
        }
    }
    newInstance.setStoreKey('toJsonOptions', toJsonOptions);
    let extendsetting = common_extend(defaultSetting, setting);
    
    let loadurl = extendsetting.loadUrl,
        menu = extendsetting.menu,
        title = extendsetting.title;

    newInstance.server.gridKey = extendsetting.gridKey;
    newInstance.server.loadUrl = extendsetting.loadUrl;
    newInstance.server.updateUrl = extendsetting.updateUrl;
    newInstance.server.updateImageUrl = extendsetting.updateImageUrl;
    newInstance.server.title = extendsetting.title;
    newInstance.server.loadSheetUrl = extendsetting.loadSheetUrl;
    newInstance.server.allowUpdate = extendsetting.allowUpdate;

    newInstance.luckysheetConfigsetting.autoFormatw = extendsetting.autoFormatw;
    newInstance.luckysheetConfigsetting.accuracy = extendsetting.accuracy;
    newInstance.luckysheetConfigsetting.total = extendsetting.data[0].total;

    newInstance.luckysheetConfigsetting.loading = extendsetting.loading;
    newInstance.luckysheetConfigsetting.allowCopy = extendsetting.allowCopy;
    newInstance.luckysheetConfigsetting.showtoolbar = extendsetting.showtoolbar;
    newInstance.luckysheetConfigsetting.showtoolbarConfig = extendsetting.showtoolbarConfig;
    newInstance.luckysheetConfigsetting.showinfobar = extendsetting.showinfobar;
    newInstance.luckysheetConfigsetting.showsheetbar = extendsetting.showsheetbar;
    newInstance.luckysheetConfigsetting.showsheetbarConfig = extendsetting.showsheetbarConfig;
    newInstance.luckysheetConfigsetting.showstatisticBar = extendsetting.showstatisticBar;
    newInstance.luckysheetConfigsetting.showstatisticBarConfig = extendsetting.showstatisticBarConfig;
    newInstance.luckysheetConfigsetting.sheetFormulaBar = extendsetting.sheetFormulaBar;
    newInstance.luckysheetConfigsetting.cellRightClickConfig = extendsetting.cellRightClickConfig;
    newInstance.luckysheetConfigsetting.sheetRightClickConfig = extendsetting.sheetRightClickConfig;
    newInstance.luckysheetConfigsetting.pointEdit = extendsetting.pointEdit;
    newInstance.luckysheetConfigsetting.pointEditUpdate = extendsetting.pointEditUpdate;
    newInstance.luckysheetConfigsetting.pointEditZoom = extendsetting.pointEditZoom;

    newInstance.luckysheetConfigsetting.userInfo = extendsetting.userInfo;
    newInstance.luckysheetConfigsetting.userMenuItem = extendsetting.userMenuItem;
    newInstance.luckysheetConfigsetting.myFolderUrl = extendsetting.myFolderUrl;
    newInstance.luckysheetConfigsetting.functionButton = extendsetting.functionButton;

    newInstance.luckysheetConfigsetting.showConfigWindowResize = extendsetting.showConfigWindowResize;
    newInstance.luckysheetConfigsetting.enableAddRow = extendsetting.enableAddRow;
    newInstance.luckysheetConfigsetting.enableAddBackTop = extendsetting.enableAddBackTop;
    newInstance.luckysheetConfigsetting.addRowCount = extendsetting.addRowCount;
    newInstance.luckysheetConfigsetting.enablePage = extendsetting.enablePage;
    newInstance.luckysheetConfigsetting.pageInfo = extendsetting.pageInfo;

    newInstance.luckysheetConfigsetting.editMode = extendsetting.editMode;
    newInstance.luckysheetConfigsetting.beforeCreateDom = extendsetting.beforeCreateDom;
    newInstance.luckysheetConfigsetting.workbookCreateBefore = extendsetting.workbookCreateBefore;
    newInstance.luckysheetConfigsetting.workbookCreateAfter = extendsetting.workbookCreateAfter;
    newInstance.luckysheetConfigsetting.remoteFunction = extendsetting.remoteFunction;
    newInstance.luckysheetConfigsetting.customFunctions = extendsetting.customFunctions;

    newInstance.luckysheetConfigsetting.fireMousedown = extendsetting.fireMousedown;
    newInstance.luckysheetConfigsetting.forceCalculation = extendsetting.forceCalculation;
    newInstance.luckysheetConfigsetting.plugins = extendsetting.plugins;

    newInstance.luckysheetConfigsetting.rowHeaderWidth = extendsetting.rowHeaderWidth;
    newInstance.luckysheetConfigsetting.columnHeaderHeight = extendsetting.columnHeaderHeight;

    newInstance.luckysheetConfigsetting.defaultColWidth = extendsetting.defaultColWidth;
    newInstance.luckysheetConfigsetting.defaultRowHeight = extendsetting.defaultRowHeight;

    newInstance.luckysheetConfigsetting.title = extendsetting.title;
    newInstance.luckysheetConfigsetting.container = extendsetting.container;
    newInstance.luckysheetConfigsetting.hook = extendsetting.hook;

    newInstance.luckysheetConfigsetting.pager = extendsetting.pager;

    newInstance.luckysheetConfigsetting.initShowsheetbarConfig = false;

    newInstance.luckysheetConfigsetting.imageUpdateMethodConfig = extendsetting.imageUpdateMethodConfig;

    if (setting.lang === "zh") flatpickr.localize(Mandarin.zh);

    // Store the currently used plugins for monitoring asynchronous loading
    newInstance.addStoreKey('asyncLoad',newInstance.luckysheetConfigsetting.plugins);

    // Register plugins
    newInstance.expendPlugins.initPlugins(extendsetting.plugins, extendsetting);
    // newInstance.setStoreKey('plugins', extendsetting.plugins);

    // Store formula information, including internationalization,调整方法调用，为设定函数内作用域20230926
    newInstance.functionlist.setCustomFunctions(extendsetting.customFunctions);

    let devicePixelRatio = extendsetting.devicePixelRatio;
    if (devicePixelRatio == null) {
        devicePixelRatio = 1;
    }
    newInstance.setStoreKey('devicePixelRatio', Math.ceil(devicePixelRatio));

    if (loadurl == "") {
        newInstance.sheetmanage.initialjfFile(menu, title, extendsetting);
        initialWorkBook(newInstance);
    } else {
        $.post(loadurl, { gridKey: newInstance.server.gridKey }, function (d) {
            let data = new Function("return " + d)();
            newInstance.setStoreKey('luckysheetfile', data);

            newInstance.sheetmanage.initialjfFile(menu, title, extendsetting);
            initialWorkBook(newInstance);

            //需要更新数据给后台时，建立WebSocket连接
            if (newInstance.server.allowUpdate) {
                newInstance.server.openWebSocket();
            }
        });
    }

    newInstance.chat.initChat();
    newInstance.luckysheetReadOrEdit.switchReadOrEdit(extendsetting.editMode);
   
    // 将当前luckysheet实例存储到luckysheet中 --modify by wangxiaofei 2023.10.8
    let currentsheet = initFunctionSheet(newInstance);
    luckysheet[extendsetting.container] = currentsheet;
    // 定时器监听luckysheet容器大小改变
    newInstance.domResize.domResizeListener();
};

/**
 * 初始化sheet页
 */
function initialWorkBook( instance ) {
    instance.initSheet.luckysheetHandler(); //Overall dom initialization
    instance.filter.initialFilterHandler(); //Filter initialization
    instance.initSheet.initialMatrixOperation(); //Right click matrix initialization
    instance.initSheet.initialSheetBar(); //bottom sheet bar initialization
    instance.initSheet.formulaBarInitial(); //top formula bar initialization
    instance.initSheet.rowColumnOperationInitial(); //row and coloumn operate initialization
    instance.initSheet.keyboardInitial(); //Keyboard operate initialization
    instance.initSheet.orderByInitial(); //menu bar orderby function initialization
    instance.initSheet.zoomInitial(); //zoom method initialization
    // printInitial(); //print initialization
    instance.initSheet.initListener();
}

 /**
  * luckysheet初始化方法赋值
  * @param {*} instance 当前实例
  * @returns api调用方法
  */
function initFunctionSheet(instance ){
    let currentsheet = {};
    let Store = instance.getStore();
    currentsheet.instance = instance;
    currentsheet = common_extend(instance.api, luckysheet);
    //获取所有表格数据
    currentsheet.getluckysheetfile = instance.get.getluckysheetfile;

    //获取当前表格 选区
    currentsheet.getluckysheet_select_save = instance.get.getluckysheet_select_save;

    //设置当前表格 选区
    currentsheet.setluckysheet_select_save = instance.set.setluckysheet_select_save;

    //获取当前表格 config配置
    currentsheet.getconfig = instance.get.getconfig;

    //二维数组数据 转化成 {r, c, v}格式 一维数组 (传入参数为二维数据data)
    currentsheet.getGridData = instance.sheetmanage.getGridData;

    //生成表格所需二维数组 （传入参数为表格数据对象file）
    currentsheet.buildGridData = instance.sheetmanage.buildGridData;

    // Refresh the canvas display data according to scrollHeight and scrollWidth
    currentsheet.luckysheetrefreshgrid = instance.refresh.luckysheetrefreshgrid;

    // Refresh canvas
    currentsheet.jfrefreshgrid = instance.refresh.jfrefreshgrid;

    // Get the value of the cell
    currentsheet.getcellvalue = instance.getdata.getcellvalue;

    // Set cell value
    currentsheet.setcellvalue = instance.setdata.setcellvalue;

    // Get selection range value
    currentsheet.getdatabyselection = instance.getdata.getdatabyselection;

    currentsheet.sheetmanage = instance.sheetmanage;

    // Data of the current table
    currentsheet.flowdata = function () {
        let Store = instance.getStore();
        return Store.flowdata;
    };

    // Set selection highlight
    currentsheet.selectHightlightShow = instance.select.selectHightlightShow;

    // Reset parameters after destroying the table
    currentsheet.destroy = instance.method.destroy;

    currentsheet.showLoadingProgress = instance.loading.showloading;
    currentsheet.hideLoadingProgress = instance.loading.hideloading;
    currentsheet.luckysheetextendData = instance.extend.luckysheetextendData;

    currentsheet.locales = locales;

    // 获取条件格式渲染的单元格数量
    currentsheet.getConditionFormatCells = instance.get.getConditionFormatCells;
    return currentsheet;
}
export { luckysheet };
