import { luckysheet_searcharray } from '../controllers/sheetSearch';

function rowLocationByIndex(row_index, container) {
    const instance = this.instance;
	let Store = instance.getStore();
    const containerId = container || instance.getInstanceId();
    let row = 0, row_pre = 0;
    row = Store.visibledatarow[row_index];

    if (row_index == 0) {
        row_pre = 0;
    }
    else {
        row_pre = Store.visibledatarow[row_index - 1];
    }

    return [row_pre, row, row_index];
}

function rowLocation(y) {
    const instance = this.instance;
    let Store = instance.getStore();
    let row_index = luckysheet_searcharray(Store.visibledatarow, y);

    if (row_index == -1 && y > 0) {
        row_index = Store.visibledatarow.length - 1;
    }
    else if (row_index == -1 && y <= 0) {
        row_index = 0;
    }

    return this.rowLocationByIndex(row_index);
}

function colLocationByIndex(col_index, container){
    const instance = this.instance;
	let Store = instance.getStore();
    const containerId = container || instance.getInstanceId();
    let col = 0, col_pre = 0;
    col = Store.visibledatacolumn[col_index];

    if (col_index == 0) {
        col_pre = 0;
    }
    else {
        col_pre = Store.visibledatacolumn[col_index - 1];
    }

    return [col_pre, col, col_index];
}

function colSpanLocationByIndex(col_index, span){
    const instance = this.instance;
	let Store = instance.getStore();
    let col = 0, col_pre = 0;
    col = Store.visibledatacolumn[col_index + span - 1];

    if (col_index == 0) {
        col_pre = 0;
    }
    else {
        col_pre = Store.visibledatacolumn[col_index - 1];
    }

    return [col_pre, col, col_index];
}

function colLocation(x) {
    const instance = this.instance;
	let Store = instance.getStore();
    let col_index = luckysheet_searcharray(Store.visibledatacolumn, x);

    if (col_index == -1 && x > 0) {
        col_index = Store.visibledatacolumn.length - 1;
    }
    else if (col_index == -1 && x <= 0) {
        col_index = 0;
    }

    return this.colLocationByIndex(col_index);
}

function mouseposition(x, y) {
    const instance = this.instance;
	let Store = instance.getStore();
    let scale = Store.scale;
    let container_offset = $("#" + Store.container).offset();
    let newX = x/scale - container_offset.left/scale - Store.rowHeaderWidth,
        newY = y/scale - container_offset.top/scale - Store.infobarHeight - Store.toolbarHeight - Store.calculatebarHeight - Store.columnHeaderHeight;
    return [newX, newY];
}

export {
    rowLocationByIndex,
    rowLocation,
    colLocationByIndex,
    colSpanLocationByIndex,
    colLocation,
    mouseposition,
}
