import { luckysheet_getcelldata } from '../function/func';
// import functionlist from '../function/functionlist';
// import Store.luckysheet_function from '../function/Store.luckysheet_function';
import formula from '../global/formula';
import { isRealNum, isRealNull } from '../global/validate';
import { modelHTML } from './constant';
import { luckysheet_count_show } from './select';
import { replaceHtml, getObjType } from '../utils/util';
import locale from '../locale/locale';
import currentSheet from './common/currentSheet';
import createHtmlDom from './common/createHtmlDom';

//插入函数
const insertFormula = {
    init: function(container){
        const _this = this;
        const instance = _this.instance;
        const containerId = instance.getInstanceId();
        let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);
        let _locale = instance.locale();
        let locale_formulaMore = _locale.formulaMore;
        let locale_button = _locale.button;

        currentInstance.off(`keyup.fxSFLI-${containerId}`).on(`keyup.fxSFLI-${containerId}`, `#searchFormulaListInput`, function(){
        // $(document).off(`keyup.fxSFLI-${containerId}`).on(`keyup.fxSFLI-${containerId}`, `#${containerId} #searchFormulaListInput`, function(){
            $(`#${containerId} #formulaTypeList`).empty();
            let txt = $(this).val().toUpperCase();
            let functionlist = Store.functionlist;

            if(txt == ""){
                //若没有查找内容则根据类别筛选
                _this.formulaListByType($(`#${containerId} #formulaTypeSelect option:selected`).val(), containerId);
            }
            else{
                for(let i = 0; i < functionlist.length; i++){
                    if(/^[a-zA-Z]+$/.test(txt)){
                        if(functionlist[i].n.indexOf(txt) != "-1"){
                            $('<div class="listBox" name="'+ functionlist[i].n +'"><span>'+ functionlist[i].n +'</span><span>'+ functionlist[i].a +'</span></div>').appendTo($(`#${containerId} #formulaTypeList`));
                        }
                    }
                    else if(functionlist[i].a.indexOf(txt) != "-1"){
                        $('<div class="listBox" name="'+ functionlist[i].n +'"><span>'+ functionlist[i].n +'</span><span>'+ functionlist[i].a +'</span></div>').appendTo($(`#${containerId} #formulaTypeList`));
                    }
                }
            }
            
            $(`#${containerId} #formulaTypeList .listBox:first-child`).addClass("on"); //默认公式列表第一个为选中状态
        });

        currentInstance.off(`change.fxFormulaTS-${containerId}`).on(`change.fxFormulaTS-${containerId}`, `#formulaTypeSelect`, function(){
        // $(document).off(`change.fxFormulaTS-${containerId}`).on(`change.fxFormulaTS-${containerId}`, `#${containerId} #formulaTypeSelect`, function(){
            let type = $(`#${containerId} #formulaTypeSelect option:selected`).val();
            _this.formulaListByType(type, containerId);
        });

        currentInstance.off(`click.fxListbox-${containerId}`).on(`click.fxListbox-${containerId}`, `#formulaTypeList .listBox`, function(){
        // $(document).off(`click.fxListbox-${containerId}`).on(`click.fxListbox-${containerId}`, `#${containerId} #formulaTypeList .listBox`, function(){
            $(this).addClass("on").siblings().removeClass("on");
        });

        //选择公式后弹出参数栏弹框
        currentInstance.off(`click.fxFormulaCf-${containerId}`).on(`click.fxFormulaCf-${containerId}`, `#luckysheet-search-formula-confirm`, function(){
        // $(document).off(`click.fxFormulaCf-${containerId}`).on(`click.fxFormulaCf-${containerId}`, `#${containerId} #luckysheet-search-formula-confirm`, function(){
            let formula = $(`#${containerId} #luckysheet-search-formula .listBox.on`).attr("name");
            let formulaTxt = '<span dir="auto" class="luckysheet-formula-text-color">=</span><span dir="auto" class="luckysheet-formula-text-color">'+ formula.toUpperCase() +'</span><span dir="auto" class="luckysheet-formula-text-color">(</span><span dir="auto" class="luckysheet-formula-text-color">)</span>';
            
            currentInstance.find("#luckysheet-rich-text-editor").html(formulaTxt);
            currentInstance.find("#luckysheet-functionbox-cell").html(currentInstance.find("#luckysheet-rich-text-editor").html());

            _this.formulaParmDialog(formula, null, containerId);
        });

        //公式参数框
        currentInstance.off(`focus.fxParamInput-${containerId}`).on(`focus.fxParamInput-${containerId}`, `#luckysheet-search-formula-parm .parmBox input`, function(){
        // $(document).off(`focus.fxParamInput-${containerId}`).on(`focus.fxParamInput-${containerId}`, `#${containerId} #luckysheet-search-formula-parm .parmBox input`, function(){
            let parmIndex = $(this).parents(".parmBox").index();
            instance.formula.data_parm_index = parmIndex;

            let formulatxt = $(this).parents(`#${containerId} #luckysheet-search-formula-parm`).find(".luckysheet-modal-dialog-title-text").text();
            let parmLen = Store.luckysheet_function[formulatxt].p.length;

            let parmDetail, parmRepeat;
            if(parmIndex >= parmLen){
                parmDetail = Store.luckysheet_function[formulatxt].p[parmLen - 1].detail;
                parmRepeat = Store.luckysheet_function[formulatxt].p[parmLen - 1].repeat;
            }
            else{
                parmDetail = Store.luckysheet_function[formulatxt].p[parmIndex].detail;
                parmRepeat = Store.luckysheet_function[formulatxt].p[parmIndex].repeat;
            }

            //参数选区显示，参数值显示
            _this.parmTxtShow($(this).val(), containerId);
            
            //计算结果
            _this.functionStrCompute();
            
            //参数名称和释义切换
            $(`#${containerId} #luckysheet-search-formula-parm .parmDetailsBox`).empty();

            let parmName = $(this).parents(".parmBox").find(".name").text();
            $('<span>'+ parmName +':</span><span>'+ parmDetail +'</span>').appendTo($(`#${containerId} #luckysheet-search-formula-parm .parmDetailsBox`));
            
            //公式参数可自增（参数自增最多5个）
            if(parmRepeat == "y"){
                let parmCount = $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).length;

                if(parmCount < 5 && parmIndex == (parmCount - 1)){
                    $('<div class="parmBox"><div class="name">'+ locale_formulaMore.valueTitle +''+ (parmCount + 1) +'</div><div class="txt"><input class="formulaInputFocus" /><i class="fa fa-table" aria-hidden="true" title="'+locale_formulaMore.tipSelectDataRange+'"></i></div><div class="val">=</div></div>').appendTo($(`#${containerId} #luckysheet-search-formula-parm .parmListBox`));
                }
            }
        });

        currentInstance.off(`blur.fxParamInput-${containerId}`).on(`blur.fxParamInput-${containerId}`, `#luckysheet-search-formula-parm .parmBox input`, function(){
        // $(document).off(`blur.fxParamInput-${containerId}`).on(`blur.fxParamInput-${containerId}`, `#${containerId} #luckysheet-search-formula-parm .parmBox input`, function(){
            let txt = $(this).val();

            if(instance.formula.getfunctionParam(txt).fn == null && !instance.formula.iscelldata(txt)){
                if(!isRealNum(txt) && txt != "" && txt.length <= 2 && txt.indexOf('"') != 0 && txt.lastIndexOf('"') != 0){
                    txt = '"' + txt + '"';
                    $(this).val(txt);

                    _this.parmTxtShow(txt, containerId);
                    _this.functionStrCompute();
                }
            }
        });
        
        currentInstance.off(`keyup.fxParamInput-${containerId}`).on(`keyup.fxParamInput-${containerId}`, `#luckysheet-search-formula-parm .parmBox input`, function(){
        // $(document).off(`keyup.fxParamInput-${containerId}`).on(`keyup.fxParamInput-${containerId}`, `#${containerId} #luckysheet-search-formula-parm .parmBox input`, function(){
            //参数选区显示，参数值显示
            _this.parmTxtShow($(this).val(), containerId);

            //计算结果
            _this.functionStrCompute();
        });

        //点击图标选取数据范围
        currentInstance.off(`click.fxParamI-${containerId}`).on(`click.fxParamI-${containerId}`, `#luckysheet-search-formula-parm .parmBox i`, function(){
        // $(document).off(`click.fxParamI-${containerId}`).on(`click.fxParamI-${containerId}`, `#${containerId} #luckysheet-search-formula-parm .parmBox i`, function(){
            instance.formula.data_parm_index = $(this).parents(".parmBox").index();
            
            //选取范围弹出框
            $(`#${containerId} #luckysheet-search-formula-parm`).hide();

            let content = '';
            
            if($(this).parents(".parmBox").find(".txt input").val() == ""){
                content = "<input id='luckysheet-search-formula-parm-select-input' class='luckysheet-datavisual-range-container' style='font-size: 14px;padding:5px;max-width:none;' spellcheck='false' aria-label='"+ locale_formulaMore.tipDataRangeTile +"' readonly='true' placeholder='"+ locale_formulaMore.tipDataRangeTile +"'>";
            }
            else{
                content = "<input id='luckysheet-search-formula-parm-select-input' class='luckysheet-datavisual-range-container' style='font-size: 14px;padding:5px;max-width:none;' spellcheck='false' aria-label='"+ locale_formulaMore.tipDataRangeTile +"' readonly='true' value='"+ $(this).parents(".parmBox").find(".txt input").val() +"'>";
            }
            instance.createHtmlDom.createDialog('luckysheet-search-formula-parm-select', content, containerId, locale_formulaMore.tipSelectDataRange, () => {}, true, false, true, '', '');
            
            //参数选区虚线框
            _this.parmTxtShow($(this).parents(".parmBox").find(".txt input").val(), containerId);
        });

        //点击确定
        currentInstance.off(`click.fxParamCf-${containerId}`).on(`click.fxParamCf-${containerId}`, `#luckysheet-search-formula-parm-confirm`, function(){
        // $(document).off(`click.fxParamCf-${containerId}`).on(`click.fxParamCf-${containerId}`, `#${containerId} #luckysheet-search-formula-parm-confirm`, function(){
            currentInstance.find("#luckysheet-wa-functionbox-confirm").click();
        });

        //选取范围后传回参数栏弹框
        currentInstance.off(`click.fxParamSelectCf-${containerId}`).on(`click.fxParamSelectCf-${containerId}`, `#luckysheet-search-formula-parm-select-confirm`, function(){
        // $(document).off(`click.fxParamSelectCf-${containerId}`).on(`click.fxParamSelectCf-${containerId}`, `#${containerId} #luckysheet-search-formula-parm-select-confirm`, function(){
            let parmIndex = $(`#${containerId} #luckysheet-search-formula-parm-select-input`).attr("data_parm_index");

            $(`#${containerId} #luckysheet-search-formula-parm-select`).hide();
            $(`#${containerId} #luckysheet-search-formula-parm`).show();
            $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(parmIndex).find(".txt input").focus();
        });
    },
    formulaListDialog: function(containerId){
        const _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
        let Store = instance.getStore();

        let _locale = instance.locale();
        let locale_formulaMore = _locale.formulaMore;
        let locale_button = _locale.button

        let content = "<div class='inpbox'><label for='searchFormulaListInput'>"+ locale_formulaMore.findFunctionTitle +"：</label><input class='formulaInputFocus' id='searchFormulaListInput' placeholder='"+ locale_formulaMore.tipInputFunctionName +"' spellcheck='false'/></div><div class='selbox'><label>"+locale_formulaMore.selectCategory+"：</label><select id='formulaTypeSelect'><option value='0'>"+locale_formulaMore.Math+"</option><option value='1'>"+locale_formulaMore.Statistical+"</option><option value='2'>"+locale_formulaMore.Lookup+"</option><option value='3'>"+locale_formulaMore.luckysheet+"</option><option value='4'>"+locale_formulaMore.dataMining+"</option><option value='5'>"+locale_formulaMore.Database+"</option><option value='6'>"+locale_formulaMore.Date+"</option><option value='7'>"+locale_formulaMore.Filter+"</option><option value='8'>"+locale_formulaMore.Financial+"</option><option value='9'>"+locale_formulaMore.Engineering+"</option><option value='10'>"+locale_formulaMore.Logical+"</option><option value='11'>"+locale_formulaMore.Operator+"</option><option value='12'>"+locale_formulaMore.Text+"</option><option value='13'>"+locale_formulaMore.Parser+"</option><option value='14'>"+locale_formulaMore.Array+"</option><option value='-1'>"+locale_formulaMore.other+"</option></select></div><div class='listbox'><label>"+locale_formulaMore.selectFunctionTitle+"：</label><div id='formulaTypeList' class='formulaTypeList'></div></div>";
        instance.createHtmlDom.createDialog('luckysheet-search-formula', content, containerId, '', () => {}, false, true, true, '', '');
        
        _this.formulaListByType("0", containerId); //默认公式列表为类型0
        $(`#${containerId} #searchFormulaListInput`).focus();
    },
    formulaListByType: function(type, containerId){
        const _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
        let Store = instance.getStore();
        $(`#${containerId} #formulaTypeList`).empty();
        let functionlist = Store.functionlist;
                    
        for(let i = 0; i < functionlist.length; i++){
            if((type == "-1" && functionlist[i].t > 14) || functionlist[i].t == type){
                $('<div class="listBox" name="'+ functionlist[i].n +'"><span>'+ functionlist[i].n +'</span><span>'+ functionlist[i].a +'</span></div>').appendTo($(`#${containerId} #formulaTypeList`));
            }
        }

        $(`#${containerId} #formulaTypeList .listBox:first-child`).addClass("on"); //默认公式列表第一个为选中状态
    },
    formulaParmDialog: function(formulaTxt, parm, containerId){ //参数弹出框
        let parm_title = '',
            parm_content = '',
            parm_list_content = '';
        const _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
        let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);

        let _locale = instance.locale();
        let locale_formulaMore = _locale.formulaMore;
        let locale_button = _locale.button;
        let functionlist = Store.functionlist;

        for(let i = 0; i < functionlist.length; i++){
            if(functionlist[i].n == formulaTxt.toUpperCase()){
                parm_title = functionlist[i].n;

                for(let j = 0; j < functionlist[i].p.length; j++){
                    if(parm == null){
                        //无参数
                        parm_list_content += '<div class="parmBox">'+
                                                '<div class="name">'+ functionlist[i].p[j].name +'</div>'+
                                                '<div class="txt">'+
                                                    '<input class="formulaInputFocus" spellcheck="false"/>' +
                                                    '<i class="fa fa-table" aria-hidden="true" title="'+locale_formulaMore.tipSelectDataRange+'"></i>'+
                                                '</div>'+
                                                '<div class="val">=</div>'+
                                             '</div>';
                    }
                    else{
                        //有参数
                        if(parm[j] == null){
                            parm[j] = "";
                        }

                        parm_list_content += '<div class="parmBox">'+
                                                '<div class="name">'+ functionlist[i].p[j].name +'</div>'+
                                                '<div class="txt">'+
                                                    '<input class="formulaInputFocus" value="'+ parm[j] +'" spellcheck="false"/>'+
                                                    '<i class="fa fa-table" aria-hidden="true" title="'+locale_formulaMore.tipSelectDataRange+'"></i>'+
                                                '</div>'+
                                                '<div class="val">=</div>'+
                                             '</div>';
                    }
                }

                parm_content =  '<div>'+
                                    '<div class="parmListBox">'+ parm_list_content +'</div>'+
                                    '<div class="formulaDetails">'+ functionlist[i].d +'</div>'+
                                    '<div class="parmDetailsBox"></div>'+
                                    '<div class="result">'+locale_formulaMore.calculationResult+' = <span></span></div>'+
                                '</div>';
            }
        }

        $(`#${containerId} #luckysheet-search-formula`).hide();
        instance.createHtmlDom.createDialog('luckysheet-search-formula-parm', parm_content, containerId, parm_title, () => {}, true, false, true, '', '');
        
        //参数栏第一个参数聚焦，显示选取虚线框
        $(`#${containerId} #luckysheet-search-formula-parm .parmBox:eq(0) input`).focus();

        //遍历参数，有参数显示值，无显示空
        $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).each(function(index,e){
            let parmtxt = $(e).find(".txt input").val();
            
            if(instance.formula.getfunctionParam(parmtxt).fn == null){ //参数不是公式
                if(instance.formula.iscelldata(parmtxt)){ //参数是选区
                    let txtdata = instance.func.luckysheet_getcelldata(parmtxt).data;

                    if(getObjType(txtdata) == "array"){ //参数为多个单元格选区
                        let txtArr = [];
                        
                        for(let i = 0; i < txtdata.length; i++){
                            for(let j = 0; j < txtdata[i].length; j++){
                                let cell = txtdata[i][j];

                                if(cell == null || isRealNull(cell.v)){
                                    txtArr.push(null);
                                }
                                else{
                                    txtArr.push(cell.v);
                                }
                            }
                        }

                        $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(index).find(".val").text(" = {"+ txtArr.join(",") +"}");
                    }
                    else{ //参数为单个单元格选区
                        $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(index).find(".val").text(" = {"+ txtdata.v +"}");
                    }
                }
                else{ //参数不是选区
                    $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(index).find(".val").text(" = {"+ parmtxt +"}");
                }
            }
            else{ //参数是公式
                $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(index).find(".val").text(" = {"+ (new Function("return " + $.trim(instance.formula.functionParserExe("=" + parmtxt)))()) +"}");
            }
        })

        currentInstance.find(`#luckysheet-formula-functionrange .luckysheet-formula-functionrange-highlight`).remove();                        
        instance.formula.data_parm_index = 0;
        instance.formula.rangestart = true;
    },
    parmTxtShow: function(parmtxt, containerId){
        const _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
        let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);
        if(instance.formula.getfunctionParam(parmtxt).fn == null){ //参数不是公式
            if(instance.formula.iscelldata(parmtxt)){ //参数是选区
                let cellrange = instance.formula.getcellrange(parmtxt);
                let r1 = cellrange.row[0], 
                    r2 = cellrange.row[1], 
                    c1 = cellrange.column[0], 
                    c2 = cellrange.column[1];
                let row = Store.visibledatarow[r2], 
                    row_pre = r1 - 1 == -1 ? 0 : Store.visibledatarow[r1 - 1];
                let col = Store.visibledatacolumn[c2], 
                    col_pre = c1 - 1 == -1 ? 0 : Store.visibledatacolumn[c1 - 1];

                currentInstance.find("#luckysheet-formula-functionrange-select").css({ 
                    "left": col_pre, 
                    "width": col - col_pre - 1, 
                    "top": row_pre, 
                    "height": row - row_pre - 1 
                }).show();
                currentInstance.find("#luckysheet-formula-help-c").hide();

                instance.select.luckysheet_count_show(col_pre, row_pre, col - col_pre - 1, row - row_pre - 1, cellrange.row, cellrange.column);

                let txtdata = instance.func.luckysheet_getcelldata(parmtxt).data;
                if(getObjType(txtdata) == "array"){ //参数为多个单元格选区
                    let txtArr = [];
                    
                    for(let i = 0; i < txtdata.length; i++){
                        for(let j = 0; j < txtdata[i].length; j++){
                            let cell = txtdata[i][j];

                            if(cell == null || isRealNull(cell.v)){
                                txtArr.push(null);
                            }
                            else{
                                txtArr.push(cell.v);
                            }
                        }
                    }

                    $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(instance.formula.data_parm_index).find(".val").text(" = {"+ txtArr.join(",") +"}");
                }
                else{ //参数为单个单元格选区
                    $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(instance.formula.data_parm_index).find(".val").text(" = {"+ txtdata.v +"}");
                }
            }
            else{ //参数不是选区
                $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(instance.formula.data_parm_index).find(".val").text(" = {"+ parmtxt +"}");

                currentInstance.find("#luckysheet-formula-functionrange-select").hide();
            }
        }
        else{   
            //参数是公式
            let txt;
            for(let k = 0; k < instance.formula.getfunctionParam(parmtxt).param.length; k++){
                if(instance.formula.iscelldata(instance.formula.getfunctionParam(parmtxt).param[k])){
                    txt = instance.formula.getfunctionParam(parmtxt).param[k];
                    break;
                }
            }

            let cellrange = instance.formula.getcellrange(txt);
            let r1 = cellrange.row[0], 
                r2 = cellrange.row[1], 
                c1 = cellrange.column[0], 
                c2 = cellrange.column[1];
            let row = Store.visibledatarow[r2], 
                row_pre = r1 - 1 == -1 ? 0 : Store.visibledatarow[r1 - 1];
            let col = Store.visibledatacolumn[c2], 
                col_pre = c1 - 1 == -1 ? 0 : Store.visibledatacolumn[c1 - 1];

            currentInstance.find("#luckysheet-formula-functionrange-select").css({ 
                "left": col_pre, 
                "width": col - col_pre - 1, 
                "top": row_pre, 
                "height": row - row_pre - 1 
            }).show();
            currentInstance.find("#luckysheet-formula-help-c").hide();

            instance.select.luckysheet_count_show(col_pre, row_pre, col - col_pre - 1, row - row_pre - 1, cellrange.row, cellrange.column);

            $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(instance.formula.data_parm_index).find(".val").text(" = {"+ (new Function("return " + $.trim(instance.formula.functionParserExe("=" + parmtxt)))()) +"}");
        }
    },
    functionStrCompute: function(containerId){
        let isVal = true; //参数不为空
        let parmValArr = []; //参数值集合
        let lvi = -1; //最后一个有值的参数索引
        const _this = this;
        const instance = _this.instance;
        containerId = containerId || instance.getInstanceId();
        let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);

        let formulatxt = $(`#${containerId} #luckysheet-search-formula-parm`).find(".luckysheet-modal-dialog-title-text").text();
        let p = Store.luckysheet_function[formulatxt].p;
        
        $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).each(function(i, e){
            let parmtxt = $(e).find(".txt input").val();

            let parmRequire;
            if(i < p.length){
                parmRequire = p[i].require;
            }
            else{
                parmRequire = p[p.length - 1].require;
            }

            if(parmtxt == "" && parmRequire == "m"){
                isVal = false;
            }

            if(parmtxt != ""){
                lvi = i;
            }
        });

        //单元格显示
        let functionHtmlTxt;
        if(lvi == -1){
            functionHtmlTxt = "=" + $(`#${containerId} #luckysheet-search-formula-parm .luckysheet-modal-dialog-title-text`).text() + "()"; 
        }
        else if(lvi == 0){
            functionHtmlTxt = "=" + $(`#${containerId} #luckysheet-search-formula-parm .luckysheet-modal-dialog-title-text`).text() + "(" + $(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(0).find(".txt input").val() + ")"; 
        }
        else{
            for(let j = 0; j <= lvi; j++){
                parmValArr.push($(`#${containerId} #luckysheet-search-formula-parm .parmBox`).eq(j).find(".txt input").val());
            }

            functionHtmlTxt = "=" + $(`#${containerId} #luckysheet-search-formula-parm .luckysheet-modal-dialog-title-text`).text() + "(" + parmValArr.join(",") + ")";    
        }

        let function_str = instance.formula.functionHTMLGenerate(functionHtmlTxt);
        currentInstance.find("#luckysheet-rich-text-editor").html(function_str);
        currentInstance.find("#luckysheet-functionbox-cell").html(currentInstance.find("#luckysheet-rich-text-editor").html());
        
        if(isVal){ //公式计算
            let fp = $.trim(instance.formula.functionParserExe(currentInstance.find("#luckysheet-rich-text-editor").text()));
            
            let result = null;

            try {
                result = new Function("return " + fp)();
            } 
            catch (e) {
                result = instance.formula.error.n;
            }

            $(`#${containerId} #luckysheet-search-formula-parm .result span`).text(result);
        }
    }
}

export default insertFormula;