/**
 * 序列操作公共方法js文件
 * wangxiaofei 2023.8.31
 */
import locale from "../locale/locale";
import tooltipDialog from "./tooltipDialog"; // 提示弹窗
const sequenceCommon = {

    /**
     * 检查序列
     * @param e 被检查对象
     * @returns 
     */
    checkSequence(e) {
        const singleExpression = ["*", "A", "R", "N", "D", "C", "E", "Q", "G", "H", "I", "L", "K", "M", "F", "P", "S", "T", "W", "Y", "V", "X", "U"];
        let t = !0;
        return -1 === singleExpression.indexOf(e.toUpperCase()) && "O" !== e.toUpperCase() && (t = !1),
        t
    },

    /**
     * 校验正则表达式
     * @param {*} reg 
     * @returns 
     */
    isReg(reg) {
        var isReg;
        try {
            isReg = eval(reg) instanceof RegExp
        } catch (e) {
            isReg = !1
        }
        return isReg
    },
    
    /**
     * 获取选区内需要进行转化大小写的有效数据
     * @param {*} e 当前索引行
     * @param {*} t 校验判断
     * @param {*} n 数据类型
     * @param {*} instance 当前实例
     * @returns 
     */
    readSelectedInformation(e, t, n, instance) {
        if(!instance) return;
        let range = instance.api.getRange();
        let column = range[range.length - 1].column;
        let row = range[range.length - 1].row;
        let r, 
        c = (row.length > 0 ? row[0] : 0, row.length > 0 ? row[1] : 0, column.length > 0 ? column[0] : 0),
        u = column.length > 0 ? column[1] : 0, l = "", f = /[0-9]+/;
        r = !1 === t ? "*" : "";
        for (let h = c; h <= u; h++) {
            let d = instance.api.getCellValue(e, h) === null ? '' : instance.api.getCellValue(e, h).toString().replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "").replace(/[\n\r]/g, "");
            if ("Axis" === d || !0 === f.test(d))
                return !1 === t ? "?" : "";
            if (d && void 0 !== d) {
                if ("" === l.replace(/\?/g, "")) {
                    let p = instance.api.getCellValue(e + 1, h);
                    if ("Axis" === p) {
                        l += r;
                        continue
                    }
                }
                for (let m = 0; m < d.length; m++)
                    !1 === _(d[m]) ? l += r : l += !0 ? d[m] : d[m].toUpperCase()
            } else
                l += r
        }
        return "string" === n ? l : "array" === n ? l.split("") : void 0
    },

    /**
     * 插入行(一次只能插入100行)
     * @param  row 最后一行
     * @param  num 列树
     * @param instance 当前实例
     */
    insertSheetRow(row, num , instance){
        if(!instance) return;
        let nowRow = row;
        let number = num;
        while (number > 100){
            instance.api.insertRow(nowRow , {number: 100 });
            number = number - 100;
            nowRow = nowRow + 100;
        }
        if( number > 0){
            instance.api.insertRow(nowRow , {number: number });
        }
    },

    /**
     * 插入列(一次只能插入100列)
     * @param  column 最后一列
     * @param  num 列数
     * @param instance 当前实例
     */
    insertSheetColumn(column, num , instance){
        if(!instance) return;
        let nowColumn = column;
        let number = num;
        while (number > 100){
            instance.api.insertColumn(nowColumn , {number: 100 });
            number = number - 100;
            nowColumn = nowColumn + 100;
        }
        if( number > 0){
            instance.api.insertColumn(nowColumn , {number: number });
        }
    },

    /**
     * 检查单序列还是多序列
     * @param  e 
     * @returns 
     */
    checkTripleOrSingle(e) {
        let  tripleExpression = ["*", "Ala", "Arg", "Asn", "Asp", "Cys", "Glu", "Gln", "Gly", "His", "Ile", "Leu", "Lys", "Met", "Phe", "Pro", "Ser", "Thr", "Trp", "Tyr", "Val", "any", "Sec"];
        let t = e.replace(/\*/g, "").replace(/\?/g, "")
          , n = t.length;
        if (n % 3 != 0)
            return !1;
        for (let r = 0; r < n; ) {
            if (-1 === tripleExpression.indexOf(t.slice(r, r + 3)))
                return !1;
            r += 3
        }
        return !0
    },
    
    /**
     * 查找选区内容的合并单元格
     * @param  t 行
     * @param  n 列
     * @param  r 总长度
     * @param instance 当前实例
     * @returns 
     */
    checkMerge( t, n, r, instance) {
        if(!instance) return;
        let sheet = instance.api.getSheet();
        let i = [], o = [];
        for (let a = n; a <= r; a++) {
            let s = sheet.data[t][a];
            null !== s && s.mc && s.mc.cs && (i.push(s.mc),
            o.push(a))
        }
        return [i, o];
    },
    
    /**
     * 计算选择范围
     * @param {*} e 
     * @param {*} instance
     * @returns 
     */
    computeRange( e, instance ) {
        let _locale = instance.locale();
        const locale_sequenceLookup = _locale.sequenceLookup;
        for (var t = "", n = 0, r = 0, i = 0; i < e.length; i++) {
            var o = parseInt(e[i].charCodeAt());
            if (o >= 65 && o <= 90)
                n = o - 65 + 1 + 26 * n;
            else if (o >= 97 && o <= 122)
                r = o - 97 + 1 + 26 * r;
            else if (o >= 48 && o <= 57)
                t += e[i];
            else {
                instance.tooltip.info('', locale_sequenceLookup.Errormessage.errorCellPosition);
            }
        }
        return n > 0 ? [n, parseInt(t)] : r > 0 ? [r, parseInt(t)] : void 0
    },
    
};
export default sequenceCommon;