import Store from "../../store";
import defaultSetting from "../../config.js";
import { deepCopy } from '../../utils/chartUtil'; // 引入深度拷贝文件
import locale  from "../../locale/locale";
import server from "../server";
import luckysheetConfigsetting from "../luckysheetConfigsetting";
import sheetmanage from "../sheetmanage";
import menuButton from "../menuButton";
import luckysheetsizeauto from "../resize";
import {changeSheetContainerSize, menuToolBarWidth} from '../resize'
import luckysheetHandler from "../handler";
import * as filter from "../filter"; // 后期补充其他方法初始化到实例中，原有的先保持不变了
import { initialMatrixOperation } from "../matrixOperation";
import { initialSheetBar } from "../sheetBar";
import { formulaBarInitial } from "../formulaBar";
import { rowColumnOperationInitial } from "../rowColumnOperation";
import { keyboardInitial } from "../keyboard";
import { orderByInitial } from "../orderBy";
import { initPlugins } from "../expendPlugins";
import { getSheetIndex,
  getRangetxt,
  getluckysheet_select_save,
  getluckysheet_scroll_status,
  getluckysheetfile,
  getconfig,
  getvisibledatarow,
  getvisibledatacolumn,
  getConditionFormatCells, } from "../../methods/get";
import { setluckysheet_select_save } from "../../methods/set";
import * as refresh from "../../global/refresh";
import functionlist from "../../function/functionlist";
import * as getdata from "../../global/getdata";
import * as setdata from '../../global/setdata';
import { setcellvalue } from "../../global/setdata";
import { seletedHighlistByindex,
  selectHightlightShow,
  selectIsOverlap,
  selectionCopyShow,
  collaborativeEditBox,
  luckysheet_count_show,
  selectTitlesShow,
  selectHelpboxFill} from "../select";
import { zoomInitial, zoomChange, zoomNumberDomBind , zoomRefreshView} from "../zoom";
// import { printInitial } from "../print";
import method from "../../global/method";
import luckysheetReadOrEdit from '../luckysheetReadOrEdit';

import * as api from "../../global/api";

import flatpickr from "flatpickr";
import Mandarin from "flatpickr/dist/l10n/zh.js";
import { initListener } from "../listener";
import { hideloading, showloading } from "../../global/loading.js";
import { luckysheetextendData } from "../../global/extend.js";
import { initChat } from '../../demoData/chat.js'
import luckysheetcreatedom from '../../global/createdom';
import handleToolbarIdMap from "../handleToolbarIdMap";
import editor from '../../global/editor';
import imageCtrl from '../imageCtrl';
import dataVerificationCtrl from '../dataVerificationCtrl';
import hyperlinkCtrl from '../hyperlinkCtrl';
import * as protection from "../protection";
import * as constant from '../constant';
import { createToolbarHtml } from '../toolbar';
import rhchInit from '../../global/rhchInit';
import tooltip from '../../global/tooltip';
import mobileinit from "../mobile";
import postil from '../postil';
import formula from '../../global/formula';
import { rowLocationByIndex,
  rowLocation,
  colLocationByIndex,
  colSpanLocationByIndex,
  colLocation,
  mouseposition 
} from '../../global/location';
import { dynamicArrayCompute, dynamicArrayHightShow } from '../../global/dynamicArray';
import freezen from '../freezen';
import * as draw from '../../global/draw';
import conditionformat from "../conditionformat";
import alternateformat from "../alternateformat";
import selection from '../selection';
import splitColumn from '../splitColumn';
import ifFormulaGenerator from "../ifFormulaGenerator";
import * as cellFormat from "../cellFormat";
import * as updateCell from '../updateCell';
import * as format from "../../global/format";
import * as getRowlen from "../../global/getRowlen";
import * as util from '../../utils/util';
import * as border from "../../global/border";
import * as chartPlugin from "../../expendPlugins/chart/plugin";
import * as printPlugin from "../../expendPlugins/print/plugin";
import * as exportXlsxPlugin from "../../expendPlugins/exportXlsx/plugin";
import pivotTable from "../pivotTable";
import * as cursorPos from "../../global/cursorPos";
import * as count from "../../global/count";
import luckysheetscrollevent from "../../global/scroll";
import cleargridelement from '../../global/cleargridelement';
import * as validate from '../../global/validate';
import createHtmlDom from '../common/createHtmlDom';
import searchReplace from '../searchReplace';
import functionImplementation from '../../function/functionImplementation';
import * as extend from '../../global/extend';
import * as sort from '../../global/sort';
import controlHistory from "../controlHistory";
import * as expendPlugins from '../expendPlugins';
import * as set from "../../methods/set";
import * as func from "../../function/func";
import * as sheetMove from "../sheetMove";
import dropCell from '../dropCell';
import * as inlineString from '../inlineString';
import insertFormula from '../insertFormula';
import locationCell from "../locationCell";
import moreFormat from "../moreFormat";
import domResize from "./domResize";
import * as loading from "../../global/loading";

export default function createInit(setting){
  const _this = this;
  let extendsetting = util.common_extend(defaultSetting, setting);
  // 实例下luckysheetConfigsetting进行深拷贝
  this.luckysheetConfigsetting = deepCopy(luckysheetConfigsetting);
  // 实例下Store进行深拷贝
  this.Store = deepCopy(Store);
  this.Store.container = setting.container;
  this.Store.luckysheetfile = extendsetting.data;
  this.Store.defaultcolumnNum = extendsetting.column;
  this.Store.defaultrowNum = extendsetting.row;
  this.Store.defaultFontSize = extendsetting.defaultFontSize;
  this.Store.fullscreenmode = extendsetting.fullscreenmode;
  this.Store.lang = extendsetting.lang; //language
  this.Store.allowEdit = extendsetting.allowEdit;
  this.Store.limitSheetNameLength = extendsetting.limitSheetNameLength;
  this.Store.defaultSheetNameMaxLength = extendsetting.defaultSheetNameMaxLength;
  this.Store.fontList = extendsetting.fontList;
  this.Store.isReadOnly = extendsetting.editMode;
  this.Store.allowEdit = !extendsetting.editMode;
  this.Store.plugins = extendsetting.plugins;
  
  // 返回当前实例id
  this.getInstanceId = function(){
    return _this.Store.container;
  }
  // 获取实例Store数据
  this.getStore = function(){
    return _this.Store;
  }
  // 获取实例Store下的key值
  this.getStoreKey = function(key){
    return _this.Store[key];
  }
  // 设置store值
  this.setStoreKey = function(key, value){
    _this.Store[key] = value;
  }
  // 追加store值
  this.addStoreKey = function(key, value){
    _this.Store[key].push(...value);
  }

  // 实例销毁方法
  // this.methodDestory = method.destroy;
  this.method = {
    ...method,
    instance: _this,
  };
  // 初始化文件
  // 初始化实例sheetmanage的方法
  this.sheetmanage = {
    ...sheetmanage,
    instance: _this,
  };

  // 初始化实例menuButton的方法
  this.menuButton = {
    ...menuButton,
    instance: _this,
  };

  // 初始化tooltip放到
  this.tooltip = {
    ...tooltip,
    instance: _this,
  };
  // 初始化rhchInit
  this.rhchInit = rhchInit;
  
  // 初始化constant
  this.constant = {
    ...constant,
    instance: _this,
  }
  
  // 初始化createdom
  this.createdom ={
    luckysheetcreatedom: luckysheetcreatedom,
    instance: _this,
  };
  // 初始化Toolbar
  this.Toolbar = {
    createToolbarHtml: createToolbarHtml,
    instance: _this,
  }
  // 初始化expendPlugins
  this.expendPlugins = {
    ...expendPlugins,
    instance: _this,
  }

  // 初始化sheet相关方法
  this.initSheet = {
    luckysheetHandler, //Overall dom initialization
    initialMatrixOperation, //Right click matrix initialization
    initialSheetBar, //bottom sheet bar initialization
    formulaBarInitial, //top formula bar initialization
    rowColumnOperationInitial, //row and coloumn operate initialization
    keyboardInitial, //Keyboard operate initialization
    orderByInitial, //menu bar orderby function initialization
    zoomInitial, //zoom method initialization
    initListener,
    zoomChange, 
    zoomNumberDomBind,
    zoomRefreshView,
    mobileinit : mobileinit,
    cleargridelement,
    instance: _this,
  }

  // 初始化editor
  this.editor = {
    ...editor,
    instance: _this,
  }
  // 初始化echart
  this.chat = {
    initChat : initChat,
    instance: _this,
  };
  // 当前locales
  this.locale = locale;  
  // 初始化luckysheetReadOrEdit
  this.luckysheetReadOrEdit = {
    ...luckysheetReadOrEdit,
    instance: _this,
  }
  // 设置handleToolbarIdMap
  this.handleToolbarIdMap = {
    ...handleToolbarIdMap,
    instance: _this,
  }
  // 初始化postil
  this.postil = {
    ...postil,
    instance: _this,
  }
  // 初始化imageCtrl;
  this.imageCtrl = {
    ...imageCtrl,
    instance: _this,
  }

  // 初始化filter;
  this.filter = {
    ...filter,
    instance: _this,
  }

  // 初始化dataVerificationCtrl
  this.dataVerificationCtrl = {
    ...dataVerificationCtrl,
    instance: _this,
  }

  // 初始化hyperlinkCtrl
  this.hyperlinkCtrl = {
    ...hyperlinkCtrl,
    instance: _this,
  }

  // 初始化selectHightlightShow
  this.select = {
    seletedHighlistByindex,
    selectHightlightShow,
    selectIsOverlap,
    selectionCopyShow,
    collaborativeEditBox,
    luckysheet_count_show,
    selectHelpboxFill,
    selectTitlesShow,
    instance: _this,
  }

  //初始化 formula
  this.formula = {
    ...formula,
    instance: _this,
  }

  // 初始化getRowlen
  this.getRowlen = {
    ...getRowlen,
    instance: _this,
  }

  // 初始化format
  this.format = {
    ...format,
    instance: _this,
  }

  // 初始化protection
  this.protection = {
    ...protection,
    instance: _this,
  };
  
  // 初始化chart的Plugin.js方法
  this.chartPlugin = {
    ...chartPlugin,
    instance: _this,
  }

  // 初始化print的Plugin.js方法
  this.printPlugin = {
    ...printPlugin,
    instance: _this,
  }

  // 初始化exportXlsx的Plugin.js方法
  this.exportXlsxPlugin = {
    ...exportXlsxPlugin,
    instance: _this,
  }

  this.border = {
    ...border,
    instance: _this,
  }

  this.util = {
    ...util,
    instance: _this,
  }
  
  // 初始化resize
  this.resize = {
    luckysheetsizeauto,
    changeSheetContainerSize,
    menuToolBarWidth,
    instance: _this,
  }

  this.location = {
    rowLocationByIndex,
    rowLocation,
    colLocationByIndex,
    colSpanLocationByIndex,
    colLocation,
    mouseposition,
    instance: _this,
  }

  this.get = {
    getSheetIndex,
    getRangetxt,
    getluckysheet_select_save,
    getluckysheet_scroll_status,
    getluckysheetfile,
    getconfig,
    getvisibledatarow,
    getvisibledatacolumn,
    getConditionFormatCells,
    instance: _this,
  }

  this.set = {
    ...set,
    instance: _this,
  }

  // 初始化freezen
  this.freezen = {
    ...freezen,
    instance: _this,
  }

  // 初始化pivotTable
  this.pivotTable = {
    ...pivotTable,
    instance: _this,
  }

  this.dynamicArray = {
    dynamicArrayCompute,
    dynamicArrayHightShow,
    instance: _this,
  }

  this.getdata = {
    ...getdata,
    instance: _this,
  }

  this.setdata = {
    ...setdata,
    instance: _this,
  }

  this.selection = {
    ...selection,
    instance: _this,
  }

  this.server = {
    ...server,
    instance: _this,
  }

  this.splitColumn = {
    ...splitColumn,
    instance: _this,
  }

  this.createHtmlDom = {
    ...createHtmlDom,
    instance: _this,
  }

  this.searchReplace = {
    ...searchReplace,
    instance: _this,
  }

  this.functionImplementation = {
    ...functionImplementation,
    instance: _this,
  }

  this.extend = {
    ...extend,
    instance: _this,
  }

  this.sort = {
    ...sort,
    instance: _this,
  }

  this.ifFormulaGenerator = {
    ...ifFormulaGenerator,
    instance: _this,
  }

  this.cellFormat = {
    ...cellFormat,
    instance: _this,
  }

  this.updateCell = {
    ...updateCell,
    instance: _this,
  }

  this.refresh = {
    ...refresh,
    instance: _this,
  }

  // 初始化conditionformat
  this.conditionformat = {
    ...conditionformat,
    instance: _this,
  }

  // 初始化alternateformat
  this.alternateformat = {
    ...alternateformat,
    instance: _this,
  }

   // 初始化 scroll
   this.scroll = {
    luckysheetscrollevent,
    instance: _this,
  }

  // 初始化draw
  this.draw = {
    ...draw,
    instance: _this,
  }
  // 初始化cursorPos
  this.cursorPos = {
    ...cursorPos,
    instance: _this,
  }

  // 初始化validate
  this.validate = {
    ...validate,
    instance: _this,
  }

  // 初始化count 
  this.count = {
    ...count,
    instance: _this,
  }

  this.api = {
    ...api,
    instance: _this,
  }


  // 其他初始化文件方法
  this.controlHistory = {
    ...controlHistory,
    instance: _this,
  }
  // 初始化func
  this.func = {
    ...func,
    instance: _this,
  }

  // 初始化sheetMove
  this.sheetMove = {
    ...sheetMove,
    instance: _this,
  }

  this.dropCell = {
    ...dropCell,
    instance: _this,
  }

  this.inlineString = {
    ...inlineString,
    instance: _this,
  }

  this.insertFormula = {
    ...insertFormula,
    instance: _this,
  }

  this.locationCell = {
    ...locationCell,
    instance: _this,
  }

  this.moreFormat = {
    ...moreFormat,
    instance: _this,
  }

  this.functionlist = {
    ...functionlist,
    instance: _this,
  }

  this.domResize = {
    ...domResize,
    instance: _this,
  }

  this.loading = {
    ...loading,
    instance: _this,
  }

}


