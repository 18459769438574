import Store from "../../store";
import { getObjType, showrightclickmenu, luckysheetContainerFocus, luckysheetfontformat, $$ } from "../../utils/util";

// 右键菜单及容器隐藏
export function hideRightButton(currentId, luckysheetConfigsetting, type, instance){
  // 获取当前实例 wangxiaofei 2023915
  const currentInstance = $("#"+currentId);
  const _locale = instance.locale();
  currentInstance.find("#luckysheet-cols-rows-shift").hide();
  
  currentInstance.find(`#luckysheet-rightclick-menu .luckysheet-cols-rows-shift-word`).text(_locale.rightclick.row);
  currentInstance.find(`#luckysheet-rightclick-menu .luckysheet-cols-rows-shift-size`).text(_locale.rightclick.height);
  currentInstance.find(`#luckysheet-rightclick-menu .luckysheet-cols-rows-shift-left`).text(_locale.rightclick.top);
  currentInstance.find(`#luckysheet-rightclick-menu .luckysheet-cols-rows-shift-right`).text(_locale.rightclick.bottom);

  currentInstance.find("#luckysheet-cols-rows-add").show();
  currentInstance.find("#luckysheet-cols-rows-data").show();
  currentInstance.find("#luckysheet-cols-rows-shift").hide();
  currentInstance.find("#luckysheet-cols-rows-handleincell").hide();

  $$(`#${currentId} #luckysheet-cols-rows-add .luckysheet-menuseparator`).style.display = "block";
  $$(`#${currentId} #luckysheet-cols-rows-add .luckysheet-menuseparator`).style.display = "block";

  // 自定义右键菜单：向上向下增加行，删除行，隐藏显示行，设置行高
  const cellRightClickConfig = luckysheetConfigsetting.cellRightClickConfig;
  let insertRoworCol = type === "row"? 
  cellRightClickConfig.insertRow : 
  cellRightClickConfig.insertColumn;
  let deleteRoworCol = type === "row"? 
  cellRightClickConfig.deleteRow : 
  cellRightClickConfig.deleteColumn ;
  let hideRoworCol = type === "row"? 
  cellRightClickConfig.hideRow : 
  cellRightClickConfig.hideColumn;
  // 如果全部按钮都隐藏，则整个菜单容器也要隐藏
  if (
    !cellRightClickConfig.copy &&
    !cellRightClickConfig.copyAs &&
    !cellRightClickConfig.paste &&
    !insertRoworCol&&
    !deleteRoworCol&&
    !hideRoworCol &&
    !cellRightClickConfig.rowHeight &&
    !cellRightClickConfig.clear &&
    !cellRightClickConfig.matrix &&
    !cellRightClickConfig.sort &&
    !cellRightClickConfig.filter &&
    !cellRightClickConfig.chart &&
    !cellRightClickConfig.image &&
    !cellRightClickConfig.link &&
    !cellRightClickConfig.data &&
    !cellRightClickConfig.cellFormat
  ) {
    return;
  }

  $$("#"+currentId+" #luckysheet-top-left-add-selected").style.display = insertRoworCol
    ? "block"
    : "none";
  $$("#"+currentId+" #luckysheet-bottom-right-add-selected").style.display = insertRoworCol
    ? "block"
    : "none";
  $$("#"+currentId+" #luckysheet-del-selected").style.display = deleteRoworCol ? "block" : "none";
  $$("#"+currentId+" #luckysheet-hide-selected").style.display = hideRoworCol ? "block" : "none";
  $$("#"+currentId+" #luckysheet-show-selected").style.display = hideRoworCol ? "block" : "none";
  $$("#"+currentId+" #luckysheet-column-row-width-selected").style.display = cellRightClickConfig.rowHeight
    ? "block"
    : "none";

  // 1. 当一个功能菜单块上方的功能块按钮都隐藏的时候，下方的功能块的顶部分割线也需要隐藏
  if (!cellRightClickConfig.copy && !cellRightClickConfig.copyAs && !cellRightClickConfig.paste) {
    $$("#"+currentId+" #luckysheet-cols-rows-add .luckysheet-menuseparator").style.display = "none";

    if (
      !insertRoworCol&&
      !deleteRoworCol&&
      !hideRoworCol && 
      !cellRightClickConfig.rowHeight
    ) {
      $$("#"+currentId+" #luckysheet-cols-rows-data .luckysheet-menuseparator").style.display = "none";
    }
  }

  // 2. 当一个功能菜单块内所有的按钮都隐藏的时候，它顶部的分割线也需要隐藏掉
  if (
    !insertRoworCol&&
    !deleteRoworCol&&
    !hideRoworCol &&
    !cellRightClickConfig.rowHeight
  ) {
    $$("#"+currentId+" #luckysheet-cols-rows-add .luckysheet-menuseparator").style.display = "none";
  }

  if (
    !cellRightClickConfig.clear &&
    !cellRightClickConfig.matrix &&
    !cellRightClickConfig.sort &&
    !cellRightClickConfig.filter &&
    !cellRightClickConfig.chart &&
    !cellRightClickConfig.image &&
    !cellRightClickConfig.link &&
    !cellRightClickConfig.data &&
    !cellRightClickConfig.cellFormat
  ) {
    $$("#"+currentId+" #luckysheet-cols-rows-data .luckysheet-menuseparator").style.display = "none";
  }
}

/**
 * 设置列列宽默认值
 * @param {*} instance 当前实例
 */
export function initWidthAndHeightRow(instance){
  const currentId = instance.getInstanceId();
  const currentInstance = $("#"+currentId);
	let Store = instance.getStore();
  //行高默认值
  let cfg = $.extend(true, {}, Store.config);
  if (cfg["rowlen"] == null) {
    cfg["rowlen"] = {};
  }

  let first_rowlen =
    cfg["rowlen"][Store.luckysheet_select_save[0].row[0]] == null
      ? Store.defaultrowlen
      : cfg["rowlen"][Store.luckysheet_select_save[0].row[0]];
  let isSame = true;

  for (let i = 0; i < Store.luckysheet_select_save.length; i++) {
    let s = Store.luckysheet_select_save[i];
    let r1 = s.row[0],
      r2 = s.row[1];

    for (let r = r1; r <= r2; r++) {
      let rowlen = cfg["rowlen"][r] == null ? Store.defaultrowlen : cfg["rowlen"][r];

      if (rowlen != first_rowlen) {
        isSame = false;
        break;
      }
    }
  }

  if (isSame) {
    currentInstance.find("#luckysheet-cols-rows-add")
      .find("input[type='number'].rcsize")
      .val(first_rowlen);
  } else {
    currentInstance.find("#luckysheet-cols-rows-add")
      .find("input[type='number'].rcsize")
      .val("");
  }
}

/**
 * 设置行列宽默认值
 * @param {*} currentInstance 
 */
export function initWidthAndHeightColumn(instance){
  const currentId = instance.getInstanceId();
  const currentInstance = $("#"+currentId);
	let Store = instance.getStore();
  //行高默认值
  let cfg = $.extend(true, {}, Store.config);
  if (cfg["columnlen"] == null) {
      cfg["columnlen"] = {};
  }

  let first_collen =
      cfg["columnlen"][Store.luckysheet_select_save[0].column[0]] == null
          ? Store.defaultcollen
          : cfg["columnlen"][Store.luckysheet_select_save[0].column[0]];
  let isSame = true;

  for (let i = 0; i < Store.luckysheet_select_save.length; i++) {
      let s = Store.luckysheet_select_save[i];
      let c1 = s.column[0],
          c2 = s.column[1];

      for (let c = c1; c <= c2; c++) {
          let collen = cfg["columnlen"][c] == null ? Store.defaultcollen : cfg["columnlen"][c];

          if (collen != first_collen) {
              isSame = false;
              break;
          }
      }
  }

  if (isSame) {
      currentInstance.find("#luckysheet-cols-rows-add")
          .find("input[type='number'].rcsize")
          .val(first_collen);
  } else {
      currentInstance.find("#luckysheet-cols-rows-add")
          .find("input[type='number'].rcsize")
          .val("");
  }
}