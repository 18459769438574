/**
 * CRISPR设计js 
 * wangxiaofei 2023.8.30
 */
import { replaceHtml } from "../utils/util";
import { modelHTML } from "./constant";
import locale from "../locale/locale";
import sequenceCommon from "./sequenceCommon"; // 序列操作公共js方法
import multiInstanceStore from "../store/multiInstance"; // 存储多实例Store wangxiaofei 1023.9.20

const crisprDesign = {
    /**
     * crispr查看
     */
    createCrisprDesignDialog() {
        let _this = this;
        let instance = _this.instance;
        let containerId = instance.getInstanceId();
        let currentInstance = $(`#${containerId}`);
        let Store = instance.getStore();
        let _locale = instance.locale();
        const locale_crisprDesign = _locale.crisprDesign;
        let content = `<div class="proteinanalysis-setting-block">
            <div class="subtemplate_wrap">
                <div id="sub_module_body" class="crispr_module_body">
                    <div id="save_as_box" class ="save_as_box crispr_box">
                        <div class="save_part">
                            <ul>
                                <li>${locale_crisprDesign.crisprTip1}</li>
                                <li><a href="https://doi.org/10.1093/nar/gky354" target = "_blank">${locale_crisprDesign.reference}</a></li>
                            </ul>
                            <div>
                                <span class="PAM">${locale_crisprDesign.selectPAM}</span>
                                <select class="PAMSelect">
                                    <option value="NGG" selected>NGG</option>
                                    <option value="NXG">NNG</option>
                                    <option value="NXGT">NNGT</option>
                                    <option value="NAA">NAA</option>
                                    <option value="NGA">NGA</option>
                                    <option value="NGX">NGN</option>
                                    <option value="NGCG">NGCG</option>
                                    <option value="NGGXG">NGGXG</option>
                                    <option value="TTCN">TTCN</option>
                                    <option value="NXAGAA">NXAGAA</option>
                                    <option value="GAT">GAT</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
        // 序列弹窗确定
        let confirmCallback = function(target, instance) {
            let seqDialog = currentInstance.find("#luckysheet-crisprDesign-dialog");
            let e = ["A", "T", "C", "G"], n = seqDialog.find(".PAMSelect").val(), r = [3, 4, 5], i = [], o = [], a = [], c = [], l = [], f = [], h = [], d = [];
            for (let p = 0; p < 4; p++)
                for (let m = 0; m < 4; m++) {
                    let y = n.replace("N", e[m]).replace("X", e[p]);
                    i.push(y)
                }
            for (let g = 0; g < i.length; g++)
                -1 === o.indexOf(i[g]) && o.push(i[g]);
            let range = luckysheet[containerId].getRange();
            let column = range[range.length - 1].column;
            let row = range[range.length - 1].row;
            let w = row.length > 0 ? row[0] : 0, 
            eri = row.length > 0 ? row[1] : 0, 
            S = column.length > 0 ? column[0] : 0, 
            O = (column.length > 0 ? column[1] : 0,'');
            for (let C = w; C <= eri; C++)
                O += sequenceCommon.readSelectedInformation(C, !0, "string", instance);
            let k = _this.handleFlipping(O);
            for (let M = 20; M < O.length; M++)
                for (let T = 0; T < r.length; T++)
                    if (M + r[T] <= O.length) {
                        var A = O.slice(M, M + r[T]);
                        -1 !== o.indexOf(A) && (a.push(A),
                        c.push(O.slice(M - 20, M)),
                        l.push(M + 1))
                    }
            for (let I = 20; I < k.length; I++)
                for (let N = 0; N < r.length; N++)
                    if (I + r[N] <= k.length) {
                        var R = k.slice(I, I + r[N]);
                        -1 !== o.indexOf(R) && (f.push(R),
                        h.push(k.slice(I - 20, I)),
                        d.push(k.length - I - 2))
                    }
            f.reverse(),
            h.reverse(),
            d.reverse();
            var P = 0
              , L = 0;
            if (f.length > 0 || a.length > 0) {
                for (sequenceCommon.insertSheetRow(w + 1, a.length + f.length, instance); L < a.length && P < f.length; )
                    d[P] >= l[L] ? (luckysheet[containerId].setCellValue(w + 2 + L + P, S, l[L], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 1, a[L], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 2, c[L], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 3, "forward", { isRefresh: false}),
                    L += 1) : d[P] < l[L] && (luckysheet[containerId].setCellValue(w + 2 + L + P, S, d[P], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 1, f[P], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 2, h[P], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 3, "reverse", { isRefresh: false}),
                    P += 1),
                    P === f.length - 1 && (luckysheet[containerId].setCellValue(w + 2 + L + P, S, l[L], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 1, a[L], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 2, c[L], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 3, "forward", { isRefresh: false}),
                    L += 1),
                    L === a.length - 1 && (luckysheet[containerId].setCellValue(w + 2 + L + P, S, d[P], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 1, f[P], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 2, h[P], { isRefresh: false}),
                    luckysheet[containerId].setCellValue(w + 2 + L + P, S + 3, "reverse", { isRefresh: false}),
                    P += 1);
                let B =locale_crisprDesign.cutsite;
                luckysheet[containerId].setCellValue(w + 1, S, B, { isRefresh: false}),
                luckysheet[containerId].setCellValue(w + 1, S + 1, "PAM", { isRefresh: false}),
                luckysheet[containerId].setCellValue(w + 1, S + 2, "gRNA", { isRefresh: false}),
                luckysheet[containerId].setCellValue(w + 1, S + 3, "Chain", { isRefresh: false});
            }
            luckysheet[containerId].setRangeShow(range);
            luckysheet[containerId].refresh();
            seqDialog.hide();
            currentInstance.find("#luckysheet-modal-dialog-mask").hide();
        }

        instance.createHtmlDom.createDialog('luckysheet-crisprDesign-dialog', content, containerId, locale_crisprDesign.crispr, confirmCallback, true, true, true, '', '', '', true);
    },

    /**
     * 打开ATUM CRISPR Design
     */
    openCrispr(){
        window.open("https://www.atum.bio/eCommerce/cas9/input")
    },

    /**
     * 序列翻转处理
     * @param {*} e 需要翻转的序列
     * @returns 
     */
    handleFlipping(e) {
        const S = new Map([["A", "T"], ["T", "A"], ["C", "G"], ["G", "C"], ["N", "N"], ["X", "X"]]);
        let t = "";
        for (let n = 0; n < e.length; n++)
            t += S.get(e[n].toUpperCase());
        return t.split("").reverse().join("");
    },
    
};
export default crisprDesign;