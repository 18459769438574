/**
 * 引物设计js 
 * 从原来的menuButton.js中抽离出单独的js文件
 * wangxiaofei 2023.8.30
 */
import locale from "../locale/locale";
import tooltipDialog from "./tooltipDialog"; // 提示弹窗

const primerDesign = {

  /**
   * 引物正负链
   * @param {*} itemValue 传值，
   * @returns 
   */
  primerChain(itemValue){
    let instance = this.instance;
    let containerId = instance.getInstanceId();
    const _locale = instance.locale();
    const primerDesignText = _locale.primerDesign;
    let legalCharArr = ['A', 'a', 'T', 't', 'C', 'c', 'G', 'g'];
    let selectedRangeArr = luckysheet[containerId].getRangeArray('twoDimensional');
    if (selectedRangeArr.length > 1) {
      instance.tooltip.info(primerDesignText.tooltipTitle, primerDesignText.createprimerTip);
      return;
    } else if (selectedRangeArr.length === 1) {
      if (selectedRangeArr[0].some(item => !item || item.length > 1)) {
        instance.tooltip.info(primerDesignText.tooltipTitle, primerDesignText.singlebasetip);
          return;
      }
      if (selectedRangeArr[0].some(item => !legalCharArr.includes(item))) {
        instance.tooltip.info(primerDesignText.tooltipTitle, primerDesignText.propertyillegalTip);
          return;
      }
      let range = luckysheet[containerId].getRange();
      let rowIndex = range[range.length - 1].row[0];
      let rangeCol = range[range.length - 1].column;
      const baseComplementarity = {
          A: 'T',
          T: 'A',
          C: 'G',
          G: 'C',
          a: 't',
          t: 'a',
          c: 'g',
          g: 'c',
      }
      let data;
      if( itemValue === 'forward' ){
        data = rangeCol[0] === 0 ? [] : ['Primer for'];
        selectedRangeArr[0].forEach(item => {
          data.push(item);
        });
        // 正向插入到单元格前面，需要处理选中去往下移一行
        range[range.length - 1].row = [range[range.length - 1].row[0] + 1 , range[range.length - 1].row[1] + 1];
      }else if(itemValue === 'reverse'){
        rowIndex++;
        data = rangeCol[0] === 0 ? [] : ['Primer rev'];
        selectedRangeArr[0].forEach(item => {
          data.push(baseComplementarity[item]);
        });
      }
      luckysheet[containerId].insertRow(rowIndex);
      luckysheet[containerId].setRangeValue([data], { range: { column: [rangeCol[0] === 0 ? rangeCol[0] : rangeCol[0] - 1, rangeCol[1]], row: [rowIndex, rowIndex] } });
      luckysheet[containerId].setRangeShow(range);
    }
  },
  /**
   * 引物性质分析
   */
  primerPropertyAnalysis(){
    let instance = this.instance;
    let containerId = instance.getInstanceId();
    const _locale = instance.locale();
    const primerDesignText = _locale.primerDesign;
    let legalCharArr = ['A', 'T', 'C', 'G'];
    let selectedRangeArr = luckysheet[containerId].getRangeArray('twoDimensional');
    if (selectedRangeArr.length > 1) {
      instance.tooltip.info(primerDesignText.tooltipTitle, primerDesignText.propertyTip);
      return;
    } else if (selectedRangeArr.length === 1) {
      let selectedRangeStr = selectedRangeArr[0].filter(item => item).join('').toUpperCase();
      if (selectedRangeStr.split('').some(item => !legalCharArr.includes(item))) {
        instance.tooltip.info(primerDesignText.tooltipTitle, primerDesignText.propertyillegalTip);
        return;
      }
      let range = luckysheet[containerId].getRange();
      let rowIndex = range[range.length - 1].row[0];
      let colIndex = range[range.length - 1].column[0];
      luckysheet[containerId].insertRow(rowIndex + 1);
      let sequenceLength = selectedRangeStr.length;
      let aNum = 0;
      let tNum = 0;
      let gNUm = 0;
      let cNum = 0;
      selectedRangeStr.split('').forEach(item => {
        if (item === 'A') {
          aNum++;
        } else if (item === 'T') {
          tNum++;
        } else if (item === 'G') {
          gNUm++;
        } else if (item === 'C') {
          cNum++;
        }
      })
      let tm = '';
      if (selectedRangeStr.length <= 13) {
        // Tm = 4 * (G + T) + 2 * (A + T)
        tm = (4 * (gNUm + cNum) + 2 * (aNum + tNum)).toFixed(1);
      } else {
        // TM= 64.9 + 41*(G+C-16.4)/(A+T+G+C) G、C、A、T分别表示引物中G、C、A、T的个数
        tm = (64.9 + 41 * (gNUm + cNum - 16.4) / (aNum + tNum + gNUm + cNum)).toFixed(1);
      }
      let gc = ((cNum + gNUm) / selectedRangeStr.length * 100).toFixed(2);
      let data = `Tm:${tm}℃ GC (%):${gc} Sequence length:${sequenceLength}`;
      luckysheet[containerId].setRangeValue([[data]], { range: { column: [colIndex, colIndex], row: [rowIndex + 1, rowIndex + 1] } });
      luckysheet[containerId].setRangeShow(range);
    }
  },

  /**
   * 打开primer3
   */
  openPrimer(){
    window.open('https://bioinfo.ut.ee/primer3-0.4.0/', '_blank');
  },
};
export default primerDesign;