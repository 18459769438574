/**
 * 获取当前操作sheet相关方法
 * 创建时间： 2023.9.12
 * 创建人：wangxiaofei
 */
const currentSheet = {
  /**
   * 获取当前操作的sheet实例id
   * @param {*} selector 选择器,格式为："#id",".class",this等
   */
  getCurrentSheetId(selector){
    const self = this;
    const current = self.getCurrentSheet(selector);
    return current ? current.attr('id') : '';
  },

  /**
   * 获取当前操作的sheet实例
   * @param {*} selector 选择器,格式为："#id",".class",this等
   */
  getCurrentSheet(selector){
    let current;
    let parentDom= $(selector).parents();
    if(parentDom && parentDom.length >= 3){ // 第三层为实例对象
      current = $(parentDom[parentDom.length - 3]);
    }
    return current;
  },
};
export default currentSheet;