export function showloading(txt) {
    let instance = this.instance;
    let containerId = instance.getInstanceId();
    let currentInstance = $(`#${containerId}`);
    currentInstance.find("#luckysheet-cell-loading").find("span").text(txt).end().show();
};

export function hideloading() {
    let instance = this.instance;
    let containerId = instance.getInstanceId();
    let currentInstance = $(`#${containerId}`);
    currentInstance.find("#luckysheet-cell-loading").hide();
};