const domResize = {
    interval: null,
    domSize: {},
    // 定时器监听luckysheet容器大小改变
    domResizeListener: function () {
        let _this = this;
        let instance = _this.instance;
        let Store = instance.getStore();
        let containerId = instance.getInstanceId();
        let currentInstance = $(`#${containerId}`);
        _this.domSize = {
            width: currentInstance.width(),
            height: currentInstance.height(),
        }
        _this.interval = setInterval(() => {
            let curDomSize = {
                width: currentInstance.width(),
                height: currentInstance.height(),
            };
            if (!_this.isEqul(_this.domSize, curDomSize)) {
                _this.domSize = JSON.parse(JSON.stringify(curDomSize));
                instance.resize.luckysheetsizeauto();
            }
        }, 200)
    },
    // 清除定时器
    removeDomResizeListener: function() {
        let _this = this;
        clearInterval(_this.interval);
    },
    isEqul: function (obj1, obj2) {
        let isEqul = true;
        for (var i in obj1) {
            if (obj1[i] !== obj2[i]) {
                isEqul = false;
            }
        }
        return isEqul;
    },
}
export default domResize;