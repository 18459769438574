import locale from "../locale/locale";
import { replaceHtml } from "../utils/util";
import { modelHTML } from "./constant";
import { createLuckyChart } from "../expendPlugins/chart/plugin";
import multiInstanceStore from "../store/multiInstance"; // 存储多实例Store wangxiaofei 1023.9.20

const luckysheetIc50Ec50 = {
  columeHeader_word_index: {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E',
    5: 'F',
    6: 'G',
    7: 'H',
    8: 'I',
    9: 'J',
    10: 'K',
    11: 'L',
    12: 'M',
    13: 'N',
    14: 'O',
    15: 'P',
    16: 'Q',
    17: 'R',
    18: 'S',
    19: 'T',
    20: 'U',
    21: 'V',
    22: 'W',
    23: 'X',
    24: 'Y',
    25: 'Z',
  },
  columeHeader_word: {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
    K: 10,
    L: 11,
    M: 12,
    N: 13,
    O: 14,
    P: 15,
    Q: 16,
    R: 17,
    S: 18,
    T: 19,
    U: 20,
    V: 21,
    W: 22,
    X: 23,
    Y: 24,
    Z: 25,
  },
  typeObj: {
    'ic50-logx-4': 3,
    'ic50-logx-3': 6,
    'ic50-logx-2': 4,
    'ic50-x-4': 1,
    'ic50-x-3': 5,
    'ic50-x-2': 2,
    'ec50-logx-4': 3,
    'ec50-logx-3': 6,
    'ec50-logx-2': 4,
    'ec50-x-4': 1,
    'ec50-x-3': 5,
    'ec50-x-2': 2,
  },
  ic50Ec50Data: {}, // 计算ic50/ec50请求参数
  ic50Data: {}, // 计算得到的ic50
  ec50Data: {}, // 计算得到的ec50
  getYStartChar: function (num, result) {
    let count = parseInt(num / 26)
    let remainder = num % 26
    result.push(this.columeHeader_word_index[remainder])
    if (count > 26) {
      this.getYStartChar(count - 1, result)
    } else {
      if (count > 0) {
        result.push(this.columeHeader_word_index[count - 1])
      }
    }
    return result
  },
  getRangeIndex: function (rangeSelectorValArr) {
    let startCharArr = rangeSelectorValArr[0].match(/[A-Z]/g)
    let colStart = 0
    startCharArr.forEach((item, i) => {
      if (i === startCharArr.length - 1) {
        colStart += this.columeHeader_word[item]
      } else {
        colStart += (this.columeHeader_word[item] + 1) * Math.pow(26, startCharArr.length - i - 1)
      }
    })
    let rowStart = Number(rangeSelectorValArr[0].match(/[1-9]\d*|0$/)[0])
    let endCharArr = rangeSelectorValArr[1].match(/[A-Z]/g)
    let colEnd = 0
    endCharArr.forEach((item, i) => {
      if (i === endCharArr.length - 1) {
        colEnd += this.columeHeader_word[item]
      } else {
        colEnd += (this.columeHeader_word[item] + 1) * Math.pow(26, endCharArr.length - i - 1)
      }
    })
    let rowEnd = Number(rangeSelectorValArr[1].match(/[1-9]\d*|0$/)[0])
    return {
      colStart: colStart,
      colEnd: colEnd,
      rowStart: rowStart,
      rowEnd: rowEnd,
    }
  },
  createDrawSettingDialog: function (itemValue, ic50ec50Data, chart, container) {
    const containerId = container || multiInstanceStore.getCurrentId();
	  let Store = multiInstanceStore.getInstance(containerId);
    $(`#luckysheet-modal-dialog-mask---${Store.container}`).show();
    $("#luckysheet-ic50-ec50-draw-setting-dialog---"+Store.container).remove();

    const _locale = instance.locale();
    const ic50ec50DrawSettingText = _locale.ic50ec50DrawSetting;

    let content = `<div class="chart-setting-wrap" style="max-height: 769px;">
        <div class="title-area">
          <div class="right-content">
            <div class="chart-title">
              <span class="title-label">图表标题</span>
              <input type="text" class="chart-title-input" placeholder="请输入图表标题">
            </div>
            <div class="mt10">
              <span class="title-label">X轴标题</span>
              <input type="text" class="x-title-input" placeholder="请输入X轴标题">
              <span class="title-label ml20">Y轴标题</span>
              <input type="text" class="y-title-input" placeholder="请输入Y轴标题">
              <div class="size-opacity-title-toggle"></div>
            </div>
            <div class="size-opacity-title-wrap mt10 hide">
              <span class="title-label">大小标题</span>
              <input type="text" class="size-title-input" placeholder="请输入大小维度标题">
              <span class="title-label ml20">透明度标题</span>
              <input type="text" class="opacity-title-input" placeholder="请输入透明度维度标题">
            </div>
          </div>
        </div>
        <div class="data-area mt20">
          <div class="right-content">
            <div class="data-group-wrap">
              <div class="data-group base-group">
                <div class="x-data-box">
                  <span class="data-label required">X轴</span>
                  <div class="range-input">
                    <input type="text" class="x-data-input" placeholder="请选择浓度作为X轴数据">
                    <i class="range-input-icon"></i>
                  </div>
                </div>
                <div class="y-data-box mt10">
                  <span class="data-label required">Y轴</span>
                  <div class="range-input">
                    <input type="text" class="y-data-input" placeholder="请选择反应率作为Y轴数据(0-100)">
                    <i class="range-input-icon"></i>
                  </div>
                  <div class="more-dimension-toggle" data-state="on">更多维度</div>
                </div>
                <div class="size-data-wrap mt10 hide">
                  <span class="data-label">大小</span>
                  <div class="range-input">
                    <input type="text" class="size-data-input" placeholder="">
                    <i class="range-input-icon"></i>
                  </div>
                </div>
                <div class="opacity-data-wrap mt10 hide">
                  <span class="data-label">透明度</span>
                  <div class="range-input">
                    <input type="text" class="opacity-data-input" placeholder="">
                    <i class="range-input-icon"></i>
                  </div>
                </div>
                <div class="group-info mt10 hide">
                  <span class="data-label">分组</span>
                  <input type="text" class="group-name-input" placeholder="请输入分组名称">
                </div>
              </div>
            </div>
            <div class="add-group-btn mt10">新增分组+</div>
          </div>
      
          <div class="mt20 hide">
            <div class="right-content">
              <span class="color-block"></span>
              <span class="change-color-btn">更改</span>
            </div>
          </div>
      
          <div class="mt20 hide">
            <div class="right-content">
              <div class="other-item">
                <input type="checkbox" id="axisCheckbox---${Store.container}"><span>坐标轴</span>
              </div>
              <div class="other-item ml20">
                <input type="checkbox" id="dataLabelCheckbox---${Store.container}"><span>数据标签</span>
              </div>
              <div class="other-item ml20">
                <input type="checkbox" id="gridLineCheckbox---${Store.container}"><span>网格线</span>
                <select id="gridLineSelect---${Store.container}">
                  <option value="all">全部</option>
                  <option value="horizontal">水平</option>
                  <option value="vertical">垂直</option>
                </select>
              </div>
              <div class="other-item ml20">
                <input type="checkbox"><span>分组标题</span>
                <select name="" id="">
                  <option value="">居右</option>
                  <option value="">居左</option>
                  <option value="">居上</option>
                  <option value="">居下</option>
                </select>
              </div>
            </div>
          </div>
        </div>`

    $("body").append(
      replaceHtml(modelHTML, {
        id: "luckysheet-ic50-ec50-draw-setting-dialog---"+Store.container,
        addclass: "luckysheet-ic50-ec50-draw-setting-dialog",
        title: ic50ec50DrawSettingText.drawSetting,
        content: content,
        botton:
          `<button id="luckysheet-ic50-ec50-draw-setting-dialog-close---${Store.container}" class="btn btn-default">${ic50ec50DrawSettingText.cancel}</button>
                    <button id="luckysheet-ic50-ec50-draw-setting-dialog-confirm---${Store.container}" class="btn btn-primary">${ic50ec50DrawSettingText.confirm}</button>`,
        style: "z-index:100003",
        "container": Store.container
      }),
    );
    let $t = $("#luckysheet-ic50-ec50-draw-setting-dialog---"+Store.container)
      .find(".luckysheet-modal-dialog-content")
      .css("min-width", 500)
      .end(),
      myh = $t.outerHeight(),
      myw = $t.outerWidth();
    let winw = $(window).width(),
      winh = $(window).height();
    let scrollLeft = $(document).scrollLeft(),
      scrollTop = $(document).scrollTop();
    $("#luckysheet-ic50-ec50-draw-setting-dialog---"+Store.container)
      .css({ left: (winw + scrollLeft - myw) / 2, top: (winh + scrollTop - myh) / 3 })
      .show();
    // 选择单元格范围
    let _this = this
    $(document).off('click.rangeInputIcon').on('click.rangeInputIcon', '.range-input-icon', function () {
      let initVal = $(this).parent().find('input').val()
      $("#luckysheet-ic50-ec50-draw-setting-dialog---"+Store.container).hide();
      instance.createHtmlDom.createRangeSelectorDialog()
      $('#luckysheet-range-selector-dialog---'+Store.container+' .dialog-range-selector-input .range-val').val(initVal)
      $('#luckysheet-range-selector-dialog---'+Store.container+' .dialog-range-selector-input .cancel-icon').click(() => {
        $(`#luckysheet-range-selector-dialog---${Store.container}`).hide()
        $(`#luckysheet-ic50-ec50-draw-setting-dialog---${Store.container}`).show()
      })
      let mulFlag = false
      $('#luckysheet-range-selector-dialog---'+Store.container+' .dialog-range-selector-input .ok-icon').click(() => {
        $(`#luckysheet-range-selector-dialog---${Store.container}`).hide()
        $(`#luckysheet-ic50-ec50-draw-setting-dialog---${Store.container}`).show()
        let rangeSelectorVal = $('#luckysheet-range-selector-dialog---'+Store.container+' .dialog-range-selector-input .range-val').val()
        $(this).parent().find('input').val(rangeSelectorVal)

        let rangeSelectorValArr = rangeSelectorVal.split(':')
        if (rangeSelectorValArr.length > 1) {
          let currentRange = _this.getRangeIndex(rangeSelectorValArr)
          if (currentRange.rowEnd - currentRange.rowStart >= 1 && currentRange.colEnd - currentRange.colStart >= 1) {
            mulFlag = true
            if ($(this).parent().parent().parent().find('.avg-type-wrap').length === 0) {
              let mulRowColHtml = `<div class="avg-type-wrap mt10">
                <span>请选择按行/按列分别计算平均值</span>
                <span>
                  <input type="radio" class="avg_type" id="avg_type_row_${new Date().getTime()}---${Store.container}" name="avg_type_${new Date().getTime()}" value="row" checked="">
                  <label for="avg_type_row_${new Date().getTime()}">按行</label>
                </span>
                <span>
                  <input type="radio" class="avg_type" id="avg_type_col_${new Date().getTime()}---${Store.container}" name="avg_type_${new Date().getTime()}" value="col">
                  <label for="avg_type_col_${new Date().getTime()}">按列</label>
                </span>
                <i class="icon tip ml10" title="对于多行多列的数据范围，图表上的数据点会取平均值显示，请选择均值按行或按列计算"></i>
              </div>`
              $(mulRowColHtml).appendTo($(this).parent().parent().parent())
            }
          }
        }
        if (!mulFlag) {
          let anotherInputClass = ''
          if ($(this).parent().parent().hasClass('x-data-box')) {
            anotherInputClass = 'y-data-box'
          } else if ($(this).parent().parent().hasClass('y-data-box')) {
            anotherInputClass = 'x-data-box'
          }
          let anotherInputVal = $(this).parent().parent().parent().find(`.${anotherInputClass} .range-input input`).val()
          let anotherInputValArr = anotherInputVal.split(':')
          if (anotherInputValArr.length > 1) {
            let anotherRange = _this.getRangeIndex(anotherInputValArr)
            if (!(anotherRange.rowEnd - anotherRange.rowStart >= 1 && anotherRange.colEnd - anotherRange.colStart >= 1)) {
              $(this).parent().parent().parent().find('.avg-type-wrap').remove()
            }
          } else {
            $(this).parent().parent().parent().find('.avg-type-wrap').remove()
          }
        }
      })
    })
    // 更多标题
    $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .size-opacity-title-toggle').click(() => {
      if ($('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .size-opacity-title-wrap').hasClass('hide')) {
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .size-opacity-title-wrap').removeClass('hide')
      } else {
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .size-opacity-title-wrap').addClass('hide')
      }
    })
    // 更多维度
    $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .more-dimension-toggle').click(() => {
      if ($('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .size-data-wrap').hasClass('hide')) {
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .size-data-wrap').removeClass('hide')
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .opacity-data-wrap').removeClass('hide')
      } else {
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .size-data-wrap').addClass('hide')
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .opacity-data-wrap').addClass('hide')
      }
    })
    // 新增分组
    $(document).off('click.addGroupBtn').on('click.addGroupBtn', '#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .add-group-btn', () => {
      let groupHtml = `<div class="data-group">
            <div class="group-divider">
              <div class="del-group" title="删除这个分组">x</div>
            </div>
            
          <div class="x-data-box" style="display: none;">
            <span class="data-label required">X轴</span>
            <div class="range-input">
              <input type="text" class="x-data-input" placeholder="请选择浓度作为X轴数据">
              <i class="range-input-icon"></i>
            </div>
          </div>
          <div class="y-data-box mt10">
            <span class="data-label required">Y轴</span>
            <div class="range-input">
              <input type="text" class="y-data-input" placeholder="请选择反应率作为Y轴数据(0-100)">
              <i class="range-input-icon"></i>
            </div>
            
          </div>
          <div class="size-data-wrap mt10">
            <span class="data-label">大小</span>
            <div class="range-input">
              <input type="text" class="size-data-input" placeholder="">
              <i class="range-input-icon"></i>
            </div>
          </div>
          <div class="opacity-data-wrap mt10">
            <span class="data-label">透明度</span>
            <div class="range-input">
              <input type="text" class="opacity-data-input" placeholder="">
              <i class="range-input-icon"></i>
            </div>
          </div>
          <div class="group-info mt10 hide" style="display: block;">
            <span class="data-label">分组</span>
            <input type="text" class="group-name-input" placeholder="请输入分组名称">
          </div>
        
          </div>
          </div>`
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .data-group-wrap .base-group .group-info').removeClass('hide')
      $(groupHtml).appendTo($('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .data-group-wrap'))
      if ($('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .data-group-wrap .base-group .size-data-wrap').hasClass('hide')) {
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .size-data-wrap').addClass('hide')
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .opacity-data-wrap').addClass('hide')
      } else {
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .size-data-wrap').removeClass('hide')
        $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .opacity-data-wrap').removeClass('hide')
      }
      // 删除分组
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .data-group-wrap .data-group .del-group').click(function () {
        $(this).parent().parent().remove()
      })
    })
    // 平均值单选框值切换
    $(document).off('change.avgTypeChange').on('change.avgTypeChange', '#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .data-group-wrap .data-group .avg-type-wrap .avg_type', function () {
      $(this).attr('checked', 'true')
      if ($(this).val() === 'row') {
        $(this).parent().parent().find('.avg_type[value="col"]').removeAttr('checked')
      } else if ($(this).val() === 'col') {
        $(this).parent().parent().find('.avg_type[value="row"]').removeAttr('checked')
      }
    })
    // 取消
    $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' #luckysheet-ic50-ec50-draw-setting-dialog-close---'+Store.container).click(() => {
      $(`#luckysheet-modal-dialog-mask---${Store.container}`).hide();
      $(`#luckysheet-ic50-ec50-draw-setting-dialog---${Store.container}`).hide()
    })
    // 确认
    $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' #luckysheet-ic50-ec50-draw-setting-dialog-confirm---'+Store.container).click(() => {
      $(`#luckysheet-modal-dialog-mask---${Store.container}`).hide();
      $(`#luckysheet-ic50-ec50-draw-setting-dialog---${Store.container}`).hide()
      let itemValueArr = itemValue.split('-')
      let pxDataArr = []
      let pyDataArr = []
      let xInputVal = $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .data-group-wrap .base-group .x-data-box .range-input .x-data-input').val()
      let yInputValArr = []
      let groupNameArr = []
      let sizeValArr = []
      let opacityValArr = []
      let sizeIputValArr = []
      let opacityInputValArr = []
      let avgTypeArr = []
      let chartTitle = $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .chart-title .chart-title-input').val()
      let xTitle = $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .x-title-input').val()
      let yTitle = $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .y-title-input').val()
      let sizeTitle = $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .size-opacity-title-wrap .size-title-input').val()
      let opacityTitle = $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .size-opacity-title-wrap .opacity-title-input').val()
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .data-group-wrap .data-group').each((i, dom) => {
        if ($(dom).hasClass('base-group')) {
          let xRange = $(dom).find('.x-data-box .range-input input').val()
          let pxValArr = luckysheet.getRangeArray('twoDimensional', { range: xRange })
          if ($(dom).find('.avg-type-wrap').length === 0) {
            let tempPxValArr = []
            pxValArr.forEach(item => {
              item.forEach(i => {
                tempPxValArr.push(i)
              })
            })
            if (itemValueArr[1] === 'x') {
              pxDataArr = tempPxValArr
            } else if (itemValueArr[1] === 'logx') {
              pxDataArr = tempPxValArr.map(item => Math.log10(item))
            }
          } else {
            let avgType = $(dom).find('.avg-type-wrap .avg_type[checked]').val()
            let tempPxValArr = []
            if (avgType === 'row') {
              pxValArr.forEach(item => {
                let sum = 0
                item.forEach(i => {
                  sum += i
                })
                tempPxValArr.push(sum / item.length)
              })
              if (itemValueArr[1] === 'x') {
                pxDataArr = tempPxValArr
              } else if (itemValueArr[1] === 'logx') {
                pxDataArr = tempPxValArr.map(item => Math.log10(item))
              }
            } else if (avgType === 'col') {
              for (let i = 0; i < pxValArr.length; i++) {
                let sum = 0
                for (let j = 0; j < pxValArr[i].length; j++) {
                  sum += pxValArr[j][i]
                }
                tempPxValArr.push(sum / pxValArr.length)
              }
              if (itemValueArr[1] === 'x') {
                pxDataArr = tempPxValArr
              } else if (itemValueArr[1] === 'logx') {
                pxDataArr = tempPxValArr.map(item => Math.log10(item))
              }
            }
          }
        }
        let yRange = $(dom).find('.y-data-box .range-input input').val()
        let pyValArr = luckysheet.getRangeArray('twoDimensional', { range: yRange })
        if ($(dom).find('.avg-type-wrap').length === 0) {
          let tempPyValArr = []
          pyValArr.forEach(item => {
            item.forEach(i => {
              tempPyValArr.push(i)
            })
          })
          pyDataArr.push(tempPyValArr)
          avgTypeArr.push('')
        } else {
          let avgType = $(dom).find('.avg-type-wrap .avg_type[checked]').val()
          let tempPyValArr = []
          if (avgType === 'row') {
            pyValArr.forEach(item => {
              let sum = 0
              item.forEach(i => {
                sum += i
              })
              tempPyValArr.push(sum / item.length)
            })
            pyDataArr.push(tempPyValArr)
          } else if (avgType === 'col') {
            for (let i = 0; i < pyValArr.length; i++) {
              let sum = 0
              for (let j = 0; j < pyValArr[i].length; j++) {
                sum += pyValArr[j][i]
              }
              tempPyValArr.push(sum / pyValArr.length)
            }
            pyDataArr.push(tempPyValArr)
          }
          avgTypeArr.push(avgType)
        }
        groupNameArr.push($(dom).find('.group-info .group-name-input').val())
        yInputValArr.push($(dom).find('.y-data-box .range-input .y-data-input').val())
        sizeIputValArr.push($(dom).find('.size-data-wrap .range-input .size-data-input').val())
        opacityInputValArr.push($(dom).find('.opacity-data-wrap .range-input .opacity-data-input').val())
      })
      this.ic50Ec50Data = {
        px: pxDataArr,
        py: pyDataArr,
        type: itemValue,
        groupNameArr: groupNameArr,
        sizeValArr: sizeValArr,
        opacityValArr: opacityValArr,
        chartTitle: chartTitle,
        xTitle: xTitle,
        yTitle: yTitle,
        sizeTitle: sizeTitle,
        opacityTitle: opacityTitle,
        xInputVal: xInputVal,
        yInputValArr: yInputValArr,
        sizeIputValArr: sizeIputValArr,
        opacityInputValArr: opacityInputValArr,
        avgTypeArr: avgTypeArr,
      }
      if (itemValueArr[0] === 'ic50') {
        this.ic50Data = []
        pyDataArr.forEach(d => {
          let data = {
            px: pxDataArr.join(','),
            py: d.join(','),
            type: this.typeObj[itemValue],
          }
          $.post({
            url: "http://chemicalservice.ineln.com/chemical/ic-fifty",
            data: JSON.stringify(data),
            success: (res) => {
              if (res.status == 1 && !Array.isArray(res.data)) {
                this.ic50Data.push(res.data)
              }
            }
          })
        })
      } else if (itemValueArr[0] === 'ec50') {
        this.ec50Data = []
        pyDataArr.forEach(d => {
          let data = {
            px: pxDataArr.join(','),
            py: d.join(','),
            type: this.typeObj[itemValue],
          }
          $.post({
            url: "http://chemicalservice.ineln.com/chemical/ic-fifty",
            data: JSON.stringify(data),
            success: (res) => {
              if (res.status == 1 && !Array.isArray(res.data)) {
                this.ec50Data.push(res.data)
              }
            }
          })
        })
      }
      if (!chart) {
        // 创建图表
        createLuckyChart('', '', '', '', 'ic50ec50', '', '')
      } else {
        createLuckyChart(chart.width, chart.height, chart.left, chart.top, 'ic50ec50', '', chart)
      }
    })
    if (!ic50ec50Data) { // 新建
      // luckysheet选区数据回填-x轴、y轴
      let xVal = ''
      let yVal = ''
      let yStart = ''
      let range = luckysheet.getRange()[luckysheet.getRange().length - 1]
      let rangeAxis = luckysheet.getRangeAxis()[luckysheet.getRangeAxis().length - 1]
      let colStartIndex = range.column[0]
      let colEndIndex = range.column[1]
      let rangeAxisArr = rangeAxis.split(':')
      if (rangeAxisArr.length > 1) {
        let startCharArr = rangeAxisArr[0].match(/[A-Z]/g)
        let startCharStr = startCharArr.join('')
        let startNum = Number(rangeAxisArr[0].match(/[1-9]\d*|0$/)[0])
        let endCharArr = rangeAxisArr[1].match(/[A-Z]/g)
        let endCharStr = endCharArr.join('')
        let endNum = Number(rangeAxisArr[1].match(/[1-9]\d*|0$/)[0])
        if (endNum === startNum) {
          xVal = rangeAxisArr[0]
        } else {
          xVal = rangeAxisArr[0] + ':' + startCharStr + endNum
        }
        if (colEndIndex - colStartIndex > 1) {
          let yStartColIndex = colStartIndex + 1
          yStart = this.getYStartChar(yStartColIndex, []).reverse().join('')
          yVal = yStart + startNum + ':' + rangeAxisArr[1]
        } else if (colEndIndex - colStartIndex === 0) {
          yVal = ''
        } else {
          if (endNum === startNum) {
            yVal = rangeAxisArr[1]
          } else {
            yVal = endCharStr + startNum + ':' + rangeAxisArr[1]
          }
        }
        // 多行多列-平均值
        if (endNum - startNum > 1 && colEndIndex - colStartIndex > 1) {
          let mulRowColHtml = `<div class="avg-type-wrap mt10">
          <span>请选择按行/按列分别计算平均值</span>
          <span>
            <input type="radio" class="avg_type" id="avg_type_row_${new Date().getTime()}---${Store.container}" name="avg_type_${new Date().getTime()}" value="row" checked="">
            <label for="avg_type_row_${new Date().getTime()}">按行</label>
          </span>
          <span>
            <input type="radio" class="avg_type" id="avg_type_col_${new Date().getTime()}---${Store.container}" name="avg_type_${new Date().getTime()}" value="col">
            <label for="avg_type_col_${new Date().getTime()}">按列</label>
          </span>
          <i class="icon tip ml10" title="对于多行多列的数据范围，图表上的数据点会取平均值显示，请选择均值按行或按列计算"></i>
        </div>`
          $(mulRowColHtml).appendTo($('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .data-group-wrap .data-group'))
        }
      } else {
        xVal = rangeAxisArr[0]
        yVal = ''
      }
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .data-group-wrap .base-group .x-data-box .range-input input').val(xVal)
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .data-group-wrap .base-group .y-data-box .range-input input').val(yVal)
    } else { // 编辑
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .chart-title .chart-title-input').val(ic50ec50Data.chartTitle)
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .x-title-input').val(ic50ec50Data.xTitle)
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .y-title-input').val(ic50ec50Data.yTitle)
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .size-opacity-title-wrap .size-title-input').val(ic50ec50Data.sizeTitle)
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .title-area .size-opacity-title-wrap .opacity-title-input').val(ic50ec50Data.opacityTitle)
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .data-group-wrap .base-group .x-data-box .range-input .x-data-input').val(ic50ec50Data.xInputVal)
      ic50ec50Data.yInputValArr.forEach((yItem, yIndex) => {
        if (yIndex !== 0) {
          $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .add-group-btn').trigger('click')
        }
      })
      $('#luckysheet-ic50-ec50-draw-setting-dialog---'+Store.container+' .luckysheet-modal-dialog-content .data-group-wrap .data-group').each((i, dom) => {
        $(dom).find('.y-data-box .range-input input').val(ic50ec50Data.yInputValArr[i])
        $(dom).find('.size-data-wrap .range-input .size-data-input').val(ic50ec50Data.sizeIputValArr[i])
        $(dom).find('.opacity-data-wrap .range-input .opacity-data-input').val(ic50ec50Data.opacityInputValArr[i])
        $(dom).find('.group-info .group-name-input').val(ic50ec50Data.groupNameArr[i])
        if (ic50ec50Data.avgTypeArr[i]) {
          let mulRowColHtml = `<div class="avg-type-wrap mt10">
            <span>请选择按行/按列分别计算平均值</span>
            <span>
              <input type="radio" class="avg_type" id="avg_type_row_${new Date().getTime()}_${i}---${Store.container}" name="avg_type_${new Date().getTime()}_${i}" value="row" checked="">
              <label for="avg_type_row_${new Date().getTime()}_${i}">按行</label>
            </span>
            <span>
              <input type="radio" class="avg_type" id="avg_type_col_${new Date().getTime()}_${i}---${Store.container}" name="avg_type_${new Date().getTime()}_${i}" value="col">
              <label for="avg_type_col_${new Date().getTime()}_${i}">按列</label>
            </span>
            <i class="icon tip ml10" title="对于多行多列的数据范围，图表上的数据点会取平均值显示，请选择均值按行或按列计算"></i>
          </div>`
          $(mulRowColHtml).appendTo($(dom))
          if (ic50ec50Data.avgTypeArr[i] === 'row') {
            $(dom).find(`.avg-type-wrap .avg_type[value="row"]`).attr('checked', 'true')
            $(dom).find(`.avg-type-wrap .avg_type[value="col"]`).removeAttr('checked')
          } else if (ic50ec50Data.avgTypeArr[i] === 'col') {
            $(dom).find(`.avg-type-wrap .avg_type[value="col"]`).attr('checked', 'true')
            $(dom).find(`.avg-type-wrap .avg_type[value="row"]`).removeAttr('checked')
          }
        }
      })
    }
  },
};
export default luckysheetIc50Ec50;