import en from './en'
import zh from './zh'
import es from './es'
import zh_tw from './zh_tw'

export const locales = {en,zh,es,zh_tw}

function locale(){
    let Store = this.getStore();
    return locales[Store.lang];
}

export default locale;