import { toolbarIdMap } from "../controllers/toolbar";

const luckysheetReadOrEdit = {
    switchReadOrEdit: function(isReadOnly) {
        const _this = this;
        const instance = _this.instance;
        const containerId = instance.getInstanceId();
	    let Store = instance.getStore();
        let currentInstance = $(`#${containerId}`);
        // 只读模式
        if (isReadOnly) {
            // 除查找、撤销、重做、批注、截图、排序筛选、数据透视表按钮，工具栏其他按钮隐藏
            Object.keys(toolbarIdMap).forEach((key, index) => {
                if (!(key === 'findAndReplace' || key === 'undo' || key === 'redo' || key === 'postil' || key === 'screenshot')) {
                    if (Array.isArray(toolbarIdMap[key])) {
                        toolbarIdMap[key].forEach(item => {
                            // currentInstance.find(item).hide();
                            currentInstance.find(item).addClass('disabled');
                        });
                    } else if (typeof toolbarIdMap[key] === 'string') {
                        // currentInstance.find(toolbarIdMap[key]).hide();
                        currentInstance.find(toolbarIdMap[key]).addClass('disabled');
                    }
                }
            })

            // 添加行按钮、输入框禁用
            currentInstance.find(`.luckysheet-bottom-controll-row .luckysheet-bottom-add-row`).attr('disabled', true);
            currentInstance.find(`.luckysheet-bottom-controll-row .luckysheet-bottom-add-row-input`).attr('disabled', true);
            // 在线表格标题输入框禁用
            currentInstance.find(`.luckysheet_info_detail_input`).attr('disabled', true);
            // 字体大小输入框
            currentInstance.find('.luckysheet-toolbar-combo-button-input').attr('disabled', true);
            // 筛选区域隐藏
            currentInstance.find('.luckysheet-filter-selected-sheet' + Store.currentSheetIndex + ', .luckysheet-filter-options-sheet' + Store.currentSheetIndex).hide();
            // sheet添加按钮添加禁用样式
            currentInstance.find(`.luckysheet-sheet-content .luckysheet-sheets-add`).addClass('disabled');
        } else { // 编辑模式
            // 除查找、撤销、重做、批注、截图、排序筛选、数据透视表按钮，其他按钮显示
            Object.keys(toolbarIdMap).forEach((key, index) => {
                if (!(key === 'findAndReplace' || key === 'undo' || key === 'redo' || key === 'postil' || key === 'screenshot')) {
                    if (Array.isArray(toolbarIdMap[key])) {
                        toolbarIdMap[key].forEach(item => {
                            // currentInstance.find(item).show();
                            currentInstance.find(item).removeClass('disabled');
                        });
                    } else if (typeof toolbarIdMap[key] === 'string') {
                        // currentInstance.find(toolbarIdMap[key]).show();
                        currentInstance.find(toolbarIdMap[key]).removeClass('disabled');
                    }
                }
            })

            // 添加行按钮、输入框解除禁用
            currentInstance.find(`.luckysheet-bottom-controll-row .luckysheet-bottom-add-row`).removeAttr('disabled');
            currentInstance.find(`.luckysheet-bottom-controll-row .luckysheet-bottom-add-row-input`).removeAttr('disabled');
            // 在线表格标题输入框解除禁用
            currentInstance.find(`.luckysheet_info_detail_input`).removeAttr('disabled');
            // 字体大小输入框
            currentInstance.find('.luckysheet-toolbar-combo-button-input').removeAttr('disabled');
            // 筛选区域显示
            currentInstance.find('.luckysheet-filter-selected-sheet' + Store.currentSheetIndex + ', .luckysheet-filter-options-sheet' + Store.currentSheetIndex).show();
            // sheet添加按钮取消禁用样式
            currentInstance.find(`.luckysheet-sheet-content .luckysheet-sheets-add`).removeClass('disabled');
        }
    },
};
export default luckysheetReadOrEdit;