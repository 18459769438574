import selection from '../controllers/selection';
import menuButton from '../controllers/menuButton';
import Store from '../store';
import currentSheet from "../controllers/common/currentSheet"; // 引入当前实例相关js
import multiInstanceStore from '../store/multiInstance';

export default function cleargridelement(event) {
    const instance = this.instance;
    const currentId = instance.getInstanceId();
    const currentInstance = $(`#${currentId}`);
    currentInstance.find("#luckysheet-cols-h-hover").hide();
    currentInstance.find("#luckysheet-rightclick-menu").hide();

    currentInstance.find(`#luckysheet-cell-selected-boxs .luckysheet-cell-selected`).hide();
    currentInstance.find(`#luckysheet-cols-h-selected .luckysheet-cols-h-selected`).hide();
    currentInstance.find(`#luckysheet-rows-h-selected .luckysheet-rows-h-selected`).hide();

    currentInstance.find("#luckysheet-cell-selected-focus").hide();
    currentInstance.find("#luckysheet-rows-h-hover").hide();
    currentInstance.find(`#luckysheet-selection-copy .luckysheet-selection-copy`).hide();
    currentInstance.find("#luckysheet-cols-menu-btn").hide();
    currentInstance.find(`#luckysheet-row-count-show, #luckysheet-column-count-show`).hide();
    if (!event) {
        instance.selection.clearcopy(event);
    }
    //else{
    //	selection.clearcopy();
    //}

    //选区下拉icon隐藏
    if(currentInstance.find("#luckysheet-dropCell-icon").is(":visible")){
        if(event){
            currentInstance.find("#luckysheet-dropCell-icon").remove();
        }
    }
    //格式刷
    if(instance.menuButton.luckysheetPaintModelOn && !event){
        instance.menuButton.cancelPaintModel();
    }
}