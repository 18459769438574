import { chart } from '../expendPlugins/chart/plugin'
import { print } from '../expendPlugins/print/plugin'
import { exportXlsx } from '../expendPlugins/exportXlsx/plugin'

const pluginsObj = {
    'chart': {
        name: 'chartPlugin',
        value: 'chart',
    },
    'print': {
        name: 'printPlugin',
        value: 'print',
    },
    'exportXlsx': {
        name: 'exportXlsxPlugin',
        value: 'exportXlsx'
    }
}

const isDemo = true

/**
 * Register plugins
 * 
 * plugins:[
 * {name:'chart'},
 * {name:'print'},
 * {name:'exportXlsx',config:{url:''}}
 * ]
 */
function initPlugins(plugins , options){
    const _this = this;
    const instance = _this.instance;
    if(plugins.length){
        plugins.forEach(plugin => {
            instance[pluginsObj[plugin.name].name][pluginsObj[plugin.name].value](options, plugin.config, isDemo)
        });
    }
}

export {
    initPlugins
}